import React from 'react';
import Utils                from '../utils/Utils';
import SimpleDialog          from './SimpleDialog';
// import i18n                 from '../../src/i18n';
// import translate from 'react-admin';

export default function MuiDialogWithControl(WrappedComponent) {

	return class extends React.Component {

		constructor(props) {
			super(props);
			this.afterClose = this.afterClose.bind(this);
			this.alert = this.alert.bind(this);
			this.confirm = this.confirm.bind(this);
			this.state = {
				open: true,
				dialogs: []
			};
		}

		/**
		 * remove dialog from dialogs
		 * @param props
		 */
		afterClose(props) {
			let { dialogs } = this.state;
			let newDialogs = [];

			dialogs.forEach(function(d) {
				if(d.props._id !== props._id) {
					newDialogs.push(d);
				}
			});

			this.setState({
				dialogs: newDialogs
			});
		}

		getDefaults() {
			return {
				_id: Utils._id(),
				open: true,
				actions: true,
				afterClose: this.afterClose
			};
		}

		show(options) {
			// if(typeof console === 'object') { console.log('MuiModal.alert',options); }
			let { dialogs } = this.state;

			let defaults = this.getDefaults();

			options = Object.assign(defaults,options);

			if(!options.key) {
				options.key = options._id;
			}

			// if(typeof console === 'object') { console.log('SHOW options',options); }

			dialogs.push(<SimpleDialog {...options} />);

			this.setState({
				dialogs: dialogs
			});
		}

		alert(options) {
			// if(typeof console === 'object') { console.log('MuiModal.alert',options); }
			let { dialogs } = this.state;


			let defaults = this.getDefaults();

			options = Object.assign(defaults,options);

			if(!options.key) {
				options.key = options._id;
			}

			dialogs.push(<SimpleDialog {...options} />);

			this.setState({
				dialogs: dialogs
			});
		}

		confirm(options) {
			// if(typeof console === 'object') { console.log('MuiModal.alert',options); }
			let { dialogs } = this.state;


			let defaults = this.getDefaults();

			options = Object.assign(defaults,options);

			if(!options.key) {
				options.key = options._id;
			}

			options.actions = [
				{
					text: 'Cancel',//i18n.t('buttons.cancel'),
					close: true
				},
				{
					text: 'OK',//i18n.t('buttons.ok'),
					close: true,
					autoFocus: true,
					variant:'contained',
					callback: options.callback
				},
			];

			dialogs.push(<SimpleDialog {...options} />);

			this.setState({
				dialogs: dialogs
			});
		}

		render() {

			// if(typeof console === 'object') { console.log('MuiDialogWithControl.render',this.props,this.state); }

			// ... and renders the wrapped component with the fresh data!
			// Notice that we pass through any additional props
			return <WrappedComponent {...this.state} {...this.props} />;
		}
	};
}

