// @link https://lodash.com/docs/4.17.5
import _ from 'lodash';
// @link https://github.com/puleos/object-hash
import { MD5, sha1 } from 'object-hash';
import equal from 'fast-deep-equal/es6';

class ObjectUtils {

	static init() {

	}

	static sha1(p) {
		return sha1(p);
	}

	static md5(p) {
		return MD5(p);
	}

	static isArray(a) {
		return _.isArray(a);
	}

	static inArray(a,v) {
		return _.indexOf(a, v) >= 0 ? true : false;
	}

	static isObject(o) {
		return _.isObject(o);
	}

	/**
	 * This is mainly for arrow functions to get all arguments with (...args) => {}
	 * and name than with:
	 * objectify(['action','options','callback'],args)
	 * @param objectNames
	 * @param args
	 */
	static objectify(objectNames,defaults,args) {
		let o = {};
		objectNames.forEach((n,i) => {
			if((!args[i]) && (typeof defaults === 'object' && defaults[i])) {
				o[n] = defaults[i];
			} else {
				o[n] = args[i];
			}
		});

		return o;
	}

	/**
	 * get a value of collection
	 * @param a
	 * @param path
	 */
	static get(a,path) {
		return _.get(a,path);
	};

	static set(a,path) {
		return _.set(a,path);
	};

	static reomveIndexFromArray(a,idx) {
		a.splice(idx, 1);
		return a;
	};

	static indexOf(a,v) {
		return _.indexOf(a,v);
	};

	static getRecordsIDs(o) {
		let values = [];
		o.forEach((r) => {
			values.push(r.id);
		});

		return values;
	}

	static findHighestValue(a,b) {
		return _.maxBy(a, function(o) {
			return o[b];
		});
	}

	static clone(a) {
		return _.clone(a);
	}

	static cloneDeep(a) {
		return _.cloneDeep(a); //_.cloneDeep(objects);
	}

	static flatToArray(o,type='merge') {
		let b = [];
		for(let i in o) {
			if(type === 'merge') {
				b = this.merge(b,o[i]);
			} else {
				b = _.concat(b, o[i]);
			}
		}
		return b;
	}

	static merge(a,b) {
		return _.merge(a,b);
	}


	static forIn(a,b) {
		return _.forIn(a,b);
	}

	/**
	 * find ALL items
	 * @param a
	 * @param fn
	 * @returns {*}
	 */
	static filter(a,fn) {
		return _.filter(a, fn);
	}

	static findKey(a,fn) {
		return _.findKey(a,fn);
	};

	/**
	 * find FIRST item
	 * @param a
	 * @param fn
	 */
	static find(a,fn) {
		return _.find(a, fn);
	}

	static findRecord(a,o) {
		let recordIndex = _.findIndex(a, o);
		if(recordIndex >= 0) {
			return a[recordIndex];
		}
		return null;
	}

	static remove(a,fn) {
		return _.remove(a, fn);
	}

	static findIndex(a,o) {
		return _.findIndex(a,o);
	}

	static isEqual(a,b) {
		// return a === b;
		return _.isEqual(a, b);
	}


	static fastDeepEqual(a,b) {
		return equal(a,b);
	}

	/**
	 * extended equal to find objects in an array,
	 * even if their position inside the array has changed
	 *
	 * @param a
	 * @param b
	 * @returns {boolean}
	 */
	static isEqualExtended(a,b) {

		let isEqual = true;

		let firstTest = _.isEqual(a, b);
		// if(typeof console === 'object') { console.log('firstTest,a, b',firstTest,a, b); }
		if(firstTest) {
			return firstTest;
		}

		if(!a) {
			a = [];
		}
		if(!b) {
			b = [];
		}

		if(a.length !== b.length) {
			return false;
		}

		let aidx = a.length;
		for(let i = 0; i<aidx;i++) {
			let ar = a[i];
			let fidx = _.findIndex(b, ar);
			if(typeof console === 'object') { console.log('try to find %o in %o ? %n',ar,b,fidx); }
			if(fidx === -1) {
				isEqual = false;
			} else if(fidx >=0 && (!_.isEqual(ar, b[fidx]))) {
				isEqual = false;
			}
		}

		return isEqual;
	}

	static __rest(s, e) {
		let t = {};
		for (let p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
			t[p] = s[p];
		if (s != null && typeof Object.getOwnPropertySymbols === "function")
			for (let i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
				t[p[i]] = s[p[i]];

		// if(typeof console === 'object') { console.log('__rest',t); }
		return t;
	}

	static __assign(obj,args) {
		let __assign = Object.assign || function(t) {
			for (let s, i = 1, n = args.length; i < n; i++) {
				s = args[i];
				for (let p in s) if (Object.prototype.hasOwnProperty.call(s, p))
					t[p] = s[p];
			}
			return t;
		};
		return __assign.apply(obj, args);
	}

	static __extends() {
		var extendStatics = function (d, b) {
			extendStatics = Object.setPrototypeOf ||
			                ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
			                function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
			return extendStatics(d, b);
		};
		return function (d, b) {
			extendStatics(d, b);
			// if(typeof console === 'object') { console.log('__extends',d,b); }

			function __() { this.constructor = d; }
			d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
		};
	}

};

ObjectUtils.init();

export default ObjectUtils;
