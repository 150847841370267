import React               from 'react';
import PropTypes           from 'prop-types';
import get                 from 'lodash/get';
import {
	BooleanField,
	DateField,
	EmailField,
	NumberField,
	TextField,
	UrlField,
	// FunctionField,
	// ChipField,
	// ReferenceField,
	// ReferenceArrayField,
	// SingleFieldList,
}                          from 'react-admin';
import DomUtils from "../../../../utils/DomUtils";
// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';

const MVTListField = (props) => {

	const { source, record } = props;

	let {
		value,
		className,
		cellRenderer,
		column,
		columnIndex,
		rowIndex,
		schemaId,
		schemaRange,
		breakpointDown,
		breakpointUp,
		conf,
		cellProps,
		cellAndHeaderClassName,
		...rest
	} = props;


	if(!value) {
		value = get(record, source);
		// if(value && typeof value !== 'string') {
		if(value && typeof value === 'object') {
			value = JSON.stringify(value);
		}
	}


	// if(typeof console === 'object') { console.log('cellAndHeaderClassName',source,cellAndHeaderClassName); }
	// if(source === 'progress')
	// 	if(typeof console === 'object') { console.log('MVTListField.props %o of %o with value %o',props,source,value,typeof value, rest); }

	if(typeof cellRenderer === 'function') {
		return cellRenderer(value,record,{column:column,columnIndex:columnIndex,rowIndex:rowIndex});
	}


	switch (schemaId) {
		case 'http://schema.org/email':
			return (
				<EmailField
					// key={props.source}
					{...rest}
				/>
			);
		case 'http://schema.org/url':
			return (
				<UrlField
					// key={props.source}
					{...rest}
				/>
			);

		case 'http://schema.org/external-url':
			return (
				<UrlField
					// key={props.source}
					{...rest}
					onClick={(e) => {
						let tag = DomUtils.findUpTag(e.currentTarget,'A');
						if(tag && typeof tag === 'object') {
							let href = DomUtils.getAttribute(tag,'href');
							if(href) {
								window.open(href);
								e.preventDefault();
								e.stopPropagation();
							}
						}
					}}
				/>
			);

		default:
		// Do nothing
	}

	switch (schemaRange) {
		case 'http://www.w3.org/2001/XMLSchema#integer':
		case 'http://www.w3.org/2001/XMLSchema#float':
			return (
				<NumberField
					// key={props.source}
					{...rest}
				/>
			);

		case 'http://www.w3.org/2001/XMLSchema#date':
		case 'http://www.w3.org/2001/XMLSchema#dateTime':
			return (
				<DateField
					// key={props.source}
					// source={props.source}
					{...rest}
				/>
			);

		case 'http://www.w3.org/2001/XMLSchema#boolean':
			return (
				<BooleanField
					// key={props.source}
					{...rest}
				/>
			);

		default:
			return (
				<TextField
					// key={props.source}
					{...rest}
				/>
			);
	}

	// return (
	// 	<span className={className}>
	// 		{ value }
	// 	</span>
	// );
};

MVTListField.defaultProps = {

};

MVTListField.propTypes = {
	className: PropTypes.object,
	record: PropTypes.object,
	source: PropTypes.string,
	// translate: PropTypes.func
};

MVTListField.displayName = 'MVTListField';
export default MVTListField;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
