import React, {Component, createElement, useEffect, useState} from 'react';
import PropTypes                                                from 'prop-types';
import { connect }                                              from 'react-redux';
import classnames                                               from 'classnames';
import { withRouter }                                           from 'react-router';
import {
	// MuiThemeProvider,
	// createMuiTheme,
	withStyles,
	createStyles,
}                                                               from '@material-ui/core/styles';
import compose                                                  from 'recompose/compose';
import MuiTheme                                       from './MuiTheme';
import AppBar                                         from './AppBar';
import Sidebar                                        from './Sidebar';
import Menu                                           from './Menu';
import Notification                                   from './Notification';
import {
	Error, GET_LIST,
	GET_ONE, useMutation,
	// GET_LIST,
	useQuery,
	// useMutation,
	// useQueryWithStore
	// Notification,
	// Menu,
	// AppBar,
	// Sidebar,
	// setSidebarVisibility
} from 'react-admin';
// REDUCERS / APIs
import AppStateApi               from '../../common/api/AppStateApi';
import { useLocale } from 'react-admin';
import { useDispatch } from 'react-redux';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import Authprovider from '../admin-store/authProvider.js';
// eslint-disable-next-line no-unused-vars
import de from "moment/locale/de";
// eslint-disable-next-line no-unused-vars
import en from "moment/locale/en-gb";

// import AppDataApi                from '../../common/api/AppDataApi';
// import { fetchAppStateIfNeeded } from '../../common/store/actions/actions';
// import LocalApi from './api/LocalApi';
// import Error from './Error';
// import defaultTheme from '../defaultTheme';

// MUI PICKERS
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';

// DO NOT UNCOMMENT ModalComponents - WE NEED THEM!
// eslint-disable-next-line no-unused-vars
import ModalComponents           from './ModalComponents';
import LoadingIndicator          from '../../common/components/common/LoadingIndicator';
import {BreadcrumbProvider} from "../components/app_title_bar/BreadCrumb";
import ObjectUtils from "../../common/utils/ObjectUtils";

// const localeMap = {
// 	en: enLocale,
// 	de: deLocale
// };

// const localeMap = {
// 	en: "en",
// 	de: "de",
// };

const styles = theme =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			zIndex: 1,
			minHeight: '100vh',
			backgroundColor: theme.palette.backgroundColors.content,
			position: 'relative',
			minWidth: 'fit-content',
			width: '100%',
		},
		appFrame: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			flexGrow: 1
		},
		contentWithSidebar: {
			display: 'flex',
			flexGrow: 1,
		},
		layout: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			flexBasis: 0,
			padding: 0,
			overflow: 'hidden',
			// marginLeft: '72px',
			[theme.breakpoints.up('xs')]: {
				paddingLeft: 0,
			},
			[theme.breakpoints.down('sm')]: {
				padding: 0,

			},
			[theme.breakpoints.only('xs')]: {
				marginLeft: '0',
			},
		},
	});

const sanitizeRestProps = ({
	                           staticContext,
	                           history,
	                           location,
	                           match,
	                           ...props
                           }) => props;

class Layout extends Component {
	state = { hasError: false, errorMessage: null, errorInfo: null };

	constructor(props) {
		super(props);
		/**
		 * Reset the error state upon navigation
		 *
		 * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
		 */
		props.history.listen(() => {
			if (this.state.hasError) {
				this.setState({ hasError: false });
			}
		});

		this.initialized = false;
		// if(typeof console === 'object') { console.log('Layout',props); }
	}

	componentDidMount() {
		this.fetchData();
	}

	UNSAFE_componentWillMount() {
		// this.props.setSidebarVisibility(true);
		this.props.dispatch({type: 'SET_SIDEBAR_VISIBILITY'});
		this.connectAPIs();
	}

	componentDidCatch(errorMessage, errorInfo) {
		this.setState({ hasError: true, errorMessage, errorInfo });
	}

	connectAPIs() {
		// connect dispatch to APIs
		if(!this.initialized) {
			const { dispatch } = this.props;
			AppStateApi.setDispatch(dispatch);
			// AppDataApi.setDispatch(dispatch);
			// LocalApi.setStore(store);
			this.initialized = true;
		}
	}

	fetchData() {
		const { dispatch } = this.props;
		// dispatch(fetchAppDataIfNeeded());
		// dispatch(fetchAppStateIfNeeded());
		dispatch({type: 'FETCH_APPSTATE_IF_NEEDED', payload: {}});
	}

	render() {
		const {
			appBar,
			children,
			classes,
			className,
			customRoutes,
			error,
			dashboard,
			logout,
			menu,
			notification,
			open,
			sidebar,
			title,
			dispatch,
			setSidebarVisibility,
			isFetchingAppState,
			versions,
			...props
		} = this.props;
		const { hasError, errorMessage, errorInfo } = this.state;

		if(1===2 && typeof console === 'object') {
			console.log('LAYOUT this.props title %o isFetchingAppState %o and props %o',title,isFetchingAppState,this.props);
		}

		if (typeof isFetchingAppState === 'undefined' || true === isFetchingAppState) {
			// if(typeof console === 'object') { console.log('LoadingIndicator.Layout',true); }
			return <LoadingIndicator type="container" />;
		}
		// if(typeof console === 'object') { console.log('Render.Layout',true); }

		return (
			<div
				className={classnames('layout', classes.root, className)}
				{...sanitizeRestProps(props)}
			>
				<div className={classes.appFrame}>
					{createElement(appBar, { title, logout, versions })}
					<main className={classes.contentWithSidebar} id="app-sidebar">
						{createElement(sidebar, {
							children: createElement(menu, {
								logout,
								versions,
								hasDashboard: !!dashboard,
							}),
						})}
						<div className={classes.layout}>
							<React.Suspense fallback={<LoadingIndicator type="container" />}>
							{hasError
							 ? createElement(error, {
									error: errorMessage,
									errorInfo,
									title,
								})
							 : children}
							</React.Suspense>
						</div>
					</main>
					{createElement(notification)}
				</div>
			</div>
		);
	}
}

const componentPropType = PropTypes.oneOfType([
	// PropTypes.element,
	PropTypes.func,
	PropTypes.string,
	PropTypes.object
]);

Layout.propTypes = {
	appBar: componentPropType,
	// appBar: PropTypes.oneOfType([
	// 	componentPropType,
	// 	PropTypes.node,
	// 	PropTypes.func,
	// 	PropTypes.string,
	// ]),
	// appBar: PropTypes.any,
	// setSidebarVisibility: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
	classes: PropTypes.object,
	className: PropTypes.string,
	customRoutes: PropTypes.array,
	dashboard: componentPropType,
	error: componentPropType,
	history: PropTypes.object.isRequired,
	logout: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.func,
		PropTypes.string,
	]),
	menu: componentPropType,
	notification: componentPropType,
	// open: PropTypes.bool,
	sidebar: componentPropType,
	// title: PropTypes.node.isRequired,
	title: PropTypes.any,
};

Layout.defaultProps = {
	appBar: AppBar,
	error: Error,
	menu: Menu,
	notification: Notification,
	sidebar: Sidebar,
};

// function mapStateToProps(state) {
// 	const { appstateByPHPOS } = state;
//
// 	const {
// 		isFetchingAppState,
// 		appstate
// 	} = (typeof appstateByPHPOS.appstate !== 'undefined') ? appstateByPHPOS : {
// 		isFetchingAppState: true,
// 		appstate          : {}
// 	};
//
// 	return {
// 		// appdata,
// 		appstate,
// 		// isFetchingAppData,
// 		isFetchingAppState,
// 		// lastUpdated
// 	};
// }

// export default connect(mapStateToProps,{
// 	fetchAppState: fetchAppStateIfNeeded,
// })(HydraAdmin);


const mapStateToProps = (state) => {
	// if(typeof console === 'object') { console.log('LAYOUT.mapStateToProps state',state); }
	return ({
		isFetchingAppState: (state.appstateByPHPOS) ? state.appstateByPHPOS.isFetchingAppState : true,
		// appState: (state.appstateByPHPOS) ? state.appstateByPHPOS.appState : null
	})
};

const EnhancedLayout = compose(
	connect(
		mapStateToProps,
		null,// { setSidebarVisibility } // Avoid connect passing dispatch in props
	),
	withRouter,
	withStyles(styles)
)(Layout);

// class LayoutWithTheme_class extends Component {
//
//
// 	render() {
// 		const { ...rest } = this.props;
// 		if(typeof console === 'object') { console.log('this.props',this.props); }
// 		return (
// 			<MuiTheme>
// 				<MuiPickersUtilsProvider utils={DateFnsUtils}>
// 					<EnhancedLayout {...rest} />
// 				</MuiPickersUtilsProvider>
// 			</MuiTheme>
// 		);
// 	}
// }


function LayoutWithTheme(props) {
	const { ...rest } = props;
	const locale = useLocale();
	const dispatch = useDispatch();
	const [versions, setVersions] = useState(null);

	const { data, loading, error } = useQuery({
		type: GET_ONE,
		resource: 'users',
		payload: { id: '/api/users/me' }
	});

	useEffect(() => {
		// loadVersions();
		getVersions();
	},[]);

	let [ mutate ] = useMutation();
	const getVersions = () => mutate(
		{
			type: GET_LIST,
			resource: 'highject_versions',
			payload: {
				pagination: {
					page: 1,
					perPage: 100
				},
				sort: {
					// field: sortField, order: 'ASC'
				},
			},
		},
		{
			// action: 'CUSTOM_FETCH_LOAD',
			onSuccess: ({ data }) => {

				let versions = {
					frontEnd: null,
					backEnd: null
				};

				if(data) {
					let frontend = ObjectUtils.findRecord(data,{id: '/api/highject_versions/VERSION_FRONTEND'});
					if(frontend) {
						versions.frontEnd = frontend.label;
					}
					let backend = ObjectUtils.findRecord(data,{id: '/api/highject_versions/VERSION_BACKEND'});
					if(backend) {
						versions.backEnd = backend.label;
					}
				}

				setVersions(versions);
			}
		}
	);

	// const [loadUser, { loading, data }]= useMutation(
	// 	{
	// 		type: GET_ONE,
	// 		resource: 'users',
	// 		payload: {
	// 			id: '/api/users/me',
	// 		},
	// 	},
	// 	{
	// 		onSuccess: ({ data }) => {
	// 			dispatch({type: 'RECEIVE_USER_DATA', data:data });
	// 			return data;
	//
	// 		},
	// 		onFailure: (error) => {
	// 			if(typeof console === 'object') { console.log('USERDATA ERROR',error); }
	// 		},
	// 	}
	// );
	//
	//
	// useEffect(() => {
	// 	loadUser();
	// 	if(typeof console === 'object') { console.log('GET USER',props,data); }
	// },[locale]);

	// if(typeof console === 'object') { console.log('LayoutWithTheme.locale',locale); }
	// let t = moment.locale(locale); // it is required to select default locale manually

	React.useMemo(
		() =>
		{
			moment.locale(locale);
			moment.updateLocale('en', {
				longDateFormat : {
					LT : 'HH:mm',
					LTS : 'HH:mm:ss',
					L : 'DD.MM.YYYY',
					LL : 'D MMMM YYYY',
					LLL : 'D MMMM YYYY HH:mm',
					LLLL : 'dddd, D MMMM YYYY HH:mm'
				},
				week : {
					dow : 1, // Monday is the first day of the week.
					doy : 4  // The week that contains Jan 4th is the first week of the year.
				}
			});
			// if(typeof console === 'object') { console.log('MOMENT',locale,moment('2019-11-09T00:00:00+01:00').format('ddd. DD. MMMM, HH:mm')); }
		},
		[locale],
	);
	// if(typeof console === 'object') { console.log('MOMENTTTTTT',locale,moment().format('ddd. DD. MMMM, HH:mm')); }

	if(error) {
		if(typeof console === 'object') { console.log('USERDATA ERROR',error); }
	}

	if (loading) {
		// if(typeof console === 'object') { console.log('LoadingIndicator.LayoutWithTheme',true); }
		return <LoadingIndicator type="container" />;
	}

	if (!versions) {
		// if(typeof console === 'object') { console.log('LoadingIndicator.LayoutWithTheme',true); }
		return <LoadingIndicator type="container" />;
	}

	// if(typeof console === 'object') { console.log('Render.LayoutWithTheme',true); }

	if(data) {
		dispatch({type: 'RECEIVE_USER_DATA', data:data });
		// if(typeof console === 'object') { console.log('/ME',data); }
		Authprovider.setPermissions(data.roles);
		Authprovider.runningUser = data;
		// Authprovider.isDeveloper();
	}

	// if(typeof console === 'object') { console.log('GET USER',props,data); }

	return (
		<MuiTheme>
			<MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
				<BreadcrumbProvider>
					<EnhancedLayout {...rest} versions={versions} />
				</BreadcrumbProvider>
			</MuiPickersUtilsProvider>
		</MuiTheme>
	);
};


LayoutWithTheme.propTypes = {
	// theme: PropTypes.object,
};

LayoutWithTheme.defaultProps = {
	// theme: defaultTheme,
};

export default LayoutWithTheme;
