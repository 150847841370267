import React, { PureComponent, Component }             from 'react';
import PropTypes                                       from 'prop-types';
import MenuItem                                        from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import { withStyles }                                  from '@material-ui/core/styles';
import { FontAwesomeIcon }                             from '@fortawesome/react-fontawesome';
import { faCoffee, faSpinner, faSync, faSave, faFlag } from '@fortawesome/free-solid-svg-icons';
import { library }                                     from '@fortawesome/fontawesome-svg-core';
import ListItemIcon                                    from '@material-ui/core/ListItemIcon';
import ListItemText                                    from '@material-ui/core/ListItemText';

const styles = theme => ({
	button: {
		margin: theme.spacing(1),
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	leftIconSpinner: {
		marginRight: (theme.spacing(1) - 2),
	},
	iconSmall: {
		fontSize: 20,
	},
});


class MuiMenuItem extends PureComponent {

	constructor( props ) {
		super( props );
		this.state = {

		};
	}

	getTitle() {
		let { classes, text, icon, spin } = this.props;

		let i = null;

		if(spin) {
			i = <FontAwesomeIcon icon={faSpinner} pulse className={classNames( classes.leftIconSpinner, classes.iconSmall )} />
		} else if(icon) {
			i = <FontAwesomeIcon className={classNames( classes.leftIcon, classes.iconSmall )} icon={icon} />;
		}

		let r = <React.Fragment>
			{i &&<ListItemIcon>
				{i}
			</ListItemIcon>}
			<ListItemText primary={text} />
		</React.Fragment>

		return r;

	}

	render() {
		const { onClick, className, disabled, selected } = this.props;

		let cl = 'mui__menuitem';
		if(className) {
			cl += ' ' + className;
		}

		return (
			<MenuItem
				onClick={onClick}
				className={cl}
				disabled={disabled}
				selected={selected}
			>
				{this.getTitle()}
			</MenuItem>
		);
	}
}

MuiMenuItem.defaultProps = {
	iconClass: 'x-fa fa-link',
}

MuiMenuItem.propTypes = {
	classes: PropTypes.object,
	onClick: PropTypes.func,
	spin    : PropTypes.bool,
	disabled: PropTypes.bool,
	selected: PropTypes.bool,
	className : PropTypes.string,
	text : PropTypes.string,
};

export default withStyles(styles)(MuiMenuItem);
