import React                          from 'react';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { connect }                                    from 'react-redux';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import blueGrey from '@material-ui/core/colors/blueGrey';
import lightBlue from '@material-ui/core/colors/lightBlue';
import indigo from '@material-ui/core/colors/indigo';

// Warning! Id you want to use React.lazy Loading of routes or components,
// keep an eye of loading your styles in the correct order
import '../App.scss';
import "../fonts/fontello/css/fontello.css";
import '../scenes/Calendar/main.scss';

// import blue from '@material-ui/core/colors/blue';
// import deepPurple from '@material-ui/core/colors/deepPurple';
// import purple from '@material-ui/core/colors/purple';
// import indigo from '@material-ui/core/colors/indigo';
// import pink from '@material-ui/core/colors/pink';


var hochkant = Object.assign(lightBlue,{
	200: '#19a8e0',
	400: '#026799',
	500: '#014466',
	600: '#01334d',
	700: '#012233',
	800: '#001a26',
	900: '#00111a'
});

// {
// 	50: '#e8eaf6',
// 	100: '#c5cae9',
// 	200: '#9fa8da',
// 	300: '#7986cb',
// 	400: '#5c6bc0',
// 	500: '#3f51b5',
// 	600: '#3949ab',
// 	700: '#303f9f',
// 	800: '#283593',
// 	900: '#1a237e',
// 	A100: '#8c9eff',
// 	A200: '#536dfe',
// 	A400: '#3d5afe',
// 	A700: '#304ffe'
// };

// Hochkant: #00b4ff
// Logo : #26ace3, Newsletter Logo: #19a8e0,
//
// 019df0: b50: 015580, b60: 026799,
// b40: 014466


// https://material-ui.com/customization/overrides/#3-specific-variation-of-a-component
// ColorTool @link https://material.io/tools/color/#!/?view.left=0&view.right=0
// https://material.io/design/color/#
// https://react-theming.github.io/create-mui-theme/


//
// const themedContentStyles = {
// 	fullscreen: {
// 		width: '100%',
// 		height: '100%'
// 	},
// 	scroll__y_touch: {
// 		overflowY: 'scroll',
// 		// '-webkit-overflow-scrolling': 'touch'
// 	},
// 	contentRoot: {
// 		display                           : 'flex',
// 		flexDirection                     : 'column',
// 		flexGrow                          : 1,
// 		flexBasis                         : 0,
// 		padding                           : theme.spacing( 3 ),
// 		[ theme.breakpoints.up( 'xs' ) ]  : {
// 			paddingLeft: 5,
// 		},
// 		[ theme.breakpoints.down( 'sm' ) ]: {
// 			padding: 0,
// 		},
// 	},
// 	resetIconFix: {
// 		padding: 0
// 	}
// };

// theme.custom.content = themedContentStyles;

function MuiTheme(props) {

	// if(typeof console === 'object') { console.log('props',props,blueGrey,Object.assign(blueGrey,{700: '#31363b',900: '#242a2e'})); }

	let n_blueGrey = Object.assign(blueGrey,{600:'#464d54',650:'#3b4147',700: '#31363b',900: '#242a2e'});

	let themeOptions = props.appstate && props.appstate.themeOptions ? props.appstate.themeOptions : {
		type: 'dark',
		colors: {
			primary: hochkant,
			secondary: n_blueGrey,
			error: red,
			textContrast: indigo[50],
			grey: grey,
			foreground: grey,
			background: n_blueGrey
		}
	};


	const theme = React.useMemo(
		() =>
		{
			return createMuiTheme({
				typography: {
					title: {
						fontWeight: 400,
					},
				},
				palette: {
					type: 'dark',
					// primary: deepPurple,
					primary: themeOptions.colors.primary,
					secondary: themeOptions.colors.secondary,
					error: themeOptions.colors.error,
					// Used by `getContrastText()` to maximize the contrast between the background and
					// the text.
					contrastThreshold: 3,
					// Used to shift a color's luminance by approximately
					// two indexes within its tonal palette.
					// E.g., shift from Red 500 to Red 300 or Red 700.
					tonalOffset: 0.2,


					background: Object.assign(themeOptions.colors.background,{
						default: themeOptions.colors.background[900],
						paper  : themeOptions.colors.background[700]
					}),
					backgroundColors: {
						sideBar: themeOptions.colors.background[700],
						appbar: themeOptions.colors.background[700],
						content: themeOptions.colors.background[900],
						popper: themeOptions.colors.primary[900],
						header: themeOptions.colors.secondary[800],
						calendarTodayHighlight: '#f72b2e',
						confirm: '#7cb342',
						confirmDark: '#6a9b3d',
						confirmDarker: '#233414',
						decline: '#c62828',
						declineDark: '#ae2323',
						declineDarker: '#581111',

						// sideBar: themeOptions.colors.secondary[900] || grey[700],
						// appbar: themeOptions.colors.secondary[900] || grey[700],
						// content: themeOptions.colors.secondary[900] || grey[900],
					},
					borderColors: {
						sideBar: themeOptions.colors.background[900]
					},
					textColors: {
						default: themeOptions.colors.foreground[ 200 ],
						grey: grey,
						link: themeOptions.colors.textContrast,
						inactiveLink: themeOptions.colors.foreground[ 600 ],
					},
					placeholder: {
						color: themeOptions.colors.grey[500]
					}
				},

				sidebar: {
					width: 240,
					closedWidth: 55,
				},

				overrides: {
					MuiGrid: {
						item: { color: themeOptions.colors.textContrast },
						root: { color: themeOptions.colors.textContrast },
					},
					MuiLink: {
						text: { color: themeOptions.colors.textContrast }
					},
					MuiButton: {
						// text: { color: deepPurple[50] }
						textPrimary: { color: themeOptions.colors.textContrast },
						contained: {
							color: themeOptions.colors.textContrast,
							backgroundColor: themeOptions.colors.secondary[800],
							'&:hover': {
								backgroundColor: themeOptions.colors.secondary[900],
							},
						},
						containedSecondary: {
							color: themeOptions.colors.textContrast,
							backgroundColor: themeOptions.colors.secondary[800],
							'&:hover': {
								backgroundColor: themeOptions.colors.secondary[900],
							},
						}
					},
					// MuiChip: {
					// 	clickableColorPrimary: { color: deepPurple[50] },
					// 	clickableColorSecondary: { color: deepPurple[50] }
					// },
					MuiChip: {
						root: {
							color: themeOptions.colors.textContrast,
							backgroundColor: themeOptions.colors.secondary[800],
							// '&:hover': {
							// 	backgroundColor: themeOptions.colors.secondary[900],
							// },
						}
					},
					MuiAppBar: {
						// backgroundColor: '#404040',
					},
					MuiFormControl: {
						fullWidth: {
							width: '100% !important'
						}
					},
					MuiListItem: {
						root: {
							'&selected': {
								backgroundColor: 'rgba(255,255,255,0.1)',
							}
						}
					},
					MuiFormLabel: {
						root: {
							'&$focused': {
								color: themeOptions.colors.primary[300]
							},
						},
					},
					MuiInput: {
						underline: {
							'&$focused:after': {
								borderBottomColor: themeOptions.colors.primary[400]
							},
						},
					},
					MuiSwitch: {
						colorPrimary: {
							color: themeOptions.colors.primary[400],
							'&$checked': {
								color: themeOptions.colors.primary[300]
							}
						},

					}
				},

				zIndex: {
					tooltip: 2500,
					downshift: 2600,
					popper: 2500,
				},

				direction: '',

				custom: {
					global: {
						center: {
							textAlign: 'center',
							margin: 'auto',
							width: '100%',
							height: '100%'
						},
						ellipsis: {
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							overflow: 'hidden'
						}
					},
					content: {

					}
				}
			});
		},
		[themeOptions],
	);


	const themedContentStyles = {
		fullscreen: {
			width: '100%',
			height: '100%'
		},
		scroll__y_touch: {
			overflowY: 'scroll',
			// '-webkit-overflow-scrolling': 'touch'
		},
		contentRoot: {
			display                           : 'flex',
			flexDirection                     : 'column',
			flexGrow                          : 1,
			flexBasis                         : 0,
			padding                           : theme.spacing( 3 ),
			[ theme.breakpoints.up( 'xs' ) ]  : {
				paddingLeft: 5,
			},
			[ theme.breakpoints.down( 'sm' ) ]: {
				padding: 0,
			},
		},
		resetIconFix: {
			padding: 0
		},
	};

	const GlobalCss = withStyles({
		// @global is handled by jss-plugin-global.
		'@global': {
			'body.mui--type_dark': {
				color: theme.palette.textColors.default,
				overflow: 'hidden',
			},
			// You should target [class*="MuiButton-root"] instead if you nest themes.
			'a': {
				color: theme.palette.textColors.link,
			},
			'.fc .fc-list-empty': {
				backgroundColor: themeOptions.colors.background[700],
			},
			'.fc .fc-view-harness': {
				backgroundColor: themeOptions.colors.background[700],
				color: themeOptions.colors.foreground[200],
			},
			'.fc .fc-list-item:hover td': {
				backgroundColor: themeOptions.colors.background[700],
			},
			'.fc-day-sun, .fc-day-sat, .fc-holiday, .fc-nonbusiness, .fc .fc-non-business': {
				backgroundColor: themeOptions.colors.background[900]
			},
			'.fc .fc-divider, .fc .fc-popover .fc-header, .fc .fc-list-heading td' : {
				backgroundColor: themeOptions.colors.background[900]
			},
			'.fc-theme-standard .fc-scrollgrid, .fc-theme-standard .fc-list, .fc th, .fc td, .fc thead, .fc tbody, .fc .fc-divider, .fc .fc-row, .fc .fc-content,.fc .fc-popover, .fc .fc-list-view, .fc .fc-list-heading td': {
				borderColor: theme.palette.divider || themeOptions.colors.background[600]
			},
			// body.mui--type_dark .fc--calendar-wrapper .fc-day-header,
			// body.mui--type_dark .fc--calendar-wrapper .fc-widget-header
			'.fc th.fc-day-today, .fc--calendar-wrapper .fc-timeline-header-row th.fc-timeline-slot.fc-day-today': {
				backgroundColor: theme.palette.backgroundColors.calendarTodayHighlight,
			},
			'body.mui--type_dark .fc--calendar-wrapper .fc th.fc-widget-header.fc-day-today': {
				backgroundColor: theme.palette.backgroundColors.calendarTodayHighlight,
			},
			'.fc th.fc-day-today span': {
				// backgroundColor: themeOptions.colors.background[ 700 ],
				color          : themeOptions.colors.foreground[ 200 ],
			},
			'.fc td.fc-day-today .fc-daygrid-day-number' : {
				backgroundColor: theme.palette.backgroundColors.calendarTodayHighlight,//themeOptions.colors.background[ 700 ],
				color          : themeOptions.colors.foreground[ 200 ],
				border: null,
			},
			'.mtv__list--content th.MuiTableCell-head' : {
				top: 0,
				zIndex: 10,
				position: 'sticky',
				// backgroundColor: theme.palette.background.paper,
				backgroundColor: theme.palette.backgroundColors.header,
			},
			'::placeholder, *::placeholder, input:placeholder, ::-webkit-input-placeholder': {
				color: theme.palette.placeholder.color
			},
			'button.ra-confirm': {
				color: theme.palette.textColors.link,
			}
		},
	})(() => null);


	theme.custom.content = themedContentStyles;

	React.useMemo(
		() =>
		{
			document.body.classList.remove('mui--type_light','mui--type_dark');
			document.body.classList.add('mui--type_'+themeOptions.type);
		},
		[themeOptions.type],
	);

	// if(typeof console === 'object') { console.log('MuiTheme',theme); }

	return (
		<MuiThemeProvider theme={theme}>
			<GlobalCss />
			{props.children}
		</MuiThemeProvider>
	);
};

const mapStateToProps = (state) => {
	// if(typeof console === 'object') { console.log('MUITheme.mapStateToProps state',state); }
	return ({
		// isFetchingAppState: (state.appstateByPHPOS) ? state.appstateByPHPOS.isFetchingAppState : true,
		appstate: (state.appstateByPHPOS) ? state.appstateByPHPOS.appstate : null
	})
};

export default connect(mapStateToProps,null)(MuiTheme);

//
// export default class MuiTheme extends PureComponent {
//
// 	render() {
//
// 		if(typeof console === 'object') { console.log('THEME: {props.theme.direction}',theme); }
//
// 		// const addMuiTypeToBodyClass = () => {
// 		// 	document.body.classList.remove('mui--type_light','mui--type_dark');
// 		// 	document.body.classList.add('mui--type_'+theme.palette.type);
// 		// };
//
// 		React.useMemo(
// 			() =>
// 			{
// 				document.body.classList.remove('mui--type_light','mui--type_dark');
// 				document.body.classList.add('mui--type_'+theme.palette.type);
// 			},
// 			[theme.palette.type],
// 		);
//
// 		// addMuiTypeToBodyClass();
//
// 		return (
// 			<MuiThemeProvider theme={theme}>
// 				{this.props.children}
// 			</MuiThemeProvider>
// 		);
// 	}
// }
