import React from 'react';
import PropTypes                           from 'prop-types';
import {
	useParams,
	useLocation
} from "react-router-dom";
import Mail from "@material-ui/icons/Feedback";
import IconButton from "@material-ui/core/IconButton";

function FeedbackButton(props) {
	const { user, versions } = props;

	let location = useLocation();
	let params = useParams();

	const do_Action = (r) => {
		// if(typeof console === 'object') { console.log('useLocation',location,params,window.screen, window.navigator,user); }

		// let subject = 'HighJect Feedback';
		let subject = 'Replace this subject with a meaningful one';
		let linebreak = '%0D%0A';
		let body = '';
		body += linebreak+linebreak;
		body += '------------------------------------------------------------------------------'+linebreak;
		body += 'Internal Data '+linebreak;
		body += '------------------------------------------------------------------------------'+linebreak;
		body += 'User: '+user.username + ' ('+user.id+') - ('+user.contact+')' + linebreak;
		body += 'Url: '+location.pathname+linebreak;
		body += 'Browser: '+window.navigator.userAgent+linebreak;
		body += 'Screen size: '+window.screen.width + 'x'+window.screen.height+linebreak;
		body += 'Window size: '+window.innerWidth + 'x'+window.innerHeight+linebreak;
		body += 'Version: UI: '+versions.frontEnd + ' / API: '+versions.backEnd+linebreak;
		body += '------------------------------------------------------------------------------'+linebreak;
		body += linebreak;
		body += 'Thanks for your feedback '+linebreak;

		let s = 'mailto:feedback@highject.com?subject='+subject+'&body='+body;
		// if(typeof console === 'object') { console.log('Feedback string',s); }

		if(r === 'get') {
			return s;
		}

		window.open(s);
	};

	return (
		<div>
			<IconButton
				size="small"
				href={do_Action('get')}
				// onClick={do_Action}
			>
				<Mail fontSize="small" />
			</IconButton>
		</div>
	);
};

FeedbackButton.propTypes = {
	user: PropTypes.object,
};

export default FeedbackButton;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
