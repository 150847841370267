import { makeStyles } from '@material-ui/core';

const useGridStyles = makeStyles(theme => ({

	w20: {
		width: '20px',
		maxWidth: '20px',
		overflow: 'hidden',
	},
	w40: {
		width: '40px',
		maxWidth: '40px',
		overflow: 'hidden',
	},
	w50: {
		width: '50px',
		maxWidth: '50px',
		overflow: 'hidden',
	},
	w60: {
		width: '60px',
		maxWidth: '60px',
		overflow: 'hidden',
	},
	w70: {
		width: '70px',
		maxWidth: '70px',
		overflow: 'hidden',
	},
	w80: {
		width: '80px',
		maxWidth: '80px',
		overflow: 'hidden',
	},
	w90: {
		width: '90px',
		maxWidth: '90px',
		overflow: 'hidden',
	},
	w100: {
		width: '100px',
		maxWidth: '100px',
		overflow: 'hidden',
	},
	w120: {
		width: '120px',
		maxWidth: '120px',
		overflow: 'hidden',
	},

	maxw200: {
		width: '200px',
		maxWidth: '200px',
		overflow: 'auto',
	},

	mmw20: {
		width: '20px',
		minWidth: '20px',
		
	},
	mw40: {
		width: '40px',
		minWidth: '40px',
		
	},
	mw50: {
		width: '50px',
		minWidth: '50px',
		
	},
	mw60: {
		width: '60px',
		minWidth: '60px',
		
	},
	mw70: {
		width: '70px',
		minWidth: '70px',
		
	},
	mw80: {
		width: '80px',
		minWidth: '80px',
		
	},
	mw90: {
		width: '90px',
		minWidth: '90px',
		
	},
	mw100: {
		width: '100px',
		minWidth: '100px',
		
	},
	mw120: {
		width: '120px',
		minWidth: '120px',
	},
	mw140: {
		width: '140px',
		minWidth: '140px',
	},
	mw160: {
		width: '160px',
		minWidth: '160px',
	},



	breakpointUpMD: {
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},

	breakpointDownSM: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	breakpointDownMD: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},

	breakpointDownLG: {
		[theme.breakpoints.down('lg')]: {
			display: 'none',
		},
	},
	breakpointDownXL: {
		[theme.breakpoints.down('xl')]: {
			display: 'none',
		},
	},
}));

export default useGridStyles;
