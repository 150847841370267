import PropTypes                         from 'prop-types';
import React, { cloneElement, useState } from 'react';
import { Drawer, makeStyles }            from '@material-ui/core';
import Fade from '@material-ui/core/Fade';

const drawerWidth = 400;

let useStyles = makeStyles(function (theme) {
	return ({
		drawerContent: {
			width: drawerWidth,
			[theme.breakpoints.up('xs')]: {
				// width: '100%',
				width: '100vw'
			},
			[theme.breakpoints.up('sm')]: {
				width: drawerWidth,
				flexShrink: 0,
			},
		}
	});
});


const MuiDrawer = props => {

	let styles = useStyles();

	let {
		open,
		onClose,
		anchor,
		disableBackdropClick,
		defaultIsEntered
	} = props;

	// const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
	const [isEntered, setIsEntered] = useState(defaultIsEntered);

	const onEntering = () => {
		if(isEntered !== true) {
			// if(typeof console === 'object') { console.log('isEntered',isEntered); }
			setTimeout(() => {
				setIsEntered(true);
			},250);

		}
	};

	return (
		<Drawer
			open={open}
			anchor={anchor}
			onClose={onClose}
			disableBackdropClick={disableBackdropClick}
			ModalProps={{
				onRendered: onEntering
			}}
			// disableBackdropTransition={!iOS}
			// disableDiscovery={iOS}
		>
			{/*{props.children}*/}
			{isEntered && cloneElement(props.children,{isEntered:isEntered, className: styles.drawerContent})}
		</Drawer>
	);

};

MuiDrawer.defaultProps = {
	defaultIsEntered: null,
	anchor: 'right',
	disableBackdropClick: false
};

MuiDrawer.propTypes = {
	open: PropTypes.bool.isRequired,
	defaultIsEntered: PropTypes.any,
	anchor: PropTypes.string,
	onClose: PropTypes.func,
	disableBackdropClick: PropTypes.bool,
};

export default MuiDrawer;
