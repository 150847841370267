import React, { useState, useEffect } from 'react';
import PropTypes                      from 'prop-types';
import {DeleteWithConfirmButton, useMutation} from 'react-admin';
import LoadingIndicator               from '../../../common/components/common/LoadingIndicator';
import Error                          from '../../../common/components/common/Error';
import { useNotify, GET_ONE }         from 'react-admin';
import { makeStyles }                 from '@material-ui/core/styles';
import classNames         from 'classnames';
import Paper              from '@material-ui/core/Paper';
import Typography         from '@material-ui/core/Typography';
import MuiButton          from '../../../common/components/common/MuiButton';
import Divider            from '@material-ui/core/Divider';
import Grid               from '@material-ui/core/Grid';
import VideoPreview       from '../../components/video/VideoPreview';
import ProjectDataDetails from './components/ProjectDataDetails';
import MuiDrawerEditor    from '../../../common/components/react-admin/form/MuiDrawerEditor';
import { Switch, Route }          from 'react-router-dom';
import History            from '../../admin-containers/History';
// import ProgressBar        from '../../../common/components/common/ProgressBar';
import LongDescription             from '../../../common/components/common/LongDescription';
import { faPen, faRedo }           from '@fortawesome/free-solid-svg-icons';
import ReferenceEditor             from '../../../common/components/react-admin/form/editors/ReferenceEditor';
import { withTranslate }           from 'react-admin';
import SupervisedUserCircleRounded from '@material-ui/icons/SupervisedUserCircleRounded';
import ColorUtils                  from '../../../common/utils/ColorUtils';
import ProjectManagementSummary    from './components/ProjectManagementSummary';
// import {Breadcrumb} from "../../components/app_title_bar/BreadCrumb";
const ProjectMasterLists = React.lazy(() => import('./components/ProjectMasterLists'));


// import _ from 'lodash';
// import MuiModalShow from "../../../common/components/react-admin/form/MuiModalShow";
// import ApiPlatformResources from "../../../api-platform-admin/src/api/ApiPlatformResources";
// import {Breadcrumb} from "../../components/app_title_bar/BreadCrumb";
// import ColorUtils                  from '../../../common/utils/ColorUtils';

// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';


let useStyles = makeStyles(function (theme) {
	return (
		{
			root: {
				flexGrow: 1,
			},
			projectActions: {
				textAlign: 'right',
			},
			header: {},
			container: {},
			fullscreen: theme.custom.content.fullscreen,
			scroll__y_touch: theme.custom.content.scroll__y_touch,
			content: theme.custom.content.contentRoot,
			dividerMargin: {
				marginTop: theme.spacing(2),
				marginBottom: theme.spacing(2),
			},
			paper: {
				...theme.mixins.gutters(),
				paddingTop: theme.spacing(2),
				paddingBottom: theme.spacing(2),
				marginBottom: theme.spacing(2),
			},
		}
	);
});

// const RefDeleteButton = React.forwardRef((props, ref) => (
// 	<DeleteWithConfirmButton {...props} forwardedRef={ref} />
// ));

const peWrStyle = {padding: '20px', border: '1px solid transparent'};
const peWrSGrtyle = {width: '160px', height: '90px'};
const peWrSGrIttyle = {width: 'calc(100% - 200px)', paddingLeft: '0', paddingRight: 0};
const peWrH2tyle = {margin:0, marginLeft: '-4px'};

function ProjectEditor(props) {

	// if(typeof console === 'object') { console.log('ProjectEditor', props, props.id); }

	const resource = 'projects';
	const projectId = props.id;

	const {
		translate,
		basePath
	} = props;

	let classes = useStyles();
	const notify = useNotify();
	// const [showOpen, setShowOpen] = useState();
	// const [showOptions, setShowOptions] = useState();
	const [project, setProject] = useState();
	const [isLoaded, setIsLoaded] = useState(null);

	// const [loading, setLoading] = useState(true);
	// const [error, setError] = useState(false);

	let [mutate, { error, loading }] = useMutation();
	const getProject = event => mutate(
		{
			type: GET_ONE,
			resource: resource,
			payload: {id: projectId/*, groups: ['related:read']*/},
			// groups: ['related:read']
		},
		{
			onSuccess: ({ data }) => {
				// if(typeof console === 'object') { console.log('SUCCEESSS',data); }
				setProject(data);
				setIsLoaded(true);
				return data;
			},
			onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
		}
	);

	// if(typeof console === 'object') { console.log('datadatadatadata',data); }

	useEffect(() => {
		getProject();
	}, [projectId]);


	const reload = () => {
		getProject();
	};

	const handleEditorClose = () => {
		History.push(basePath+'/'+encodeURIComponent( projectId ));
	};

	const handleEditorSave = () => {
		handleEditorClose();
		getProject();
	};

	const handleEditorOpen = () => {
		History.push(basePath+'/'+encodeURIComponent( projectId )+'/editor');
	};

	const matchEditorRoute = (match) => {
		const isMatch = match && match.params && match.params.id !== 'create' ? true : false;
		return isMatch;
	};

	const deleteButton = true;
	const deleteButtonId = 'confirmdeletebutton';
	const triggerDelete = () => {
		document.getElementById(deleteButtonId).click();
	};
	const afterDelete = () => {
		History.push(basePath);
	};

	if (!isLoaded) {
		return <LoadingIndicator type="container" />;
	}
	// if (loading) return <LoadingIndicator type="container" />;
	if (error) return <Error error={error} />;
	if (!project) return null;

	// if(typeof console === 'object') { console.log('project',project); }

	let color = ColorUtils.ligthenHexColor(project.color,50);

	return (
		<React.Fragment>
			{/*<Breadcrumb to={props.match.url}>{project.title}</Breadcrumb>*/}
			<Switch>
				<Route
					path={props.match.url+'/masterlists'}
					component={ProjectMasterLists}
				//	{...props}
					children={({ match, ...rest }) => {
						// if(typeof console === 'object') { console.log('Route.masterlists',match,rest); }
						return (
							match && <ProjectMasterLists
							//	{...props}
								match={match}
								basePath={basePath}
								parentMatch={props.match}
								project={project}
								projectId={projectId}
							/>
						)}
					}
				/>
				<Route>
					<div className={classNames(classes.content, classes.scroll__y_touch)}
						 style={peWrStyle}
					>
						<Grid
							container spacing={8} className={classes.header}
						>
							<Grid item style={peWrSGrtyle}>
								<VideoPreview src={project.src} className="img--rectangle-preview small" />
							</Grid>
							<Grid item style={peWrSGrIttyle}>
								<h2
									style={peWrH2tyle}
								>
									{project.title} {project.shortTitle && project.shortTitle.length > 0 && <span>({project.shortTitle})</span>}
								</h2>
							</Grid>
						</Grid>

						<ProjectDataDetails
							project={project}
							color={color}
							// reloadProject={reload}
							// eventStatus={true}
							// config={config}
							// record={record}
							// getController={getController}
							// isAdmin={isAdmin}
						/>

						{project.description && project.description.length >0 &&<LongDescription
							lineBreaks={true}
							leftContainerWidth="40px"
							value={project.description}
						/>}

						<div className={classes.projectActions}>
							<MuiButton spin={loading} color="secondary"  variant="contained" icon={faRedo} onClick={reload}>Reload</MuiButton>
							<MuiButton variant="contained" icon={faPen} onClick={handleEditorOpen}>Edit</MuiButton>
							{1===2 &&<MuiButton variant="contained" icon={faPen} onClick={triggerDelete}>Delete Test</MuiButton>}
							{deleteButton && <DeleteWithConfirmButton
								id={deleteButtonId}
								resource={resource}
								record={project}
								label={null}
								redirect={() => {
									afterDelete();
								}}
							/>}
						</div>

						<Divider className={classes.dividerMargin} />
						<ProjectManagementSummary
							basePath={props.basePath}
							projectId={projectId}
							resource={props.resource}
							project={project}
						/>
						<Divider className={classes.dividerMargin} />
						<Grid
							container spacing={8} className={classes.container}
						>
							<Grid item xs={4}>
								<ReferenceEditor
									title={translate('resources.project_members.name')}
									icon={<SupervisedUserCircleRounded />}
									location={props.location}
									resource={props.resource}
									reference="project_memberships"
									basePath={props.basePath}
									filter={{project: projectId}}
									initialValues={{project: projectId}}
									referenceId={projectId}
									editor="drawer"
									callback={reload}
								/>
							</Grid>
							<Grid item xs={4}>
							</Grid>
							<Grid item xs={4}>
							</Grid>
						</Grid>


						{1===1 && <Route
							path={basePath+'/:id/editor'}
						>
							{({ match }) => {

								// const isMatch = match && match.params && match.params.id !== 'create' ? true : false;

								const isMatch = matchEditorRoute(match);
								// if(isMatch && typeof console === 'object') { console.log('EDIT ROUTE match,isMatch',decodeURIComponent(match.params.id),encodeURIComponent(match.params.id),match,isMatch); }

								let id = null;
								if ( isMatch ) {
									id = match.params.id;
									if ( typeof id === 'string' ) {
										id = decodeURIComponent( id );
									}
								} else {
									return null;
								}

								// if ( typeof console === 'object' ) { console.log( 'isMatch?', isMatch , match); }

								return (
									<MuiDrawerEditor
										resource={props.resource}
										basePath={props.basePath}
										isOpen={isMatch}
										id={id}
										options={props.options}
										handleEditorClose={handleEditorClose}
										redirect={(redirect,id,record) => {
											// if(typeof console === 'object') { console.log('FORM redirect',redirect,id,record); }
											handleEditorSave(redirect,id,record);
										}}
									/>
								);
							}}
						</Route>}
					</div>
				</Route>
			</Switch>

		</React.Fragment>
	);
};

ProjectEditor.defaultProps = {

};

ProjectEditor.propTypes = {
	id: PropTypes.string,
	basePath: PropTypes.string,
	// classes: PropTypes.object,
	// translate: PropTypes.func
};

export default withTranslate(ProjectEditor);
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
