import React from 'react';
// import ReactDOM from 'react-dom';
// import Msg from '../../common/utils/Msg';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import MuiModal from '../../common/modal';

// import { Notification } from 'react-admin';
import { Notification } from '../../common/components/react-admin';

const MyNotification = (props) => {

	// if(typeof console === 'object') { console.log('MyNotification',props); }

	return <Notification {...props} autoHideDuration={5000} anchorOrigin={{
		vertical  : 'top',
		horizontal: 'right',
	}} />;

};

export default MyNotification;
