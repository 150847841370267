import React     from 'react';
import PropTypes from 'prop-types';
// import get       from 'lodash/get';
import {
	// ArrayInput,
	// BooleanInput,
	// DateInput,
	// NumberInput,
	// ReferenceArrayInput,
	// ReferenceInput,
	// required,
	// SelectArrayInput,
	SelectInput,
	// SimpleFormIterator,
	// TextInput,
}                            from 'react-admin';
import { makeStyles }        from '@material-ui/core';

// let useStyles = makeStyles(function (theme) {
// 	return ({
// 		resetIconFix: theme.custom.content.resetIconFix,
// 	});
// });
//
// const useSelectInputStyles = makeStyles(
// 	theme => ({
// 		input: {
// 			minWidth: theme.spacing(5),
// 		},
// 	}),
// 	{ name: 'RaSelectInput' }
// );

// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';

const MVTSelectField = (props) => {

	// if(typeof console === 'object') { console.log('MVTSelectField',props); }
	// let styles = useStyles();
	// let selectInputStyles = useSelectInputStyles();

	const {
		submit,
		...rest
	} = props;

	return (
		<SelectInput
			fullWidth={true}
			// classes={selectInputStyles}
			// className={styles.resetIconFix}
			className="mvt__input--fullwidth mvt__input--resetminwidth"
			{...rest}
		/>
	);
};

MVTSelectField.defaultProps = {
	translateChoice: false
};

MVTSelectField.propTypes = {
	className: PropTypes.any,
	record: PropTypes.object,
	source: PropTypes.string,
	translateChoice: PropTypes.bool,
	// translate: PropTypes.func
};

MVTSelectField.displayName = 'MVTSelectField';
export default MVTSelectField;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
