import Api from '../../api-platform-admin/src/api/Api';
import AppStateApi from '../api/AppStateApi';

/**
 *

 https://github.com/marmelab/react-admin/blob/next/docs/CreateEdit.md#validation
 Built-in Field Validators
 React-admin already bundles a few validator functions, that you can just require, and use as input-level validators:

 required(message) if the field is mandatory,
 minValue(min, message) to specify a minimum value for integers,
 maxValue(max, message) to specify a maximum value for integers,
 minLength(min, message) to specify a minimum length for strings,
 maxLength(max, message) to specify a maximum length for strings,
 number(message) to check that the input is a valid number,
 email(message) to check that the input is a valid email address,
 regex(pattern, message) to validate that the input matches a regex,
 choices(list, message) to validate that the input is within a given list,

 */

class CommonApi extends Api {

	static debug = false;

	static contentKey = null;
	static baseParams = null;
	// static keepExtraParams = null;
	static sorting = null;
	static groupBy = null;
	static gridSettings = {};

	// static groupField = null;
	// static filterBy = null;
	// static filter = null;
	static stateKey = null;
	// static modelName = null;
	// static storeName = null;
	// static baseStoreName = null;
	// static cacheData = null;
	//
	// static gridConf = {};
	// static formConf = {};
	//
	// static remoteSort = true;
	// static remoteFilter = true;
	// static autoLoad = false;
	// static setRecord = false;
	// static reloadStoreOnCreate = true;
	//
	// static reloadRecordOnSave = false;
	//
	// static getStoreConf(contentData) {
	// 	contentData = contentData || {};
	// 	let obj = Object.assign( this, contentData );
	//
	// 	return this.getStore(obj).then(store => {
	// 		obj.store = store;
	// 		return obj;
	// 	});
	// }
	//
	// static getCacheKey(options) {
	// 	return this.getStateKey(options);
	// }
	//
	// static getCache(k, options = null)  {
	// 	let key = this.getCacheKey(options);
	//
	// 	if(key) {
	// 		return AppCacheApi.getCache([key, k]);
	// 	}
	//
	// 	return null;
	// }
	//
	// static setCache(k,v, options = null) {
	// 	let key = this.getCacheKey( options );
	// 	if ( key ) {
	// 		AppCacheApi.setCache( [ key, k ], v );
	// 	}
	// }

	static test() {
		if(typeof console === 'object') { console.log('PHPOSApiBase-TEST',true); }
		super.test();
	}

	static config(conf) {
		Object.keys(conf).forEach(key => {
			if(typeof this[key] !== 'undefined') {
				this[key] = conf[key];
			} else {
				if(typeof console === 'object') {
					console.log(
						'PHPOSApi.config, you are tryying to set the a variable %s in PHPOSApi, which is not yet defined',
						key
					);
				}
			}
		});
	}

	static getContentKey(contentData) {

		if(this.contentKey) {
			return this.contentKey;
		}

		if(!contentData) {
			console.warn('PHPOSApi - No contentData defined',contentData);
			return false;
		}

		let { contentKey } = contentData;

		if(!contentKey) {
			console.warn('PHPOSApi - No contentKey defined',contentData);
		}

		return this.contentKey = contentKey;
	}


	static getStateKey(options) {

		if(this.stateKey) {
			return this.stateKey;
		}

		let stateKey;

		if(options && options.stateKey) {
			stateKey = options.stateKey;
		}

		if(!stateKey) {
			let contentKey = this.getContentKey(options);
			stateKey = 'PHPOSApi-' + contentKey;
		}

		if(stateKey) {
			this.stateKey = stateKey;
		}

		return stateKey;
	}

	static getState(k, options) {
		let stateKey = this.getStateKey(options);

		if(stateKey) {
			return AppStateApi.getState([stateKey, k]);
		}

		return null;
	}

	static setState(k,v, options = null) {
		let stateKey = this.getStateKey( options );
		if ( stateKey ) {
			AppStateApi.setState( [ stateKey, k ], v );
		}
	}



	/**
	 *
	 * @param data
	 * @returns {*[]}
	 */
	static getShowActions(data) {
		return [];
	}

	/**
	 *
	 * @param data
	 * @returns {*[]}
	 */
	static getEditActions(data) {
		return [];
	}

	static getShowFields(fields) {
		return this.getGridColumns(fields);
	}

	static matchGridToShowFields(fields) {

		fields.map((field) => {
			// if(typeof console === 'object') { console.log('MAP field',field); }

			if(field.fieldProps) {
				delete(field.fieldProps.cellAndHeaderClassName);
				delete(field.fieldProps.headerClassName);
				delete(field.fieldProps.cellClassName);
				delete(field.fieldProps.width);

				if(field.fieldProps.header) {
					field.fieldProps.label = field.fieldProps.header;
					field.fieldProps.addLabel = true;
					delete(field.fieldProps.header);
				}

			}

			return field;
		});

		return fields;
	};

	/**
	 * get columns
	 *
	 * array must contain:
	 * name: fieldname
	 * header: fieldlabel
	 *
	 * array may contain
	 * hidden: {boolean} default: false
	 * isSortable: {boolean} default: false
	 * columnWidth: {function}, must return a number
	 * width: {number}
	 * height: {number}
	 *
	 * tag: e.g. 'TextField', defined Tags in common/components/grid/mui-virtualized-table/index.js
	 * tagProps: {object} properties added to tag component
	 *
	 * referenceTag: e.g. 'ReferenceField', defined referenceTags in common/components/grid/mui-virtualized-table/index.js
	 * referenceTagProps: {object}, minmum requirements: {basePath:'/users',reference:'users'}
	 * (in case of using a referenceField, you must define a field which will be outlined in tagProps {source:'username'}
	 *
	 * cellRenderer: {function} gets @params column,value,rowData,opts, must return a compoment
	 *
	 * cell: {function} gets @params rowData, must return a component
	 * cellProps: {object}, cell properties
	 *
	 *
	 *
	 * @returns {{hidden: boolean, name: string, header: string, isSortable: boolean}[]}
	 */
	static getGridColumns(listFields) {

		if(listFields) {
			return listFields;
		}

		return [
			{
				name: 'id',
				header     : '#',
				hidden   : false,
				isSortable: true
			}
		];
	}

	/**
	 * Standard grid settings
	 * @returns {}
	 */
	static getGridSettings() {

		let s = {
			fullscreen: true
		};

		if(this.gridSettings) {
			Object.assign(s,this.gridSettings);
		}

		// if(typeof console === 'object') { console.log('getGridSettings',s); }

		return s;
	}


	static getGridSorting(options) {

		let sorting = this.sorting;

		if(options && options.sorting) {
			sorting = options.sorting;
		}

		let n = this.getState('sortProperty');
		let d = this.getState('sortDirection');
		// if(typeof console === 'object') { console.log('getGridSorting-N',options,n,d); }

		if(n) {
			return {
				field: n,
				order: d ? d.toUpperCase() : 'ASC'
			};
		}

		// this.setGridSorting(sorting);
		// if(typeof console === 'object') { console.log('getGridSorting',sorting); }
		return sorting;
	}

	static setGridSorting(sorting,dir) {

		if(sorting) {
			this.setState('sortProperty', sorting);
			this.setState('sortDirection', dir);
		}

		return sorting;
	}

	static getGridGroupByColumns(options) {

		let groupBy = this.groupBy;

		if(options && options.groupBy) {
			groupBy = options.groupBy;
		}

		let n = this.getState('groupBy');
		// if(typeof console === 'object') { console.log('getGridSorting-N',options,n,d); }

		if(n) {
			return n;
		}

		// this.setGridSorting(sorting);
		// if(typeof console === 'object') { console.log('getGridSorting',sorting); }
		return groupBy;
	}


	static getGridPermanentFilter( permanentFilter ) {
		return permanentFilter;
	}

	static getGridFilterDefaults( filterDefaults ) {
		return filterDefaults;
	}

	static getGridFilter( ) {
		return [];
	}

	static getGridBulkActions( ) {
		return [];
	}

	static getGridRowActions( ) {
		return [];
	}




	static getCreateFormSettings() {
		return this.getFormSettings();;
	}

	static getFormSettings() {
		return {};
	}

	static getCreateFormFields(formFields) {
		return this.getFormFields(formFields);
	};

	static getFormFields(formFields) {

		if(formFields) {
			return formFields;
		}

		return [
			{
				name: 'id',
				label: '#',
			}
		];
	}

	static validateForm(values) {
		const errors = {};
		return errors;
	}

}

export default CommonApi;
