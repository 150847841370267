import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


function PlanningProjects(props) {
    return (
        <SvgIcon {...props}>
            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" role="img">
                <path
                    d="M21.1,9.2H2.9c-0.3,0-0.5,0.2-0.5,0.5v11.2C2.4,22,3.3,23,4.5,23h15.1c1.1,0,2.1-0.9,2.1-2.1V9.8 C21.6,9.5,21.4,9.2,21.1,9.2z M6.1,20.1c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S7,20.1,6.1,20.1z M9,13.1 l-2.2,2.2l-0.5,0.5c-0.1,0.1-0.4,0.1-0.5,0l-1.4-1.4c-0.1-0.1-0.1-0.4,0-0.5l0.5-0.5c0.1-0.1,0.4-0.1,0.5,0L6,14l1.9-1.9 C8,12,8.3,12,8.4,12.1L9,12.6C9.1,12.8,9.1,13,9,13.1z M19.8,19.2c0,0.3-0.2,0.5-0.5,0.5h-8.8c-0.3,0-0.5-0.2-0.5-0.5V18 c0-0.3,0.2-0.5,0.5-0.5h8.8c0.3,0,0.5,0.2,0.5,0.5V19.2z M19.8,14.6c0,0.3-0.2,0.5-0.5,0.5h-8.8c-0.3,0-0.5-0.2-0.5-0.5v-1.2 c0-0.3,0.2-0.5,0.5-0.5h8.8c0.3,0,0.5,0.2,0.5,0.5V14.6z"></path>
                <path
                    d="M19.6,3.8h-2.1V1.5C17.5,1.2,17.3,1,17,1h-1.7c-0.3,0-0.5,0.2-0.5,0.5v2.2H9.2V1.5C9.2,1.2,9,1,8.7,1H7 C6.7,1,6.5,1.2,6.5,1.5v2.2H4.4c-1.1,0-2.1,0.9-2.1,2.1v1.5c0,0.3,0.2,0.5,0.5,0.5H21c0.3,0,0.5-0.2,0.5-0.5V5.8 C21.6,4.7,20.7,3.8,19.6,3.8z"></path>
                <title>Planning: Projects</title>
            </svg>
        </SvgIcon>
    );
}

export default PlanningProjects;