import React, { cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { linkToRecord } from 'ra-core';

const useStyles = makeStyles(
    theme => ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: -theme.spacing(1),
            marginBottom: -theme.spacing(1),
        },
    }),
    { name: 'RaSingleFieldList' }
);

// useful to prevent click bubbling in a datagrid with rowClick



/**
 * Iterator component to be used to display a list of entities, using a single field
 *
 * @example Display all the books by the current author
 * <ReferenceManyField reference="books" target="author_id">
 *     <SingleFieldList>
 *         <ChipField source="title" />
 *     </SingleFieldList>
 * </ReferenceManyField>
 *
 * By default, it includes a link to the <Edit> page of the related record
 * (`/books/:id` in the previous example).
 *
 * Set the linkType prop to "show" to link to the <Show> page instead.
 *
 * @example
 * <ReferenceManyField reference="books" target="author_id" linkType="show">
 *     <SingleFieldList>
 *         <ChipField source="title" />
 *     </SingleFieldList>
 * </ReferenceManyField>
 *
 * You can also prevent `<SingleFieldList>` from adding link to children by setting
 * `linkType` to false.
 *
 * @example
 * <ReferenceManyField reference="books" target="author_id" linkType={false}>
 *     <SingleFieldList>
 *         <ChipField source="title" />
 *     </SingleFieldList>
 * </ReferenceManyField>
 */
function MVTSingleFieldList({
                             classes: classesOverride,
                             className,
                             ids,
                             data,
                             resource,
                             basePath,
                             children,
                             callback,
                             callbackOptions,
                                currentSort,
                                setSort,
                                loading,
                                loaded,
                             ...rest
                         }) {

    const classes = useStyles({ classes: classesOverride });

    // const handleClick = event => {
    //     event.preventDefault();
    //     event.stopPropagation();
    // };

    if (loaded === false) {
        return <LinearProgress />;
    }

    return (
        <div
            className={classnames(classes.root, className)}
            // onClick={handleClick}
            {...rest}
        >
            {ids.map(id => {

                let record = data[id];

                return cloneElement(Children.only(children), {
                    key: id,
                    record: record,
                    resource,
                    basePath,
                    onClick: (ev) => {
                        ev.stopPropagation();
                        ev.preventDefault();

                        if(typeof callback === 'function') {
                            callback(ev,resource,id,record,basePath,callbackOptions);
                        }
                    }
                });
            })}
        </div>
    );
}

MVTSingleFieldList.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.element.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    data: PropTypes.object,
    ids: PropTypes.array,
    resource: PropTypes.string,
    callback: PropTypes.func,
    callbackOptions: PropTypes.object,
};

MVTSingleFieldList.defaultProps = {
    classes: {},
    callbackOptions: {},
};

export default MVTSingleFieldList;
