import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { withStyles, createStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import classnames from 'classnames';

import {
	hideNotification,
	getNotification,
	translate,
	undo,
	complete,
	undoableEventEmitter,
}                     from 'ra-core';
import Msg            from '../../../utils/Msg';
import EmptyComponent from '../../common/EmptyComponent';


const styles = theme =>
	createStyles({
		confirm: {
			backgroundColor: theme.palette.background.default,
		},
		warning: {
			backgroundColor: theme.palette.error.light,
		},
		undo: {
			color: theme.palette.primary.light,
		},
	});

/**
 * OVERRIDE OF
 * node_modules/ra-ui-materialui/src/layout/Notification.js
 */

class Notification extends React.Component {
	state = {
		open: false,
	};
	componentWillMount = () => {
		this.setOpenState(this.props);
	};
	componentWillReceiveProps = nextProps => {
		this.setOpenState(nextProps);
	};

	setOpenState = ({ notification }) => {
		this.setState({
			open: !!notification,
		});
	};

	handleRequestClose = () => {
		this.setState({
			open: false,
		});
	};

	handleExited = () => {
		const { notification, hideNotification, complete } = this.props;
		if (notification && notification.undoable) {
			complete();
			undoableEventEmitter.emit('end', { isUndo: false });
		}
		hideNotification();
	};
	//
	// handleUndo = () => {
	// 	const { undo } = this.props;
	// 	undo();
	// 	undoableEventEmitter.emit('end', { isUndo: true });
	// };

	componentDidUpdate( prevProps, prevState, snapshot ) {
		// if(typeof console === 'object') {
		// 	console.log('NOTIFICATION.componentDidUpdate.props',this.props,this.state.open,prevState.open);
		// }

		if(this.state.open === true && this.state.open !== prevState.open) {
			this.toastify();
		}
	}

	toastify() {
		const {
			translate,
			notification
		} = this.props;

		// if(typeof console === 'object') { console.log('toastify',this.props,notification); }

		let messageArgs = {};
		if(notification.messageArgs) {
			messageArgs = notification.messageArgs;
		}

		let toastArgs = Object.assign({
			message: translate(notification.message, notification.messageArgs),
			level: notification.type,
			autoClose: 2000
		},messageArgs);

		Msg.toast(toastArgs);

		setTimeout(() => {
			this.handleExited();
		},500);
	}

	render() {
		const {
			undo,
			complete,
			classes,
			className,
			type,
			translate,
			notification,
			autoHideDuration,
			hideNotification,
			...rest
		} = this.props;
		const {
			warning,
			confirm,
			undo: undoClass, // Rename classes.undo to undoClass in this scope to avoid name conflicts
			...snackbarClasses
		} = classes;


		return (
			<EmptyComponent
				open={this.state.open}
				message={
					notification &&
					notification.message &&
					translate(notification.message, notification.messageArgs)
				}
				autoHideDuration={
					(notification && notification.autoHideDuration) ||
					autoHideDuration
				}
				disableWindowBlurListener={
					notification && notification.undoable
				}
				// onExited={this.handleExited}
				// onClose={this.handleRequestClose}
				ContentProps={{
					className: classnames(
						classes[(notification && notification.type) || type],
						className
					),
				}}
				// action={
				// 	notification && notification.undoable ? (
				// 		<Button
				// 			color="primary"
				// 			className={undoClass}
				// 			size="small"
				// 			onClick={this.handleUndo}
				// 		>
				// 			{translate('ra.action.undo')}
				// 		</Button>
				// 	) : null
				// }
				classes={snackbarClasses}
				{...rest}
		/>);


		// return (
		// 	<Snackbar
		// 		open={this.state.open}
		// 		message={
		// 			notification &&
		// 			notification.message &&
		// 			translate(notification.message, notification.messageArgs)
		// 		}
		// 		autoHideDuration={
		// 			(notification && notification.autoHideDuration) ||
		// 			autoHideDuration
		// 		}
		// 		disableWindowBlurListener={
		// 			notification && notification.undoable
		// 		}
		// 		onExited={this.handleExited}
		// 		onClose={this.handleRequestClose}
		// 		ContentProps={{
		// 			className: classnames(
		// 				classes[(notification && notification.type) || type],
		// 				className
		// 			),
		// 		}}
		// 		action={
		// 			notification && notification.undoable ? (
		// 				<Button
		// 					color="primary"
		// 					className={undoClass}
		// 					size="small"
		// 					onClick={this.handleUndo}
		// 				>
		// 					{translate('ra.action.undo')}
		// 				</Button>
		// 			) : null
		// 		}
		// 		classes={snackbarClasses}
		// 		{...rest}
		// 	/>
		// );
	}
}

Notification.propTypes = {
	complete: PropTypes.func,
	classes: PropTypes.object,
	className: PropTypes.string,
	notification: PropTypes.shape({
		message: PropTypes.string,
		type: PropTypes.string,
		autoHideDuration: PropTypes.number,
		messageArgs: PropTypes.object,
	}),
	type: PropTypes.string,
	hideNotification: PropTypes.func.isRequired,
	autoHideDuration: PropTypes.number,
	translate: PropTypes.func.isRequired,
	undo: PropTypes.func,
};

Notification.defaultProps = {
	type: 'info',
	autoHideDuration: 4000,
};

const mapStateToProps = state => ({
	notification: getNotification(state),
});

export default compose(
	withStyles(styles),
	translate,
	connect(
		mapStateToProps,
		{
			complete,
			hideNotification,
			undo,
		}
	)
)(Notification);
