// import React             from 'react';
import LocalApi          from '../LocalApi';
import { useTranslate }           from 'react-admin';
import { minLength, required } from 'react-admin';

/**
 * cld_ProjectRoles
 *
 *
 *
 *
 *
 *
 */

class ProjectRoles extends LocalApi {

	static contentKey = 'project_roles';
	static sorting = { field: 'rank', order: 'ASC' };
	// static hasEdit = false;
	static hasShow = false;


	static getFormFields(formFields,map = 'formFields') {
		// if(typeof console === 'object') { console.log('getFormFields',formFields); }
		let f = [];
		const translate = useTranslate();

		let fields = {
			title: {
				MVTInputField: true,
				inputProps: {
					label: translate('fields.label'),
					validate:  [required(), minLength(2)]
				}
			},
			rank: {
				MVTInputField: true,
				inputProps: {
					label: translate('fields.rank'),
					validate:  [required()]
				}
			},
		};

		if(map === 'formFields') {
			f = this.mapFields(formFields,fields);
		}

		return f;
	}

	static validateForm(values) {
		// if(typeof console === 'object') { console.log('validateForm with values',values); }
		const errors = {
			// title: ['myroot.validation.required']
		};
		return errors;
	}

	static getGridPermanentFilter( permanentFilter ) {
		return permanentFilter;
	}

	static getGridFilterDefaults( filterDefaults ) {
		return {
			// title: '999',
			// description: 'super'
		};
	}

	static getGridFilter( ) {
		return [];
	}

	static getGridColumns(listFields,map = 'listfields') {
		// if(typeof console === 'object') { console.log('getGridColumns',listFields); }

		const translate = useTranslate();

		let fields = {
			title: {
				isSortable: true,
				// FunctionField: true,
				fieldProps: {
					// width: 200,
					label    : translate('fields.title'),
				}
			},
			rank: {
				isSortable: true,
				// FunctionField: true,
				fieldProps: {
					width: 40,
					label    : translate('fields.rank'),
				}
			},
		};

		let df = [];
		if(map === 'listfields') {
			df = this.mapFields(listFields,fields);
		}

		return df;
	}

}

export default ProjectRoles;
