// import React, { Component } from 'react';
import React from 'react';
import PHPOSApi from '../../common/api/PHPOSApi';
import UserAvatar from '../components/user/UserAvatar';
import DefaultProfilePicSVG from '../../public/_img/icons/profile-picture.svg';

/**
 * LocalApi
 *
 * CLD_Config: change response
 * 1. split configuration and records
 * 2. get real fields of phpos definitions (to pass to model, or load model in own file config) and use cld config as "columns / fields" config
 *
 *
 */

// if(typeof console === 'object') { console.log('PHPOSApi',PHPOSApi); }

class LocalApi extends PHPOSApi {
// class LocalApi  {

	static test() {
		// super();
		if(typeof console === 'object') { console.log('CISMApi-TEST',true); }
		super.test();
	}


	static userAvatar(src,sid) {

		//https://lp.dev7.cismteam.de/album-img/user/382/100x100,ex-crop-cc.jpg?f=/app/_img/icons/profile-picture.svg

		if(
			sid &&
			(typeof sid !== 'number') &&
			(sid.length === 0)
		) {
			sid = 0;
		} else if(!sid) {
			sid = 0;
		}

		// DISABELD FOR NOW!
		return '/_img/icons/profile-picture.svg';

		if(!src) {
			src = '/album-img/user/'+sid+'/180x180,ex-crop-cc.jpg?f=/app/_img/icons/profile-picture.svg';
		}

		return src;
	}

	static _r_UserAvatar( value, record, v, user_sid ) {

		return <UserAvatar
			className="img--circle-preview small"
			src={v}
			user={{ sid: user_sid }}
		/>;
	}

	static _r_UserUserAvatar( value, record ) {
		if ( (!value) || (!value.length) ) {
			value = DefaultProfilePicSVG;
		}

		let user_sid = (record) ? record.get( 'sid' ) : null;

		return this._r_UserAvatar( value, record, value, user_sid);
	}

}

export default LocalApi;
