import React, { useState, useEffect }                       from 'react';
import PropTypes                   from 'prop-types';
import { connect }                 from 'react-redux';
import inflection                  from 'inflection';
import compose                     from 'recompose/compose';
import { makeStyles, withStyles }  from '@material-ui/core/styles';
import classnames                  from 'classnames';
import { getResources } from 'ra-core';
import {GET_LIST, useMutation, withTranslate} from 'react-admin';
import DefaultIcon                 from '@material-ui/icons/ViewList';
import AboutIcon                   from  '@material-ui/icons/Info';
import { CloudUpload }                from  '@material-ui/icons';
import CalendarIcon from './menu-icons/CalendarIcon';
import PlanningProjects from './menu-icons/PlanningProjects';
import PlanningSplit from './menu-icons/PlanningSplit';
import PlanningTeam from './menu-icons/PlanningTeam';
import PlanningWorkstations from './menu-icons/PlanningWorkstations';
import PlanningMisc from './menu-icons/PlanningMisc';
import ProjectsAll from './menu-icons/ProjectsAll';
import ProjectsNegotiation from './menu-icons/ProjectsNegotiation';
import ProjectsOngoing from './menu-icons/ProjectsOngoing';
import People from './menu-icons/People';
import Companies from './menu-icons/Companies';
import TextFormat   from  '@material-ui/icons/TextFormat';
import {
	DashboardMenuItem,
	MenuItemLink,
	Responsive
}                   from 'react-admin';
import authProvider from '../admin-store/authProvider';
import ObjectUtils from "../../common/utils/ObjectUtils";
import Badge from '@material-ui/core/Badge';

// import request from 'superagent';
// import config from '../host.config';

// import MovieIcon       from '@material-ui/icons/Movie';
// import CalendarIcon                from  '@material-ui/icons/CalendarToday';
// import { DesktopMac, People, Star, Gavel, Duo, CloudUpload, EmojiTransportation }                from  '@material-ui/icons';

// import { faInfo }          from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon }          from '@fortawesome/react-fontawesome';



// const styles = createStyles({
// 	main: {
// 		display: 'flex',
// 		flexDirection: 'column',
// 		justifyContent: 'flex-start',
// 		// borderLeft: '7px solid #ffffff'
// 	},
// 	menuitem: {
// 		paddingLeft: theme.spacing( 3 )
// 	}
// });

const styles = theme => ({
	main: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},
	menuitem: {
		// color: 'rgba(255, 255, 255, 0.7)'
	},
	badge: {
		'& > .MuiBadge-anchorOriginTopLeftRectangle': {
			left: '20px',
			top: '10px'
		}
	}
});

const useStyles = makeStyles(theme => ({
	versionsRoot: {
		color: theme.palette.textColors.grey[600],
		margin: '10px 0 0 10px',
		fontSize: '0.8em',
		position: 'absolute',
		bottom: '2em',
	}
}));

/*
.MuiSvgIcon-root {
	font-size:2rem;
}

#app-sidebar .MuiListItemIcon-root { 
	min-width: 50px !important;
}

 */

const useMenuItemClasses = makeStyles(function (theme) { return ({
	root: {
		// color: theme.palette.text.secondary,
		color: 'rgba(255, 255, 255, 0.7)',
		paddingLeft: theme.spacing( 3 ) - 3,
		lineHeight: '2.6'
	},
	active: {
		color: theme.palette.text.primary,
		borderLeft: '4px solid '+theme.palette.text.primary,
		paddingLeft: theme.spacing( 3 ) -7,
	},
	icon: {
		minWidth: theme.spacing(5),
		color: 'inherit'
	},

}); });

const translatedResourceName = (resource, translate) =>
	translate(`resources.${resource.name}.name`, {
		smart_count: 2,
		_:
			resource.options && resource.options.label
			? translate(resource.options.label, {
				smart_count: 2,
				_: resource.options.label,
			})
			: inflection.humanize(inflection.pluralize(resource.name)),
	});

const Menu = ({
	classes,
	className,
	dense,
	hasDashboard,
	onMenuClick,
	// open,
	pathname,
	resources,
	translate,
	logout,
	user,
	versions,
  ...rest
}) => {

	const menuItemClasses = useMenuItemClasses();
	const styles = useStyles();
	const isChris = authProvider.isChris();//(user && user.id === '/api/users/2') ? true : false;
	const isDeveloper = authProvider.isDeveloper();
	// const [versions, setVersions] = useState(null);


	// useEffect(() => {
	// 	// loadVersions();
	// 	getVersions();
	// },[]);
	//
	// let [ mutate ] = useMutation();
	// const getVersions = () => mutate(
	// 	{
	// 		type: GET_LIST,
	// 		resource: 'highject_versions',
	// 		payload: {
	// 			pagination: {
	// 				page: 1,
	// 				perPage: 100
	// 			},
	// 			sort: {
	// 				// field: sortField, order: 'ASC'
	// 			},
	// 		},
	// 	},
	// 	{
	// 		// action: 'CUSTOM_FETCH_LOAD',
	// 		onSuccess: ({ data }) => {
	//
	// 			let versions = {
	// 				frontEnd: null,
	// 				backEnd: null
	// 			};
	//
	// 			if(data) {
	// 				let frontend = ObjectUtils.findRecord(data,{id: '/api/highject_versions/VERSION_FRONTEND'});
	// 				if(frontend) {
	// 					versions.frontEnd = frontend.label;
	// 				}
	// 				let backend = ObjectUtils.findRecord(data,{id: '/api/highject_versions/VERSION_BACKEND'});
	// 				if(backend) {
	// 					versions.backEnd = backend.label;
	// 				}
	// 			}
	//
	// 			setVersions(versions);
	// 		}
	// 	}
	// );

	return (
		<div className={classnames(classes.main, className)} {...rest}>
			{1===2 && hasDashboard && <DashboardMenuItem onClick={onMenuClick} />}
			<MenuItemLink
				className={classes.menuitem}
				classes={menuItemClasses}
				leftIcon={<CalendarIcon />}
				// leftIcon={<FontAwesomeIcon size="sm" style={{  }} icon={faInfo} />}
				to="/planning/calendar"
				primaryText={translate('menu.planning.calendar')}
				onClick={onMenuClick}
				dense={dense}
			/>
			<MenuItemLink
				className={classes.menuitem}
				classes={menuItemClasses}
				leftIcon={<PlanningSplit />}
				// leftIcon={<FontAwesomeIcon size="sm" style={{  }} icon={faInfo} />}
				to="/splitplanning/resources/projects"
				primaryText={translate('menu.planning.splitview')}
				onClick={onMenuClick}
				dense={dense}
			/>
			<MenuItemLink
				className={classes.menuitem}
				classes={menuItemClasses}
				leftIcon={<PlanningProjects />}
				// leftIcon={<FontAwesomeIcon size="sm" style={{  }} icon={faInfo} />}
				to="/planning/resources/projects"
				primaryText={translate('menu.planning.resources.projects')}
				onClick={onMenuClick}
				dense={dense}
			/>
			<MenuItemLink
				className={classes.menuitem}
				classes={menuItemClasses}
				leftIcon={<PlanningTeam />}
				// leftIcon={<FontAwesomeIcon size="sm" style={{  }} icon={faInfo} />}
				to="/planning/resources/people"
				primaryText={translate('menu.planning.resources.people')}
				onClick={onMenuClick}
				dense={dense}
			/>
			<MenuItemLink
				className={classes.menuitem}
				classes={menuItemClasses}
				leftIcon={<PlanningWorkstations />}
				// leftIcon={<FontAwesomeIcon size="sm" style={{  }} icon={faInfo} />}
				to="/planning/resources/workstations"
				primaryText={translate('menu.planning.resources.workstations')}
				onClick={onMenuClick}
				dense={dense}
			/>
			<MenuItemLink
				className={classes.menuitem}
				classes={menuItemClasses}
				leftIcon={<PlanningMisc />}
				// leftIcon={<FontAwesomeIcon size="sm" style={{  }} icon={faInfo} />}
				to="/planning/resources/misc"
				primaryText={translate('menu.planning.resources.misc')}
				onClick={onMenuClick}
				dense={dense}
			/>
			<MenuItemLink
				className={classes.menuitem}
				classes={menuItemClasses}
				leftIcon={<ProjectsAll />}
				// leftIcon={<FontAwesomeIcon size="sm" style={{  }} icon={faInfo} />}
				to="/projects"
				primaryText={translate('menu.projects.projects_all')}
				onClick={onMenuClick}
				dense={dense}
			/>
			<MenuItemLink
				className={classes.menuitem}
				classes={menuItemClasses}
				leftIcon={<ProjectsNegotiation />}
				// leftIcon={<FontAwesomeIcon size="sm" style={{  }} icon={faInfo} />}
				to="/projects-acquisition"
				primaryText={translate('menu.projects.acquisition')}
				onClick={onMenuClick}
				dense={dense}
			/>
			<MenuItemLink
				className={classes.menuitem}
				classes={menuItemClasses}
				leftIcon={<ProjectsOngoing />}
				// leftIcon={<FontAwesomeIcon size="sm" style={{  }} icon={faInfo} />}
				to="/projects-in-progress"
				primaryText={translate('menu.projects.in_progress')}
				onClick={onMenuClick}
				dense={dense}
			/>
			<MenuItemLink
				className={classes.menuitem}
				classes={menuItemClasses}
				leftIcon={<People />}
				// leftIcon={<FontAwesomeIcon size="sm" style={{  }} icon={faInfo} />}
				to="/people"
				primaryText={translate('menu.people')}
				onClick={onMenuClick}
				dense={dense}
			/>
			<MenuItemLink
				className={classes.menuitem}
				classes={menuItemClasses}
				leftIcon={<Companies />}
				// leftIcon={<FontAwesomeIcon size="sm" style={{  }} icon={faInfo} />}
				to="/companies"
				primaryText={translate('menu.companies')}
				onClick={onMenuClick}
				dense={dense}
			/>

			{resources
				.filter(r => r.hasList)
				.filter(r => r.icon)
				.map(resource => {

					// if(typeof console === 'object') { console.log('resource',resource); }

					let icon = <DefaultIcon />;
					if(typeof resource.icon === 'object') {
						icon = resource.icon;
					} else if(typeof resource.icon === 'string') {
						icon = <resource.icon />;
					}
					// if(typeof console === 'object') { console.log('icon?',typeof resource.icon, icon); }

					return(
						<MenuItemLink
							className={classes.menuitem}
							classes={menuItemClasses}
							key={resource.name}
							to={`/${resource.name}`}
							primaryText={translatedResourceName(resource, translate)}
							leftIcon={icon}
							onClick={onMenuClick}
							dense={dense}
					/>)
				})}

			<MenuItemLink
				className={classes.menuitem}
				classes={menuItemClasses}
				leftIcon={<AboutIcon />}
				// leftIcon={<FontAwesomeIcon size="sm" style={{  }} icon={faInfo} />}
				to="/about"
				primaryText={translate('menu.about')}
				onClick={onMenuClick}
				dense={dense}
			/>
			{1===2 && (isDeveloper) && <MenuItemLink
				className={classes.menuitem}
				classes={menuItemClasses}
				leftIcon={<CloudUpload />}
				// leftIcon={<FontAwesomeIcon size="sm" style={{  }} icon={faInfo} />}
				to="/dropzone-test"
				primaryText="Dropzone Test"
				onClick={onMenuClick}
				dense={dense}
			/>}
			{1===2 && isChris && <MenuItemLink
				className={classes.menuitem}
				classes={menuItemClasses}
				leftIcon={<CalendarIcon />}
				// leftIcon={<FontAwesomeIcon size="sm" style={{  }} icon={faInfo} />}
				to="/calendar"
				primaryText={translate('menu.calendar')}
				onClick={onMenuClick}
				dense={dense}
			/>}
			{1===2 && isChris &&<MenuItemLink
				className={classes.menuitem}
				classes={menuItemClasses}

				leftIcon={<AboutIcon />}
				// leftIcon={<FontAwesomeIcon size="sm" style={{  }} icon={faInfo} />}
				to="/test"
				primaryText="Test"
				onClick={onMenuClick}
				dense={dense}
			/>}
			{1===2 && isChris &&<MenuItemLink
				className={classes.menuitem}
				classes={menuItemClasses}

				leftIcon={<TextFormat />}
				// leftIcon={<FontAwesomeIcon size="sm" style={{  }} icon={faInfo} />}
				to="/fields"
				primaryText="Fields"
				onClick={onMenuClick}
				dense={dense}
			/>}
			{1===2 && isChris &&<MenuItemLink
				className={classes.menuitem}
				classes={menuItemClasses}

				leftIcon={<TextFormat />}
				// leftIcon={<FontAwesomeIcon size="sm" style={{  }} icon={faInfo} />}
				to="/editor"
				primaryText="Editor"
				onClick={onMenuClick}
				dense={dense}
			/>}
			{versions && <div className={styles.versionsRoot}>
				{/*<div>Versions</div>*/}
				<div>UI: {versions.frontEnd}</div>
				<div>API: {versions.backEnd}</div>
			</div>}
			<Responsive xsmall={logout} medium={null} />
		</div>
	)
};

Menu.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	dense: PropTypes.bool,
	hasDashboard: PropTypes.bool,
	logout: PropTypes.element,
	onMenuClick: PropTypes.func,
	pathname: PropTypes.string,
	resources: PropTypes.array.isRequired,
	translate: PropTypes.func.isRequired,
};

Menu.defaultProps = {
	onMenuClick: () => null,
};

const mapStateToProps = state => (() => {
	return {
		resources: getResources(state),
		user: state.user.data,
		pathname: state.router.location.pathname, // used to force redraw on navigation
	}
});

const enhance = compose(
	withTranslate,
	connect(
		mapStateToProps,
		{}, // Avoid connect passing dispatch in props,
		null,
		{
			areStatePropsEqual: (prev, next) =>
				prev.resources.every(
					(value, index) => value === next.resources[index] // shallow compare resources
				) &&
				prev.pathname === next.pathname
				// && prev.open === next.open,
		}
	),
	withStyles(styles),
);

export default enhance(Menu);
