// @link https://lodash.com/docs/4.17.5
import _ from 'lodash';


class FuncUtils {

	static init() {

	}

	static debounce(func,wait=0,options={}) {
		return _.debounce(func, wait,options);
	}

	static await(ms) {
		return function(x) {
			return new Promise(resolve => setTimeout(() => resolve(x), ms));
		};
	}

	static throttle(func,wait,options) {
		return _.throttle(func,wait,options);
	}

};

export default FuncUtils;
