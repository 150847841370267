import ObjectUtils from './ObjectUtils';

class Utils {

	static id_idx = 0;

	static _id = function() {
		Utils.id_idx++;
		return 'u'+Utils.id_idx;
	};

	/* String functions */
	static toHHMM = function(s) {
		var sec_num = parseInt(s, 10); // don't forget the second param
		var hours   = Math.floor(sec_num / 3600);
		var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
		if (hours   < 10) {hours   = "0"+hours;}
		if (minutes < 10) {minutes = "0"+minutes;}
		var time    = hours+'.'+minutes;
		return time;
	}

	/**
	 * debug changings in reacts shouldComponentUpdate
	 *
	 * Usage:
	   let updateProps = [ 'match', 'uav', 'appstate', 't' ];
	   Utils.changed(this.constructor.name, updateProps, this.props, props);
	 * @param name
	 * @param keys
	 * @param old
	 * @param v
	 * @returns {boolean}
	 */
	static changed(name, keys, old, v, debug = false) {
		for (var i = 0; i < keys.length; i++) {

			let isEqual = ObjectUtils.isEqual( v[ keys[ i ] ], old[ keys[ i ] ] );

			// if(typeof console === 'object') { console.log(
			// 	'%s CHANGED GOING THROUGH %s isEqual? %s',name,keys[i],isEqual
			// ); }

			// if (v[keys[i]] !== old[keys[i]]) { // <-- this is not correct
			if(!isEqual) {
				if(debug) {
					console.log(
						'%c %s: %s has changed from %o to %o', 'color: #c00',
						name, keys[i], old[keys[i]], v[keys[i]], isEqual
					);
				}
				return true
			}
		}
		return false
	}

};

export default Utils;