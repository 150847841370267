import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


function PlanningSplit(props) {
    return (
        <SvgIcon {...props}>
            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" role="img">
                <path d="M19.6,3.8h-2.1V1.5C17.5,1.2,17.3,1,17,1h-1.7c-0.3,0-0.5,0.2-0.5,0.5v2.2H9.2V1.5C9.2,1.2,9,1,8.7,1H7
	C6.7,1,6.5,1.2,6.5,1.5v2.2H4.4c-1.1,0-2.1,0.9-2.1,2.1v1.5c0,0.3,0.2,0.5,0.5,0.5H21c0.3,0,0.5-0.2,0.5-0.5V5.8
	C21.6,4.7,20.7,3.8,19.6,3.8z"/>
                <path d="M21.1,9.2H2.9c-0.3,0-0.5,0.2-0.5,0.5v11.2C2.4,22,3.3,23,4.5,23h15.1c1.1,0,2.1-0.9,2.1-2.1V9.8
	C21.6,9.5,21.4,9.2,21.1,9.2z M7.3,16.4c0.1-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.3-0.1,0.4-0.1h0.2v0.2
	c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.1,0.3-0.3,0.4S8,16.7,7.8,16.8c-0.1,0.1-0.3,0.1-0.4,0.1H7.2C7.3,16.7,7.3,16.6,7.3,16.4z
	 M10.2,19.6c-0.1,0.6-0.3,1.1-0.6,1.5c-0.3,0.4-0.8,0.7-1.3,0.7c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.3-0.1-0.4-0.1s-0.3,0.1-0.4,0.1
	c-0.1,0.1-0.3,0.1-0.4,0.1c-0.5,0-1-0.3-1.3-0.7c-0.3-0.4-0.5-0.9-0.6-1.5c-0.1-0.3-0.1-0.7,0-1.1c0.1-0.4,0.2-0.7,0.4-1
	s0.4-0.4,0.8-0.5C6,17,6.3,17,6.7,17.1c0.3,0.1,0.6,0.2,0.8,0.3c0.2-0.1,0.4-0.2,0.8-0.3C8.5,17,8.8,17,9,17
	c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.3,0.3,0.6,0.4,1C10.2,18.9,10.2,19.2,10.2,19.6z M15.9,12.5c1.3,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3
	s-2.3-1-2.3-2.3S14.6,12.5,15.9,12.5z M19.8,20.7c0,0.5-0.4,1-1,1h-5.9c-0.5,0-1-0.4-1-1v-0.6c0-1.3,1.1-2.4,2.4-2.4h0.2
	c0.4,0.2,0.9,0.3,1.4,0.3s1-0.1,1.4-0.3h0.2c1.3,0,2.4,1.1,2.4,2.4L19.8,20.7L19.8,20.7z"/>
                <title>Planning: Splitview</title>
            </svg>
        </SvgIcon>
    );
}

export default PlanningSplit;