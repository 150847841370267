// import request from 'superagent';
// import React, { Component } from 'react';
import ReduxStoreApi from './ReduxStoreApi';
import AppStateApi from './AppStateApi';
// import AppDataApi from './AppDataApi';
// import AppCacheApi from './AppCacheApi';
// import config from '../../src/host.config';
import { Mixin } from '../utils/Mixin';
// import XHR from '../utils/XHR';
import PHPOSApiGrid from './mixins/PHPOSApiGrid';
import PHPOSApiForm from './mixins/PHPOSApiForm';
import PHPOSApiList from './mixins/PHPOSApiList';
// import ServerAdapter from '../../src/store/adapter';
// import Msg           from '../utils/Msg';
// import i18n          from '../../src/i18n';

/**
 * PHPOSApi
 *
 * CLD_Config: change response
 * 1. split configuration and records
 * 2. get real fields of phpos definitions (to pass to model, or load model in own file config) and use cld config as "columns / fields" config
 *
 * te
 */

/**
 * create PHPOSApi
 * @param options
 * @returns {PP}
 */
export function createPHPOSApi(conf) {

	class PP extends PHPOSApi {}

	PP.config( conf );

	if ( 1 === 2 && typeof console === 'object' ) {
		console.log(
			'%c CreatePHPOSApi with options %o and contentKey, autoLoad?',
			'color:#f00',
			conf,
			PP.contentKey,
			PP.autoLoad
		);
	}

	return PP;
}


class PHPOSApiBase extends ReduxStoreApi {
// class PHPOSApiBase {

	static debug = false;

	static adapter = 'JSONAdapter';

	//
	static contentKey = null;
	// static phposclass = null;
	static baseParams = null;
	// static keepExtraParams = null;
	static sorting = null;
	//
	// static groupField = null;
	// static filterBy = null;
	// static filter = null;
	static stateKey = null;
	// static modelName = null;
	// static storeName = null;
	// static baseStoreName = null;
	// static cacheData = null;
	//
	// static gridConf = {};
	// static formConf = {};
	//
	// static remoteSort = true;
	// static remoteFilter = true;
	// static autoLoad = false;
	// static setRecord = false;
	// static reloadStoreOnCreate = true;
	//
	// static reloadRecordOnSave = false;
	//
	// static getStoreConf(contentData) {
	// 	contentData = contentData || {};
	// 	let obj = Object.assign( this, contentData );
	//
	// 	return this.getStore(obj).then(store => {
	// 		obj.store = store;
	// 		return obj;
	// 	});
	// }
	//
	// static getCacheKey(options) {
	// 	return this.getStateKey(options);
	// }
	//
	// static getCache(k, options = null)  {
	// 	let key = this.getCacheKey(options);
	//
	// 	if(key) {
	// 		return AppCacheApi.getCache([key, k]);
	// 	}
	//
	// 	return null;
	// }
	//
	// static setCache(k,v, options = null) {
	// 	let key = this.getCacheKey( options );
	// 	if ( key ) {
	// 		AppCacheApi.setCache( [ key, k ], v );
	// 	}
	// }

	static test() {
		if(typeof console === 'object') { console.log('PHPOSApiBase-TEST',true); }
		super.test();
	}

	static config(conf) {
		Object.keys(conf).forEach(key => {
			if(typeof this[key] !== 'undefined') {
				this[key] = conf[key];
			} else {
				if(typeof console === 'object') {
					console.log(
						'PHPOSApi.config, you are tryying to set the a variable %s in PHPOSApi, which is not yet defined',
						key
					);
				}
			}
		});
	}

	static getContentKey(contentData) {

		if(this.contentKey) {
			return this.contentKey;
		}

		if(!contentData) {
			console.warn('PHPOSApi - No contentData defined',contentData);
			return false;
		}

		let { contentKey } = contentData;

		if(!contentKey) {
			console.warn('PHPOSApi - No contentKey defined',contentData);
		}

		return this.contentKey = contentKey;
	}


	static getStateKey(options) {

		if(this.stateKey) {
			return this.stateKey;
		}

		let stateKey;

		if(options && options.stateKey) {
			stateKey = options.stateKey;
		}

		if(!stateKey) {
			let contentKey = this.getContentKey(options);
			stateKey = 'PHPOSApi-' + contentKey;
		}

		if(stateKey) {
			this.stateKey = stateKey;
		}

		return stateKey;
	}

	static getState(k, options) {
		let stateKey = this.getStateKey(options);

		if(stateKey) {
			return AppStateApi.getState([stateKey, k]);
		}

		return null;
	}

	static setState(k,v, options = null) {
		let stateKey = this.getStateKey( options );
		if ( stateKey ) {
			AppStateApi.setState( [ stateKey, k ], v );
		}
	}
}


class PHPOSApi extends Mixin(PHPOSApiBase).with(PHPOSApiGrid,PHPOSApiList,PHPOSApiForm) {

	test() {
		// super();
		if(typeof console === 'object') { console.log('PHPOSApi-TEST',true); }
	}
}
//
// PHPOSApiTest.test();

export default PHPOSApi;
