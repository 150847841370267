import React, { Component } from 'react';
import PropTypes         from 'prop-types';
import LocalApi           from '../../api/LocalApi';
import ObjectUtils from '../../../common/utils/ObjectUtils';

export default class UserAvatar extends Component {

	constructor( props ) {
		super( props );

		this.debug = false;

		this.loadProfile = this.loadProfile.bind(this);

		this.state = {
			src: props.src
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		const { user } = this.props;
		const { src } = this.state;
		let r = false;

		let isEqualUser = ObjectUtils.isEqual(user,nextProps.user);
		let isEqualSrc = ObjectUtils.isEqual(src,nextState.src);
		let isEqualProps = ObjectUtils.isEqual(this.props.src,nextProps.src);

		if((!isEqualUser) || (!isEqualSrc) || (!isEqualProps)) {
			r = true;
		} else if(src !== nextState.src) {
			r = true;
		}

		if(this.debug && typeof console === 'object') {
			console.log(
				'UserAvatar.shouldComponentUpdate RETURN ' +
				'r,nextProps, nextState,this.props,this.state',
				r,isEqualUser,isEqualSrc,isEqualProps, this.state,this.props,nextProps
			);
		}

		return r;
	}

	componentDidMount() {
		this.getJitUrl();
	}

	componentDidUpdate() {
		this.getJitUrl();
	}

	getJitUrl() {
		const { src, user } = this.props;

		// if(src) {
		// 	this.setState( {
		// 		src: src
		// 	} );
		// }

		if(typeof user !== 'object') {
			return false;
		}

		this.setState( {
			src: LocalApi.userAvatar(src,user.sid)
		} );
	}

	loadProfile(e) {
		const { loadProfile, user } = this.props;

		if(this.debug && typeof console === 'object') {
			console.log('loadProfile of user',user);
		}

		if(user && loadProfile !== false) {
			e.preventDefault();

			// ModalApi.openUserProfileWindow(user);
			if(typeof console === 'object') { console.log('loadProfile openUserProfileWindow not implemented YET',true); }
			return false;
		}
	}

	render() {
		let { src } = this.state;
		let { className, style, alt } = this.props;

		if(!className) {
			className = 'img--circle-preview medium';
		}

		return (
			<img
				style={style}
				alt={alt}
				onClick={this.loadProfile}
				className={className}
				src={src}
			/>
		);
	}
}

UserAvatar.propTypes = {
	// forceUpdate: PropTypes.bool,
	loadProfile: PropTypes.bool,
	src        : PropTypes.string,
	className  : PropTypes.string,
	user       : PropTypes.object, // sid, name
	style      : PropTypes.object,
};


// export default withTranslate()(ComponentTemplate);
