import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {GET_LIST, useMutation} from "react-admin";
import DateUtils from "../../../../../common/utils/DateUtils";
import LoadingIndicator from "../../../../../common/components/common/LoadingIndicator";
// import Typography from '@material-ui/core/Typography';
// import PropTypes                           from 'prop-types';
// import moment from "moment";
// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
	popover: {
		pointerEvents: 'none',
	},
	paper: {
		padding: theme.spacing(1),
		minWidth: '200px',
		maxWidth: '500px',
		minHeight: '20px',
	},
	title: {
		textTransform: 'uppercase',
		fontWeight: 600,
		marginBottom: '3px',
		display: 'block'
	},
	date: {
		display: 'block',
		marginBottom: '3px',
	},
	event: {
		margin: '3px 0 9px 0',
		'& > span': {
			display: 'inline-block',
			marginRight: '3px'
		}
	},
	eventInfos: {
		fontSize: '0.8em',
		'& > span': {
			display: 'inline-block',
			marginRight: '3px'
		}
	},
	project: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		paddingLeft: '12px',
		paddingRight: '12px',
		textOverflow: 'ellipsis',
		border: '1px solid rgba(255, 255, 255, 0.5)',
		borderRadius: '16px',
	},
	errorText: {
		display: 'inline-block',
		fontSize: '0.8em',
		maxWidth: '280px',
	}
}));

function ShowAvailableResourcesChip(props) {

	const {
		type,
		status,
		resourceData,
		fromDate,
		toDate,
		translate,
		...rest
	} = props;

	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const [data, setData] = useState(null);
	const [errorText, setError] = useState(false);

	const handlePopoverOpen = (event) => {

		if(status === 'free') {
			return false;
		}

		setAnchorEl(event.currentTarget);
		getEvents({});
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const getFilter = () => {

		let f = {
			fromDate: fromDate,
			toDate: toDate,
			// booking_status: r.booking_status,
		}

		if(type === 'human_resources') {
			f[ 'contact' ] = { selection: [resourceData['person']] };
		} else if(type === 'technical_resources') {
			f[ 'technical_resource' ] = { selection: [resourceData['id']] };
		}

		return {
			pagination: {
				page: 1,
				perPage: 5000
			},
			sort: {
				field: 'date', order: 'ASC'
			},
			filter: f,
			appParams: {noCache: true},
			groups: ['fullcalendar:read'],
			cacheTTL: 1000*60
		};
	};

	let [ mutate ] = useMutation();
	const getEvents = event => mutate(
		{
			type: GET_LIST,
			resource: 'calendar_events',
			payload: getFilter(event),
		},
		{
			// action: 'CUSTOM_FETCH_LOAD',
			onSuccess: ({ data }) => {
				// if(typeof console === 'object') { console.log('getOptions.data',event,data); }

				if(!data) {
					data = [];
				}

				setData(data);
				return data;

			},
			onFailure: (error) => {
				if(error && error.response && error.response.status === 500) {
					setError(translate('calendar.noEventPrivileges'));
				} else {
					setError(translate('calendar.error'));
				}
			},
		}
	);


	/**
	 * @id: "/api/calendar_events/1676"
	 @type: "CalendarEvent"
	 id: 1676
	 title: "Edit, Typo"
	 calendarTitle: "Offline Edit • Edit, Typo"
	 allDay: true
	 start: "2020-09-21T00:00:00+02:00"
	 end: "2020-09-27T00:00:00+02:00"
	 daysOfWeek: null
	 project: "/api/projects/434"
	 projectName: "BMW Vorstand"
	 projectNumber: "20157"
	 category: "/api/event_categories/28"
	 temptative: false
	 backgroundColor: "#0097a7"
	 categoryName: "Offline Edit"
	 attendees: []
	 peopleResources: ["/api/people/193", "/api/people/194"]
	 peopleResourcesNames: ["alex_m", "andré"]
	 technicalResources: ["/api/technical_resources/11"]
	 isMultiDay: true
	 notes: null
	 internalNotes: null
	 technicalResourcesNames: ["Avid 5"]
	 eventsGroup: "/api/events_groups/98"
	 cssClassNames: "normal grouped project"
	 * @param data
	 * @returns {JSX.Element}
	 */
	const showData = (data) => {

		let r = [];

		// if(typeof console === 'object') { console.log('showDatashowDatashowDatashowData',data); }

		if(data) {
			data.forEach((d) => {

				let date;
				// let startDate = moment(d.start);
				// let ff_compare_date =  M_day.format('YYYY-MM-DD');

				if(d.allDay) {
					date = DateUtils.format(d.start,DateUtils.getDateFormat());
					if(d.isMultiDay && d.end) {
						date += ' - ' + DateUtils.format(d.end,DateUtils.getDateFormat());
					}
				} else {
					date = DateUtils.format(d.start,DateUtils.getTimestampFormat());
					if(d.isMultiDay && d.end) {
						date += ' - ' + DateUtils.format(d.end,DateUtils.getTimestampFormat());
					}
				}

				// if(typeof console === 'object') { console.log('EVENT',d,date,typeof d.allDay, d.id); }

				let eventClassName = classes.event;
				if(d.temptative) {
					eventClassName = clsx(classes.event, 'temptative')

				}

				r.push(<div key={d.id} className={eventClassName}>
						<div className={classes.title}>{d.title}</div>
						<div className={classes.date}>{date}</div>
						<div className={classes.eventInfos}>
							{d.projectNumber &&
								<span>
									<i className="icon-hash"></i>&nbsp;
									<span className={classes.project} style={{backgroundColor: d.backgroundColor}}>
										{d.projectNumber} {d.projectName}
									</span>
								</span>
							}
							{d.categoryName && <span><i className="icon-tag-1"></i>&nbsp;{d.categoryName}</span>}
							{d.peopleResourcesNames && <span><i className="icon-user"></i>&nbsp;{d.peopleResourcesNames.join(', ')}</span>}
							{d.technicalResourcesNames && <span><i className="icon-direction"></i>&nbsp;{d.technicalResourcesNames.join(', ')}</span>}
						</div>
					</div>
				);


			});
		}

		if(r && r.length >= 0) {
			return r;
		}

		return null;

		// return <div>DATEN</div>;
	};

	const showLoader = (errorText) => {

		// let r = {errorText ? <div>{errorText}</div> : <LoadingIndicator type="container" /> }
		let r = <LoadingIndicator type="container" />;

		if(errorText) {
			r = <span className={classes.errorText}>{errorText}</span>;
		}

		return r;
	};


	return (
		<React.Fragment>
			<Chip
				{...rest}
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
			/>
			<Popover
				id="mouse-over-popover"
				className={classes.popover}
				classes={{
					paper: classes.paper,
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				{open && <div className="popover-event-wrapper">
					{
						data ?
							<div>{showData(data)}</div> :
							<div>{showLoader(errorText)}</div>
					}
				</div>}
			</Popover>
		</React.Fragment>
	);
};

ShowAvailableResourcesChip.defaultProps = {

};

ShowAvailableResourcesChip.propTypes = {
	// classes: PropTypes.object,
	// translate: PropTypes.func
};

export default ShowAvailableResourcesChip;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
