import React, { PureComponent } from 'react';
import PropTypes                from 'prop-types';
import Button                   from '@material-ui/core/Button';
import Menu                     from '@material-ui/core/Menu';
import MenuItem                 from '@material-ui/core/MenuItem';
import Badge                    from '@material-ui/core/Badge';
import MuiMenuItem              from './MuiMenuItem';
import Utils                    from '../../utils/Utils';
// import { Button }           from '@sencha/ext-modern';
// import { withNamespaces } from "react-i18next";
// import { connect } from 'react-redux';

import Backdrop   from '@material-ui/core/Backdrop';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import MuiButton  from './MuiButton';

/**
 * Prevents scrolling of content behind the backdrop.
 */
// class BackDropIOSWorkaround extends PureComponent {
// 	onTouchMove(event) {
// 		event.preventDefault();
// 	}
//
// 	render() {
// 		return (
// 			<Backdrop {...this.props} onTouchMove={this.onTouchMove}/>
// 		);
// 	}
// }


export default class MuiMenu extends PureComponent {

	constructor( props ) {
		super( props );
		this.state = {
			anchorEl: null,
		};

		this.baseId = Utils._id();

	}

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	// rewrite me!
	getTitle() {
		let { badgeText, text, titleClassName, iconClassName, badgeColor, badgeClassName,arrow } = this.props;

		let r;

		let tClass = 'button__textcontainer';

		if(titleClassName) {
			tClass += ' '+titleClassName;
		}

		if(iconClassName) {
			iconClassName += ' iconClassName';
			tClass = tClass + ' hasIcon ';
		}

		r = <div className={tClass}>
				{iconClassName &&<div className={iconClassName} />}
				{text && <div className="button__text">{text}</div>}
				{arrow && <div className="button__arrow--down x-fa fa-caret-down" />}
			</div>;

		if(badgeText) {
			r = <Badge className={badgeClassName} badgeContent={badgeText} color={badgeColor}>
				{r}
			</Badge>;
		}

		return r;
	}

	/**
	 * MenuItems as MUICalmMenuItem wrapped in a div container
	 * @returns {*}
	 */
	getMenuItems() {
		const { menuItems } = this.props;

		let r = [];

		if(
			typeof menuItems === 'object'
		) {
			menuItems.forEach((menuItem,index) => {

				let {closeOnClick, show, close, onClick, ...rest} = menuItem;

				if(typeof show !== 'undefined' && show === false) {
					return false;
				}

				if(typeof close === 'undefined') {
					close = true;
				}

				if(closeOnClick) {
					onClick = () => {
						let menuRef = this;
						if(close) {
							this.handleClose();
						}
						closeOnClick(menuRef);
					};
				}

				r.push(<MuiMenuItem
					key={index.toString()}
					onClick={onClick}
					{...rest}
				/>);
			});
		}



		return r.length ? r : null;
	}

	render() {
		const { anchorEl } = this.state;
		const {
			icon,
			// className,
			// style,
			wrapperClassName,
			// buttonColor,
			// buttonSize,
			// buttonVariant,
		} = this.props;

		let id = this.baseId + '_simple-menu';

		return (
			<div className={wrapperClassName}>
				<MuiButton
					// color="inherit"
					variant="contained"
					size="small"
					icon={icon}
					onClick={this.handleClick}
					// aria-controls={control}
					// aria-haspopup="true"
				>
					{this.getTitle()}
				</MuiButton>
				<Menu
					className="max__zindex"
					// id={id}
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={this.handleClose}
					// BackdropComponent={BackDropIOSWorkaround}
					BackdropProps={{
						invisible: true,
						onTouchMove: (event) => {
							event.preventDefault();
							this.handleClose();
						}
					}}
				>
					{this.getMenuItems()}
					{this.props.children}
					{/*<MenuItem onClick={this.handleClose}>Logout</MenuItem>*/}
				</Menu>
			</div>
		);
	}
}

MuiMenu.defaultProps = {
	badgeColor    : 'default',
	badgeClassName: 'none',
	badgeText     : 0,
	iconClass     : 'x-fa fa-ellipsis-v',
}

MuiMenu.propTypes = {
	// spin            : PropTypes.bool,
	disabled        : PropTypes.bool,
	// arrow           : PropTypes.bool,
	// iconCls         : PropTypes.string,
	badgeText       : PropTypes.any,
	iconClassName   : PropTypes.string,
	className       : PropTypes.string,
	style           : PropTypes.object,
	titleClassName  : PropTypes.string,
	icon            : PropTypes.any,
	text            : PropTypes.string,
	buttonColor      : PropTypes.string,
	buttonSize      : PropTypes.string,
	buttonVariant      : PropTypes.string,
	badgeColor      : PropTypes.string,
	badgeClassName  : PropTypes.string,
	wrapperClassName: PropTypes.string,
	menuItems       : PropTypes.array,
};

// export default withNamespaces()(ComponentTemplate);
// export default withNamespaces()(connect(null,null)(ComponentTemplate));
