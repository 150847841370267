import React, { FunctionComponent, useCallback } from 'react';
import PropTypes from 'prop-types';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormGroup, { FormGroupProps } from '@material-ui/core/FormGroup';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
// import { FieldTitle  } from 'ra-core';
import { useInput, InputProps } from 'ra-core';
// import sanitizeRestProps from './sanitizeRestProps';
// import {InputHelperText} from 'react-admin';
// import {InputPropTypes} from 'react-admin';
import FalseIcon from '@material-ui/icons/Clear';
import TrueIcon from '@material-ui/icons/Done';
import { Tooltip, Typography } from '@material-ui/core';
// import { TypographyProps } from '@material-ui/core/Typography';
import { useTranslate } from 'ra-core';

const GridBooleanInput: FunctionComponent<
    InputProps<SwitchProps> &
        Props & Omit<FormGroupProps, 'defaultValue' | 'onChange' | 'onBlur' | 'onFocus'>
    > = ({
                                     handleSubmit,
                                     format,
             label,
             fullWidth,
             helperText,
             onBlur,
             onChange,
             onFocus,
             options,
             disabled,
             parse,
             resource,
             source,
             validate,
             ...rest
         }) => {

    // if(typeof console === 'object') { console.log('GridBooleanInput 1',rest); }

    const {
        // id,
        input: { onChange: finalFormOnChange, type, value, ...inputProps },
        // isRequired,
        // meta: { error, touched },
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        type: 'checkbox',
        validate,
        ...rest,
    });

    // if(typeof console === 'object') { console.log('GridBooleanInput',inputProps,options,type, value,rest, typeof handleSubmit); }
    const translate = useTranslate();

    const handleChange = useCallback(
        (event, value) => {
            finalFormOnChange(value);

            if(typeof handleSubmit === 'function') {
                handleSubmit();
            }
        },
        [finalFormOnChange]
    );


    let ariaLabel = value === false ? 'ra.boolean.false' : 'ra.boolean.true';

    if (inputProps.checked === false) {
        return (
            <Typography
            //    {...sanitizeRestProps(rest)}
                component="span"
                variant="body2"
                onClick={(e) => {
                    e.stopPropagation();
                    handleChange(e, true);
                }}
                // className={className}
            >
                <Tooltip title={translate(ariaLabel, { _: ariaLabel })}>
                    <FalseIcon data-testid="false" />
                </Tooltip>
            </Typography>
        );
    }


    if (inputProps.checked === true) {
        return (
            <Typography
                //    {...sanitizeRestProps(rest)}
                component="span"
                variant="body2"
                onClick={(e) => {
                    e.stopPropagation();
                    handleChange(e, false);
                }}
                // className={className}
            >
                <Tooltip title={translate(ariaLabel, { _: ariaLabel })}>
                    <TrueIcon data-testid="true" />
                </Tooltip>
            </Typography>
        );
    }

    return (
        <Typography
            // className={className}
            // {...sanitizeRestProps(rest)}
            component="span"
            variant="body2"
        />
    );

    // return (
    //     <FormGroup {...sanitizeRestProps(rest)}>
    //         <FormControlLabel
    //             control={
    //                 <Switch
    //                     id={id}
    //                     color="primary"
    //                     onChange={handleChange}
    //                     {...inputProps}
    //                     {...options}
    //                     disabled={disabled}
    //                 />
    //             }
    //             label={
    //                 <FieldTitle
    //                     label={label}
    //                     source={source}
    //                     resource={resource}
    //                     isRequired={isRequired}
    //                 />
    //             }
    //         />
    //         <FormHelperText error={!!error}>
    //             <InputHelperText
    //                 touched={touched}
    //                 error={error}
    //                 helperText={helperText}
    //             />
    //         </FormHelperText>
    //     </FormGroup>
    // );
};

GridBooleanInput.propTypes = {
    // ...InputPropTypes,
    options: PropTypes.shape(Switch.propTypes),
    disabled: PropTypes.bool,
};

GridBooleanInput.defaultProps = {
    options: {},
};

export default GridBooleanInput;