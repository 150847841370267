import config             from '../../host.config.js';
// import React, { Component } from 'react';
// import { Mixin }          from '../../common/utils/Mixin';
import JSONServerAdapter  from './apdapter/JSONServerAdapter';
import PHPOSServerAdapter from './apdapter/PHPOSServerAdapter';
import FHEMServerAdapter  from './apdapter/FHEMServerAdapter';
import PouchServerAdapter from './apdapter/PouchServerAdapter';

/*
 import('./Baz')
 .then((Baz) => {
 console.log(Baz.Baz);
 });

 const Baz = require(foo).Baz;

*/
class ServerAdapterBase {

	static api = {
		path: config.api.path
	};

	static pageSize = 50;
	static idParam = 'id';
	static pageParam = '_page';
	static limitParam = '_limit';
	static rootProperty = 'records';
	static totalProperty = 'totat';

	static getApiPath(method) {}

	static getApiMethod( method ) {
		let m = 'GET';

		switch ( method ) {
			case 'DELETE':
			case 'PUT':
			case 'POST':
			case 'CREATE':
			default:
				m = 'POST';

		}

		return m;
	}
}

// export default {
export default class ServerAdapter {

	/**
	 * how to make this dynamic?
	 * @param adapter
	 * @returns {*}
	 */
	// getAdpapter(adapter) {
	static getAdpapter(adapter) {

		let r;

		switch(adapter) {
			case'PouchServerAdapter':
				r = PouchServerAdapter( ServerAdapterBase );
				break;
			case'FHEMServerAdapter':
				r = FHEMServerAdapter( ServerAdapterBase );
				break;
			case'PHPOSServerAdapter':
				r = PHPOSServerAdapter( ServerAdapterBase );
				break;
			default:
				r = JSONServerAdapter( ServerAdapterBase );
		}

		return r;
	}
}
