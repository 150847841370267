import React from 'react';
import ReactDOM from 'react-dom';
import HydraAdmin from './HydraAdmin';
import * as serviceWorker from './serviceWorker';

import config             from './host.config.js';
const entrypoint = config.api.path;
// // import registerServiceWorker from './languageServiceWorker';
// import './i18n';

ReactDOM.render(<HydraAdmin entrypoint={entrypoint} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
