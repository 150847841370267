import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


function PlanningWorkstations(props) {
    return (
        <SvgIcon {...props}>
            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" role="img">
                <g>
                    <path
                        d="M21.1,9.2H2.9c-0.3,0-0.5,0.2-0.5,0.5v11.2c0,1.1,0.9,2.1,2.1,2.1h15.1c1.1,0,2.1-0.9,2.1-2.1V9.8 C21.6,9.5,21.4,9.2,21.1,9.2z M11.8,12.8c0.1-0.5,0.2-0.8,0.4-1.1c0.2-0.2,0.4-0.3,0.7-0.4c0.2-0.1,0.4-0.1,0.7-0.1l0.3,0l0,0.3 c0,0.2,0,0.5-0.1,0.7c-0.1,0.3-0.2,0.5-0.4,0.7c-0.2,0.2-0.4,0.3-0.7,0.4c-0.2,0.1-0.4,0.1-0.7,0.1l-0.3,0 C11.8,13.2,11.8,13,11.8,12.8z M16.4,17.7c-0.2,0.9-0.5,1.7-0.9,2.3c-0.5,0.7-1.2,1.1-2.1,1.1c-0.2,0-0.4-0.1-0.7-0.2 c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.2,0.1-0.5,0.2-0.7,0.2c-0.8,0-1.5-0.4-2.1-1.1c-0.5-0.6-0.8-1.4-0.9-2.3 c-0.1-0.5-0.1-1.1,0-1.7c0.1-0.6,0.3-1.1,0.6-1.5c0.3-0.4,0.7-0.7,1.2-0.8c0.3-0.1,0.7,0,1.3,0.1c0.5,0.1,0.9,0.3,1.2,0.5 c0.3-0.2,0.7-0.3,1.2-0.5c0.5-0.1,1-0.2,1.3-0.1c0.5,0.1,0.9,0.4,1.2,0.8c0.3,0.4,0.5,0.9,0.6,1.5C16.5,16.6,16.5,17.1,16.4,17.7z"></path>
                    <path
                        d="M19.6,3.8h-2.1V1.5C17.5,1.2,17.3,1,17,1h-1.7c-0.3,0-0.5,0.2-0.5,0.5v2.2H9.2V1.5C9.2,1.2,9,1,8.7,1H7 C6.7,1,6.5,1.2,6.5,1.5v2.2H4.4c-1.1,0-2.1,0.9-2.1,2.1v1.5c0,0.3,0.2,0.5,0.5,0.5h18.2c0.3,0,0.5-0.2,0.5-0.5V5.8 C21.6,4.7,20.7,3.8,19.6,3.8z"></path>
                </g>
                <title>Planning: Workstations</title>
            </svg>
        </SvgIcon>
    );
}

export default PlanningWorkstations;