import React from 'react';
import {
    Button,
    useMutation,
    GET_ONE,
    // useRefresh,
    useNotify,
} from 'react-admin';
import PropTypes from "prop-types";
import {
    VerifiedUserSharp
} from "@material-ui/icons";
import authProvider from "../../../../../src/admin-store/authProvider";
import AppStateApi from "../../../../api/AppStateApi";

const SwitchUser = (props) => {

    const { label, resource, data } = props;

    // if(typeof console === 'object') { console.log('SwitchUser',props); }

    const notify = useNotify();
    const [getUser, { loading }] = useMutation({
        type: GET_ONE,
        resource: 'users',
        payload: {id: data.user},
        },
        {
            onSuccess: (user) => {
                // if(typeof console === 'object') { console.log('user',user); }
                if(user && user.username) {
                    do_switchUser(user.username);
                }
            },
            onFailure: error => notify('Error: User not found', 'warning'),
        }
    );

    const switchUser = () => {
        if(resource === 'users' && data.username) {
            do_switchUser(data.username);
        }
        else {
            getUser(data.user);
        }
    };


    const do_switchUser = (username) => {
        AppStateApi.clearState();
        authProvider.setUserSwitch(username);
        window.location.href = '/';//window.location.href;
    };


    return (
        <Button
            label={label}
            disabled={loading}
            onClick={switchUser}
        ><VerifiedUserSharp /></Button>
    );
};

SwitchUser.propTypes = {
    resource: PropTypes.string,
    label: PropTypes.any,
    data: PropTypes.object
};

export default SwitchUser;