import React, {useEffect, useState} from 'react';
import PropTypes                                   from 'prop-types';
import AutoSizer                                   from 'react-virtualized/dist/commonjs/AutoSizer';
import Datagrid                                    from './MyDatagrid';
import { makeStyles }                              from '@material-ui/core';
import {
	// useCheckMinimumRequiredProps,
	// useListController,
	getListControllerProps,
	// ComponentPropType,
}                                                  from 'ra-core';
import {
	Pagination,
	// DatagridLoading
}                     from 'react-admin';
import _ from 'lodash';
import ObjectUtils from "../../../utils/ObjectUtils";
// import isEqual from "lodash/isEqual";
// import FuncUtils      from '../../../utils/FuncUtils';
// import MaterialTableComposition       from 'material-table';
// import memoize                        from 'memoize-one';

const useStyles = makeStyles(theme => ({
	table: {
		tableLayout: 'auto',
	},
	headerCell: {
		position: 'sticky',
		top: 0,
		zIndex: 10,
		backgroundColor: theme.palette.background.paper,
	}

}));

/**
 * @param props
 * @returns {*}
 * @constructor
 */

function DataGridWrapper(props) {

	// if(typeof console === 'object') { console.log('props',props); }

	const {
		data,
		ids,
		// perPage,
		// page,
		// total,
		currentSort,
		setSort,
	} = props;

	const {
		conf,
		isRowSelectable,
		cellAndHeaderClassName,
		getSort,
		gridOptimized,
		groupBy,
		parentHeight,
		fullheight,

		// configFactory,
		...rest
	} = props;

	// if(typeof console === 'object') { console.log('DataGridWrapper props %o loaded %o',props,props.loaded); }

	const classes = useStyles();
	const [groupedIds, SetGroupedIds] = useState();
	const [groupIds, SetGroupIds] = useState();




	/**
		- Re-Sort ids by groupBy field
	 	- save groupedIds array
	 	-
	 */
	useEffect(() => {

		// let groupBy = ['status'];
		// if(typeof console === 'object') { console.log('groupBy',groupBy,ids,data); }


		if(!groupBy) {
			return undefined;
		}

		if(ids && ids.length > 0) {

			let newIds = [];
			let groupIdsObj = {};
			let lastId = null;
			let groupRowIds = [];

			ids.forEach((id) => {

				groupBy.forEach((group) => {
					let ad = data[id];
					let groupFieldValue = ad[group];
					let ld = lastId ? data[lastId] : null;

					// if(typeof console === 'object') { console.log('ad[group]',ad[group]); }
					if(!groupIdsObj[groupFieldValue]) {
						groupIdsObj[groupFieldValue] = [];
					}
					groupIdsObj[groupFieldValue].push(id);

					if(ld && ld[group] !== ad[group]) {
						let hasGroupTitle = ObjectUtils.findRecord(groupRowIds,{value: groupFieldValue});
						if(!hasGroupTitle) {
							groupRowIds.push({id:id,groupBy:group,value: groupFieldValue});
						}
					} else if(!ld) {
						groupRowIds.push({id:id,groupBy:group,value: groupFieldValue});
					}

					lastId = id;
				});
			});

			_.forEach(groupIdsObj, function(value, key) {
				newIds = newIds.concat(value);
			});

			SetGroupedIds(newIds);
			SetGroupIds(groupRowIds);
			// if(typeof console === 'object') { console.log('groupIdsObj,groupIds,newIds',ids,groupIdsObj,groupIds,newIds); }
		}
	},[
		data,
		ids,
		groupBy
	]);

	const _setSort = ( sorting, d, a ) => {

		// if(typeof console === 'object') { console.log('setSort',props,sorting,currentSort); }

		let order = 'ASC';

		if(currentSort && currentSort.field === sorting) {
			order = 'DESC';
		}

		if(conf) {
			conf.setGridSorting(sorting,order);
		}

		setSort(sorting,order);
	};

	const getHeight = (height) => {

		const filterToolbar = 72;
		const tHead = 0;//37;
		const pagingFooter = 52;
		// const minHeight = 64 || theme.mixins.toolbar.minHeight; // desktop style...

		height = height - filterToolbar - tHead - pagingFooter;

		if(parentHeight) {
			height = height - parentHeight;
		}

		return height;
	};

	const controllerProps = getListControllerProps(props);
	// if(typeof console === 'object') { console.log('controllerPropso',controllerProps,props); }
	controllerProps.rowsPerPageOptions = [5, 10, 25, 50, 75, 100];

	//React.createElement(Pagination, controllerProps)

	const getDatagrid = (width,height) => {
		// if(typeof console === 'object') { console.log('getDatagrid',width,height); }

		const wrSt = {
			width: width+'px',
			// width: '100%',
			height: height+'px',
			display: 'table',
			position: 'relative'
		};

		const scSt = {
			height: fullheight && props.total > 0 ? getHeight(height) + 'px' : 'auto',
			maxHeight: getHeight(height) + 'px',
			maxWidth: width  + 'px',
			overflowY: 'auto',
			overflowX: 'auto'
		};

		return (
			<div className="mvt__datagridwrapper" style={wrSt}>
				<div className="mvt__datagridwrapper--scroller" style={scSt}>
					<Datagrid
						{...rest}
						groupIds={groupIds}
						groupedIds={groupedIds}
						classes={classes}
						hasBulkActions={true}
						setSort={_setSort}
						hover={false}
						optimized={gridOptimized}
					>
						{props.children}
					</Datagrid>
				</div>
				<Pagination {...controllerProps} />
			</div>
		);
	};
	// const debounceRender = useCallback(FuncUtils.debounce(getDatagrid, 100), []);

	return (
		<AutoSizer
			//disableWidth={true}
			className="mvt__datagridwrapper--autosizer"
		>
			{({ width, height }) => {

				// if(typeof console === 'object') { console.log('DataGridWrapper.AutoSizer width, height',width, height); }
				// if(typeof console === 'object') { console.log('this.debounceRender(width, height,columns)',this.debounceRender(width, height,columns)); }

				if(width === 0 || height === 0) {
					return null;
				}

				return (getDatagrid(width,height));
				// return (debounceRender(width, height));
			}}
		</AutoSizer>

	);
};

DataGridWrapper.defaultProps = {

};

DataGridWrapper.propTypes = {
	data: PropTypes.object,
	ids: PropTypes.array,
	perPage: PropTypes.number,
	page: PropTypes.number,
	total: PropTypes.number,

	currentSort: PropTypes.object,
	getSort: PropTypes.func,
	fullheight:PropTypes.bool,
	parentHeight:PropTypes.number,
	// configFactory: PropTypes.func,
	// classes: PropTypes.object,
	// translate: PropTypes.func
};

function areEqual(prevProps, nextProps) {

	// let isEqualAll = ObjectUtils.fastDeepEqual(prevProps, nextProps);
	// if(isEqualAll) {
	// 	if(typeof console === 'object') { console.log('DataGridWrapper.isEqualAll',prevProps, nextProps); }
	// 	return true;
	// } else {
	// 	if(typeof console === 'object') { console.log('DataGridWrapper.isNOTEqualAll',prevProps, nextProps); }
	//
	// }

	// const { children: _, ...prevPropsWithoutChildren } = prevProps;
	// const { children: __, ...nextPropsWithoutChildren } = nextProps;
	// return isEqual(prevPropsWithoutChildren, nextPropsWithoutChildren);

	return false;

	let a = {
		children: prevProps.children,
		basePath: prevProps.basePath,
		currentSort: prevProps.currentSort,
		// data: prevProps.data,
		displayedFilters: prevProps.displayedFilters,
		filterValues: prevProps.filterValues,
		ids: prevProps.ids,
		// isRowSelectable: prevProps.isRowSelectable,
		loaded: prevProps.loaded,
		loading: prevProps.loading,
		page: prevProps.page,
		perPage: prevProps.perPage,
		resource: prevProps.resource,
		// rowClick: prevProps.rowClick,
		selectedIds: prevProps.selectedIds,
		total: prevProps.total,
	};
	let b = {
		children: nextProps.children,
		basePath: nextProps.basePath,
		currentSort: nextProps.currentSort,
		// data: nextProps.data,
		displayedFilters: nextProps.displayedFilters,
		filterValues: nextProps.filterValues,
		ids: nextProps.ids,
		// isRowSelectable: nextProps.isRowSelectable,
		loaded: nextProps.loaded,
		loading: nextProps.loading,
		page: nextProps.page,
		perPage: nextProps.perPage,
		resource: nextProps.resource,
		// rowClick: nextProps.rowClick,
		selectedIds: nextProps.selectedIds,
		total: nextProps.total,
	};

	let isEqualA = ObjectUtils.isEqual(a,b);
	if(isEqualA) {
		// if(typeof console === 'object') { console.log('DataGridWrapper.IS-Equal DO NOT UPDATE',prevProps, nextProps); }
		// if(typeof console === 'object') { console.log('DataGridWrapper.IS-Equal DO NOT UPDATE AB',isEqualA,a,b); }
		return true;
	} else {
		// if(typeof console === 'object') { console.log('DataGridWrapper.UPDATE isEqualA',isEqualA,a,b); }
		// if(typeof console === 'object') { console.log('DataGridWrapper.UPDATE',prevProps, nextProps); }
	}

	/*
	 return true if passing nextProps to render would return
	 the same result as passing prevProps to render,
	 otherwise return false
	 */
}
export default React.memo(DataGridWrapper,areEqual);
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
