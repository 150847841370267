// import request from 'superagent';
// import React, { Component } from 'react';
import React                       from 'react';
import CommonApi                   from '../../common/admin-api/CommonApi';
import UserAvatar                  from '../components/user/UserAvatar';
import DefaultProfilePicSVG        from '../../public/_img/icons/profile-picture.svg';
import MVTReferenceEditorInput     from '../../common/components/react-admin/form/fields/MVTReferenceEditorInput';
import SupervisedUserCircleRounded from '@material-ui/core/SvgIcon/SvgIcon';
import { useTranslate }            from 'react-admin';
import useGridStyles               from '../../common/components/helpers/useGridStyles';
import classNames                  from 'classnames';

/**
 * LocalApi
 *
 * CLD_Config: change response
 * 1. split configuration and records
 * 2. get real fields of phpos definitions (to pass to model, or load model in own file config) and use cld config as "columns / fields" config
 *
 *
 */

// if(typeof console === 'object') { console.log('PHPOSApi',PHPOSApi); }

class LocalApi extends CommonApi {
// class LocalApi  {

	static test() {
		// super();
		if(typeof console === 'object') { console.log('CISMApi-TEST',true); }
		super.test();
	}


	static userAvatar(src,sid) {

		//https://lp.dev7.cismteam.de/album-img/user/382/100x100,ex-crop-cc.jpg?f=/app/_img/icons/profile-picture.svg

		if(
			sid &&
			(typeof sid !== 'number') &&
			(sid.length === 0)
		) {
			sid = 0;
		} else if(!sid) {
			sid = 0;
		}

		if(!src) {
			src = '/album-img/user/'+sid+'/180x180,ex-crop-cc.jpg?f=/app/_img/icons/profile-picture.svg';
		}

		return src;
	}

	static _r_UserAvatar( value, record, v, user_sid ) {

		return <UserAvatar
			className="img--circle-preview small"
			src={v}
			user={{ sid: user_sid }}
		/>;
	}

	static _r_UserUserAvatar( value, record ) {
		if ( (!value) || (!value.length) ) {
			value = DefaultProfilePicSVG;
		}

		let user_sid = (record) ? record.get( 'sid' ) : null;

		return this._r_UserAvatar( value, record, value, user_sid);
	}


	static getNumbersInput(options = {}) {
		const translate = useTranslate();

		const {col,formTab,...rest} = options;

		return {
			col: col || 1,
			formTab: formTab || 2,
			JSX: <MVTReferenceEditorInput
				title={translate('resources.contact_numbers.name')}
				icon={<SupervisedUserCircleRounded />}
				filterField="contact"
				reference="contact_numbers"
				{...rest}
			/>
		};
	};

	static getAddressesInput(options = {}) {
		const translate = useTranslate();

		const {col,formTab,...rest} = options;

		return {
			col: col || 1,
			formTab: formTab || 2,
			JSX: <MVTReferenceEditorInput
				title={translate('resources.contact_addresses.name')}
				icon={<SupervisedUserCircleRounded />}
				filterField="contact"
				reference="contact_addresses"
				{...rest}
			/>
		};
	};

	static getNumbersListField(options = {}) {
		const translate = useTranslate();
		const classes = useGridStyles();

		const {col,...fieldProps} = options;

		return {

			MVTReferenceArrayField: true,
			isSortable: true,
			fieldProps: {
				label    : translate('fields.numbers'),
				cellAndHeaderClassName: classNames(classes.mw120,classes.breakpointDownMD),
				column: {
					tag: 'PhoneNumber',
				},
				...fieldProps
			}
		}
	}

	static getRunningProjectFilter() {
		// return {project:['!= running']};
		return {project:'running'};
	}

}

export default LocalApi;
