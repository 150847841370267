import React, { useState, useEffect, useCallback } from 'react';
import PropTypes                                   from 'prop-types';
import deburr                                      from 'lodash/deburr';
import Downshift                                   from 'downshift';
import { makeStyles }                              from '@material-ui/core/styles';
import TextField                                   from '@material-ui/core/TextField';
import Paper                                       from '@material-ui/core/Paper';
import MenuItem                                    from '@material-ui/core/MenuItem';
import ArrowDropDownIcon         from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon           from '@material-ui/icons/ArrowDropUp';
import Clear                     from '@material-ui/icons/Clear';
import { InputAdornment, Chip, Avatar }                            from '@material-ui/core';
import { IconButton }                                from '@material-ui/core';
import { useForm, useFormState }                     from 'react-final-form';
import ObjectUtils                                             from '../../../../utils/ObjectUtils';
import { withTranslate, LinearProgress }                                       from 'react-admin';
import CircularProgress                                        from '@material-ui/core/CircularProgress';
import { useMutation, GET_LIST, GET_ONE, GET_MANY, useNotify, useInput } from 'react-admin';
import FuncUtils                                                         from '../../../../utils/FuncUtils';
import Popper             from '@material-ui/core/Popper';
import StringUtils        from '../../../../utils/StringUtils';
import ListSubheader      from '@material-ui/core/ListSubheader';
import { useField }       from 'react-final-form'
import AppCacheApi        from '../../../../api/AppCacheApi';
import CreateDialogButton from '../actions/CreateDialogButton';

// import MenuList                                                from '@material-ui/core/MenuList';
// import LinearProgress from '../layout/LinearProgress';
// import Popper                    from '@material-ui/core/Popper';
// import Chip                                        from '@material-ui/core/Chip';
// import { LinearProgress }        from 'react-admin';

function renderInput(inputProps) {
	const { InputProps, classes, ref, ...other } = inputProps;

	// if(typeof console === 'object') { console.log('InputProps.ref',InputProps,other); }

	return (
		<TextField
			margin="dense"
			InputProps={{
				inputRef: ref,
				classes: {
					root: classes.inputRoot,
					input: classes.inputInput,
				},
				...InputProps,
			}}
			{...other}
		/>
	);
}

renderInput.propTypes = {
	/**
	 * Override or extend the styles applied to the component.
	 */
	classes: PropTypes.object.isRequired,
	InputProps: PropTypes.object,
};

function renderSuggestion(suggestionProps) {
	const { suggestion, index, autoFocusListItem, itemProps, highlightedIndex, selectedItem, titleField, idField, renderSuggestionFN } = suggestionProps;
	// let isHighlighted = highlightedIndex === index;

	let isSelected = false;
	if(selectedItem && ObjectUtils.isArray(selectedItem) ) {
		let record = ObjectUtils.findRecord(selectedItem,{id: suggestion[ idField ]});
		if(record) {
			isSelected = true;
		}
	} else if(selectedItem && typeof selectedItem === 'string') {
		isSelected = (selectedItem || '').indexOf( suggestion[ titleField ] ) > -1;
	} else if(selectedItem && typeof selectedItem === 'object') {
		isSelected = (selectedItem[ titleField ] || '').indexOf( suggestion[ titleField ] ) > -1;
	}

	// if(typeof console === 'object') { console.log('isSelected',itemProps,index,suggestion,isSelected,highlightedIndex); }

	if(suggestion.renderMenuItemtype && suggestion.renderMenuItemtype === 'ListSubheader') {
		return <ListSubheader
			disabled
			disableSticky={true}
			key={suggestion[titleField]}
		>
			{suggestion[titleField]}
		</ListSubheader>;
	}


	const isSelStyle = {
		fontWeight: isSelected ? 500 : 400,
	};

	return (
		<MenuItem
			{...itemProps}
			autoFocus={index === autoFocusListItem}
			key={suggestion[idField]}
			selected={isSelected || (highlightedIndex === index)}
			component="div"
			style={isSelStyle}
		>
			{/*{suggestion[titleField]}*/}
			{typeof renderSuggestionFN === 'function' ? renderSuggestionFN(suggestion) : suggestion[titleField]}
		</MenuItem>
	);
}

renderSuggestion.propTypes = {
	highlightedIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]).isRequired,
	index: PropTypes.number.isRequired,
	itemProps: PropTypes.object.isRequired,
	selectedItem: PropTypes.string.isRequired,
	suggestion: PropTypes.shape({
		label: PropTypes.string.isRequired,
	}).isRequired,
	renderSuggestionFN: PropTypes.func
};

function getSuggestions(data, value, {
		useSearch,
		titleField,
		showEmpty = false,
        keepValueOnly = false, // keep all choices
        removeValueOnly = true, // remove selected values from choices
        // removeItemsOnInputValueLength = false, // remove value on typing, but keep on selection
	} = {}
	) {
	const inputValue = deburr(value.trim()).toLowerCase();
	const inputLength = inputValue.length;
	let count = 0;

	if(!ObjectUtils.isArray(data)) {
		return [];
	}

	// if(typeof console === 'object') { console.log('',true); }
	let useIndexOf = true;

	return inputLength === 0 && !showEmpty
	       ? []
	       : data.filter(suggestion => {
	       	// if(typeof console === 'object') { console.log('suggestion',suggestion,titleField); }
			if(!suggestion) {
				return false;
			}

			if(!suggestion[titleField]) {
	       		return false;
	        }

			let keep = count < 50;

			// if(keep && removeItemsOnInputValueLength && inputValue) {
			//
			// }

			if(1===2 && typeof console === 'object') { console.log('keepValueOnly %o, removeValueOnly %O, suggestion %o, tolower %o',
				keepValueOnly,
				removeValueOnly,
				suggestion[titleField].toLowerCase(),
				suggestion[titleField].slice(0, inputLength).toLowerCase()); }

			if(keep && useSearch) {
				keep = true;
			}
			else if(keep && keepValueOnly) {
				if(useIndexOf) {
					keep = suggestion[titleField].toLowerCase().search(inputValue) >= 0 ? true : false;
				} else {
					keep = suggestion[titleField].slice(0, inputLength).toLowerCase() === inputValue;
				}
			}
			else if(keep && removeValueOnly && inputLength) {
				if(useIndexOf) {
					keep = suggestion[titleField].toLowerCase().search(inputValue) < 0 ? true : false;
				} else {
					keep = suggestion[titleField].slice(0, inputLength).toLowerCase() !== inputValue;
				}
				// if(typeof console === 'object') { console.log('removeValueOnly',keep,suggestion[titleField].slice(0, inputLength).toLowerCase(),inputValue); }
			}

			if (keep) {
				count += 1;
			}
			// if(typeof console === 'object') { console.log('renderSuggestions',keep,inputValue); }
			return keep;
		});
}

const useStyles = makeStyles(theme => ({
	root: {
		// marginTop: theme.spacing(1),
		// marginBottom: (theme.spacing(1) / 2),
		flexGrow: 1,
	},
	container: {
		flexGrow: 1,
		position: 'relative',
	},
	paperPopper: {
		backgroundColor: theme.palette.backgroundColors.popper,
		color: theme.palette.textColors.default,
		maxHeight: 300,
		overflow: 'auto'
	},
	paper: {
		position: 'absolute',
		zIndex: 1, //theme.zIndex.downshift + 1,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0,
		maxHeight: 200,
		overflow: 'auto'
	},
	downshiftPopper:{
		zIndex: theme.zIndex.popper
	},
	chip: {
		margin: theme.spacing(0.5, 0.25),
	},
	avatar: {
		margin: theme.spacing(0.5, 0.25),
		color: theme.palette.textColors.default,
		fontSize: '11px',
		width: 20,
		height: 20,
	},
	inputRoot: {
		flexWrap: 'wrap',
	},
	inputInput: {
		width: 'auto',
		flexGrow: 1,
	},
	divider: {
		height: theme.spacing(2),
	},
	placeholder: {
		// color: '#9f9f9f',
		color: theme.palette.placeholder.color
	},
}));



const DropdownAdornment = ({
	   isOpen,
	   openMenu,
	   onFocus,
	   closeMenu,
	   inputHasFocus,
	   setSelectedItem,
	   loading,
	   useSearch,
	   search,
       basePath,
       reference,
	   createReference,
       label,
       handleCreateSave,
       createButton,
	   resetButton
	}) => {

	// let createButton = true;
	// let loading = false;

	// if(typeof console === 'object') { console.log('rest',rest); }
	// return null;

	return (
		<InputAdornment position="end">
			{1===2 && loading ? <CircularProgress color="inherit" size={20} /> : null}
			{createButton && <CreateDialogButton
				basePath={basePath}
				reference={reference}
				createReference={createReference}
				label={label}
				handleEditorSave={handleCreateSave}
			/>}
			{resetButton &&<IconButton
				// tabIndex="-1"
				size="small"
				onMouseDown={(event) => {
					event.preventDefault();
					event.stopPropagation();

					setSelectedItem(null);
					// clearSelection(event);

					if(useSearch) {
						search('');
					}

					if(!isOpen) {
						onFocus(event);
					}
				}}
			>
				{loading ? <CircularProgress color="inherit" size={20} /> : <Clear fontSize="small" />}
			</IconButton>}
			<IconButton
				size="small"
				onMouseDown={(event) => {
					// if(typeof console === 'object') { console.log('ArrowDropUpIcon.event',isOpen,inputHasFocus()); }
					event.preventDefault();
					event.stopPropagation();

					if(isOpen) {
						closeMenu();
					} else {
						openMenu();
					}
				}}
			>
				{isOpen ?  <ArrowDropUpIcon fontSize="small" /> : <ArrowDropDownIcon fontSize="small" />}
			</IconButton>
		</InputAdornment>
	);
};

const joinSelectedItems = (selectedItem,titleField) => {
	let joined = [];

	if(selectedItem && selectedItem.length) {
		selectedItem.map( ( item ) => {
			if(item) {
				joined.push( item[titleField] );
			}
			return item;
		} );
	}

	return joined.join(', ');
};

const MultipleDropdownStartAdornment = (props) => {

	const {
		selectedItem,
		idField,
		titleField,
		classes,
		handleDelete,
		multipleDropdownStartAdornment,
		multipleDropdownStartAdornmentOptions = {},
		startAdornmentField,
		startAdornmentColorField,
	} = props;

	if(!multipleDropdownStartAdornment) {
		return null;
	}

	// if(typeof console === 'object') { console.log('MultipleDropdownStartAdornment',props); }

	const getLabel = (item) => {

		if(typeof startAdornmentField === 'function') {
			return startAdornmentField(item);
		}

		let field = startAdornmentField || titleField;
		let r = '';

		// if(multipleDropdownStartAdornment === 'avatar') {
		switch (multipleDropdownStartAdornment) {
			case'avatar':
				r = StringUtils.getInitials(item[field]);
				break;
			case'text':
				r = joinSelectedItems(selectedItem,startAdornmentField);
				break;
			default:
				r = item[field];
		};

		return r;
	};


	if(multipleDropdownStartAdornment === 'avatar') {
		//<Avatar>H</Avatar>

		return (
			selectedItem.map(item => (
				<Avatar
					key={item[idField]}
					tabIndex={-1}
					alt={item[titleField]}
					className={classes.avatar}
					{...multipleDropdownStartAdornmentOptions}
				>
					{getLabel(item)}
				</Avatar>
			))
		);
	}

	if(multipleDropdownStartAdornment === 'text') {
		if (selectedItem && selectedItem.length) {
			return <span className={classes.placeholder} {...multipleDropdownStartAdornmentOptions}>
				{getLabel(selectedItem)}
			</span>;
		}
	}

	return (
		selectedItem.map(item => {

			let {style, ...rest} = multipleDropdownStartAdornmentOptions;

			if(!style) {
				style = {};
			}

			let s = Object.assign({},{
				justifyContent: 'space-between',
				width         : '100%',
			},style);

			if(s && startAdornmentColorField && item && item[startAdornmentColorField]) {
				s.backgroundColor = item[startAdornmentColorField];
			}

			return (<Chip
				style={s}
				key={item[idField]}
				tabIndex={-1}
				label={getLabel(item)}
				className={classes.chip}
				onDelete={() => {handleDelete(item);}}
				{...rest}
			/>)
		})
	);

};

// let popperNode;
// let popperInputNode;

const useInputMultipleStyles = makeStyles(theme => ({
	root: {
		// marginTop: theme.spacing(1),
		// marginBottom: (theme.spacing(1) / 2),
		flexGrow: 1,
	},
	inputRoot: {
		flexWrap: 'wrap',
	},
	inputInput: {
		width: 'auto',
		flexGrow: 1,
	},
}));

const useInputSingleLineStyles = makeStyles(theme => ({
	root: {
		// marginTop: theme.spacing(1),
		// marginBottom: (theme.spacing(1) / 2),
		flexGrow: 1,
	},
	inputRoot: {
		flexWrap: 'nowrap',
	},
	inputInput: {
		width: 'auto',
		flexGrow: 1,
	},
}));

function DownshiftWrapper( props ) {

	const classes = useStyles();

	let {
		setSelectedItem,
		titleField,
		selectedRecord,
		loading,
		data,
		useSearch,
		onKeyPress,
		getPlaceholder,
		handleKeyDown,
		inputReadOnly,
		openMenuOnClick,
		setInputPropId,
		inputMultipleStyles,
		label,

		multiple,
		multipleDropdownStartAdornment,
		multipleDropdownStartAdornmentOptions,
		startAdornmentField,
		startAdornmentColorField,
		idField,
		handleDelete,
		type,
		setInputFocus,
		inputHasFocus,
		search,
		helperText,
		getAnchorEl,
		getContainerRefWidth,
		showEmpty,
		keepValueOnly,
		removeValueOnly,
		resetButton,
		renderSuggestionFN,

		// createButton
		reference,
		basePath,
		handleCreateSave,
		createButton,
		createReference
	} = props;

	return (
		<Downshift
			onChange={selection => {
				// if(typeof console === 'object') { console.log('onChange.selection',selection); }
				setSelectedItem( selection, 'onChange' );
			}}
			// onStateChange={(st) => {
			// 	if(typeof console === 'object') { console.log('onStateChange',st); }
			// 	// return inputValue && this.setState({ inputValue })
			// }}
			itemToString={item => {
				// if(typeof console === 'object') { console.log('itemToString',item,titleField); }
				return (item && item[titleField] ? item[titleField] : '')}
			}
			selectedItem={selectedRecord}
			defaultIsOpen={false}
			// initialInputValue={getVisibleValue(value)}
			// initialIsOpen={true}
			// defaultIsOpen={true}
		>
			{({
				  clearSelection,
				  getInputProps,
				  getItemProps,
				  getLabelProps,
				  getMenuProps,
				  highlightedIndex,
				  inputValue,
				  isOpen,
				  openMenu,
				  closeMenu,
				  toggleMenu,
				  selectedItem,
				  clearItems,
				  setHighlightedIndex,
			  }) => {

				if(loading) {
					clearItems();
				}

				// if(typeof console === 'object') { console.log('inputValue',loading,inputValue); }

				const { onBlur, onChange, onFocus, onClick, ...inputProps } = getInputProps({
					onChange: event => {
						// if(typeof console === 'object') { console.log('onChange',event.target.value); }
						if (event.target.value === '') {
							clearSelection();
						}
					},
					// onClick: () => {
					// 	if(type === 'select') {
					// 		openMenu();
					// 	}
					// },
					// onFocus: () => {
					// 	openMenu();
					// },
					onKeyUp: (event) => {
						// if(typeof console === 'object') { console.log('onKeyUp',event,highlightedIndex); }

						if(event.key === 'ArrowDown' || event.key === 'ArrowUp') {
							if (typeof highlightedIndex !== 'object' && highlightedIndex >= 0) {
								let recordAtHLIndex = data[ highlightedIndex ];
								// if ( typeof console === 'object' ) { console.log( 'recordAtHLIndex', recordAtHLIndex ); }
								if (
									recordAtHLIndex &&
									(recordAtHLIndex.renderMenuItemtype === 'ListSubheader' ||
									 recordAtHLIndex.skipHighlightedIndex === true)
								) {
									let higthligthFix = event.key === 'ArrowDown' ? 1 : -1;
									setHighlightedIndex( (highlightedIndex + higthligthFix) );
								}
							}
						}
						if(useSearch) {
							onKeyPress(event);
						}
					},
					onKeyDown: (event) => {
						// if(typeof console === 'object') { console.log('onKeyDown',inputValue); }
						handleKeyDown( event, inputValue );
					},
					placeholder: getPlaceholder(),
				});


				if(inputReadOnly) {
					inputProps.readOnly = true;
				}

				if(openMenuOnClick) {
					inputProps.onMouseDown = () => {
						// openMenu();
						toggleMenu();
					};
					inputProps.onFocus = () => {
						openMenu();
					};
				}

				let { id } = getInputProps();
				// inoutPropId = id;
				setInputPropId(id);

				// if(typeof console === 'object') { console.log('inputValue, selectedItem',inputValue, selectedItem); }
				// if(typeof console === 'object') { console.log('getInputProps',inoutPropId,getInputProps()); }
				// let suggestionInputValue = inputValue;
				// if(typeof console === 'object') { console.log('getMenuProps %o data %o selectedItem %o inputValue %o',getMenuProps(),data,selectedItem,inputValue); }

				// isOpen = true;
				let usePopper = true;

				// if(typeof console === 'object') { console.log('onFocus()',onFocus,inoutPropId,isOpen); }
				// if(typeof console === 'object') { console.log('props',props); }

				const paperStyle = { marginTop: 8, width: getContainerRefWidth(id)};

				return (

					<div
						className={classes.container}
						id={id + '-container'}
						// ref={ node => { popperNode = node; }}
					>


						{renderInput({

							fullWidth: true,
							classes: inputMultipleStyles,
							label: label,
							InputLabelProps: getLabelProps({
								shrink: true
							}),
							InputProps: {
								onClick,
								onBlur,
								onChange,
								onFocus,
								startAdornment: multiple ? <MultipleDropdownStartAdornment
									multipleDropdownStartAdornment={multipleDropdownStartAdornment}
									multipleDropdownStartAdornmentOptions={multipleDropdownStartAdornmentOptions}
									startAdornmentField={startAdornmentField}
									startAdornmentColorField={startAdornmentColorField}
									selectedItem={selectedItem}
									idField={idField}
									titleField={titleField}
									classes={classes}
									handleDelete={handleDelete}
								/> : null,
								endAdornment:  <DropdownAdornment
									type={type}
									loading={loading}
									setSelectedItem={setSelectedItem}
									clearSelection={clearSelection}
									isOpen={isOpen}
									onFocus={(type === 'select') ? inputProps.onFocus : setInputFocus}
									// onBlur={onBlur}
									// onMouseDown={onFocus}
									inputHasFocus={inputHasFocus}
									closeMenu={closeMenu}
									openMenu={openMenu}
									toggleMenu={toggleMenu}
									useSearch={useSearch}
									search={search}
									resetButton={resetButton}

									// createButton
									label={label}
									basePath={basePath}
									reference={reference}
									handleCreateSave={handleCreateSave}
									createButton={createButton}
									createReference={createReference}
								/>
							},
							inputProps,
							helperText: helperText,
							// ref: node => {
							// 	popperNode = node;
							// },
							// ref: textInputRef
						})}


						{usePopper ?
						 <Popper className={classes.downshiftPopper} placement="bottom-start" open={isOpen} anchorEl={getAnchorEl(id)}>
							 <div {...(isOpen ? getMenuProps({}, { suppressRefError: true }) : {})}>
								 <Paper
									 square
									 className={classes.paperPopper}
									 style={paperStyle}
								 >

									 {getSuggestions(data,  inputValue, {
										 useSearch: useSearch,
										 titleField: titleField,
										 showEmpty: showEmpty,
										 keepValueOnly: keepValueOnly,
										 removeValueOnly: removeValueOnly,
										 // removeItemsOnInputValueLength: removeItemsOnInputValueLength
									 }).map((suggestion, index) =>
										 renderSuggestion({
											 titleField: titleField,
											 idField: idField,
											 suggestion,
											 index,
											 // itemProps: getItemProps({ index:index,item: suggestion[titleField] }),
											 itemProps: getItemProps({ key: suggestion[idField], index:index, item: suggestion }),
											 highlightedIndex,
											 selectedItem,
											 renderSuggestionFN,
										 }),
									 )}
								 </Paper>
							 </div>
						 </Popper>
						           :
						 <div {...getMenuProps()} className={classes.downshiftMenu}>
							 {isOpen ? (
								 <Paper className={classes.paper} square>
									 {getSuggestions(data,  inputValue, {
										 titleField: titleField,
										 showEmpty: showEmpty,
										 keepValueOnly: keepValueOnly,
										 removeValueOnly: removeValueOnly,

									 }).map((suggestion, index) =>
										 renderSuggestion({
											 titleField: titleField,
											 idField: idField,
											 suggestion,
											 index,
											 // itemProps: getItemProps({ item: suggestion[titleField] }),
											 itemProps: getItemProps({ item: suggestion }),
											 highlightedIndex,
											 selectedItem,
											 renderSuggestionFN,
										 }),
									 )}
								 </Paper>
							 ) : null}
						 </div>
						}


					</div>

				);
			}}
		</Downshift>
	);

}

function areEqualD(prevProps, nextProps) {

	// let isEqualAll = ObjectUtils.fastDeepEqual(prevProps, nextProps);
	// if(isEqualAll) {
	// 	if(typeof console === 'object') { console.log('isEqualAll',prevProps, nextProps); }
	// 	return true;
	// } else {
	// 	if(typeof console === 'object') { console.log('isNOTEqualAll',prevProps, nextProps); }
	// }
	// return false;
	let a = {
		selectedRecord: prevProps.selectedRecord,
		loading: prevProps.loading,
		data: prevProps.data,
		// search: prevProps.search,
		helperText: prevProps.helperText
	};
	let b = {
		selectedRecord: nextProps.selectedRecord,
		loading: nextProps.loading,
		data: nextProps.data,
		// search: nextProps.search,
		helperText: nextProps.helperText
	};

	let isEqualA = ObjectUtils.fastDeepEqual(a,b);
	if(isEqualA) {
		// if(typeof console === 'object') { console.log('DownshiftWrapper.areEqual',prevProps, nextProps); }
		// if(typeof console === 'object') { console.log('this.props.isEqualA',isEqualA,a,b); }
		return true;
	} else {
		// if(typeof console === 'object') { console.log('this.props.NOT isEqualA',isEqualA,a,b); }
		// if(typeof console === 'object') { console.log('isEqualA',prevProps, nextProps); }
	}

	/*
	 return true if passing nextProps to render would return
	 the same result as passing prevProps to render,
	 otherwise return false
	 */
}


const DownshiftWrapperMemo = React.memo(DownshiftWrapper,areEqualD);

/**
 * React-Admin Autocomplete
 *
 * @param props
 * @returns {*}
 * @constructor
 */
function IntegrationDownshift(props) {
	// const ref = useRef();

	const {
		translate,
		label,
		source,
		choices,
		helperText,
		idField,
		showEmpty,
		callback,
		submit, // inline editor form submit
		multiple,
		combineData,
		permanentFilter,
		startAdornmentField,
		startAdornmentColorField,
		createButton,
		createReference,
		resetButton,
		renderSuggestionFN,
		setParent
	} = props;


	let {
		loadResource,
		reference,
		withForm,
		keepValueOnly,
		removeValueOnly,
		// removeItemsOnInputValueLength,
		useSearch,
		type,
		multipleDropdownStartAdornment,
		multipleDropdownStartAdornmentOptions,
		placeholder,
		openMenuOnClick,
		inputReadOnly,
		rootStyle,
		value,
		initialValue,
	} = props;

	let inputMultipleStyles = useInputMultipleStyles();
	const classes = useStyles();

	value = value || initialValue;

	// let debugResource =
	// if(reference === 'projects' && typeof console === 'object') { console.log('IntegrationDownshift props',reference,value,initialValue); }

	// use as standard select
	if(type === 'select') {
		keepValueOnly = false;
		removeValueOnly = false;
		useSearch = false;
		inputMultipleStyles = useInputSingleLineStyles;
		openMenuOnClick = true;
		inputReadOnly = true;
	} else if(type === 'filterselectsearch') {
		openMenuOnClick = true;
		useSearch = true;
		loadResource = true;
		withForm=false;
		inputMultipleStyles = useInputSingleLineStyles;
		// multipleDropdownStartAdornment = 'avatar';
	} else {
		if(multiple) {
			keepValueOnly = true;
		} else {
			// removeItemsOnInputValueLength = true;
			keepValueOnly = true;
			inputMultipleStyles = useInputSingleLineStyles;
		}
	}

	let inoutPropId = null;
	let isMounted = true;
	const notify = useNotify();
	const [searchString,setSearchString] = useState(null);
	const [isInitialized,setIsInitialized] = useState(false);
	const [dataLoaded,setDataLoaded] = useState(false);
	const [selectedRecordsFound,setSelectedRecordsFound] = useState(false);


	let form = null;
	let formState = null;
	let _inputProps = null;

	if(withForm) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		form = useForm();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		formState = useFormState();

		// eslint-disable-next-line react-hooks/rules-of-hooks
		_inputProps = useInput({
			resource: reference,
			source: source,
		});

		// eslint-disable-next-line react-hooks/rules-of-hooks
		// let fieldProps = useField(source,{
		// 	resource: reference,
		// 	source: source,
		// });

		// if(typeof console === 'object') { console.log('_inputProps_inputProps_inputProps_inputProps_inputProps VALUE',formState,_inputProps,_inputProps.input.value); }
		// if(typeof console === 'object') { console.log('formStateformStateformState',reference,_inputProps,source,formState,form.getFieldState(source),props); }
	}

	let {
		optionText,
		titleField,
		searchField
	} = props;

	if(optionText) {
		titleField = optionText;
	}

	let initialSelectedRecord = {};
	if(multiple) {
		initialSelectedRecord = [];
	}

	const getFilter = (event) => {

		let f = {};

		if(permanentFilter) {
			f = ObjectUtils.clone(permanentFilter);
		}

		// let sortField = (searchString) ? searchTitleField : titleField;

		if(event && event.search) {
			let q = searchField || titleField;
			f[q] = event.search;
		}

		// if(typeof console === 'object') { console.log('getFilter',reference,event,f,permanentFilter); }

		return {
			pagination: {
				page: 1,
				perPage: 100
			},
			sort: {
				field: titleField, order: 'ASC'
			},
			filter: f,
			localParams: {test: true}
		};
	};

	const getChoices = () => {
		let r = choices;
		if(loadResource) {
			const cache = AppCacheApi.getCache(GET_LIST, reference, getFilter());
			if(cache) {
				// if(typeof console === 'object') { console.log('CACHHHHHHEEEEE',cache); }
				r = cache.data;
			}
		}
		if(typeof combineData === 'function') {
			r = combineData(r);
		}
		return r;
	};

	// const [data,setData] = useState(getChoices());
	const [data,setData] = useState(null);
	const [selectedRecord, setSelectedRecord] = React.useState(initialSelectedRecord);
	const [loading, setLoading] = React.useState(false);

	if(formState && formState.values && ObjectUtils.get(formState.values,source) ) {
		value = ObjectUtils.get(formState.values,source);
	} else if(_inputProps && _inputProps.input && _inputProps.input.value) {
		value = _inputProps.input.value;
	}

	// if(typeof console === 'object') { console.log('formState',reference,value); }
	// if(typeof console === 'object') { console.log('formState',reference,formState); }
	// if(typeof console === 'object') { console.log('AutoComplete',reference, value,props); }

	const setCompleteData = (data) => {
		let completeData = data;
		if(typeof combineData === 'function') {
			completeData = combineData(data);
		}
		// if(typeof console === 'object') { console.log('completeData',typeof combineData, completeData); }
		setData( completeData );
		if(!dataLoaded) setDataLoaded( true );

	};

	const selectItems = () => {
		if(!isInitialized) {
			// if(reference === 'people' && typeof console === 'object') { console.log('selectItems,value',withForm,reference,isInitialized,value,data); }
			getSelectedItem( value );
		}
	};

	React.useEffect(() => {
		setData(getChoices());

		if ( typeof setParent === 'function' ) {
			// export these functions to parent
			setParent( {
				getSelectedItem:getSelectedItem,
				reference: reference,
			} );
		}

	}, []);

	React.useEffect(() => {
		// if(typeof console === 'object') { console.log('useEffect.selectItems',reference,data); }
		if(data && data.length) {
			selectItems();
		}
	}, [data]);

	React.useEffect(() => {
		if(loadResource) {
			loadItems();
		}
		return () => {
			isMounted = false;
		}
	}, [loadResource]);

	// do we need this?
	// very strange is, that coices changes,
	// if we change the value of the form
	// if we really need it, try after "isInitialized"
	React.useEffect(() => {

		let isEqual = ObjectUtils.fastDeepEqual(data,choices);

		// if(typeof console === 'object') { console.log('choices.data',isEqual,data,choices); }
		if( (!isEqual) && (!loadResource) ) {
			setCompleteData( choices );
		} else if(isEqual === true && (!loadResource)) {
			if(!dataLoaded) setDataLoaded( true );
		}
	}, [choices]);

	useEffect(() => {
		// if(typeof console === 'object') { console.log('useEffect.useSearch,typeof searchString,searchString',useSearch,typeof searchString,searchString); }
		if(useSearch && typeof searchString === 'string') {
			loadItems();
		}
	},[searchString]);


	// React.useEffect(() => {
	// 	if(typeof console === 'object') { console.log('useEffect,value',withForm,reference,value,props,_inputProps); }
	// 	getSelectedItem( value );
	// }, [value]);

	React.useEffect(() => {
		selectItems();
	}, [value]);

	// React.useEffect(() => {
	// 	if(typeof console === 'object') { console.log('useEffect,value',withForm,reference,isInitialized,value,props,formState,_inputProps); }
	// 	getSelectedItem( value );
	// }, []);



	let [ mutate ] = useMutation();
	const getOptions = event => mutate(
		{
			type: GET_LIST,
			resource: reference,
			payload: getFilter(event),
		},
		{
			// action: 'CUSTOM_FETCH_LOAD',
			onSuccess: ({ data }) => {
				// if(typeof console === 'object') { console.log('getOptions.data',reference,data); }

				if(!data) {
					data = [];
				}

				if(isMounted) {
					// setData( data );
					setCompleteData( data );
					setLoading( false );
					if(!dataLoaded) setDataLoaded( true );
				}
				return data;

			},
			onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
		}
	);

	let [ getOne ] = useMutation();
	const getSelectedRecord = event => getOne(
		{
			type: GET_ONE,
			resource: reference,
			payload: { id: event.id },
		},
		{
			onSuccess: ({ data }) => {
				// if(typeof console === 'object') { console.log('data',data); }
				if(isMounted) {
					setSelectedRecord( data );
					setLoading( false );
					if(!selectedRecordsFound) setSelectedRecordsFound( true );
				}
				return data;

			},
			onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
		}
	);

	let [ getMany ] = useMutation();
	const getSelectedRecords = event => getMany(
		{
			type: GET_MANY,
			resource: reference,
			payload: { ids: event.ids },
		},
		{
			onSuccess: ({ data }) => {
				// if(typeof console === 'object') { console.log('data',data,event); }
				if(isMounted) {
					let d = data;
					if(event.foundRecords && event.foundRecords.length) {
						d = d.concat(event.foundRecords);
						// if(typeof console === 'object') { console.log('ALLLLLL RECORDS',d); }
					}
					setSelectedRecord( d );
					// if(typeof console === 'object') { console.log('setSelectedRecord',d); }
					setLoading( false );
					if(!selectedRecordsFound) setSelectedRecordsFound( true );
				}
				return data;

			},
			onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
		}
	);

	const loadItems = () => {
		setLoading(true);
		getOptions({
			search: useSearch ? searchString : null
		});
	};

	const search = (s) => {
		// if(typeof console === 'object') { console.log('s',s); }
		setSearchString(s);
	};

	const onKeyPress = (ev) => {
		if (ev.key === 'Enter') {
			ev.preventDefault();
		}
		debounceSearch(ev.target.value);
	};

	const debounceSearch = useCallback(FuncUtils.debounce(search, 500), []);

	const getSelectedItems = (val) => {

		// if(typeof console === 'object') { console.log('getSelectedItems',val); }

		if(!val) {
			// if(!selectedRecordsFound) setSelectedRecordsFound( true );
			if(!selectedRecordsFound) {
				setSelectedRecord([]);
				setSelectedRecordsFound( true );
			}
			return [];
			// return;
		} else if(val && (!val.length)) {
			// if(typeof console === 'object') { console.log('getSelectedItems.selectedRecordsFound',selectedRecordsFound,val); }
			// if(!selectedRecordsFound) setSelectedRecordsFound( true );
			if(!selectedRecordsFound) {
				setSelectedRecord([]);
				setSelectedRecordsFound( true );
			}
			return [];
			// return;
		}

		// if(typeof console === 'object') { console.log('getSelectedItem',reference,val,selectedRecord); }

		let allRecordsFound = true;
		let foundRecords = [];
		let missingRecords = [...val];

		if(selectedRecord && selectedRecord.length && val && val.length) {
			val.forEach((v) => {

				let vID = v;
				if(v && typeof v === 'object' && v.id) {
					vID = v.id;
				}

				let hasRecord = ObjectUtils.findRecord(selectedRecord,{id: vID});
				if(!hasRecord) {
					allRecordsFound = false;
				} else {
					foundRecords.push(hasRecord);
					let remove = ObjectUtils.indexOf(missingRecords,v);
					missingRecords = ObjectUtils.reomveIndexFromArray(missingRecords,remove);
					// if(typeof console === 'object') { console.log('foundRecords,missingRecords',reference,foundRecords,missingRecords); }

				}
			});
			// if(typeof console === 'object') { console.log('foundRecords,missingRecords',reference,allRecordsFound,foundRecords,missingRecords); }

			if(allRecordsFound) {
				// setSelectedRecord(foundRecords);
				if(!selectedRecordsFound) setSelectedRecordsFound( true );
				return selectedRecord;
			}
		}

		// if(typeof console === 'object') { console.log('allRecordsFoundo',selectedRecord); }

		allRecordsFound = true;
		// let records = [];
		missingRecords.forEach((v) => {
			let record = ObjectUtils.findRecord(data,{id: v});
			if(record) {
				// records.push(record);
				foundRecords.push(record);
				let remove = ObjectUtils.indexOf(missingRecords,v);
				missingRecords = ObjectUtils.reomveIndexFromArray(missingRecords,remove);
				// if(typeof console === 'object') { console.log('foundRecords,missingRecords',data,v,missingRecords,remove,foundRecords,missingRecords); }

			} else {
				allRecordsFound = false;
			}
		});

		// if(typeof console === 'object') { console.log('foundRecords,missingRecords',reference,allRecordsFound,foundRecords,missingRecords); }

		if(allRecordsFound) {
			setSelectedRecord(foundRecords);
			if(!selectedRecordsFound) setSelectedRecordsFound( true );
			return foundRecords;
		}

		// clean missing missingRecords from missing
		// api ids through combined data
		missingRecords = missingRecords.filter(
			id => id.indexOf('/api') === 0 ? true : false
		);

		// if(typeof console === 'object') { console.log('getSelectedRecords?',reference,missingRecords,foundRecords,data); }


		if(missingRecords && missingRecords.length) {
			getSelectedRecords({
				ids: missingRecords,
				foundRecords: foundRecords
			});
		} else if(foundRecords && foundRecords.length) {
			setSelectedRecord( foundRecords );
			setLoading( false );
			if(!selectedRecordsFound) setSelectedRecordsFound( true );
		}
	};

	const getSelectedItem = (val) => {
		if(multiple) {
			return getSelectedItems(val);
		}

		if(!val) {
			if(!selectedRecordsFound) setSelectedRecordsFound( true );
			return;
		} else if(val && (!val.length)) {
			if(!selectedRecordsFound) setSelectedRecordsFound( true );
			return;
		}

		if(selectedRecord && selectedRecord.id === val) {
			if(!selectedRecordsFound) setSelectedRecordsFound( true );
			return selectedRecord;
		}

		if(val) {
			let record = ObjectUtils.findRecord(data,{id: val});
			// if(reference === 'projects' && typeof console === 'object') { console.log('getSelectedItemgetSelectedItemgetSelectedItem',reference,record,data,val); }
			if(record) {
				setSelectedRecord(record);
				if(!selectedRecordsFound) setSelectedRecordsFound( true );
				return record;
			} else {
				getSelectedRecord({
					id: val
				});
			}
		}

		return selectedRecord;
	};


	/**
	 * handle multipe delete
	 * @param item
	 * @returns {Function}
	 */
	const handleDelete = (item) => {
		setIsInitialized(true);
		const newSelectedItem = [...selectedRecord];

		ObjectUtils.remove(newSelectedItem,(r) => {
			return r.id === item.id;
		});

		setSelectedRecord( newSelectedItem );

		if(form) {
			let values = [];
			newSelectedItem.forEach((r) => {
				values.push(r.id);
			});
			// if(typeof console === 'object') { console.log('form.source',source,values); }

			form.change( source, values );
		}

		if(typeof callback === 'function') {
			callback(newSelectedItem);
		}

		if(typeof submit === 'function') {
			submit();
		}
	};

	/**
	 * mulitple key backspace delete
	 * @param event
	 */
	const handleKeyDown = (event,inputValue) => {
		// if(typeof console === 'object') { console.log('Input.handleKeyDown',inputValue,event,event.key); }
		if (selectedRecord.length && !inputValue.length && event.key === 'Backspace') {
			// let d = selectedRecord.slice(0, selectedRecord.length - 1);
			let d = selectedRecord[selectedRecord.length - 1];
			// setSelectedRecord(d);
			handleDelete(d);
		}
		// else if(event.key === 'ArrowDown') {
			// setAutoFocusListItem(0);
		// }
	};

	// const handleListKeyDown = (event,isOpen,toggleMenu) => {
	// 	// if(typeof console === 'object') { console.log('handleListKeyDown.event.key',event.key); }
	//
	// 	if (event.key === 'Tab') {
	// 		event.preventDefault();
	// 		if(isOpen) {
	// 			toggleMenu();
	// 		}
	// 	}
	// };

	/**
	 * select multiple records
	 * @param s
	 */
	const setSelectedItems = (s,action) => {

		// input field changed to empty string
		if(action === 'onChange' && s === null) {
			return;
		}

		let newSelectedItem = [];

		if(s) {
			newSelectedItem = [...selectedRecord];

			let hasRecord = ObjectUtils.findIndex(newSelectedItem,{id: s.id});
			// if(typeof console === 'object') { console.log('newSelectedItem, s, action, hasRecord',newSelectedItem, s, action, hasRecord); }
			// Delete record from selection if clicked twice / again
			if(action === 'onChange' && hasRecord >= 0) {
				return handleDelete(s);
			}

			if (hasRecord === -1) {
				newSelectedItem = [...newSelectedItem, s];
			}

			setSelectedRecord( newSelectedItem );

			if(form) {
				let values = [];
				newSelectedItem.forEach((r) => {
					values.push(r.id);
				});
				// if(typeof console === 'object') { console.log('form.source',source,values); }

				form.change( source, values );
			}
		} else {
			setSelectedRecord( newSelectedItem );
			if(form) {
				form.change( source, [] );
			}
		}
		// if(typeof console === 'object') { console.log('setSelectedItems',newSelectedItem); }
		if(typeof callback === 'function') {
			callback(newSelectedItem);
		}

		if(typeof submit === 'function') {
			submit();
		}
	};

	/**
	 * select single record
	 * @param s
	 */
	const setSelectedItem = (s,action='reset') => {
		setIsInitialized(true);

		if(multiple) {
			return setSelectedItems(s,action);
		}

		if(s) {
			let v = s[idField];
			setSelectedRecord( s );
			if(form) {
				form.change( source, v );
			}
		} else {
			setSelectedRecord( {} );
			if(form) {
				form.change( source, null );
			}
		}

		if(typeof callback === 'function') {
			callback(s);
		}

		if(typeof submit === 'function') {
			submit();
		}
	};

	const handleCreateSave = (redirect,id,record) => {
		// if(typeof console === 'object') { console.log('handleCreateSave',redirect,id,record); }
		setSelectedItem(record);
	};


	// if(typeof console === 'object') { console.log('popperNode',popperNode,textInputRef.current); }

	const getContainerRefWidth = (id) => {
		inoutPropId = id || inoutPropId;
		let popperNodeWidth = 300;

		if(inoutPropId) {
			// let ip = document.getElementById(inoutPropId);
			let cp = document.getElementById(inoutPropId+ '-container');
			if(cp) {
				// if ( typeof console === 'object' ) { console.log( 'inoutPropId', inoutPropId, ip, ip.clientWidth, ip.clientLeft,ip.offsetLeft, cp.clientWidth ); }
				return cp.clientWidth;// + 60 - ip.offsetLeft;
			}
		}

		// if(typeof console === 'object') { console.log('popperNodeWidth',popperNodeWidth); }
		return popperNodeWidth;
	};

	const setInputPropId = (inoutPropId) => {
		inoutPropId = inoutPropId;
	};

	const getAnchorEl = (id) => {

		inoutPropId = id || inoutPropId;

		if(inoutPropId) {
			// if(typeof console === 'object') { console.log('inoutPropId',inoutPropId,document.getElementById(inoutPropId)); }
			return document.getElementById(inoutPropId+ '-container');
		}

		return null;
	};

	const setInputFocus = () => {
		let el = getAnchorEl();
		if(el) {
			el.focus();
		}
	};

	const inputHasFocus = () => {
		let el = getAnchorEl();
		if(el) {
			return (document.activeElement === el);
		}

		return null;
	};

	const getPlaceholder = () => {
		if(placeholder) {
			return placeholder;
		}

		return (type === 'select') ? translate('Select') : translate('Type to search or select');
	};

	// const handleClickAway = (p) => {
	// 	if(typeof console === 'object') { console.log('handleClickAway',p,p.target); }
	// 	p.stopPropagation();
	// 	{/*<ClickAwayListener onClickAway={handleClickAway}></ClickAwayListener>*/}
	// };

	// if(typeof console === 'object') { console.log('selectedRecord',selectedRecord); }

	if(1===1 && reference === 'peopleResources' && typeof console === 'object') {
		console.log('RENDER NOW %o selectedRecordsFound %o dataLoaded %o selectedRecord %o data %o',
			reference,selectedRecordsFound,dataLoaded, selectedRecord, data
		);
	}

	if ((!dataLoaded) || (!selectedRecordsFound)) {
		return null;
		return <LinearProgress />;
	}

	if(1===2 && reference === 'technicalResources' && typeof console === 'object') {
		console.log('RENDER NOW %o selectedRecordsFound %o dataLoaded %o selectedRecord %o data %o',
			reference,selectedRecordsFound,dataLoaded, selectedRecord, data
		);
	}

	// if(typeof console === 'object') { console.log('props',props); }
	/*
	allowEmpty: true
	basePath: "/people"
	className: ""
	fullWidth: true
	id: "companies"
	idField: "id"
	keepValueOnly: false
	label: "Companies"
	loadResource: true
	locale: "de"
	margin: undefined
	multiple: true
	multipleDropdownStartAdornment: "chip"
	optionText: "name"
	options: {suggestionsContainerProps: {…}}
	record: {@context: "/api/contexts/Person", @id: "/api/people/17", @type: "Person", honorificPrefix: null, givenName: "Arndt", …}
	reference: "companies"
	referenceTagProps: {basePath: "/companies", reference: "companies"}
	removeValueOnly: false
	resource: "people"
	rootStyle: {}
	showEmpty: true
	source: "companies"
	tag: "AutocompleteArrayInput"
	tagProps: {optionText: "name", reference: "companies", loadResource: true}
	titleField: "title"
	translate: ƒ (key, options)
	type: "autocomplete"
	useSearch: true
	variant: "standard"
	withForm: true
	*/

	return (

		<div className={classes.root} style={rootStyle}>
			<DownshiftWrapperMemo
				setSelectedItem={setSelectedItem}
				titleField={titleField}
				selectedRecord={selectedRecord}
				loading={loading}
				// loading={false}
				data={data}
				useSearch={useSearch}
				onKeyPress={onKeyPress}
				getPlaceholder={getPlaceholder}
				handleKeyDown={handleKeyDown}
				inputReadOnly={inputReadOnly}
				openMenuOnClick={openMenuOnClick}
				setInputPropId={setInputPropId}
				inputMultipleStyles={inputMultipleStyles}
				label={label}

				multiple={multiple}
				multipleDropdownStartAdornment={multipleDropdownStartAdornment}
				multipleDropdownStartAdornmentOptions={multipleDropdownStartAdornmentOptions}
				startAdornmentField={startAdornmentField}
				startAdornmentColorField={startAdornmentColorField}
				idField={idField}
				handleDelete={handleDelete}
				type={type}
				setInputFocus={setInputFocus}
				inputHasFocus={inputHasFocus}
				search={search}
				helperText={helperText}
				getAnchorEl={getAnchorEl}
				getContainerRefWidth={getContainerRefWidth}
				showEmpty={showEmpty}
				keepValueOnly={keepValueOnly}
				removeValueOnly={removeValueOnly}
				resetButton={resetButton}
				renderSuggestionFN={renderSuggestionFN}

				//createButton and Editor
				basePath={props.basePath}
				reference={reference}
				createReference={createReference}
				handleCreateSave={handleCreateSave}
				createButton={createButton}
			/>

		</div>

	);
}

IntegrationDownshift.defaultProps = {
	multipleDropdownStartAdornment: 'chip',
	type: 'autocomplete', // select (use Select like standard select for small lists / references) || autocomplete
	titleField: 'title',
	idField: 'id',
	showEmpty: true,
	loadResource: true,
	useSearch: true,
	withForm: true,
	keepValueOnly: false, // keep all choices
	removeValueOnly: false, // remove selected values from choices
	rootStyle: {},
	createButton: false,
	resetButton: true,
};

IntegrationDownshift.propTypes = {
	value: PropTypes.any,
	initialValue: PropTypes.any,
	// initialSelectedRecord: PropTypes.any,
	permanentFilter: PropTypes.object,
	rootStyle: PropTypes.object,
	multipleDropdownStartAdornmentOptions: PropTypes.object,
	multipleDropdownStartAdornment: PropTypes.string,
	placeholder: PropTypes.string,
	startAdornmentField: PropTypes.string,
	startAdornmentColorField: PropTypes.string,
	type: PropTypes.string,
	source: PropTypes.string,
	titleField: PropTypes.string,
	searchField: PropTypes.string,
	idField: PropTypes.string,
	openMenuOnClick: PropTypes.bool,
	inputReadOnly: PropTypes.bool,
	multiple: PropTypes.bool,
	showEmpty: PropTypes.bool,
	search: PropTypes.bool,
	load: PropTypes.bool,
	withForm: PropTypes.bool,
	callback: PropTypes.func,
	submit: PropTypes.func, // inline editor form submit
	reference: PropTypes.any,
	keepValueOnly: PropTypes.bool,
	removeValueOnly: PropTypes.bool,
	combineData: PropTypes.func, // add data to resource data (presets)
	createButton: PropTypes.bool,
	resetButton: PropTypes.bool,
	renderSuggestionFN: PropTypes.func,
	createReference: PropTypes.string
};



function areEqual(prevProps, nextProps) {

	// let isEqualAll = ObjectUtils.fastDeepEqual(prevProps, nextProps);
	// if(isEqualAll) {
	// 	// if(typeof console === 'object') { console.log('isEqualAll',prevProps, nextProps); }
	// 	return true;
	// } else {
	// 	// if(typeof console === 'object') { console.log('isNOTEqualAll',prevProps, nextProps); }
	//
	// }
	// return false;
	let a = {
		record: prevProps.record,
		value: prevProps.value,
		choices: prevProps.choices,
	};
	let b = {
		record: nextProps.record,
		value: nextProps.value,
		choices: nextProps.choices,
	};

	let isEqualA = ObjectUtils.fastDeepEqual(a,b);
	if(isEqualA) {
		// if(typeof console === 'object') { console.log('areEqual',prevProps, nextProps); }
		// if(typeof console === 'object') { console.log('this.props.isEqualA',isEqualA,a,b); }
		return true;
	}
	// else {
	// 	if(typeof console === 'object') { console.log('NOT areEqual',prevProps, nextProps); }
	// 	if(typeof console === 'object') { console.log('NOT this.props.isEqualA',isEqualA,a,b); }
	// }

	/*
	 return true if passing nextProps to render would return
	 the same result as passing prevProps to render,
	 otherwise return false
	 */
}
export default React.memo(withTranslate(IntegrationDownshift), areEqual);
// export default withTranslate(IntegrationDownshift);
