// admin/src/authProvider.js
// https://github.com/marmelab/react-admin/blob/next/docs/Authentication.md
import config      from '../host.config.js';
import ObjectUtils from '../../common/utils/ObjectUtils';

// Change this to be your own authentication token URI.
// if(typeof console === 'object') { console.log('process.env',process.env); }
const entrypoint = config.api.host;
const authenticationTokenUri = `${entrypoint}/authentication_token`;

const authProvider = {
	runningUser: null,
	getUser: () => {
		return authProvider.runningUser;
	},
	isDeveloper: () => {
		let user = authProvider.getUser();
		const isChris = (user && user.id === '/api/users/2') ? true : false;
		const isLo = (user && user.id === '/api/users/1') ? true : false;
		return (isChris || isLo) ? true : false;
	},
	isChris: () => {
		let user = authProvider.getUser();
		return (user && user.id === '/api/users/2') ? true : false;
	},
	login: ({ username, password }) =>  {

		// if(typeof console === 'object') { console.log('authenticationTokenUri',username, password); }
		//
		// const request = new Request(authenticationTokenUri, {
		// 	method: 'POST',
		// 	body: JSON.stringify({ username, password }),
		// 	headers: new Headers({ 'Content-Type': 'application/json' }),
		// });


		return fetch(authenticationTokenUri, {
			method: 'POST',
			body: JSON.stringify({ username, password }),
			headers: new Headers({
				'Content-Type': 'application/json',
			}),
		})

		//return fetch(request)
			.then(response => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText);
				}
				else if (response.status === 401 || response.status === 403) {
					if(typeof console === 'object') { console.log('Authprovider.FETCH error',response.status); }
					localStorage.removeItem('token');
					window.location.replace('/login');
					return Promise.reject();
				}
				return response.json();
			})
			.then(({ token }) => {
				localStorage.setItem('token', token);
				window.location.replace('/');
				return token;
			});
	},
	getUserSwitch: () => {
		return localStorage.getItem('x-user-switch');
	},
	setUserSwitch: (username) => {
		if(!username) {
			localStorage.removeItem('x-user-switch');
		} else {
			localStorage.setItem('x-user-switch',username);
		}
	},
	getAuthHeader: () => {
		return { 'Authorization': `Bearer ${localStorage.getItem('token')}`};
	},
	killToken: () => {
		localStorage.removeItem('token');
	},
	logout: () => {
		localStorage.removeItem('token');
		localStorage.removeItem('permissions');


		return fetch('/logout', {
			method: 'GET',
		})
			.then(response => {
				window.location.replace('/login');
				return Promise.resolve();
			});


	},
	checkError: (error) => {
		let status  = error.status;
		let type  = error.type;

		if(error.response && error.response.status) {
			status = error.response.status;
			type = error.response.type;
		}

		if(typeof console === 'object') { console.log('Authprovider.checkError',error,status); }

		if (
			type ==='cors' &&
			(status === 401 || status === 403)
		) {
			// localStorage.removeItem('token');
			// window.location.replace('/login');
			// return Promise.reject();
			authProvider.logout();
		}

		return Promise.resolve();
	},

	checkAuth: () => {
		// if(typeof console === 'object') { console.log('checkAuth',localStorage.getItem('token')); }
		return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
	},
	setPermissions: (roles) => {
		localStorage.setItem('permissions', JSON.stringify(roles));
	},
	checkPermission: (role) => {
		let roles = localStorage.getItem('permissions');
		if(!roles) {
			return false;
		}

		let permissions = JSON.parse(roles);

		if(ObjectUtils.isArray(permissions)) {
			return ObjectUtils.inArray(permissions,role);
		} else if(typeof permissions === 'object') {
			let t = false;
			ObjectUtils.forIn(permissions,(o) => {
				if(o === role) t = true;
			});
			return t;
		}

		return false;
	},
	getPermissions: () => {
		// see https://github.com/marmelab/react-admin/blob/next/docs/Authorization.md
		// for custommizing permissions / roles
		// let p = ['admin'];
		// if(typeof console === 'object') { console.log('GET PERMISSSIONS! CONFIGURE',params,p); }
		const roles = localStorage.getItem('permissions');
		return roles ? Promise.resolve(JSON.parse(roles)) : Promise.reject();
		// return Promise.resolve();
	},
};

export default authProvider;
