import React, { Component } from 'react';
import MuiDialogWithControl from './MuiDialogWithControl';

class MuiModal extends Component {

	createDialogs() {
		let { dialogs } = this.props;

		let dialogComponents = dialogs.map((d) => {
			return d;
		});

		return dialogComponents;
	}


	render() {

		// if(typeof console === 'object') { console.log('MuiModal.render this.state,this.props',this.state,this.props); }

		return (
			<div className="muiModals">
				{this.createDialogs()}
			</div>
		);
	}
}

export default MuiDialogWithControl(MuiModal);