// import React             from 'react';
import LocalApi          from '../LocalApi';
import { useTranslate }           from 'react-admin';
// import { minLength, required } from 'react-admin';

/**
 * cld_
 *
 *
 *
 *
 *
 *
 */

class ContactNumbers extends LocalApi {

	static contentKey = 'contact_numbers';
	static sorting = { field: 'title', order: 'ASC' };
	// static hasEdit = false;
	static hasShow = false;
	static hasList = false;

	static getFormFields(formFields,map = 'formFields') {
		// if(typeof console === 'object') { console.log('getFormFields',formFields); }
		let f = [];
		const translate = useTranslate();

		let fields = {
			contact:null,
			number: {
				inputProps: {
					label: translate('fields.number'),
				}
			},
			// type: {
			// 	inputProps: {
			// 		label: translate('fields.type'),
			// 	}
			// },
			type: {
				MVTReferenceInput: true,
				inputProps: {
					tag     : 'AutocompleteInput',
					label    : translate('fields.type'),
					// initialValue: 'new',
					tagProps: {
						optionText: 'title',
						reference: 'number_types',
						loadResource: false,
						search: false,
						type: 'select',
					},
				}
			},
			title: {
				inputProps: {
					label: translate('fields.title'),
				}
			},
			// type: null,
			// title: {
			// 	MVTInputField: true,
			// 	inputProps: {
			// 		label: translate('fields.label'),
			// 		validate:  [required(), minLength(2)]
			// 	}
			// },
		};

		if(map === 'formFields') {
			f = this.mapFields(formFields,fields);
		}

		return f;
	}

	static validateForm(values) {
		// if(typeof console === 'object') { console.log('validateForm with values',values); }
		const errors = {
			// title: ['myroot.validation.required']
		};
		return errors;
	}

	static getGridPermanentFilter( permanentFilter ) {
		return permanentFilter;
	}

	static getGridFilterDefaults( filterDefaults ) {
		return {
			// title: '999',
			// description: 'super'
		};
	}

	static getGridFilter( ) {
		return [];
	}

	static getGridColumns(listFields,map = 'listfields') {
		// if(typeof console === 'object') { console.log('getGridColumns',listFields); }

		// const translate = useTranslate();

		let fields = {
			// title: {
			// 	isSortable: true,
			// 	// FunctionField: true,
			// 	fieldProps: {
			// 		width: 200,
			// 		header    : translate('fields.title'),
			// 	}
			// },
		};

		let df = [];
		if(map === 'listfields') {
			df = this.mapFields(listFields,fields);
		}

		return df;
	}

}

export default ContactNumbers;
