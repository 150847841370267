import React, { useState } from 'react';
import Menu                           from '@material-ui/core/Menu';
import MenuItem                           from '@material-ui/core/MenuItem';
import ListSubheader                           from '@material-ui/core/ListSubheader';
import { ListItemIcon }                           from '@material-ui/core';
import { connect }                from 'react-redux';
import inflection                 from 'inflection';
import compose                    from 'recompose/compose';
import { getResources }           from 'ra-core';
import { withTranslate }          from 'react-admin';
import { faEdit }                 from '@fortawesome/free-solid-svg-icons';
import DefaultIcon                 from '@material-ui/icons/ViewList';
import History          from '../../admin-containers/History';
import {
	MenuItemLink,
}                     from 'react-admin';
import MuiButton      from '../../../common/components/common/MuiButton';
import { makeStyles } from '@material-ui/core';
import { SettingsApplications } from '@material-ui/icons';
import ObjectUtils from "../../../common/utils/ObjectUtils";

// import DefaultIcon from '@material-ui/core/SvgIcon/SvgIcon';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// const ITEM_HEIGHT = 48;

let useStyles = makeStyles(function () {
	return ({
		listHeader: {
			position: 'static'
		},
	});
});

function EditorMenu(props) {

	const {
		dense,
		resources,
		translate,
		user,
	} = props;

	// if(typeof console === 'object') { console.log('props',props); }

	const MenuId = 'resource_menu_app_bar';
	const [menuItems, setMenuItems] = useState([]);
	const classes = useStyles();
	// eslint-disable-next-line react-hooks/exhaustive-deps

	// useEffect(() => {
	// 		getResources();
	// 	},
	// 	[resources]
	// );

	const translatedResourceName = (resource, translate) =>
		translate(`resources.${resource.name}.name`, {
			smart_count: 2,
			_:
				resource.options && resource.options.label
				? translate(resource.options.label, {
					smart_count: 2,
					_: resource.options.label,
				})
				: inflection.humanize(inflection.pluralize(resource.name)),
		});

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const onMenuClick = (path) => {
		History.push(path);
		handleClose();
	};

	// const __getResources = () => {
	//
	// 	let groups = [];
	// 	let mn = [];
	//
	// 	resources.filter(r => r.hasList)
	// 		.filter(r => !r.icon)
	// 		.forEach((r)=>{
	//
	// 		let showInMenu = r.options.resource.showInMenu || false;
	// 		if(showInMenu) {
	// 			let group = r.options.resource.menuGroup;
	// 			if(!groups[group]) {
	// 				groups[group] = [];
	// 			}
	// 			groups[group].push(r);
	// 		}
	// 	});
	//
	//
	// 	Object.keys(groups).forEach((g) => {
	// 		// if(typeof console === 'object') { console.log('g',g,groups[g]); }
	//
	// 		mn.push(<ListSubheader className={classes.listHeader} key={g}>{g}</ListSubheader>);
	//
	// 		groups[g].forEach((r) => {
	// 			let icon = <DefaultIcon />;
	// 			if(typeof r.icon === 'object') {
	// 				icon = r.icon;
	// 			} else if(typeof r.icon === 'string') {
	// 				icon = <r.icon />;
	// 			}
	//
	// 			mn.push(<MenuItem
	// 				key={r.name}
	// 				icon={icon}
	// 				onClick={() => {onMenuClick(`/${r.name}`)}}
	// 				dense={dense}
	// 			>
	// 				<ListItemIcon>
	// 					{icon}
	// 				</ListItemIcon>
	// 				{translatedResourceName(r, translate)}
	//
	// 			</MenuItem>);
	// 		});
	//
	// 	});
	//
	// 	// if(typeof console === 'object') { console.log('groupso',groups); }
	// 	// let MNS = <React.Fragment>{mn.map((m) => { return m;})}</React.Fragment>;
	// 	setMenuItems(mn);
	// };

	React.useMemo(() => {
		// if(typeof console === 'object') { console.log('getResources',resources); }
		let groups = [];
		let mn = [];

		resources.filter(r => r.hasList)
			.filter(r => !r.icon)
			.forEach((r)=>{

				let showInMenu = r.options.resource.showInMenu || false;
				if(showInMenu) {
					let group = r.options.resource.menuGroup;
					if(!groups[group]) {
						groups[group] = [];
					}
					groups[group].push(r);
				}
			});


		Object.keys(groups).forEach((g) => {
			// if(typeof console === 'object') { console.log('g',g,groups[g]); }

			if(!ObjectUtils.inArray(user.menuGroups,g)) {
				return;
			}

			mn.push(<ListSubheader className={classes.listHeader} key={g}>{g}</ListSubheader>);

			groups[g].forEach((r) => {
				let icon = <DefaultIcon />;
				if(typeof r.icon === 'object') {
					icon = r.icon;
				} else if(typeof r.icon === 'string') {
					icon = <r.icon />;
				}

				mn.push(<MenuItem
					key={r.name}
					icon={icon}
					onClick={() => {onMenuClick(`/${r.name}`)}}
					dense={dense}
				>
					<ListItemIcon>
						{icon}
					</ListItemIcon>
					{translatedResourceName(r, translate)}

				</MenuItem>);
			});

		});

		// if(typeof console === 'object') { console.log('groupso',groups); }
		// let MNS = <React.Fragment>{mn.map((m) => { return m;})}</React.Fragment>;
		setMenuItems(mn);
	},[resources]);


	return (
		<React.Fragment>
			<MuiButton
				size="small"
				variant="contained"
				aria-label="more"
				aria-controls={MenuId}
				aria-haspopup="true"
				onClick={handleClick}
				className="Mui-Button--IconOnly"
				// color="secondary"
				icon={<SettingsApplications />}
			/>
			<Menu
				id={MenuId}
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				// PaperProps={{
				// 	style: {
				// 		maxHeight: ITEM_HEIGHT * 4.5,
				// 		width: 280,
				// 	},
				// }}
			>
				{/*{menuItems}*/}
				{menuItems.map((m) => { return m;})}

				{1===2 && resources
					.filter(r => r.hasList)
					.filter(r => !r.icon)
					.map(resource => {

						let icon = <DefaultIcon />;
						if(typeof resource.icon === 'object') {
							icon = resource.icon;
						} else if(typeof resource.icon === 'string') {
							icon = <resource.icon />;
						}
						// if(typeof console === 'object') { console.log('icon?',typeof resource.icon, icon); }

						return(
							<MenuItemLink
								// className={classes.menuitem}
								// classes={menuItemClasses}
								key={resource.name}
								to={`/${resource.name}`}
								primaryText={translatedResourceName(resource, translate)}
								leftIcon={icon}
								onClick={onMenuClick}
								dense={dense}
							/>)
					})}
			</Menu>
		</React.Fragment>
	);
};

EditorMenu.defaultProps = {

};

EditorMenu.propTypes = {
	// classes: PropTypes.object,
	// translate: PropTypes.func
};

const mapStateToProps = state => ({
	resources: getResources(state),
	pathname: state.router.location.pathname, // used to force redraw on navigation
});

const enhance = compose(
	withTranslate,
	connect(
		mapStateToProps,
		{}, // Avoid connect passing dispatch in props,
		null,
		{
			areStatePropsEqual: (prev, next) =>
				prev.resources.every(
					(value, index) => value === next.resources[index] // shallow compare resources
				) &&
				prev.pathname === next.pathname
			// && prev.open === next.open,
		}
	),
	// withStyles(styles),
);

export default enhance(EditorMenu);

// export default EditorMenu;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
