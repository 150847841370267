import React, { PureComponent, isValidElement } from 'react';
import PropTypes                           from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faSpinner, faSync, faSave } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import CircularProgress from "@material-ui/core/CircularProgress";
// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';


library.add(faCoffee, faSpinner, faSync, faSave);

const styles = theme => ({
	button: {
		margin: theme.spacing(1),
	},
	input: {
		display: 'none',
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	leftIconSpinner: {
		marginRight: (theme.spacing(1) - 2),
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	iconSmall: {
		fontSize: 20,
	},
});


class MuiButton extends PureComponent {

	constructor(props) {
		super(props);

		this.getIcon = this.getIcon.bind(this);
	}

	getIcon() {
		const {
			classes,
			spin,
			icon,
		} = this.props;
		let i = null;


		if(spin) {
			//i = <FontAwesomeIcon icon={faSpinner} pulse className={classNames( classes.leftIconSpinner, classes.iconSmall )} />
			i = <CircularProgress color="inherit" size={20} />;
		} else if(icon) {
			if(isValidElement(icon)) {
				// if(typeof console === 'object') { console.log('ICON???',typeof icon,icon); }
				i = icon;
			} else {
				i = <FontAwesomeIcon className={classNames( classes.leftIcon, classes.iconSmall )} icon={icon} />;
			}
		}

		return i;
	}

	render() {

		const {
			text,
			onClick,
			color,
			classes,
			className,
			autoFocus,
			variant,
			size,
			type,
			disabled,
			disableFocusRipple,
			disableRipple
		} = this.props;

		let buttonClassName = className || classes.button;

		return (
			<Button
				color={color}
				onClick={onClick}
		        className={buttonClassName}
				autoFocus={autoFocus}
				variant={variant}
				size={size}
				type={type}
				disabled={disabled}
				disableFocusRipple={disableFocusRipple}
				disableRipple={disableRipple}
			>
				{this.getIcon()}
				{text && text}
				{this.props.children && this.props.children}
			</Button>
		);
	}
}

MuiButton.defaultProps = {
	color: 'primary',
	autoFocus: false,
	disabled: false,
	disableRipple: false,
	disableFocusRipple: true
};

MuiButton.propTypes = {
	evalUAV    : PropTypes.object,
	checkRight    : PropTypes.object,
	spin    : PropTypes.bool,
	disabled: PropTypes.bool,
	icon : PropTypes.any,
	className : PropTypes.any,

	classes: PropTypes.object.isRequired,
	onClick: PropTypes.func,
	color: PropTypes.any,
	variant: PropTypes.string,
	size: PropTypes.string,
	type: PropTypes.string,
	autoFocus: PropTypes.bool,
	disableRipple: PropTypes.bool,
	disableFocusRipple: PropTypes.bool,
};

export default withStyles(styles)(MuiButton);
