import React     from 'react';
import PropTypes from 'prop-types';
import {
	PasswordInput,
}                            from 'react-admin';

// import { makeStyles }        from '@material-ui/core';
//
// let useStyles = makeStyles(function (theme) {
// 	return ({
// 		resetIconFix: theme.custom.content.resetIconFix,
// 	});
// });
// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';

const MVTPasswordInputField = (props) => {

	// if(typeof console === 'object') { console.log('MVTPasswordInputField',props); }

	return (
		<PasswordInput
			fullWidth={true}
			{...props}
		/>
	);
};

MVTPasswordInputField.defaultProps = {

};

MVTPasswordInputField.propTypes = {
	className: PropTypes.any,
	record: PropTypes.object,
	source: PropTypes.string,
	// translate: PropTypes.func
};

MVTPasswordInputField.displayName = 'MVTPasswordInputField';
export default MVTPasswordInputField;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
