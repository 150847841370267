import React, { useState, useEffect } from 'react';
import PropTypes                           from 'prop-types';
import Chip from '@material-ui/core/Chip';
import {makeStyles} from "@material-ui/core/styles";
// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';
let useStyles = makeStyles(function () {
	return ({
		wrapper: {
			padding: '0',
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			justifyContent: 'space-between',
		},
		content: {
			flexGrow: 1,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
	});
});

function ProjectDropdownSuggestion(props) {

	const classes = useStyles();

	const {
		suggestion
	} = props;

	return (
		<div className={classes.wrapper}>
			<div className={classes.content}>{suggestion.shortTitle || suggestion.title}</div>
			{(suggestion['id'].indexOf('/api/projects') >= 0) &&<Chip size="small" style={{backgroundColor: suggestion.color}} label={suggestion.projectNumber} />}
		</div>
	);
};

ProjectDropdownSuggestion.defaultProps = {

};

ProjectDropdownSuggestion.propTypes = {
	// classes: PropTypes.object,
	// translate: PropTypes.func
};

export default ProjectDropdownSuggestion;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
