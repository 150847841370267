import React, { useState, useEffect } from 'react';
import PropTypes                      from 'prop-types';
import { useForm, useFormState }                                from 'react-final-form';
import ApiPlatformResources      from '../../../../../api-platform-admin/src/api/ApiPlatformResources';
import { useMutation, GET_LIST } from 'react-admin';
import { useNotify }             from 'react-admin';
import { ArrayInput, TextInput } from 'react-admin';
import { LinearProgress }        from 'ra-ui-materialui';
import SimpleFormIterator        from './SimpleFormIterator';
import ApiPlatformUtils          from '../../../../../api-platform-admin/src/utils/ApiPlatformUtils';
import ObjectUtils               from '../../../../utils/ObjectUtils';
// import LoadingIndicator                                         from '../../../common/LoadingIndicator';
// import Error                     from '../../../common/Error';
// import { makeStyles }            from '@material-ui/core/styles';
// import AutocompleteInput         from './MUIAutocompleteDownshift';
// import ReferenceEditor                from '../editors/ReferenceEditor';
// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';


// const useStyles = makeStyles(theme => ({
// 	root: {
// 		width: '100%',
// 		// maxWidth: 360,
// 		backgroundColor: theme.palette.background.paper,
// 	},
//
// }));


const textInputStyle = {width: '100%'};
function MVTReferenceEditorInput(props) {

	// if(typeof console === 'object') { console.log('MVTReferenceEditorInput.props',props); }

	// const {
	// 	id,
	// 	...rest
	// } = props;

	const {
		filterField,
		sorting,
		filter,
		resettable,
		clearAlwaysVisible,
		fullWidth,
		initalValues,
		...fieldRest
	} = props;

	const {
		source,
		resource,
		reference,
		variant
	} = props;

	let resourceId, addIdField, value;
	let isMounted = true;
	let form = null;
	let formState = null;
	let withForm = true;

	if(withForm) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		form = useForm();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		formState = useFormState();
	}

	if(formState && formState.values) {
		value = formState.values[source];
		resourceId = formState.values['id'];
		// if(typeof console === 'object') { console.log('MVTReferenceEditorInput.formState',source,formState.values); }

	}

	const notify = useNotify();
	// let classes = useStyles();
	const [initialized, setIsInitialized] = useState(0);
	// const [render, reRender] = useState(0);
	// const [editorIndex, setEditorIndex] = useState();
	const referenceOptions = ApiPlatformResources.getResourceOptions(reference);

	// if(typeof console === 'object') { console.log('MVTReferenceEditorInput props,referenceOptions',resourceId,props,referenceOptions,formState.values); }

	const getSorting = () => {
		if(sorting) {
			return sorting;
		} else if(referenceOptions && referenceOptions.configFactory && referenceOptions.configFactory.conf) {
			return referenceOptions.configFactory.conf.getGridSorting();
		}

		return null;
	};

	const getFilter = () => {
		if(filter) {
			return filter;
		}

		let f = {};
		f[filterField || resource] = resourceId;
		return f;
	};

	const getInitalValues = () => {
		//initialValues
		initalValues[filterField || resource] = resourceId;
		return initalValues;
	};

	let [mutate] = useMutation();
	const get = event => mutate(
		{
			type: GET_LIST,
			resource: reference,
			payload: {
				pagination: { page: 1, perPage: 100 },
				sort: getSorting(),
				filter: getFilter(event),
				// groups: ['related:read']
			},
		},
		{
			// undoable: true,
			onSuccess: ({ data }) => {
				// if(typeof console === 'object') { console.log('MVTReferenceEditorInput DATA props,referenceOptions',isMounted,data,props,referenceOptions); }
				if(isMounted) {
					// setIsInitialized( true );
					// form.change( source, data );
					setValue(data);
				}
				return data;

			},
			onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
		}
	);


	const setValue = (data) =>{
		// if(typeof console === 'object') { console.log('MVTReferenceEditorInput.setValue',source,data,formState.values); }
		if(!initialized) {
			setIsInitialized( true );
		}
		// reRender(render + 1);
		form.change( source, data );
	};

	/**
	 * this is working but we need the same data in list and get one
	 */
	const findSubResourceData = () => {
		let SR = source + '_SR_';
		if(
			// 1===2 &&
			value &&
			ObjectUtils.isArray(value) &&
			typeof value[0] === 'object')
		{
			setValue(value);
		} else if(
			// 1===2 &&
			formState.values[SR] &&
			ObjectUtils.isArray(formState.values[SR]) &&
			typeof formState.values[SR][0] === 'object')
		{
			setValue(formState.values[SR]);
		} else {
			get();
		}
	};

	useEffect(() => {
		// if(typeof console === 'object') { console.log('useEffect.value',value); }
		if(
			value &&
			ObjectUtils.isArray(value) &&
			typeof value[0] === 'string'
		) {
			// if(typeof console === 'object') { console.log('useEffect.value.findSubResourceData',value); }
			findSubResourceData();
		} else {
			if(!initialized) {
				setIsInitialized( true );
			}
		}
	},[value]);

	useEffect(() => {
		if(1===2 && resourceId) {
			findSubResourceData();
		}
		return () => {
			isMounted = false;
		}
	},[resourceId]);


	// const actionCallback = () => {
	// 	get();
	// };
	//
	// const newCallback = () => {
	// 	setEditorIndex(0);
	// 	get();
	// };
	//
	// const handleEdit = (index) => {
	// 	setEditorIndex(index);
	// };

	// if(typeof console === 'object') { console.log('ProjectMemberships.props',props,id); }

	let {
		options: {
			api,
			fields,
			inputFactory,
			configFactory,
			// resource
		},
		// addIdInput = false === hasIdentifier(fields),
	} = ApiPlatformUtils.resolveEditorProps({options:referenceOptions});


	let editields = fields;
	// let validateForm = () => {};
	// let formSettings = {};

	if(configFactory.conf) {
		editields = configFactory.conf.getFormFields(editields);
		// validateForm = configFactory.conf.validateForm;
		// formSettings = configFactory.conf.getFormSettings();
	}


	// if(typeof console === 'object') { console.log('editieldseditieldseditields',editields); }


	// if (!initialized) return <LoadingIndicator />;
	// if (loading) return <LoadingIndicator />;
	// if (error) return <Error />;
	// if (!data) return null;

	const inputFields = editields.map(field =>
		inputFactory(field, {
			api,
			resource: referenceOptions.resource
		}),
	);


	const getValue = () => {

		if(formState.values[source] && typeof formState.values[source] === 'object') {

			if(ObjectUtils.isArray(formState.values[source]) && typeof formState.values[source][0] === 'string') {
				return null;
			}

			return formState.values[source];
		}

		return true;
	};

	// if (!initialized) return <LoadingIndicator />;
	if (!initialized) return <LinearProgress />;
	if (!getValue()) return <LinearProgress />;

	// if(typeof console === 'object') { console.log('fieldRest.record',fieldRest,fieldRest.record,formState.values); }

	return (
		<div>
			{1===2 && (!initialized) && <LinearProgress />}
			<ArrayInput source={source} {...fieldRest} record={formState.values}>
				<SimpleFormIterator
					initialValues={getInitalValues()}
					// reRender={render}
				>
					{inputFields}
					{addIdField &&<TextInput
						placeholder="calendarEvent"
						source="calendarEvent"
						variant={variant}
						value={resourceId}
						// fullwidth={true}
						style={textInputStyle}
					/>}
				</SimpleFormIterator>
			</ArrayInput>
		</div>
	);
};

MVTReferenceEditorInput.defaultProps = {
	initalValues: {}
};

MVTReferenceEditorInput.propTypes = {
	initalValues: PropTypes.object,
	// classes: PropTypes.object,
	// translate: PropTypes.func
};

function areEqual(prevProps, nextProps) {

	// let isEqualAll = ObjectUtils.fastDeepEqual(prevProps, nextProps);
	// if(isEqualAll) {
	// 	if(typeof console === 'object') { console.log('isEqualAll',prevProps, nextProps); }
	// 	return true;
	// } else {
	// 	if(typeof console === 'object') { console.log('isNOTEqualAll',prevProps, nextProps); }
	//
	// }

	let a = {
		record: prevProps.record,
		value: prevProps.value,
	};
	let b = {
		record: nextProps.record,
		value: nextProps.value,
	};

	let isEqualA = ObjectUtils.fastDeepEqual(a,b);
	if(isEqualA) {
		// if(typeof console === 'object') { console.log('areEqual',prevProps, nextProps); }
		// if(typeof console === 'object') { console.log('this.props.isEqualA',isEqualA,a,b); }
		return true;
	}

	/*
	 return true if passing nextProps to render would return
	 the same result as passing prevProps to render,
	 otherwise return false
	 */
}
export default React.memo(MVTReferenceEditorInput,areEqual);
// export default MVTReferenceEditorInput;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
