import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


function ProjectsOngoing(props) {
    return (
        <SvgIcon {...props}>
            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" role="img">
                <g>
                    <path
                        d="M18.6,3.2l2.2,4.4h-3.3l-2.2-4.4h-2.2l2.2,4.4H12L9.8,3.2H7.6l2.2,4.4H6.5L4.3,3.2H3.2C2,3.2,1,4.2,1,5.4 v13.2c0,1.2,1,2.2,2.2,2.2h17.6c1.2,0,2.2-1,2.2-2.2V3.2H18.6z M16.2,19.7c-3.2,0-5.7-2.6-5.7-5.7c0-3.2,2.5-5.7,5.7-5.7 s5.7,2.5,5.7,5.7S19.4,19.7,16.2,19.7z"></path>
                    <path
                        d="M16.2,9.4c-2.5,0-4.6,2.1-4.6,4.6s2.1,4.6,4.6,4.6s4.6-2.1,4.6-4.6S18.7,9.4,16.2,9.4z M18.6,16.3l-3-1.8 v-3.4h0.8v3l2.6,1.5L18.6,16.3z"></path>
                </g>
                <title>Project: Ongoing</title>
            </svg>
        </SvgIcon>
    );
}

export default ProjectsOngoing;