// import config from '../../src/host.config';
import MD5                    from '../utils/MD5';
import { GET_LIST, GET_MANY, GET_MANY_REFERENCE, UPDATE, CREATE, DELETE, UPDATE_MANY, DELETE_MANY } from 'react-admin';
import ObjectUtils            from '../utils/ObjectUtils';

/**
 * AppCacheApi
 *
 * bind dispatch / store
 * use customReducer to store cache
 * (src/admin-store/reducers/customFetchLoad.js)
 * get valid cache in
 * src/admin-store/enhanceDataProvider.js
 *
 * https://github.com/JumboInteractiveLimited/redux-cache/tree/master/src
 * https://github.com/emn178/js-sha1/tree/master/src
 */
class AppCacheApi {

	static cache = {};
	static enableCache = true;
	static cloneCache = true;
	static debug = false;
	static debugResource = '';//'technical_resources';//'calendar_events';
	static debugReducer = false;

	static cacheTypes = [
		GET_LIST,
		GET_MANY,
		GET_MANY_REFERENCE
	];

	static cacheActions = [
		'RA/CRUD_GET_MATCHING_SUCCESS',
		'RA/CRUD_GET_MANY_SUCCESS',
		'CUSTOM_FETCH_SUCCESS'
	];

	static invalidateCacheTypes = [
		UPDATE, CREATE, DELETE, UPDATE_MANY, DELETE_MANY
	];

	static newerCacheResources = [
		'event_participations'
	];

	static dispatch = null;
	static DEFAULT_DURATION_MS = 1000 * 60 * 5;

	static dispatchStore(d) {

		let dispatch;

		if(this.store) {
			dispatch = this.store.dispatch;
		} else if(this.dispatch) {
			dispatch = this.dispatch;
		}

		if(dispatch) {
			return dispatch(d);
		} else {
			throw 'Neather a store or dispatch is defined!';
		}
	}

	static setDispatch(d) {
		this.dispatch = d;
	};

	static setStore(store) {
		this.store = store;
	};

	static setCache(action, cache) {

		if(!this.enableCache) {
			return null;
		}

		cache = cache || this.cache;

		const { meta, requestPayload, payload} = action;

		if(
			meta &&
			meta.resource &&
			ObjectUtils.inArray(AppCacheApi.cacheTypes,meta.fetchResponse)
		) {
			const resource = meta.resource;

			if(ObjectUtils.inArray(AppCacheApi.newerCacheResources,resource)) {
				return false;
			}

			if(requestPayload && requestPayload.appParams && requestPayload.appParams.noCache) {
				return false;
			}

			const hash = MD5.md5( JSON.stringify( requestPayload ) );
			if(!cache[resource]) {
				cache[resource] = {};
			}

			if(!cache[resource][hash]) {
				let pL = { ...payload };
				pL.cacheTTL = this.generateCacheTTL( requestPayload.cacheTTL );
				cache[resource][hash] = pL;
				if (resource  === this.debugResource && typeof console === 'object' ) {
					console.log( 'CACHE '+meta.fetchResponse+' of %o with hash %o', resource, hash, cache, requestPayload );
				}
			}


			// if ( this.debug && typeof console === 'object' ) { console.log( 'cache GET_LIST of %o with hash %o', resource, hash ); }
		}

		this.cache = cache;
		return true;
	}

	static getCache(type, resource, payload) {

		if(!this.enableCache) {
			return null;
		}

		if(
			resource &&
			ObjectUtils.inArray(AppCacheApi.cacheTypes,type)
		) {
			const hash = MD5.md5( JSON.stringify( payload ) );
			// if(typeof console === 'object') { console.log('getCache of %o with hash %o',resource,hash); }
			// if(resource  === this.debugResource && typeof console === 'object') { console.log('getCache of %o with hash %o',resource,hash,payload,this.cache); }
			if(this.cache[resource] && this.cache[resource][hash]) {
				let cacheTTL = this.cache[resource][hash].cacheTTL;
				let isValid = this.checkCacheValid(cacheTTL);
				if(resource  === this.debugResource && typeof console === 'object') {
					console.log('CACHE FOUND of resource %O with hash %o is valid? %o',resource,hash,isValid);
				}
				// if(this.debug &&  typeof console === 'object') { console.log('CACHE FOUND of resource %O with hash %o',resource,hash); }
				if(isValid) {
					if(this.cloneCache) {
						// PERFORMANE? But we need to clone the data...
						let clone = ObjectUtils.cloneDeep(this.cache[ resource ][ hash ]);
						return clone;
					}
					return this.cache[ resource ][ hash ];
				} else {
					this.cache[ resource ][ hash ] = null;
				}
			}
		}

		return null;

		// const nested = fromJS(this.cache);
		// let n = nested.getIn(ns);
		// let a = null;
		// let b = null;
		//
		// if(typeof n === 'object') {
		// 	n = (Iterable.isIndexed(n)) ? n.toArray() : n.toObject();
		// }
		//
		// if(this.debug && typeof console === 'object') {
		// 	console.log('getCache %o in %o with type %s',ns,n,typeof n);
		// }
		//
		// return n;
	}

	static invalidateCache(resource) {
		if(resource) {
			this.cache[resource] = {};
		} else {
			this.cache = {};
		}
	};

	// static hashCode = function(h) {
	// 	let hash = 0;
	// 	if (h.length === 0) {
	// 		return hash;
	// 	}
	// 	for (let i = 0; i < h.length; i++) {
	// 		let char = h.charCodeAt(i);
	// 		hash = ((hash<<5)-hash)+char;
	// 		hash = hash & hash; // Convert to 32bit integer
	// 	}
	// 	return hash;
	// };

	static checkCacheValid(cacheUntil) {
		const currentTime = Date.now();
		return !!(cacheUntil > currentTime);
	};

	static generateCacheTTL(duration) {
		duration = duration || this.DEFAULT_DURATION_MS;
		return Date.now() + duration;
	}
}

export default AppCacheApi;
