import React, { useCallback, useState } from 'react';
import { useFormState }       from 'react-final-form';
import {
	UPDATE,
	CREATE,
	useMutation,
	SaveButton as RA_SaveButton,
	useRedirect,
	useNotify
}                             from 'react-admin';
import PropTypes              from 'prop-types';
// import {makeStyles} from "@material-ui/core/styles";

// const useStyles = makeStyles(
// 	theme => ({
// 		button: {
// 			position: 'relative',
// 		},
// 		leftIcon: {
// 			marginRight: theme.spacing(1),
// 			color: theme.palette.textColors.default
// 		},
// 		icon: {
// 			fontSize: 18,
// 		},
// 	}),
// 	// { name: 'RaSaveButton' }
// );

const SaveButton = props => {

	const {actionType, ...rest} = props;

	// if(typeof console === 'object') { console.log('SaveButton.props',props); }

	let { basePath, redirect, resource, record, options } = props;

	// const classes = useStyles();

	const formState = useFormState();
	const [ update ] = useMutation();
	const [ create ] = useMutation();
	const redirectTo = useRedirect();
	const notify = useNotify();
	const [saving, setSaving] = useState(false);

	// if(typeof console === 'object') { console.log('SaveButton.rest',rest,formState); }

	const handleClick = useCallback(() => {

		if (!formState.valid) {
			notify('ra.message.invalid_form', 'warning');
			return;
		}

		if(typeof redirect === 'undefined') {
			redirect = 'list';
		}
		// if(typeof console === 'object') { console.log('SaveButton.handleClick',formState,basePath,record,record.id,formState.values); }

		let values = formState.values;

		if(options && options.configFactory && options.configFactory.conf) {
			if(typeof options.configFactory.conf.onBeforeSubmit === 'function') {
				values = options.configFactory.conf.onBeforeSubmit(values,{actionType:actionType});
				// if(typeof console === 'object') { console.log('onBeforeSubmitonBeforeSubmit',values); }
			}
		}

		setSaving(true);

		if(actionType === 'create') {

			const doCreate = () => create(
				{
					type: CREATE,
					resource: resource,
					payload: {data: values},
				},
				{
					onSuccess: ({ data }) => {
						// if(typeof console === 'object') { console.log('doCreate.onSuccessonSuccess',basePath,data,data.id); }
						setSaving(false);

						notify( 'ra.notification.created', 'info', {
							smart_count: 1,
						} );

						redirectTo( redirect, basePath, data.id, data );

					},
					onFailure: (error) => {
						if(typeof console === 'object') { console.log('Create error',error.message); }
						setSaving(false);
						notify(`Error: ${error.message}`, 'error', {autoClose: false});
					},
				}
			);

			doCreate();

		} else {

			const doUpdate = () => update(
				{
					type: UPDATE,
					resource: resource,
					payload: {data: values, id: record.id},
				},
				{
					onSuccess: ({ data }) => {
						// if(typeof console === 'object') { console.log('doUpdate.onSuccessonSuccess',basePath,data,data.id); }
						setSaving(false);
						notify( 'ra.notification.updated', 'info', {
							smart_count: 1,
						} );
						redirectTo( redirect, basePath, data.id, data );
					},
					onFailure: (error) => {
						if(typeof console === 'object') { console.log('Update error',error.message); }
						setSaving(false);
						notify(`Error: ${error.message}`, 'error', {autoClose: false});
					},
				}
			);

			doUpdate();
		}

	}, [
		formState.valid,
		formState.values,
		create,
		update,
		notify,
		redirectTo,
		redirect,
		basePath,
		options
	]);

	return <RA_SaveButton
		{...rest}
		saving={saving}
		handleSubmitWithRedirect={handleClick}
		disabled={formState.invalid}
	/>;
};

SaveButton.defaultProps = {
	actionType: 'update'
};

SaveButton.propTypes = {
	actionType: PropTypes.string,
};

export default SaveButton;
