import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


function PlanningTeam(props) {
    return (
        <SvgIcon {...props}>
            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" role="img">
                <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" role="img">
                    <g>
                        <path
                            d="M21.1,9.2H2.9c-0.3,0-0.5,0.2-0.5,0.5v11.2c0,1.1,0.9,2.1,2.1,2.1h15.1c1.1,0,2.1-0.9,2.1-2.1V9.8 C21.6,9.5,21.4,9.2,21.1,9.2z M15.3,11.8c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S14.2,11.8,15.3,11.8z M9.4,11.2 c1.3,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3s-2.3-1-2.3-2.3S8.1,11.2,9.4,11.2z M13.3,19.4c0,0.5-0.4,1-1,1H6.4c-0.5,0-1-0.4-1-1v-0.6 c0-1.3,1.1-2.4,2.4-2.4H8c0.4,0.2,0.9,0.3,1.4,0.3s1-0.1,1.4-0.3H11c1.3,0,2.4,1.1,2.4,2.4L13.3,19.4L13.3,19.4z M17.6,19.8H14 c0,0,0-0.1,0-0.1v-0.8c0-0.8-0.3-1.5-0.8-2.1c0.3-0.2,0.7-0.3,1.1-0.3h0.1c0.3,0.1,0.6,0.2,0.9,0.2s0.6-0.1,0.9-0.2h0.1 c1.3,0,2.3,1,2.3,2.3C18.6,19.3,18.2,19.8,17.6,19.8z"></path>
                        <path
                            d="M19.6,3.8h-2.1V1.5C17.5,1.2,17.3,1,17,1h-1.7c-0.3,0-0.5,0.2-0.5,0.5v2.2H9.2V1.5C9.2,1.2,9,1,8.7,1H7 C6.7,1,6.5,1.2,6.5,1.5v2.2H4.4c-1.1,0-2.1,0.9-2.1,2.1v1.5c0,0.3,0.2,0.5,0.5,0.5h18.2c0.3,0,0.5-0.2,0.5-0.5V5.8 C21.6,4.7,20.7,3.8,19.6,3.8z"></path>
                    </g>
                    <title>Planning: Team</title>
                </svg>
            </svg>
        </SvgIcon>
    );
}

export default PlanningTeam;