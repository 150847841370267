import React     from 'react';
import PropTypes from 'prop-types';
// import get       from 'lodash/get';
import {
	ArrayInput, BooleanInput, DateInput, DateTimeInput, NumberInput, SimpleFormIterator,
	// ArrayInput,
	// BooleanInput,
	// DateInput,
	// NumberInput,
	// ReferenceArrayInput,
	// ReferenceInput,
	// required,
	// SelectArrayInput,
	// SelectInput,
	// SimpleFormIterator,
	TextInput,
} from 'react-admin';
import { makeStyles }        from '@material-ui/core';
import MVTDateTimeInput from "./MVTDateTimeInput";
import GridBooleanInput from "../../grid/fields/GridBooleanInput";

let useStyles = makeStyles(function (theme) {
	return ({
		resetIconFix: theme.custom.content.resetIconFix,
	});
});


// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';

const MVTInputField = (props) => {

	const {
		range,
		resettable,
		submit,
		...rest
	} = props;

	// if(typeof console === 'object') { console.log('MVTInputField %o with props %o',props.source,props); }
	let styles = useStyles();

	// let {
	// 	className,
	// 	record,
	// 	source,
	// 	cellRenderer,
	// 	...rest
	// } = props;
	//
	//
	// let value = get(record, source);
	// if(value && typeof value !== 'string') {
	// 	value = JSON.stringify(value);
	// }


	switch (range) {
		case 'GridBooleanInput':
			return (
				<GridBooleanInput
					fullWidth={true}
					handleSubmit={submit}
					{...rest}
				/>
			);
		case 'http://www.w3.org/2001/XMLSchema#array':
			return (
				<ArrayInput {...rest}>
					<SimpleFormIterator>
						<TextInput />
					</SimpleFormIterator>
				</ArrayInput>
			);

		case 'http://www.w3.org/2001/XMLSchema#integer':
			return (
				<NumberInput
					fullWidth={true}
					{...rest}
				/>
			);

		case 'http://www.w3.org/2001/XMLSchema#decimal':
			return (
				<NumberInput
					fullWidth={true}
					step="0.1"
					{...rest}
				/>
			);

		case 'http://www.w3.org/2001/XMLSchema#boolean':
			return <BooleanInput
				{...rest}
			/>;

		case 'http://www.w3.org/2001/XMLSchema#date':
			// case 'http://www.w3.org/2001/XMLSchema#dateTime':
			return <DateInput
				fullWidth={true}
				{...rest}
			/>;

		case 'http://www.w3.org/2001/XMLSchema#dateTime':
			return <MVTDateTimeInput
				fullWidth={true}
				settings={{
					fieldType: 'datetime'
				}}

				{...rest}
			/>;


		// case 'http://www.w3.org/2001/XMLSchema#dateTime':
		// 	return <DateTimeInput
		// 		fullWidth={true}
		// 		key={field.name}
		// 		source={field.name}
		// 		normalizeData={(d) => {
		// 			if(typeof console === 'object') { console.log('normalizeDatanormalizeDatanormalizeDatanormalizeData',d); }
		// 			return d;
		// 		}}
		// 		{...props}
		// 	/>;

		default:
			if(props.type && props.type === 'hidden') {
				return (
					<TextInput type="hidden" source={props.source} label={null} />
				);
			}

			let R_resettable_props = {};
			let R_resettable = resettable;

			if(R_resettable) {
				R_resettable_props = {
					resettable: props.multiline ? false : true,
					clearAlwaysVisible:true,
					className:styles.resetIconFix
				};
			}

			return (
				<TextInput
					fullWidth={true}
					{...R_resettable_props}
					{...rest}
				/>
			);
	}


};

MVTInputField.defaultProps = {
	resettable: true,
};

MVTInputField.propTypes = {
	className: PropTypes.any,
	record: PropTypes.object,
	source: PropTypes.string,
	resettable: PropTypes.bool,
	// translate: PropTypes.func
};

MVTInputField.displayName = 'MVTListField';
export default MVTInputField;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
