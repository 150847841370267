import React                       from 'react';
import LocalApi                    from '../LocalApi';
import { useTranslate }            from 'react-admin';
import { minLength, required }     from 'react-admin';
import useGridStyles               from '../../../common/components/helpers/useGridStyles';
import classNames                  from 'classnames';
/**
 * cld_
 *
 *
 *
 *
 *
 *
 */

class Companies extends LocalApi {

	static contentKey = 'companies';
	static sorting = { field: 'companyName', order: 'ASC' };
	// static hasEdit = false;
	// static hasShow = false;


	static getFormFields(formFields,map = 'formFields') {
		// if(typeof console === 'object') { console.log('getFormFields',formFields); }
		let f = [];
		const translate = useTranslate();
		/*
		0: Field {name: "companyName", id: "http://schema.org/name", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
		1: Field {name: "isCustomer", id: "https://lp-api.dev.highject.com/api/docs.jsonld#Company/isCustomer", range: "http://www.w3.org/2001/XMLSchema#boolean", reference: null, required: false, …}
		2: Field {name: "isSupplier", id: "https://lp-api.dev.highject.com/api/docs.jsonld#Company/isSupplier", range: "http://www.w3.org/2001/XMLSchema#boolean", reference: null, required: false, …}
		3: Field {name: "isAgency", id: "https://lp-api.dev.highject.com/api/docs.jsonld#Company/isAgency", range: "http://www.w3.org/2001/XMLSchema#boolean", reference: null, required: false, …}
		4: Field {name: "name", id: "https://lp-api.dev.highject.com/api/docs.jsonld#Company/name", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
		5: Field {name: "notes", id: "https://lp-api.dev.highject.com/api/docs.jsonld#Company/notes", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
		6: Field {name: "email", id: "https://lp-api.dev.highject.com/api/docs.jsonld#Company/email", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
		7: Field {name: "addresses", id: "https://lp-api.dev.highject.com/api/docs.jsonld#Company/addresses", range: "https://lp-api.dev.highject.com/api/docs.jsonld#ContactAddress", reference: Resource, required: false, …}
		8: Field {name: "numbers", id: "https://lp-api.dev.highject.com/api/docs.jsonld#Company/numbers", range: "https://lp-api.dev.highject.com/api/docs.jsonld#ContactNumber", reference: Resource, required: false, …}
		9: Field {name: "isDefaultVendor", id: "https://lp-api.dev.highject.com/api/docs.jsonld#Company/isDefaultVendor", range: "http://www.w3.org/2001/XMLSchema#boolean", reference: null, required: false, …}
		length: 10*/

		let fields = {
			companyName: {
				col: 0,
				inputProps: {
					label: translate('fields.companyName'),
					validate:  [required(), minLength(2)],
					autoFocus: true,
				}
			},
			isCustomer: {
				col: 0,
				inputProps: {
					label: translate('fields.isCustomer'),
				}
			},
			isSupplier: {
				col: 0,
				inputProps: {
					label: translate('fields.isSupplier'),
				}
			},
			isAgency: {
				col: 0,
				inputProps: {
					label: translate('fields.isAgency'),
				}
			},
			// name: {
			// 	col: 0,
			// 	inputProps: {
			// 		label: translate('fields.name'),
			// 	}
			// },
			name: null,
			homepage: {
				col: 1,
				inputProps: {
					label: translate('fields.homepage'),
				}
			},
			notes: {
				col: 0,
				inputProps: {
					label    : translate('fields.notes'),
					multiline: true,
				}
			},
			email: {
				col: 1,
				inputProps: {
					label: translate('fields.email'),
				}
			},
			numbers: this.getNumbersInput(),
			addresses: this.getAddressesInput(),
			isDefaultVendor: null,
			// numbers: null,
		};

		if(map === 'formFields') {
			f = this.mapFields(formFields,fields);
		}

		// if(typeof console === 'object') { console.log('formFields, fields,f',formFields,fields,f); }

		return f;
	}

	static validateForm(values) {
		// if(typeof console === 'object') { console.log('validateForm with values',values); }
		const errors = {
			// title: ['myroot.validation.required']
		};
		return errors;
	}

	static getShowFields(showfields,map = 'showfields') {


		// const translate = useTranslate();
		// const classes = useGridStyles();

		let fields = this.getGridColumns(showfields);

		// if(typeof console === 'object') { console.log('getShowFields',fields); }

		let df = this.matchGridToShowFields(fields);

		return df;

		// return this.getGridColumns(fields);
	}

	static getGridPermanentFilter( permanentFilter ) {
		return permanentFilter;
	}

	static getGridFilterDefaults( filterDefaults ) {
		return {
			// title: '999',
			// description: 'super'
		};
	}

	static getGridFilter( ) {
		return [];
	}


	static getGridColumns(listFields,map = 'listfields') {
		// if(typeof console === 'object') { console.log('getGridColumns',listFields); }

		const translate = useTranslate();
		const classes = useGridStyles();

		let fields = {
			companyName: {
				isSortable: true,
				fieldProps: {
					label    : translate('fields.companyName'),
				}
			},
			// name: {
			// 	isSortable: true,
			// 	fieldProps: {
			// 		label    : translate('fields.name'),
			// 	}
			// },
			name: null,
			email: {
				isSortable: true,
				fieldProps: {
					label    : translate('fields.email'),
					schemaId: 'http://schema.org/email'
				}
			},
			homepage: {
				fieldProps: {
					label    : translate('fields.homepage'),
					schemaId: 'http://schema.org/external-url',
					cellAndHeaderClassName: classNames(classes.breakpointDownLG),
				}
			},
			numbers: this.getNumbersListField(),
			notes: null,
			isCustomer: {
				fieldProps: {
					label    : translate('fields.isCustomer'),
					cellAndHeaderClassName: classNames(classes.w60,classes.breakpointDownSM),
				}
			},
			isSupplier: {
				fieldProps: {
					label    : translate('fields.isCostumerSupplier'),
					cellAndHeaderClassName: classNames(classes.w60,classes.breakpointDownSM),
				}
			},
			isAgency: {
				fieldProps: {
					label    : translate('fields.isAgency'),
					cellAndHeaderClassName: classNames(classes.w60,classes.breakpointDownSM),
				}
			},
			addresses: null,
			people: null,
			isDefaultVendor: null,
		};

		let df = [];
		if(map === 'listfields') {
			df = this.mapFields(listFields,fields);
		}

		return df;
	}

}

export default Companies;
