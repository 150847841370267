import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, createStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import withWidth from '@material-ui/core/withWidth';
import compose from 'recompose/compose';
import { toggleSidebar as toggleSidebarAction } from 'ra-core';
import Clock               from 'react-live-clock';
import Spacer              from '../../common/components/common/Spacer';
import Headroom            from './Headroom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock }        from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt }   from '@fortawesome/free-solid-svg-icons';
import RALanguageSwitcher from '../components/app_title_bar/RALanguageSwitcher';
import AppStateApi        from '../../common/api/AppStateApi';
import MuiButton          from '../../common/components/common/MuiButton';
import authProvider       from '../admin-store/authProvider';
import EditorMenu         from '../components/app_title_bar/EditorMenu';
import { withTranslate }  from 'react-admin';
import { useLocale }      from 'react-admin';
import UserMenu           from './UserMenu';
import FeedbackButton from "../components/app_title_bar/FeedbackButton";
import {BreadcrumbPortal} from "../components/app_title_bar/BreadCrumb";
// import LoadingIndicator   from '../../common/components/common/LoadingIndicator';


// import Typography from '@material-ui/core/Typography';
// import { useTheme } from '@material-ui/styles';
// import LogoutCountdown     from '../components/app_title_bar/LogoutCountdown';
// import request           from 'superagent';
// import LoadingIndicator    from '../../common/components/common/LoadingIndicator';
// import UserMenu            from './UserMenu';

// import ObjectUtils  from '../../common/utils/ObjectUtils';
// import Paper        from '@material-ui/core/Paper';
// import { useAuth } from 'react-admin';

const styles = theme =>
	createStyles({
		appbar: {
			backgroundColor: theme.palette.backgroundColors.appbar,
			zIndex: 1300,
			height: '48px'
		},
		toolbar: {
			paddingRight: 24,
		},
		menuButton: {
			marginLeft: '0.5em',
			marginRight: '0.5em',
		},
		menuButtonIconClosed: {
			transition: theme.transitions.create(['transform'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			transform: 'rotate(0deg)',
		},
		menuButtonIconOpen: {
			transition: theme.transitions.create(['transform'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			transform: 'rotate(180deg)',
		},
		title: {
			flex: 1,
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		},
		grow: {
			flexGrow: 1,
		},
		sectionDesktop: {
			marginRight: theme.spacing(3),
			display: 'none',
			[theme.breakpoints.up('sm')]: {
				display: 'flex',
			},
		},
		sectionMobile: {
			display: 'flex',
			[theme.breakpoints.up('md')]: {
				display: 'none',
			},
		},
		iconSmall: {
			fontSize: 20,
		},
	});

const rightMarginStyle = {marginRight: '5px'};

const AppBar = ({
    children,
    classes,
    className,
    logo,
    logout,
    open,
    title,
    toggleSidebar,
    userMenu,
    width,
    user,
	translate,
	versions,
    ...rest
}) => {

	const locale = useLocale();

	// if(typeof console === 'object') { console.log('AppBar',locale); }
	// useAuth();
	// const { permissions } = usePermissions();
	const toggleAppSidebar = () => {
		// if(typeof console === 'object') { console.log('open',!open); }
		AppStateApi.setState(['Sidebar','open'], !open);
	};

	// if(typeof console === 'object') { console.log('AppBar',user); }


	return (
		<MuiAppBar
			className={classes.appbar}
			// color="secondary"
			// color="appbar"
			position="static"
			{...rest}
		>
			<Toolbar
				disableGutters
				variant={width === 'xs' ? 'regular' : 'dense'}
				className={classes.toolbar}
			>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={toggleAppSidebar}
					className={classNames( classes.menuButton, 'App---Sidebar__Menu-Button' )}
				>
					<MenuIcon
						classes={{
							root: open
								? classes.menuButtonIconOpen
								: classes.menuButtonIconClosed,
						}}
					/>
				</IconButton>

				{/*{Children.count( children ) === 0 ? (*/}
				{/*	<Typography*/}
				{/*		variant="title"*/}
				{/*		color="inherit"*/}
				{/*		className={classes.title}*/}
				{/*		id="react-admin-title"*/}
				{/*	/>*/}
				{/*) : (*/}
				{/*	 children*/}
				{/* )}*/}

				{/*<i className="fa fa-clock-o margin-right" aria-hidden="true" />*/}
				<FontAwesomeIcon className={classes.iconSmall} style={rightMarginStyle} icon={faClock} />
				<Clock
					locale={locale}
					align="right"
					format={locale === 'de' ? 'ddd DD. MMMM, HH:mm' : 'ddd DD. MMMM, HH:mm'}
					ticking={true}
					// timezone={'UTC'}
					// timezone={ 'Europe/Berlin'}
					interval={60000}
				/>
				<Spacer width={3} />
				{translate('Uhr')}
				<Spacer width={10} />
				{1===2 &&(authProvider.checkPermission('ROLE_ADMIN') || authProvider.checkPermission('ROLE_INTERNAL_ADMIN')) && <EditorMenu user={user} />}

				{(user.menuGroups && user.menuGroups.length > 0) && <EditorMenu user={user} />}
				<FeedbackButton user={user} versions={versions} />
				<BreadcrumbPortal />
				<div className={classes.grow} />
				{1===2 &&<MuiButton size="small" variant="contained" icon={faSignOutAlt} onClick={authProvider.logout}>Logout</MuiButton>}
				<UserMenu user={user} />
				{1===2 &&<RALanguageSwitcher />}
				{/*<LoadingIndicator type="simple" />*/}
				{/*{cloneElement( userMenu, { logout } )}*/}
			</Toolbar>
		</MuiAppBar>
	);

	return (
		<Headroom>
			<MuiAppBar
				className={classes.appbar}
				// color="secondary"
				// color="appbar"
				position="static"
				{...rest}
			>
				<Toolbar
					disableGutters
					variant={width === 'xs' ? 'regular' : 'dense'}
					className={classes.toolbar}
				>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={toggleAppSidebar}
						className={classNames( classes.menuButton, 'App---Sidebar__Menu-Button' )}
					>
						<MenuIcon
							classes={{
								root: open
								      ? classes.menuButtonIconOpen
								      : classes.menuButtonIconClosed,
							}}
						/>
					</IconButton>

					{/*{Children.count( children ) === 0 ? (*/}
					{/*	<Typography*/}
					{/*		variant="title"*/}
					{/*		color="inherit"*/}
					{/*		className={classes.title}*/}
					{/*		id="react-admin-title"*/}
					{/*	/>*/}
					{/*) : (*/}
					{/*	 children*/}
					{/* )}*/}

					{/*<i className="fa fa-clock-o margin-right" aria-hidden="true" />*/}
					<FontAwesomeIcon className={classes.iconSmall} style={rightMarginStyle} icon={faClock} />
					<Clock
						locale={locale}
						align="right"
						format={locale === 'de' ? 'ddd DD. MMMM, HH:mm' : 'ddd DD. MMMM, HH:mm'}
						ticking={true}
						// timezone={'UTC'}
						// timezone={ 'Europe/Berlin'}
						interval={60000}
					/>
					<Spacer width={3} />
					{translate('Uhr')}
					<Spacer width={10} />
					{1===2 &&(authProvider.checkPermission('ROLE_ADMIN') || authProvider.checkPermission('ROLE_INTERNAL_ADMIN')) && <EditorMenu user={user} />}

					{(user.menuGroups && user.menuGroups.length > 0) && <EditorMenu user={user} />}
					<FeedbackButton user={user} />
					<BreadcrumbPortal />
					<div className={classes.grow} />
					{1===2 &&<MuiButton size="small" variant="contained" icon={faSignOutAlt} onClick={authProvider.logout}>Logout</MuiButton>}
					<UserMenu user={user} />
					{1===2 &&<RALanguageSwitcher />}
					{/*<LoadingIndicator type="simple" />*/}
					{/*{cloneElement( userMenu, { logout } )}*/}
				</Toolbar>
			</MuiAppBar>
		</Headroom>
	)
};

AppBar.propTypes = {
	children: PropTypes.node,
	classes: PropTypes.object,
	className: PropTypes.string,
	logout: PropTypes.element,
	open: PropTypes.bool,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	toggleSidebar: PropTypes.func.isRequired,
	userMenu: PropTypes.node,
	width: PropTypes.string,
};

AppBar.defaultProps = {
	// userMenu: <UserMenu />,
};

const enhance = compose(
	connect(
		(state) => {
		// if(typeof console === 'object') { console.log('state',state); }
		return {
				// locale: state.i18n ? state.i18n.locale : 'de', // force redraw on locale change
				user: (state.user && state.user.data) ? state.user.data : null,
				open: (state.appstateByPHPOS && state.appstateByPHPOS.appstate.Sidebar) ? state.appstateByPHPOS.appstate.Sidebar.open : false,
			}
		},
		{
			toggleSidebar: toggleSidebarAction,
		}
	),
	withStyles(styles),
	withWidth()
);

export default enhance(withTranslate(AppBar));
