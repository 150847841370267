import React, { useState, useEffect }                            from 'react';
import PropTypes                                                 from 'prop-types';
import Grid                                                      from '@material-ui/core/Grid';
import Date                                                      from '../../../../common/components/common/Date';
import { faProjectDiagram, faCalendar, faRoute, faTags, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles, Typography }      from '@material-ui/core';
import MVTReferenceField
                           from '../../../../common/components/react-admin/grid/fields/MVTReferenceField';
import MVTReferenceArrayField
                           from '../../../../common/components/react-admin/grid/fields/MVTReferenceArrayField';
import ColorUtils          from '../../../../common/utils/ColorUtils';
import { withTranslate }           from 'react-admin';
import ReferenceFieldEditor from "../../../../common/components/react-admin/form/editors/ReferenceFieldEditor";

// import { isFieldSortable }                                       from '../../../../api-platform-admin/src/fieldFactory';
// import { ReferenceArrayField, SingleFieldList } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';

const useSingleListStyles = makeStyles(
	theme => ({
		root: {
			display: 'flex',
			flexWrap: 'wrap',
			// flexDirection: 'column',
			marginTop: -theme.spacing(1),
			marginBottom: -theme.spacing(1),
		},
	}),
	{ name: 'RaSingleFieldList' }
);

let useStyles = makeStyles(function (theme) {
	return (
		{
			root: {
				// flexGrow: 1,
				margin: '20px 0 0 10px',
				justify: 'flex-start',
				justifyContent: 'space-between',
			},
			rowroot: {
				// flexGrow: 1,
				margin: '3px 0 0 3px',
				justify: 'flex-start',
				justifyContent: 'space-between',
			},
			item: {
				marginRight: '20px',
				marginBottom: '10px',
				whiteSpace: 'nowrap',
			},
			icon: {
				marginLeft: 0,
				marginRight: '5px',
				color: props => props.color,
				// color: props => ColorUtils.ligthenHexColor(props.color,50),
			},
			chipLabel: {
				margin: '0 5px 10px 0',
				display: 'inline-block',
				color: props => props.color,
			}
		}
	);
});

function ProjectDataDetails(props) {
	const { translate, color } = props;
	let data = props.project;
	let classes = useStyles({color:color});
	let singleListStyles = useSingleListStyles({color:color});
	const listTagPropsOptions = {classes: singleListStyles};

	return (
		<Grid
			container
			spacing={2}
			direction="row"
			// justify="flex-start"
			className={classes.root}
		>

			<Grid
				container
				spacing={2}
				direction="row"
				className={classes.rowroot}
			>
				{
					data.projectNumber &&
					<Grid item className={classes.item} xs>
						<FontAwesomeIcon className={classes.icon} icon={faProjectDiagram} />
						{data.projectNumber}
					</Grid>
				}
				{
					data.creationDate &&
					<Grid item className={classes.item} xs>
						<FontAwesomeIcon className={classes.icon} icon={faCalendar} />
						<Date
							value={data.creationDate}
							className="margin-right-20"
							parseFormat="ISO"
						/>
					</Grid>
				}
				{
					1===2 && typeof data.location === 'object' &&
					<Grid item className={classes.item} xs>
						<FontAwesomeIcon className={classes.icon} icon={faHome} />
						{data.location.name}

					</Grid>
				}
				{
					typeof data.location === 'string' &&
					<Grid item className={classes.item} xs>
						<FontAwesomeIcon className={classes.icon} icon={faHome} />
						<MVTReferenceField
							record={data}
							childSource="name"
							source="location"
							column={{name:'location'}}
							reference={{name:'locations'}}
							maxCardinality={1}
						/>
					</Grid>
				}
				{
					typeof data.status === 'object' &&
					<Grid item className={classes.item} xs>
						<FontAwesomeIcon className={classes.icon} icon={faRoute} />
						{data.status.title}
					</Grid>
				}
				{
					typeof data.status === 'string' &&
					<Grid item className={classes.item} xs>
						<FontAwesomeIcon className={classes.icon} icon={faRoute} />
						<MVTReferenceField
							record={data}
							childSource="title"
							source="status"
							column={{name:'status'}}
							reference={{name:'project_statuses'}}
							maxCardinality={1}
						/>
					</Grid>
				}

				{
					typeof data.tags === 'object' && data.tags.length > 0 &&
					<Grid item className={classes.item} xs>
						{/*<FontAwesomeIcon className={classes.icon} icon={faTags} />*/}
						<MVTReferenceArrayField
							record={data}
							childSource="title"
							source="tags"
							column={{name:'tags'}}
							reference={{name:'project_tags'}}
							// maxCardinality={1}
						/>
					</Grid>
				}
				{
					1===2 && typeof data.tags === 'object' &&
					<Grid item className={classes.item} xs>
						<FontAwesomeIcon className={classes.icon} icon={faTags} />
						{data.tags.map((tag,index) => {
							return <span key={tag.id}>{tag.title}{(index +1) < data.tags.length > 0 && <span>, </span>}</span>
						})}
					</Grid>
				}
			</Grid>
			<Grid
				container
				spacing={2}
				direction="row"
				className={classes.rowroot}
			>

				{
					typeof data.producers === 'object' && data.producers.length  > 0 &&
					<Grid item className={classes.item} xs>
						{/*<FontAwesomeIcon className={classes.icon} icon={faRoute} />*/}
						<Typography variant="caption" className={classes.chipLabel}>
							{translate('fields.producers')}
						</Typography>
						<ReferenceFieldEditor
							record={data}
							childSource="name"
							source="producers"
							reference={{name:'people'}}
							listTagPropsOptions={listTagPropsOptions}
							// callback={reloadProject}
						/>
					</Grid>
				}

				{
					typeof data.postProducers === 'object' && data.postProducers.length > 0 &&
					<Grid item className={classes.item} xs>
						<Typography variant="caption" className={classes.chipLabel}>
							{translate('fields.postProducers')}
						</Typography>
						<ReferenceFieldEditor
							record={data}
							childSource="name"
							source="postProducers"
							reference={{name:'people'}}
							listTagPropsOptions={listTagPropsOptions}
							// callback={reloadProject}
						/>
					</Grid>
				}


				{
					typeof data.directors === 'object' && data.directors.length > 0 &&
					<Grid item className={classes.item} xs>
						{/*<FontAwesomeIcon className={classes.icon} icon={faRoute} />*/}
						<Typography variant="caption" className={classes.chipLabel}>
							{translate('fields.directors')}
						</Typography>
						<ReferenceFieldEditor
							record={data}
							childSource="name"
							source="directors"
							reference={{name:'people'}}
							listTagPropsOptions={listTagPropsOptions}
							// callback={reloadProject}
						/>
					</Grid>
				}

				{
					typeof data.customerContacts === 'object' &&  data.customerContacts.length > 0 &&
					<Grid item className={classes.item} xs>
						{/*<FontAwesomeIcon className={classes.icon} icon={faRoute} />*/}
						<Typography variant="caption" className={classes.chipLabel}>
							{translate('fields.customers')}
						</Typography>
						<ReferenceFieldEditor
							record={data}
							childSource="name"
							source="customerContacts"
							reference={{name:'companies'}}
							listTagPropsOptions={listTagPropsOptions}
							// callback={reloadProject}
						/>
					</Grid>
				}

				{
					typeof data.agencyContacts === 'object' && data.agencyContacts.length > 0 &&
					<Grid item className={classes.item} xs>
						{/*<FontAwesomeIcon className={classes.icon} icon={faRoute} />*/}
						<Typography variant="caption" className={classes.chipLabel}>
							{translate('fields.agencies')}
						</Typography>
						<ReferenceFieldEditor
							record={data}
							childSource="name"
							source="agencyContacts"
							reference={{name:'companies'}}
							listTagPropsOptions={listTagPropsOptions}
							// callback={reloadProject}
						/>
					</Grid>
				}


			</Grid>





		</Grid>

	);
};

ProjectDataDetails.defaultProps = {

};

ProjectDataDetails.propTypes = {
	project: PropTypes.object,
	color: PropTypes.string,
	// classes: PropTypes.object,
	reloadProject: PropTypes.func,
};

export default withTranslate(ProjectDataDetails);
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));