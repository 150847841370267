import React, { useState } from 'react';
import MuiButton from '../../../common/components/common/MuiButton';
import UAV                 from '../../../common/utils/UAV';
import { Language } from '@material-ui/icons';
import classNames from 'classnames';
import MenuItem            from '@material-ui/core/MenuItem';
import Menu                from '@material-ui/core/Menu';
import ListItemIcon        from '@material-ui/core/ListItemIcon';
import ListItemText        from '@material-ui/core/ListItemText';
import { makeStyles }              from '@material-ui/core/styles';
import { useSetLocale, useLocale } from 'react-admin';
// import PropTypes           from 'prop-types';
// import { withTranslate }             from 'react-admin';
// import { withStyles }      from '@material-ui/core/styles';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import AppStateApi                 from '../../../common/api/AppStateApi';
// import { useDispatch } from 'react-redux';
// import request             from 'superagent';
// import { faFlag }          from '@fortawesome/free-solid-svg-icons';

let useStyles = makeStyles(function (theme) {
	return ({
		leftIcon: {
			marginRight: theme.spacing(1),
		},
		iconSmall: {
			fontSize: 20,
		},
	});
});


const LocaleSwitcher = () => {

	const locale = useLocale();
	const setLocale = useSetLocale();

	let classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);

	const languages = [
		{ code: 'en', label: 'English' },
		{ code: 'de', label: 'Deutsch' },
	];

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleChangeLanguage = (lng) => {
		setLocale(lng);
		setAPILanguage(lng);
		handleClose();
	};

	const setAPILanguage = (lng) => {

		localStorage.setItem('lang',lng);

		// request
		// 	.get( '/calm-i18n/set' ) // new: '/api/logout' (not working yet)
		// 	.query( { lng: lng } )
		// 	.end( ( err, res ) => {
		// 		if ( typeof console === 'object' ) { console.log( 'setAPILanguage', err, res ); }
		// 	} );
	};

	const renderLanguageChoiceMenu = ({ code, label }) => {

		return (
			<MenuItem
				selected={code === locale}
				key={code}
				onClick={() => handleChangeLanguage(code)}
			>
				<ListItemIcon>
					<Language />
				</ListItemIcon>
				<ListItemText primary={label} />
			</MenuItem>
		);
	};

	return (
		<div>
			<MuiButton
				variant="contained"
				size="small"
				icon={<Language />}
				onClick={handleClick}
				className="Mui-Button--IconOnly"
			/>
			<Menu
				// id={control}
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{languages.map(language => renderLanguageChoiceMenu(language))}
				{1===2 && UAV.isSysAdmin() && <MenuItem
					onClick={() => {
						// i18n.reloadResources();
					}}
				>
					<ListItemIcon>
						<Language className={classNames( classes.leftIcon, classes.iconSmall )} />
					</ListItemIcon>
					<ListItemText primary="Reload resources" />
				</MenuItem>}
			</Menu>
		</div>
	);
};

export default LocaleSwitcher;
