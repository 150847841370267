import request       from 'superagent';
import config        from '../../src/host.config';

/**
 * XHR
 */
export default class XHR {

	static initEvents() {
		if(typeof Ext === 'object') {
			Ext.Ajax.on( 'requestexception', XHR.extRequestException );
		}

	}

	static gotoLogin() {
		let host = window.location.host;

		if ( config.loginHosts ) {
			host = config.loginHosts[ host ];
		}

		window.location.href = host;
	}

	static extRequestException( conn, response, options, eOpts) {

		if(typeof console === 'object') {
			console.log('%c extRequestException, conn, response, options, eOpts',
				'color: #0ff',conn, response, options, eOpts);
		}

		if(response && response.status && response.status === 403) {
			this.gotoLogin();
		}

	}

	static superagentException(error) {
		console.log('superagentException', Object.keys(error), error.response, error);

		if(error.response && error.response.status && error.response.status === 403) {
			this.gotoLogin();
		}
	}

};
