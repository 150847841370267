import React, { useState, useEffect } from 'react';
import PropTypes                           from 'prop-types';
import MVTReferenceArrayField from "../../grid/fields/MVTReferenceArrayField";
import ApiPlatformResources from "../../../../../api-platform-admin/src/api/ApiPlatformResources";
import _ from "lodash";
import MuiModalShow from "../MuiModalShow";
import CustomShowToolbar from "../../../../../api-platform-admin/src/components/CustomShowToolbar";
import MuiButton from "../../../common/MuiButton";
import ContentCreate from '@material-ui/icons/Create';
import MuiModalEditor from "../MuiModalEditor";
import MVTReferenceField from "../../grid/fields/MVTReferenceField";
import { makeStyles }        from '@material-ui/core';
import {fade} from "@material-ui/core/styles/colorManipulator";

// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
	theme => ({
		link: {
			color: theme.palette.textColors.link,
			'&:hover': {
				color: fade(theme.palette.textColors.default, 0.9),
			},
		},
	}),
	{ name: 'ReferenceEditorLink' }
);

const contentCreateStyle = {paddingRight: '5px'};
const dialogContentStyle={
	width   : '90vh',
	maxWidth: '800px',
};

function ReferenceFieldEditor(props) {

	// if(typeof console === 'object') { console.log('ReferenceFieldEditor.props',props); }

	let {
		record,
		childSource,
		source,
		reference,
		column,
		callback,
		maxCardinality,
		listTagPropsOptions,
		// editorBasePath,
		...rest
	} = props;

	const classes = useStyles();

	if(props.tagProps && props.tagProps.source) {
		childSource = props.tagProps.source;
	}

	const [showOpen, setShowOpen] = useState();
	const [editorOpen, setEditorOpen] = useState();
	const [callbackOptions, setCallbackOptions] = useState();
	const resource = reference.name;
	const options = ApiPlatformResources.getResourceOptions(resource);

	const handleEditorSave = (basePath,id,record) => {
		// if(typeof console === 'object') { console.log('FORM redirect',basePath,id,record); }
		handleEditorClose();

		if(typeof callback === 'function') {
			callback(basePath,id,record);
		}
	};

	const handleEditorOpen = () => {
		setEditorOpen(true);
		handleShowClose();
	};

	const handleEditorClose = () => {
		setEditorOpen(false);
	};

	const handleShowClose = () => {
		setShowOpen(false);
	};

	const handleShowOpen = (ev,resource,id,record,basePath,o) => {

		o = o || {};
		o.id = id || _.get(record,'id');
		o.resource = resource;
		o.basePath = basePath;
		o.options = options;

		if(childSource) {
			o.title = _.get(record,childSource);
		}

		// if(typeof console === 'object') { console.log('o',o); }

		setCallbackOptions(o);
		setShowOpen(true);
	};


	if(maxCardinality === 1) {
		column = Object.assign({}, column, {
			referenceTagProps: {
				callback: handleShowOpen,
				callbackOptions: {},
				basePath:'/' + reference.name,
				reference: reference.name,
				resourceLinkPath: false,
			},
			tagProps: {
				source: childSource,
				className: classes.link
			}
		} );
	} else {
		column = Object.assign({}, column, {
			listTag: 'MVTSingleFieldList',
			listTagProps: Object.assign({
				callback: handleShowOpen,
				callbackOptions: {}
			},listTagPropsOptions)
		} );
	}

	return (
		<React.Fragment>
			{maxCardinality === 1 ? <MVTReferenceField
				record={record}
				childSource={childSource}
				source={source}
				column={column}
				reference={reference}
				{...rest}
			/> :
				<MVTReferenceArrayField
					record={record}
					childSource={childSource}
					source={source}
					column={column}
					reference={reference}
					{...rest}
				/>
			}
			{showOpen && <MuiModalShow
				toolbar={
					<CustomShowToolbar
						editButton={false}
					>
						<MuiButton
							size="small"
							icon={<ContentCreate fontSize="small" style={contentCreateStyle} />}
							// variant="contained"
							onClick={handleEditorOpen}>
							Edit
						</MuiButton>
					</CustomShowToolbar>
				}
				contentWidth="90vh"
				maxWidth={false}
				dialogContentStyle={dialogContentStyle}
				title={callbackOptions.title}
				closeButton={true}
				disableBackdropClick={true}
				isOpen={showOpen}
				id={callbackOptions.id}
				basePath={callbackOptions.basePath}
				resource={callbackOptions.resource}
				options={Object.assign( {}, callbackOptions.options, { cloneButton: false } )}
				handleEditorClose={handleShowClose}
			/>}
			{editorOpen && <MuiModalEditor
				contentWidth="90vh"
				maxWidth={false}
				dialogContentStyle={dialogContentStyle}
				title={callbackOptions.title}
				closeButton={true}
				disableBackdropClick={true}
				isOpen={editorOpen}
				id={callbackOptions.id}
				basePath={callbackOptions.basePath}
				resource={callbackOptions.resource}
				options={Object.assign( {}, callbackOptions.options, { cloneButton: false } )}
				handleEditorClose={handleEditorClose}
				// minWidth={600}
				redirect={(redirect,id,record) => {
					handleEditorSave(redirect,id,record);
				}}
			/>}

		</React.Fragment>
	);
};

ReferenceFieldEditor.defaultProps = {
	column: {},
	listTagPropsOptions: {}
};

ReferenceFieldEditor.propTypes = {
	record: PropTypes.object,
	childSource: PropTypes.string,
	source: PropTypes.string,
	reference: PropTypes.object,
	column: PropTypes.object,
	column: PropTypes.object,
	listTagPropsOptions: PropTypes.object,
	callback: PropTypes.func,
	maxCardinality: PropTypes.number
};

export default ReferenceFieldEditor;
