// import React             from 'react';
import LocalApi         from '../LocalApi';
import { useTranslate } from 'react-admin';
import ObjectUtils      from '../../../common/utils/ObjectUtils';
// import { minLength, required } from 'react-admin';

/**
 * cld_
 *
 *
 *
 *
 *
 *
 */

class ContactAddresses extends LocalApi {

	static contentKey = 'contact_addresses';
	static sorting = { field: 'title', order: 'ASC' };
	// static hasEdit = false;
	static hasShow = false;
	static hasList = false;

	static getFormFields(formFields,map = 'formFields') {
		// if(typeof console === 'object') { console.log('getFormFields',formFields); }
		let f = [];
		const translate = useTranslate();

		let fields = {
			contact:null,
			title: {
				inputProps: {
					label: translate('fields.title'),
				}
			},
			street: {
				inputProps: {
					label: translate('fields.street'),
				}
			},
			postCode: {
				inputProps: {
					label: translate('fields.postCode'),
				}
			},
			city: {
				inputProps: {
					label: translate('fields.city'),
				}
			},
			state: {
				inputProps: {
					label: translate('fields.state'),
				}
			},
			country: {
				inputProps: {
					label: translate('fields.country'),
				}
			},
			latitude: {
				inputProps: {
					label: translate('fields.latitude'),
				}
			},
			longitude: {
				inputProps: {
					label: translate('fields.longitude'),
				}
			},
			coordinates: {
				inputProps: {
					label: translate('fields.coordinates'),
				}
			},
		};

		if(map === 'formFields') {
			f = this.mapFields(formFields,fields);
		}

		// this.util_exportFields(f);

		return f;
	}

	static validateForm(values) {
		// if(typeof console === 'object') { console.log('validateForm with values',values); }
		const errors = {
			// title: ['myroot.validation.required']
		};
		return errors;
	}

	static getGridPermanentFilter( permanentFilter ) {
		return permanentFilter;
	}

	static getGridFilterDefaults( filterDefaults ) {
		return {
			// title: '999',
			// description: 'super'
		};
	}

	static getGridFilter( ) {
		return [];
	}

	static getGridColumns(listFields,map = 'listfields') {
		// if(typeof console === 'object') { console.log('getGridColumns',listFields); }

		// const translate = useTranslate();

		let fields = {
			// title: {
			// 	isSortable: true,
			// 	// FunctionField: true,
			// 	fieldProps: {
			// 		width: 200,
			// 		header    : translate('fields.title'),
			// 	}
			// },
		};

		let df = [];
		if(map === 'listfields') {
			df = this.mapFields(listFields,fields);
		}

		return df;
	}

}

export default ContactAddresses;
