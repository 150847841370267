import React, { useState, useEffect } from 'react';
// import PropTypes                           from 'prop-types';
// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';
import FullCalendar                   from '@fullcalendar/react';
import dayGridPlugin                  from '@fullcalendar/daygrid';
import timeGridPlugin                 from '@fullcalendar/timegrid';
import listPlugin                     from '@fullcalendar/list';
import interactionPlugin              from '@fullcalendar/interaction' // needed for dayClick

// import timelinePlugin              from '@fullcalendar/timeline' // needed for dayClick
// import resourceTimelinePlugin              from '@fullcalendar/resource-timeline' // needed for dayClick
// import resourceCommonPlugin              from '@fullcalendar/resource-common' // needed for dayClick

// import deLocale                       from '@fullcalendar/core/locales/de';
// import enLocale                       from '@fullcalendar/core/locales/en-gb';
import '../Calendar/main.scss';
import { Button }  from '@material-ui/core';
import ObjectUtils from '../../../common/utils/ObjectUtils';
import equal from 'fast-deep-equal/es6';

class FullcalendarWrapperCOM extends React.Component {
	constructor(props) {
		super(props);
		this.state = {init: 1};
		this.initialized = false;
	}

	shouldComponentUpdate(nextProps, nextState) {

		// if(!this.initialized) {
		// 	this.initialized = true;
		// 	return true;
		// }



		let a = {
			width: this.props.plugins,
			height: this.props.eventSources,
			weekends: this.props.weekends,
			weekNumbers: this.props.weekNumbers,
		};
		let b = {
			width: nextProps.plugins,
			height: nextProps.eventSources,
			weekends: nextProps.weekends,
			weekNumbers: nextProps.weekNumbers,
		};

		let FDE = equal(a, b);
		if(typeof console === 'object') { console.log('FDE',FDE); }

		let isEqualA = ObjectUtils.isEqualExtended(a,b);
		if(typeof console === 'object') { console.log('this.props.isEqualA',isEqualA,a,b); }

		if(!isEqualA) {
			if(typeof console === 'object') { console.log('this.props.isEqualA',isEqualA,a,b); }
			return true;
		}

		let isEqual = ObjectUtils.isEqualExtended(this.props.eventSources,nextProps.eventSources);
		if(typeof console === 'object') { console.log('this.props.calendarEventSources',isEqual,this.props.eventSources,nextProps.eventSources); }

		if(!isEqual) {
			if(typeof console === 'object') { console.log('this.props.calendarEventSources',isEqual,this.props.eventSources,nextProps.eventSources); }
			return true;
		}

		// if(this.props.forwardRef !== nextProps.forwardRef) {
		// 	if(typeof console === 'object') { console.log('REF CHANGED',nextProps.forwardRef); }
		// 	return true;
		// }

		// if(typeof console === 'object') { console.log('this.props.isEqualA',isEqualA,a,b); }
		// if(typeof console === 'object') { console.log('this.props.calendarEventSources',isEqual,this.props.eventSources,nextProps.eventSources); }

		return false;
	}

	render() {

		// if(typeof console === 'object') { console.log('FullCalendar.RENDER.xthis.props',this.props); }

		return (
			<FullCalendar
				{...this.props}
				// ref={this.props.forwardRef}
			/>
		);
	}
}


const FullcalendarWrapper = React.forwardRef((props, ref) => {

	// ObjectUtils.isEqualExtended()

	// if(typeof console === 'object') { console.log('FullcalendarWrapper',props); }

	return (
		<FullCalendar
			{...props}
			ref={ref}
		/>
	);
});

function CalendarTest(props) {
	const [ openEditor, setOpenEditor ] = useState( false );

	let initialEvents = [
		{ title: 'event 1', date: '2019-11-15' },
		{ title: 'event 2', date: '2019-11-02' }
	];

	const getPlugins = () => {
		return [ interactionPlugin,dayGridPlugin,timeGridPlugin,listPlugin ];
	};

	const handleEventRender = (info) => {
		if(typeof console === 'object') { console.log('handleEventRender',info); }
	};

	let calendarEventSources = [
		{
			events: initialEvents,
		},
	];

	return (
		<div>
			<Button href="#" onClick={
				() => {
					setOpenEditor(!openEditor)
				}
			}>Open Something: {openEditor ? 'Yes' : 'No'}</Button>
			<FullcalendarWrapperCOM
				plugins={getPlugins()}
				eventSources={calendarEventSources}
				eventRender={handleEventRender}
			/>
		</div>
	);
};

CalendarTest.defaultProps = {

};

CalendarTest.propTypes = {
	// classes: PropTypes.object,
	// translate: PropTypes.func
};

export default CalendarTest;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
