import React, { useState, useEffect } from 'react';
import PropTypes         from 'prop-types';
import { useMutation, GET_LIST } from 'react-admin';
import { useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import {
	Card,
	CardHeader,
	CardContent,
	ListItemSecondaryAction,
	IconButton
}                               from '@material-ui/core';
import LoadingIndicator         from '../../../../components/common/LoadingIndicator';
import Error                    from '../../../../components/common/Error';
import { withTranslate }        from 'react-admin';
import Create_MVT            from '../../../../../api-platform-admin/src/Create_MVT';
import ApiPlatformResources  from '../../../../../api-platform-admin/src/api/ApiPlatformResources';
import SimpleList_MVT        from '../../../../../api-platform-admin/src/SimpleList_MVT';
import Edit_MVT            from '../../../../../api-platform-admin/src/Edit_MVT';
import EditIcon            from '@material-ui/icons/Edit';
import Cancel              from '@material-ui/icons/Cancel';
import Add                 from '@material-ui/icons/AddCircle';
import MuiDrawerCreator    from '../MuiDrawerCreator';
import MuiDrawerEditor     from '../MuiDrawerEditor';
// import DeleteIcon          from '@material-ui/icons/Delete';

// import { connect } from 'react-redux';
// import InlineEditingList from './InlineEditingList';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
	header: {
		backgroundColor: theme.palette.backgroundColors.header,
		'& .MuiCardHeader-action': {
			marginTop: theme.spacing(1),
			marginRight: 0,
		}
	},
	headerIcon: {
		// paddingRight: theme.spacing(1),
		// paddingTop: theme.spacing(2),
	},
	paper: {
		flexGrow: 1,
	},
	cardContent: {
		padding: 0,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
		'&:last-child': {
			paddingBottom: theme.spacing(1),
		}
	},
	root: {
		width: '100%',
		// maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
		padding: 0,
		'& .MuiListItemSecondaryAction-root':{
			right: theme.spacing(1),
		}
	},
	inline: {
		display: 'inline',
	},
	avatar: {
		backgroundColor: '#bdbdbd',//theme.palette.background.paper,
	},
	listItem: {
		padding: 0,
		paddingTop: theme.spacing(1/2),
		paddingBottom: theme.spacing(1),
		'&.MuiListItem-secondaryAction': {
			paddingRight: theme.spacing(5),
		}
	},
	listItemText: {
		lineHeight: '1.25',
		'& h6': {
			lineHeight: '1.25',
		}
	}
}));



const ReferenceEditorList = (props) => {

	const {
		actionCallback,
		data,
		total,
		basePath,
		resource,
		dense,
		referenceOptions,
		editor
	} = props;
	const classes = useStyles();
	const [editorIndex, setEditorIndex] = useState();

	// if(typeof console === 'object') { console.log('TeamList',data,basePath,referenceOptions); }
	let conf = null;
	if(referenceOptions.configFactory && referenceOptions.configFactory.conf) {
		conf = referenceOptions.configFactory.conf;
	}

	const handleEdit = (index) => {
		setEditorIndex(index);
	};

	const editCallback = (basePath,id,record) => {
		handleEdit();
		actionCallback(basePath,id,record);
	};

	return (
		<div>
			<List
				dense={dense}
				className={classes.root}
			>
				{data.map((record, index) =>
					<React.Fragment key={record['id']}>
						<ListItem
							className={classes.listItem}
							disableGutters={true}
							dense={dense}
							divider={(index +1) < total ? true : false}
							// selected={index===editorIndex}
						>
							{conf && typeof conf.getListAvatar === 'function' ? conf.getListAvatar(record) : null }

							<ListItemText
								className={classes.listItemText}
								disableTypography
								secondaryTypographyProps={{

								}}
								primary={
									conf && typeof conf.getListPrimary === 'function' ? conf.getListPrimary(record) : null
								}
								secondary={
									<React.Fragment>
										{conf && typeof conf.getListSecondary === 'function' ? conf.getListSecondary(record) : null}

										{1=== 2 && index===editorIndex &&<Edit_MVT
											// record={record}
											id={record.id}
											resource={resource}
											basePath={basePath}
											options={referenceOptions}
											formProps={{
												redirect: (basePath,id,record) => {
													// if(typeof console === 'object') { console.log('redirect',basePath,id,record); }
													editCallback(basePath,id,record);
												}
											}}
										/>}
									</React.Fragment>
								}
							/>
							<ListItemSecondaryAction>
								{(index!==editorIndex) ?
								 <IconButton
									 size="small"
									 edge="end"
									 onClick={() => handleEdit(index)}
									 // aria-label="delete"
								 >
									 <EditIcon />
								 </IconButton>
								                       :
								 <IconButton
									 size="small"
									 edge="end"
									 onClick={() => handleEdit(null)}
									 // aria-label="delete"
								 >
									 <Cancel />
								 </IconButton>
								}
							</ListItemSecondaryAction>
						</ListItem>
						{editor === 'inline' && index===editorIndex &&<ListItem>
							<Edit_MVT
								// record={record}
								id={record.id}
								resource={resource}
								basePath={basePath}
								// options={referenceOptions}
								options={Object.assign({},referenceOptions,{cloneButton: false})}
								formProps={{
									redirect: (basePath,id,record) => {
										// if(typeof console === 'object') { console.log('redirect',basePath,id,record); }
										editCallback(basePath,id,record);
									}
								}}
							/>
						</ListItem>}
						{editor === 'drawer' && index===editorIndex &&
						 <MuiDrawerEditor
							 isOpen={index===editorIndex}
							 handleEditorClose={() => {
								 handleEdit(null);
							 }}
							 // record={record}
							 id={record.id}
							 resource={resource}
							 basePath={basePath}
							 // options={referenceOptions}
							 options={Object.assign({},referenceOptions,{cloneButton: false})}
							 // redirect={(a,b) => {
							 // 	if(typeof console === 'object') { console.log('redirect',a,b); }
							 // 	editCallback();
							 // }}
							 formProps={{
								 redirect: (basePath,id,record) => {
									 if(typeof console === 'object') { console.log('redirect',basePath,id,record); }
									 editCallback(basePath,id,record);
								 }
							 }}
						 />
						}

						{1===2 && (index +1) < total &&<Divider variant="inset" component="li" />}
					</React.Fragment>
				)}
			</List>
		</div>
	);
};

ReferenceEditorList.defaultProps = {
	dence: true
};

ReferenceEditorList.propTypes = {
	dence: PropTypes.bool
};


function ReferenceEditor(props) {

	const {
		// options,
		id,
		reference,
		// referenceId,
		// resource,
		basePath,
		filter,
		sorting,
		initialValues,
		title,
		icon,
		subheader,
		cardStyle,
		translate,
		editor,
		callback
	} = props;
	const notify = useNotify();

	let classes = useStyles();
	const [initialized, setIsInitialized] = useState(0);
	const [editorIndex, setEditorIndex] = useState();
	const referenceOptions = ApiPlatformResources.getResourceOptions(reference);

	// if(typeof console === 'object') { console.log('props,referenceOptions',props,referenceOptions); }

	const getSorting = () => {
		if(sorting) {
			return sorting;
		} else if(referenceOptions && referenceOptions.configFactory && referenceOptions.configFactory.conf) {
			return referenceOptions.configFactory.conf.getGridSorting();
		}

		return null;
	};

	let [mutate, { data, total, error }] = useMutation();
	const get = event => mutate(
		{
			type: GET_LIST,
			resource: reference,
			payload: {
				pagination: { page: 1, perPage: 100 },
				sort: getSorting(),
				filter: filter,
				// groups: ['related:read']
			},
		},
		{
			onSuccess: ({ data }) => {
				setIsInitialized(true);
				return data;

			},
			onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
		}
	);


	useEffect(() => {
		get();
	},[id]);

	const actionCallback = (basePath,id,record) => {
		get();

		if(typeof callback === 'function') {
			callback(basePath,id,record);
		}
	};

	const newCallback = (basePath,id,record) => {
		setEditorIndex(0);
		get();

		if(typeof callback === 'function') {
			callback(basePath,id,record);
		}
	};

	const handleEdit = (index) => {
		setEditorIndex(index);
	};

	// if(typeof console === 'object') { console.log('ProjectMemberships.props',props,id); }

	if (!initialized) return <LoadingIndicator />;
	// if (loading) return <LoadingIndicator />;
	if (error) return <Error />;
	// if (!data) return null;

	return (
		<React.Fragment>
			<Card className={classes.paper} elevation={1} style={cardStyle}>
				<CardHeader
					className={classes.header}
					avatar={
						<Avatar aria-label={title} className={classes.avatar}>
							{icon}
						</Avatar>
					}
					action={
						<React.Fragment>
							{(1!==editorIndex) ?
							 <IconButton
								 size="small"
								 className={classes.headerIcon}
								 onClick={() => handleEdit(1)}
							 >
								 <Add />
							 </IconButton>
							                   :
							 <IconButton
								 size="small"
								 onClick={() => handleEdit(0)}
							 >
								 <Cancel />
							 </IconButton>
							}
						</React.Fragment>
					}
					title={title}
					subheader={subheader}
				/>
				<CardContent
					className={classes.cardContent}
				>
					{editor === 'inline' && 1===editorIndex &&<React.Fragment>
						<p>{translate('buttons.add')}</p>
						<Create_MVT
							location={props.location}
							resource={reference}
							basePath={basePath}
							options={referenceOptions}
							formProps={{
								initialValues: initialValues,
								redirect: (basePath,id,record) => {
									// if(typeof console === 'object') { console.log('redirect',basePath,id,record); }
									newCallback(basePath,id,record);
								}
							}}
						/>
					</React.Fragment>}

					{editor === 'drawer' && 1===editorIndex &&<MuiDrawerCreator
						handleEditorClose={() => {
							handleEdit(0);
						}}
						isOpen={Boolean(editorIndex)}
						location={props.location}
						resource={reference}
						basePath={basePath}
						options={referenceOptions}
						formProps={{
							initialValues: initialValues,
							redirect: (basePath,id,record) => {
								// if(typeof console === 'object') { console.log('redirect',basePath,id,record); }
								newCallback(basePath,id,record);
							}
						}}

					/>}

					{1===2 &&data &&<SimpleList_MVT
						hasCreate={false}
						hasEdit={false}
						hasShow={false}
						location={props.location}
						resource={reference}
						basePath={basePath}
						options={referenceOptions}
						total={total}
						data={data}
					/>
					}

					{data &&<ReferenceEditorList
						actionCallback={actionCallback}
						resource={reference}
						basePath={basePath}
						// options={options}
						relationId={id}
						referenceOptions={referenceOptions}
						total={total}
						data={data}
						editor={editor}
					/>}
				</CardContent>
			</Card>
		</React.Fragment>
	);

};

ReferenceEditor.defaultProps = {
	cardStyle: {},
	editor: 'inline'
};

ReferenceEditor.propTypes = {
	location: PropTypes.object.isRequired,
	resource: PropTypes.string.isRequired,
	reference: PropTypes.string.isRequired,
	basePath: PropTypes.string.isRequired,
	title: PropTypes.string,
	subheader: PropTypes.string,
	icon: PropTypes.any,
	cardStyle: PropTypes.object,
	editor: PropTypes.string,
	sorting: PropTypes.object,
	filter: PropTypes.object,
	callback: PropTypes.func,
};

// const mapStateToProps = (state, props) => {
// 	// if(typeof console === 'object') { console.log('TEST.mapStateToProps state',state,props); }
//
// 	// let r = props.resource || 'project_memberships';
// 	// let id = '/api/project_memberships/1' || props.id;
//
// 	return ({
// 		// resource: state.admin.resources[r],
// 		// record: state.admin.resources[r]
// 		//         ? state.admin.resources[r].data[id]
// 		//         : null,
// 		version: state.admin.ui.viewVersion,
// 	})
// };


// export default connect(mapStateToProps,null)(ProjectMemberships);
export default withTranslate(ReferenceEditor);
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
