import React                         from 'react';
import PropTypes                     from 'prop-types';
import get                           from 'lodash/get';
import { TextField } from 'react-admin';
import ReferenceField from './RAReferenceField';
import { makeStyles }                from '@material-ui/core';
// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';
/**
 *
 * MVTReferenceField
 *
 * config e.g.:
 * referenceTagProps: {basePath:'/locations',reference:'locations'},
 * tagProps: {source:'name'},
 *
 * @param props
 * @returns {*}
 * @constructor
 */

var referenceStyles = makeStyles(function (theme) { return ({
	link: {
		textDecoration: 'none',
		color: theme.palette.textColors.link,
	},
}); });

const MVTReferenceField = (props) => {
	// if(typeof console === 'object') { console.log('MVTReferenceField.props',props); }
	let {
		value,
		//className,
		record,
		source,
		cellRenderer,
		column = {},
		columnIndex,
		rowIndex,
		reference,
		childSource,
		//...rest
	} = props;

	let refStyles = referenceStyles();


	/**
	 * Componets array to match components in tag name of column definition
	 * @type {{NumberColumn: NumberColumn, DateColumn: DateColumn, Column: Column}}
	 */
	let components = {
		TextField: TextField,
		ReferenceField  : ReferenceField,
		// Column      : Column
	};

	if(!value) {
		value = get(record, source);
		if(value && typeof value !== 'string') {
			value = JSON.stringify(value);
		}
	}

	// if(typeof console === 'object') { console.log('MVTListField.props %o of %o with value %o',props,source,value); }

	if(typeof cellRenderer === 'function') {
		return cellRenderer(value,record,{column:column,columnIndex:columnIndex,rowIndex:rowIndex});
	}

	let { tag, referenceTag, tagProps, referenceTagProps } = column;
	const FieldTag = components[ tag || 'TextField' ];
	const RefTag = components[ referenceTag || 'ReferenceField' ];
	if(!tagProps) {
		tagProps = {
			source: childSource
		};
	}

	if(!referenceTagProps) {
		referenceTagProps = {
			basePath:'/' + reference.name,
			reference: reference.name
		};
	}

	//return (<LinearProgress />);

	// if(typeof console === 'object') { console.log('column',column); }

	return (
		<RefTag
			source={source}
			record={record}
			allowEmpty={true}
			link={false}
			classes={refStyles}
			{...referenceTagProps}
		>
			<FieldTag {...tagProps} />
		</RefTag>
	);
};

MVTReferenceField.defaultProps = {

};

MVTReferenceField.propTypes = {
	className: PropTypes.object,
	record: PropTypes.object,
	source: PropTypes.string,
	// translate: PropTypes.func
};

MVTReferenceField.displayName = 'MVTReferenceField';
export default MVTReferenceField;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
