// import { HydraAdmin } from '@api-platform/admin';
// export default () => <HydraAdmin entrypoint="https://demo.api-platform.com"/>; // Replace with your own API entrypoint
// import apiDocumentationParser from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
// import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import parseHydraDocumentation from '../api-platform-admin/src/hydra/parseHydraDocumentation';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import AdminBuilder from './AdminBuilder';
import dataProvider from '../api-platform-admin/src/hydra/hydraClient';
import authProvider from './admin-store/authProvider';
import enhanceDataProvider from './admin-store/enhanceDataProvider';
// import { Route, Redirect } from 'react-router-dom';
// // Static ROUTING
// import { Route, Redirect } from 'react-router-dom';
// import About from './scenes/About/About';
// import Test from './scenes/Test/Test';
// import Calendar from './scenes/Calendar/Calendar';

// Reducers
// import { connect }       from 'react-redux';
// import bitcoinRateReducer from './admin-store/reducers/bitcoinRateReducer';
// import { appdataByPHPOS, appstateByPHPOS, options } from '../common/store/reducers/reducers';
// import createAdminStore from './admin-store/createAdminStore';
// import { createAdminStore } from 'ra-core';

// REDUCERS / APIs --> MOVED TO LAYOUT
// import AppStateApi from '../common/api/AppStateApi';
// import AppDataApi from '../common/api/AppDataApi';
// import LocalApi from './api/LocalApi';

// SAGAS
// import bitcoinSaga      from './admin-store/sagas/bitcoinSaga';
import LoadingIndicator from '../common/components/common/LoadingIndicator';

// import { fetchHydra as baseFetchHydra } from '../api-platform-admin/src/hydra/fetchHydra';
import baseFetchHydra                                       from '../api-platform-admin/src/hydra/fetchHydra';
// import { replaceResources } from '@api-platform/admin';

// const entrypoint = process.env.REACT_APP_API_ENTRYPOINT; // Change this by your own entrypoint if you're not using API Platform distribution
const fetchHeaders = {
	'Authorization': `Bearer ${localStorage.getItem('token')}`,
	// 'x-switch-user': 'alex'
};

const userSwitch = authProvider.getUserSwitch();
if(userSwitch) {
	fetchHeaders['x-switch-user'] = userSwitch;
}

const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
	...options,
	headers: new Headers(fetchHeaders),
});

const apiDocumentationParser = entrypoint =>
	parseHydraDocumentation(entrypoint, {
		headers: new Headers(fetchHeaders),
	}).then(
		({ api }) => {
			// change resources: https://api-platform.com/docs/admin/getting-started/
			// if(typeof console === 'object') { console.log('api1',api); }
			// const projects = api.resources.find(({ name }) => 'projects' === name);
			// if(typeof console === 'object') { console.log('projects',projects); }
			// replaceResources(api.resources, newResources);
			// if(typeof console === 'object') { console.log('api',api); }
			return ({ api })
		},
		result => {
			const { api, status } = result;

			if (status === 401) {
				authProvider.killToken();
				if(typeof console === 'object') { console.log('api2',window.location,status,result); }

				if(window.location.href.indexOf('/login') === -1) {
					window.location.replace( '/login' );
				}

				return Promise.resolve({
					api,
					status,
					customRoutes: [
						{/*<Route path="/" render={() => <Redirect to="/login" />} />,*/},
					],
				});
			}

			return Promise.reject(result);
		}
	);

export default class HydraAdmin extends Component {
	static defaultProps = {
		apiDocumentationParser,
		// customReducers:{
		// 	bitcoinRate: bitcoinRateReducer,
		// 	appdataByPHPOS, appstateByPHPOS, options
		// },
		// customSagas:[ bitcoinSaga ],
		error: 'Unable to retrieve API documentation.',
		loading: <LoadingIndicator type="container" />,
		dataProvider,
		authProvider,
	};

	static propTypes = {
		apiDocumentationParser: PropTypes.func,
		customRoutes: PropTypes.array,
		entrypoint: PropTypes.string.isRequired,
		error: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
		loading: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element]),
		dataProvider: PropTypes.func,
	};

	state = {
		api: null,
		customRoutes: [],
		hasError: false,
		loaded: false,
	};

	componentDidMount() {
		this.loadResources();
	}


	loadResources() {
		this.props
			.apiDocumentationParser(this.props.entrypoint)
			.then(
				({api, customRoutes = []}) => {

					// if(typeof console === 'object') { console.log('api',api); }
					if(!api) {
						return {
							loaded: true,
							hasError: true
						};
					}

					const promises = api.resources.map(resource =>
						resource.getParameters(),
					);

					// if(typeof console === 'object') { console.log('apiDocumentationParser.promises',promises); }

					return Promise.all(promises).then(responses => {
						api.resources.forEach((resource, index) => {
							resource.parameters = responses[index];

							// if(typeof console === 'object') { console.log('apiDocumentationParser.resource.parameters',resource.parameters); }

						});
						// if(typeof console === 'object') { console.log('apiDocumentationParser.api',api); }

						return {
							api,
							customRoutes,
							hasError: false,
							loaded: true,
						};
					});
				},
				data => {
					if (data instanceof Error) {
						console.error(data);

						return {
							hasError: true,
							loaded: true,
						};
					}


					return {
						api: data.api,
						customRoutes: data.customRoutes,
						hasError: true,
						loaded: true,
					};
				},
			)
			.then(this.setState.bind(this));
	}

	render() {

		// if(typeof console === 'object') { console.log('HydraAdmin',this.state,this.props); }

		if (false === this.state.loaded) {
			return 'function' === typeof this.props.loading ? (
				<this.props.loading />
			) : (
				       <span className="loading">{this.props.loading}</span>
			       );
		}

		if (true === this.state.hasError) {
			return 'function' === typeof this.props.error ? (
				<this.props.error />
			) : (
				<span className="error">{this.props.error} <a href="/login">Login</a></span>
			       );
		}

		const dataProvider = this.props.dataProvider(this.state.api,fetchHydra);
		const enhancedDataProvider = enhanceDataProvider(dataProvider);

		return (
			<AdminBuilder
				{...this.props}
				api={this.state.api}
				customRoutes={this.state.customRoutes}
				dataProvider={enhancedDataProvider}
			/>
		);
	}
}

