import React, { PureComponent, Children, cloneElement } from 'react';
import PropTypes                    from 'prop-types';
import { connect }                  from 'react-redux';
import compose                      from 'recompose/compose';
import Drawer                       from '@material-ui/core/Drawer';
import { withStyles, createStyles } from '@material-ui/core/styles';
import withWidth                    from '@material-ui/core/withWidth';
import { setSidebarVisibility }     from 'ra-core';
// import { ClickAwayListener }        from '@material-ui/core';
import {
	Responsive
}                                   from 'react-admin';
import AppStateApi                  from '../../common/api/AppStateApi';
import DomUtils                     from '../../common/utils/DomUtils';

export const DRAWER_WIDTH = 240;
export const CLOSED_DRAWER_WIDTH = 72;

const styles = theme =>
	createStyles({
		drawerRoot: {
			// backgroundColor: theme.palette.backgroundColors.sideBar,
			borderRight: '1px solid rgba(0, 0, 0, 0.12)',
			borderColor: theme.palette.borderColors.sideBar,
			// position: 'absolute',
			// zIndex: 11,
			// height: '100%'
		},
		drawerPaper: {
			position: 'relative',
			// height: 'auto',
			height: '100%',
			overflowX: 'hidden',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			// backgroundColor: 'transparent',
			backgroundColor: theme.palette.backgroundColors.sideBar,
			// marginTop: '0.5em',
			paddingTop: '0.5em',
			borderRight: 'none',
			[theme.breakpoints.only('xs')]: {
				// marginTop: 0,
				paddingTop: 0,
				height: '100vh',
				position: 'inherit',
				backgroundColor: theme.palette.background.default,
			},
			[theme.breakpoints.up('md')]: {
				border: 'none',
				// marginTop: '1.5em',
				paddingTop: '1.5em',
			},
		},
	});

// We shouldn't need PureComponent here as it's connected
// but for some reason it keeps rendering even though mapStateToProps returns the same object
class Sidebar extends PureComponent {
	UNSAFE_componentWillMount() {
		const { width, setSidebarVisibility } = this.props;
		if (width !== 'xs' && width !== 'sm') {
			setSidebarVisibility(true);
		}
	}

	handleClose = () => {
		this.props.setSidebarVisibility(false);
		AppStateApi.setState(['Sidebar','open'], false);
	};

	handleClickAwyClose = (event) => {
		if(typeof console === 'object') { console.log('handleClickAwyClose',event,event.target); }
		let t = DomUtils.findUpClassName(event.target,'App---Sidebar__Menu-Button');
		if(!t) {
			this.handleClose();
		}
		//App---Sidebar__Menu-Button
	};

	toggleSidebar = () => {
		AppStateApi.setState(['Sidebar','open'], !this.props.open);
	};

	render() {
		const {
			children,
			classes,
			closedSize,
			open,
			setSidebarVisibility,
			size,
			width,
			...rest
		} = this.props;

		// if(typeof console === 'object') { console.log('open',open); }

		return (
			<Responsive
				xsmall={
					<Drawer
						className={classes.drawerRoot}
						variant="temporary"
						open={open}
						PaperProps={{
							className: classes.drawerPaper,
							style: { width: size },
						}}
						onClose={this.toggleSidebar}
						{...rest}
					>
						{cloneElement(Children.only(children), {
							onMenuClick: this.handleClose,
						})}
					</Drawer>
				}
				small={
					<Drawer
						className={classes.drawerRoot}

						variant="permanent"
						open={open}
						PaperProps={{
							className: classes.drawerPaper,
							style: {
								width: open ? size : closedSize,
							},
						}}
						onClose={this.toggleSidebar}
						{...rest}
					>
						{cloneElement(Children.only(children), {
							dense: true,
							onMenuClick: this.handleClose,
						})}
					</Drawer>
				}
				medium={
					<Drawer
						className={classes.drawerRoot}
						variant="permanent"
						style={{
							width: open ? size : closedSize,
						}}
						// variant="persistent"
						// docked={false}
						open={open}
						PaperProps={{
							className: classes.drawerPaper,
							style: {
								width: open ? size : closedSize,
							},
						}}
						onClose={this.toggleSidebar}
						{...rest}
					>
						{cloneElement(Children.only(children), {
							dense: true,
							onMenuClick: this.handleClose,
						})}
					</Drawer>
				}
			/>
		);
	}
}

Sidebar.propTypes = {
	children: PropTypes.node.isRequired,
	classes: PropTypes.object,
	closedSize: PropTypes.number,
	open: PropTypes.bool.isRequired,
	size: PropTypes.number,
	width: PropTypes.string,
};

Sidebar.defaultProps = {
	size: DRAWER_WIDTH,
	closedSize: CLOSED_DRAWER_WIDTH,
};

const mapStateToProps = state => ({
	open: (state.appstateByPHPOS && state.appstateByPHPOS.appstate.Sidebar) ? state.appstateByPHPOS.appstate.Sidebar.open : false,
	locale: state.locale, // force redraw on locale change
});

export default compose(
	connect(
		mapStateToProps,
		{ setSidebarVisibility }
	),
	withStyles(styles),
	withWidth({ resizeInterval: Infinity }) // used to initialize the visibility on first render
)(Sidebar);
