import React               from 'react';
import { Route, Redirect } from 'react-router-dom';
// import ProjectMasters      from './scenes/Projects/components/ProjectMasters';
// import ProjectList         from './scenes/Projects/ProjectList';
// import Calendar         from './scenes/Calendar/Calendar';
// import PlanningCalendar from './scenes/planning/PlanningCalendar';
// import Planning         from './scenes/planning/Planning';
// import About            from './scenes/About/About';
// import Test             from './scenes/Test/Test';
// import Fields           from './scenes/Test/Fields';
// import Editor           from './scenes/Test/Editor';
import CalendarTest     from './scenes/Test/CalendarTest';
// import DropzoneTest     from './scenes/Test/DropzoneTest';
// import TreeViewTest     from './scenes/Test/TreeViewTest';
// import GridTest     from './scenes/Test/GridTest';
// import ProjectEditor    from './scenes/Projects/ProjectEditor';
const Calendar = React.lazy(() => import('./scenes/Calendar/Calendar'));
const PlanningCalendar = React.lazy(() => import('./scenes/planning/PlanningCalendar'));
const Planning = React.lazy(() => import('./scenes/planning/Planning'));
const SplitPlanning = React.lazy(() => import('./scenes/planning/SplitPlanning'));
const ProjectEditor = React.lazy(() => import('./scenes/Projects/ProjectEditor'));
const ProjectList = React.lazy(() => import('./scenes/Projects/ProjectList'));
// const ProjectMasters = React.lazy(() => import('./scenes/Projects/components/ProjectMasters'));
// const ProjectMasterLists = React.lazy(() => import('./scenes/Projects/components/ProjectMasterLists'));
const About = React.lazy(() => import('./scenes/About/About'));


export default function CustomRoutes(props) {

	const { factorizedResources } = props;


	let CalendarEventsRosourceOptions = factorizedResources && factorizedResources['calendar_events'] ? factorizedResources['calendar_events'].props : {};
	let ProjectsRosourceOptions = factorizedResources && factorizedResources['projects'] ? factorizedResources['projects'].props : {};

	if(1===2 && typeof console === 'object') {
		console.log('CalendarEventsRosourceOptions',
			factorizedResources.length,
			factorizedResources['calendar_events'],
			// factorizedResources['calendar_events'].props,
			CalendarEventsRosourceOptions
		);
	}

	return [
		<Route
			// exact
			// path="/projects/:id?"
			path={[
				// '/projects/:id?/:page?',
				// '/projects-in-progress/:id?/:page?',
				// '/projects-acquisition/:id?/:page?',
				'/projects/:id?',
				'/projects-in-progress/:id?',
				'/projects-acquisition/:id?',
			]}
			render={
				( props ) => {

					const { match } = props;
					const isMatch = match && match.params && match.params.id && match.params.id !== 'create' ? true : false;
					// if(typeof console === 'object') { console.log('ROUTE props',props,isMatch); }

					let basePath = '/projects';
					switch ( props.match.path ) {
						case'/projects-in-progress/:id?':
							basePath = '/projects-in-progress';
							break;
						case'/projects-acquisition/:id?':
							basePath = '/projects-acquisition';
							break;
					}

					let id = null;
					if(isMatch) {
						// if(typeof console === 'object') { console.log('PROJECT match',match,props); }

						id = match.params.id;
						if(typeof id === 'string') {
							id = decodeURIComponent(id);
						}

						// if(match.params.page === 'masterlists') {
						// 	return (
						// 		<ProjectMasterLists
						// 			{...props}
						// 			{...ProjectsRosourceOptions}
						// 			resource={ProjectsRosourceOptions.name}
						// 			basePath={basePath}
						// 			id={id}
						// 		/>
						// 	);
						// }
						//
						// if(match.params.page === 'masters') {
						// 	return (
						// 		<ProjectMasters
						// 			{...props}
						// 			{...ProjectsRosourceOptions}
						// 			resource={ProjectsRosourceOptions.name}
						// 			basePath={basePath}
						// 			id={id}
						// 		/>
						// 	);
						// }

						return (
							<ProjectEditor
								{...props}
								{...ProjectsRosourceOptions}
								resource={ProjectsRosourceOptions.name}
								basePath={basePath}
								id={id}
							/>
						);
					}

					// if(typeof console === 'object') { console.log('ROUTING.basePath',basePath); }

					return (<ProjectList
					{...props}
					{...ProjectsRosourceOptions}
					resource={ProjectsRosourceOptions.name}
					basePath={basePath}
				/>)
			}}
		/>,
		<Route
			// exact
			path="/calendar/:date?"
			render={
				( props ) => <Calendar {...props} {...CalendarEventsRosourceOptions} />
			}
		/>,
		<Route
			// exact
			path="/planning/calendar/:date?"
			render={
				( props ) => <PlanningCalendar {...props} {...CalendarEventsRosourceOptions} />
			}
		/>,
		<Route
			// exact
			path="/planning/resources/:resource/:date?/:resourceId?"
			render={
				( props ) => <Planning {...props} {...CalendarEventsRosourceOptions} />
			}
		/>,
		<Route
			// exact
			path="/splitplanning/resources/:resource/:date?/:resourceId?"
			render={
				( props ) => <SplitPlanning {...props} {...CalendarEventsRosourceOptions} />
			}
		/>,
		<Route exact path="/about" component={About} />,
		//<Route path="/test" component={Test} />,
		//<Route path="/fields" component={Fields} />,
		//<Route path="/editor" component={Editor} />,
		<Route path="/calendar-test" component={CalendarTest} />,
		//<Route path="/treeview" component={TreeViewTest} />,
		//<Route path="/dropzone-test" component={DropzoneTest} />,
		//<Route path="/grid-test" component={GridTest} />,
		<Redirect exact from="/" to="/planning/calendar" /> // default redirect

	];
}
