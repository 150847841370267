import React, { useState, useEffect } from 'react';
import PropTypes                      from 'prop-types';
import CircularProgress               from '@material-ui/core/CircularProgress';
import { AddCircleOutline }           from '@material-ui/icons';
import { IconButton }                 from '@material-ui/core';
import MuiModalCreator                from '../MuiModalCreator';
import ApiPlatformResources           from '../../../../../api-platform-admin/src/api/ApiPlatformResources';
// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';
const dialogContentStyle = {
	// maxHeight: '400px',
	width: '90vh',
	maxWidth: '800px',
};

function CreateDialogButton(props) {

	const {
		loading,
		handleEditorSave,
		basePath,
		reference,
		createReference,
		label
	} = props;

	const [open,setOpen] = useState();

	const ref = createReference || reference;

	const referenceOptions = ApiPlatformResources.getResourceOptions(ref);

	// if(typeof console === 'object') { console.log('CreateDialogButton',label,props,referenceOptions); }

	const handleEditorOpen = () => {
		setOpen(true);
	};

	const handleEditorClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<IconButton
				size="small"
				onMouseDown={(event) => {
					event.preventDefault();
					event.stopPropagation();
					handleEditorOpen();
				}}
			>
				{loading ? <CircularProgress color="inherit" size={20} /> : <AddCircleOutline fontSize="small" />}
			</IconButton>
			{open && <MuiModalCreator
				contentWidth="90vh"
				maxWidth={false}
				dialogContentStyle={dialogContentStyle}
				title={label}
				closeButton={true}
				// disableBackdropClick={true}
				isOpen={open}

				resource={ref}
				basePath={basePath}
				// initialValues={initialValues}
				options={referenceOptions}
				// location={props.location}

				handleEditorClose={handleEditorClose}
				redirect={(redirect,id,record) => {
					// if(typeof console === 'object') { console.log('FORM redirect',redirect,id,record,typeof handleEditorSave); }
					handleEditorClose();
					if(typeof handleEditorSave === 'function') {
						handleEditorSave(redirect,id,record);
					}
					return false;
				}}

			/>}
		</React.Fragment>
	);
};

CreateDialogButton.defaultProps = {

};

CreateDialogButton.propTypes = {
	handleEditorSave: PropTypes.func,
	loading: PropTypes.bool,
	basePath: PropTypes.string,
	resource: PropTypes.string,
	// classes: PropTypes.object,
	// translate: PropTypes.func
};

export default CreateDialogButton;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
