import localforage from "localforage";

/**
 * Doku: https://localforage.github.io/localForage/#data-api-getitem
 */

class LocalForage {

	static db = null;
	static store = null;

	static initDB(name,version=1,storename) {
		const config = {
			driver      : localforage.INDEXEDDB,
			name        : name || 'PHPOS',
			version     : version || 1,
			storeName   : storename, // Should be alphanumeric, with underscores.
		};

		return this.store = localforage.createInstance(config);
	}

	static getStore(store) {
		store = store || this.store;

		if(!store) {
			throw 'No store defined in LocalForage';
		}

		return store;
	}

	static get(store,key,value) {
		return this.getStore(store).getItem(key);
	}

	static add(store,key,value) {
		return this.getStore(store).setItem(key,value);
	}

	static clear(store) {
		return this.getStore(store).clear();
	}


	static removeItem(store,key) {
		return this.getStore(store).removeItem(key);
	}

}

export default LocalForage;
