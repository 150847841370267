import React                   from 'react';
import LocalApi                from '../LocalApi';
import ProgressBar             from '../../../common/components/common/ProgressBar';
import { useTranslate }        from 'react-admin';
import { minLength, required } from 'react-admin';
import VideoPreview            from '../../components/video/VideoPreview';
// import { makeStyles }          from '@material-ui/core';
import useGridStyles from '../../../common/components/helpers/useGridStyles';
import classNames from 'classnames';

// import Toolbar        from '@material-ui/core/Toolbar';
// import Typography          from '@material-ui/core/Typography';
// import Tooltip             from '@material-ui/core/Tooltip';
// import IconButton          from '@material-ui/core/IconButton';
// // import DeleteIcon          from '@material-ui/core/SvgIcon/SvgIcon';
// import classNames          from 'classnames';
// // import { lighten } from '@material-ui/core/styles/colorManipulator';
// // import Tooltip from '@material-ui/core/Tooltip';
// import FilterListIcon      from '@material-ui/icons/FilterList';
// import MuiButton           from '../../common/components/common/MuiButton';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import { TextField, ReferenceField }       from 'react-admin';
// import ProgressBar from '../../common/components/common/ProgressBar';
// import EditButton           from '../../common/components/admin-grid/components/EditButton';



/**
 * cld_Projects
 *
 *
 *
 *
 *
 *
 */

class Projects extends LocalApi {

	static contentKey = 'projects';
	static sorting = { field: 'creationDate', order: 'DESC' };
	// static sorting = { field: 'projectNumber', order: 'DESC' };
	// static groupBy = ['status'];
	// static hasEdit = false;
	static hasShow = false;


	static getFormFields(formFields,map = 'formFields') {
		// if(typeof console === 'object') { console.log('getFormFields',formFields); }
		let f = [];
		const translate = useTranslate();

		let fields = {
			projectNumber: {
				MVTInputField: true,
				inputProps: {
					label    : translate('fields.projectNumber'),
					validate:  [required(), minLength(2)],
					autoFocus: true,
					// initialValue:'New Project' ,
				}
			},
			title: {
				MVTInputField: true,
				inputProps: {
					validate:  [required(), minLength(2)]
				}
			},
			shortTitle: {
				MVTInputField: true,
				inputProps: {
					validate:  [required(), minLength(2)]
				}
			},
			type: {
				MVTReferenceInput: true,
				inputProps: {
					tag     : 'AutocompleteInput',
					label    : translate('fields.type'),
					// initialValue: 'new',
					tagProps: {
						optionText: 'title',
						reference: 'project_types',
						loadResource: false,
						search: false,
						// keepValueOnly: false,
						// removeValueOnly: false,
						type: 'select',
						initialValue: '/api/project_types/production',
					},
				}
			},
			description: {
				// MVTListField: true,
				inputProps: {
					label    : translate('fields.description'),
					multiline: true,
					// initialValue: 'Test',
					style:{minHeight: '3em'},
					// required: true,
					// validate: [required(), minLength(10)]
				}
			},
			color: {
				// MUIColorPicker: true
				inputProps: {
					label    : translate('fields.color'),
					initialValue: '#00b4ff',
				}
			},
			creationDate: {
				inputProps: {
					label    : translate('fields.creationDate'),
					initialValue: new Date(),
				}
			},
			// location: {
			// 	MVTReferenceInput: true,
			// 	inputProps: {
			// 		tag     : 'AutocompleteInput',
			// 		label    : translate('fields.location'),
			// 		tagProps: {
			// 			optionText: 'name',
			// 			reference: 'locations',
			// 			loadResource: false,
			// 			search: false,
			// 		},
			// 	}
			// },
			status: {
				MVTReferenceInput: true,
				inputProps: {
					tag     : 'AutocompleteInput',
					label    : translate('fields.status'),
					// initialValue: 'new',
					tagProps: {
						optionText: 'title',
						reference: 'project_statuses',
						loadResource: false,
						search: false,
						// keepValueOnly: false,
						// removeValueOnly: false,
						type: 'select',
						initialValue: '/api/project_statuses/new',
					},
				}
			},

			tags: {
				MVTReferenceArrayInput: true,
				inputProps: {
					tag     : 'AutocompleteArrayInput',
					label    : translate('fields.tags'),
					// initialValue: 'new',
					tagProps: {
						optionText: 'title',
						reference: 'project_tags',
						loadResource: false,
						search: false,
						multi: true,
						// initialValue: 'new',
					},
				}
			},
			vendor: {
				MVTReferenceInput: true,
				inputProps: {
					tag     : 'AutocompleteInput',
					label    : translate('fields.vendor'),
					tagProps: {
						optionText: 'name',
						reference: 'vendors',
						loadResource: false,
						search: false,
						createButton: true,
					},
				}
			},
			projectMemberships: null,
			// status: null,
			// location: null,
			// tags: null,
			// progress: {
			// 	inputProps: {
			// 		label    : translate('fields.progress'),
			// 		initialValue: '0',
			// 	}
			// },
			progress: null,
			calendarEvents: null,
			masterlists: null,
			producers: {
				MVTReferenceArrayInput: true,
				inputProps: {
					tag     : 'AutocompleteArrayInput',
					label    : translate('fields.producer'),
					tagProps: {
						optionText: 'name',
						reference: 'people',
						loadResource: false,
						search: true,
						multi: true,
						createButton: true,
					},
				}
			},
			postProducers: {
				MVTReferenceArrayInput: true,
				inputProps: {
					tag     : 'AutocompleteArrayInput',
					label    : translate('fields.postProducers'),
					tagProps: {
						optionText: 'name',
						reference: 'people',
						loadResource: false,
						search: true,
						multi: true,
						createButton: true,
					},
				}
			},
			directors: {
				MVTReferenceArrayInput: true,
				inputProps: {
					tag     : 'AutocompleteArrayInput',
					label    : translate('fields.director'),
					tagProps: {
						optionText: 'name',
						reference: 'people',
						loadResource: false,
						search: true,
						multi: true,
						createButton: true,
					},
				}
			},
			customerContacts: {
				MVTReferenceArrayInput: true,
				inputProps: {
					tag     : 'AutocompleteArrayInput',
					label    : translate('fields.customer'),
					tagProps: {
						optionText: 'name',
						reference: 'companies',
						loadResource: false,
						search: true,
						multi: true,
						createButton: true,
					},
				}
			},
			agencyContacts: {
				MVTReferenceArrayInput: true,
				inputProps: {
					tag     : 'AutocompleteArrayInput',
					label    : translate('fields.agency'),
					tagProps: {
						optionText: 'name',
						reference: 'companies',
						loadResource: false,
						search: true,
						multi: true,
						createButton: true,
					},
				}
			},

			// vendor: null,
			// status: null,
			// tags: null,
			location: null,
			// directors: null,
			// producers: null,
			// postProducers: null,
			// customerContacts: null,
			// agencyContacts: null,
		};

		if(map === 'formFields') {
			f = this.mapFields(formFields,fields);
		}

		// f.push(
		// 	{
		// 		name: "flag_project_status",
		// 		MVTSelectField: true,
		// 		inputProps: {
		// 			label    : 'Status',
		// 			choices: [
		// 				{ id: 'closed', name: 'Closed' },
		// 				{ id: 'open', name: 'Open' },
		// 			],
		// 			initialValue: 'open'
		// 			// validate: [required(), minLength(10)]
		// 		}
		// 	}
		// );

		return f;
	}

	static validateForm(values) {
		// if(typeof console === 'object') { console.log('validateForm with values',values); }
		const errors = {
			// title: ['myroot.validation.required']
		};
		return errors;
	}

	static getGridPermanentFilter( permanentFilter ) {
		return permanentFilter;
	}

	static getGridFilterDefaults( filterDefaults ) {
		return {
			// title: '999',
			// description: 'super'
		};
	}

	static getGridFilter( ) {
		return [];

		return [{
			description: "",
			range: "http://www.w3.org/2001/XMLSchema#string",
			required: false,
			variable: "description",
		}];
	}

	static renderProgress(value,rowData,opts) {

		if(value === null) {
			value = 0;
		}

		// if(typeof console === 'object') { console.log('value',value, typeof value); }

		if(value && typeof value !== 'number') {
			value = parseInt(value);
		}

		return <ProgressBar percent={value} />;
	}

	static renderPreview(value,rowData,opts) {
		return <VideoPreview src={value} className="img--rectangle-preview grid" />
	}

	static getGridColumns(listFields,map = 'listfields') {
		// if(typeof console === 'object') { console.log('getGridColumns',listFields); }

		// map fields
		let lf = [];
		const translate = useTranslate();
		// const classes = useGridStyles();
		const classes = useGridStyles();

		let fields = {
			projectNumber: {
				// isSortable: true,
				fieldProps: {
					// width: 50,
					label    : '#',
					cellClassName: classes.w80,
					// headerClassName: classes.w50
				}
			},
			title: {
				isSortable: true,
				// FunctionField: true,
				fieldProps: {
					label    : translate('fields.title'),
				}
			},
			type: {
				isSortable: true,
				MVTReferenceField: true,
				fieldProps: {
					sortBy: 'type.title',
					label    :translate('fields.type'),
					cellAndHeaderClassName: classNames(classes.w100,classes.breakpointDownSM),
				}
			},
			status: {
				isSortable: true,
				MVTReferenceField: true,
				fieldProps: {
					sortBy: 'status.rank',
					label    :translate('fields.status'),
					cellAndHeaderClassName: classNames(classes.w100,classes.breakpointDownSM),
				}
			},
			shortTitle: null,
			masters: null,
			masterLists: null,
			description: null,
			creationDate: {
				// isSortable: true,
				// MVTListField: true,
				fieldProps: {
					label    : translate('fields.creationDate'),
					// breakpointUp: 'md',
					cellAndHeaderClassName: classNames(classes.w120,classes.breakpointDownSM),
					// headerClassName: classes.breakpointDownSM,
				}
			},
			directors: {
				// isSortable: true,
				MVTReferenceArrayField: true,
				ReferenceFieldEditor: true,
				fieldProps: {
					label    :translate('fields.directors'),
					// breakpointUp: 'lg',
					cellAndHeaderClassName: classes.breakpointDownLG,
					// tagProps: {source:'name'},
				}
			},
			producers: {
				// isSortable: true,
				MVTReferenceArrayField: true,
				ReferenceFieldEditor: true,
				fieldProps: {
					label    :translate('fields.producers'),
					breakpointUp: 'lg',
					// cellAndHeaderClassName: classes.breakpointDownMD,
					cellAndHeaderClassName: classNames(classes.maxw200,classes.breakpointDownMD),
					// breakpointDown: 'lg',
					// referenceTag: 'ReferenceField',
					// referenceTagProps: {basePath:'/locations',reference:'locations'},
					tagProps: {source:'name'},
				}
			},
			customerContacts: {
				// isSortable: true,
				MVTReferenceArrayField: true,
				ReferenceFieldEditor: true,
				fieldProps: {
					label    :translate('fields.customers'),
					breakpointUp: 'xl',
					cellAndHeaderClassName: classes.breakpointDownXL,

					// breakpointDown: 'lg',
					// referenceTag: 'ReferenceField',
					// referenceTagProps: {basePath:'/locations',reference:'locations'},
					tagProps: {source:'name'},
				}
			},
			agencyContacts: {
				// isSortable: true,
				MVTReferenceArrayField: true,
				ReferenceFieldEditor: true,
				fieldProps: {
					label    :translate('fields.agencies'),
					breakpointUp: 'xl',
					cellAndHeaderClassName: classes.breakpointDownXL,
					// breakpointDown: 'lg',
					// referenceTag: 'ReferenceField',
					// referenceTagProps: {basePath:'/locations',reference:'locations'},
					tagProps: {source:'name'},
				}
			},
			postProducers: {
				// isSortable: true,
				MVTReferenceArrayField: true,
				ReferenceFieldEditor: true,
				fieldProps: {
					label    :translate('fields.postProducers'),
					breakpointUp: 'lg',
					cellAndHeaderClassName: classes.breakpointDownLG,

					// breakpointDown: 'lg',
					// referenceTag: 'ReferenceField',
					// referenceTagProps: {basePath:'/locations',reference:'locations'},
					tagProps: {source:'name'},
				}
			},
			tags: {
				MVTReferenceArrayField: true,
				fieldProps: {
					// breakpointUp: 'lg',
					cellAndHeaderClassName: classes.breakpointDownLG,
					// headerClassName: classes.breakpointDownLG,

					// cellAndHeaderClassName: classes.breakpointDownMD,
					// headerClassName: classes.breakpointDownMD,

				}
			},
			// tags: null,
			calendarEvents: null,
			projectMemberships: null,
			progress: null,
			color: null,
			groups: null,
			vendor: {
				// isSortable: true,
				MVTReferenceField: true,
				fieldProps: {
					label    :translate('fields.vendor'),
					breakpointUp: 'xl',
					cellAndHeaderClassName: classes.breakpointDownXL,

					// breakpointDown: 'lg',
					// referenceTag: 'ReferenceField',
					// referenceTagProps: {basePath:'/locations',reference:'locations'},
					tagProps: {source:'name'},
				}
			},


			location: null,
			// directors: null,
			// producers: null,
			// customerContacts: null,
			// agencyContacts: null,
			// vendor: null,
			// status: null,
			// tags: null,
			// location: null,
			// directors: null,
			// producers: null,
			// postProducers: null,
			// customerContacts: null,
			// agencyContacts: null,
		};

		let df = [];
		if(map === 'listfields') {
			df = this.mapFields(listFields,fields);
		}

		lf = lf.concat(df);

		// lf.push({
		// 	// MVTListField: true,
		// 	width       : 150,
		// 	source: 'progress',
		// 	name: 'progress',
		// 	range: "http://www.w3.org/2001/XMLSchema#string",
		// 	fieldProps: {
		// 		label      :  translate('fields.progress'),
		// 		hidden      : false,
		// 		cellRenderer: this.renderProgress
		// 	}
		// });

		return lf;
	}

	// static getGridGroupColumns() {
	// 	return ['status'];
	// }

	//
	// static getToolbars(props) {
	// 	const { selectedRowIds, classes, title } = props;
	//
	// 	// if(typeof console === 'object') { console.log('cld_Project.getToolbars',props); }
	//
	// 	return (
	// 		<Toolbar
	// 			className={classNames(classes.root, {
	// 				[classes.highlight]: selectedRowIds && selectedRowIds.length > 0,
	// 			})}
	// 		>
	// 			<div className={classes.title}>
	// 				{selectedRowIds && selectedRowIds.length ? (
	// 					<Typography color="inherit" variant="subtitle1">
	// 						{selectedRowIds.length} selected
	// 					</Typography>
	// 				) : (
	// 					 <Typography variant="h6" id="tableTitle">
	// 						 {title}
	// 					 </Typography>
	// 				 )}
	// 			</div>
	// 			<MuiButton
	// 				icon="sync"
	// 				onClick={props.getParent().load.bind(props.getParent())}
	// 			>
	//
	// 			</MuiButton>
	// 			<div className={classes.spacer} />
	// 			<div className={classes.actions}>
	// 				{selectedRowIds && selectedRowIds.length > 0 &&
	// 				 <Tooltip title="Delete">
	// 					 <IconButton aria-label="Delete">
	// 						 <FontAwesomeIcon
	// 							 icon="minus-circle"
	// 							 size="1x"
	// 						 />
	// 					 </IconButton>
	// 				 </Tooltip>
	// 				}
	// 				<Tooltip title="Filter list">
	// 					<IconButton aria-label="Filter list">
	// 						<FilterListIcon />
	// 					</IconButton>
	// 				</Tooltip>
	// 			</div>
	// 		</Toolbar>
	// 	);
	// }

}

export default Projects;
