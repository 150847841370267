import React from 'react';
import PropTypes                      from 'prop-types';
import Input                      from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl               from '@material-ui/core/FormControl';
import IconButton                from '@material-ui/core/IconButton';
import { makeStyles }            from '@material-ui/core';
import OpenInNew                 from '@material-ui/icons/OpenInNew';
import { useForm, useFormState } from 'react-final-form';
import { withTranslate }         from 'react-admin';
import TreeView                  from '../../../admin-components/TreeView';
import Clear                     from '@material-ui/icons/Clear';
import CircularProgress          from '@material-ui/core/CircularProgress';
import InputLabel                from '@material-ui/core/InputLabel';

const useStyles = makeStyles(function (theme) {
	return ({
		textField: {},
		formControl: {
			// margin: theme.spacing(1),
			// minWidth: 40,
			// maxWidth: '100%',
		},
		formControlFilter: {
			display: 'flex',
			width: '100%'
		},
		formControlEditor: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1) / 2,
			width: '100%'
		},
		dialogTitleRoot: {
			margin: 0,
			padding: theme.spacing(2),
		},
		dialogContent: {
			maxHeight: '60vh'
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
	});
});

function EventCategoryFilter(props) {

	const {
		title,
		label,
		itemHeight,
		itemWidth,
		fullWidth,
		formControlClass,
		reference,
		action,
		withForm,
		source,
		selection,
		initialSelection
	} = props;

	const classes = useStyles();
	let filterRef = React.createRef();
	const [open, setOpen] = React.useState(false);
	const [cetegories, setCategories] = React.useState([]);
	const [resetCategories, setResetCategories] = React.useState(0);
	const [loading, setLoading] = React.useState(false);

	let form = null;
	let formState = null;

	if(withForm) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		form = useForm();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		formState = useFormState();
	}

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const onClick = (ev) => {
		ev.preventDefault();
		filterRef.current.blur();
		handleClickOpen();
	};

	const updateCategories = (c) => {
		setCategories(c);

		let IDs = [];
		c.map((r) => {
			IDs.push(r.id);
			return r;
		});

		if(action) {
			action(IDs);
		}

		if(form) {
			if(selection === 'multiple') {
				form.change( source, IDs );
			} else {
				form.change( source, IDs[0] );
			}
		}
	};

	const getCategories = () => {
		let titles = [];
		cetegories.map((c) => {
			titles.push(c.title);
			return c;
		});

		return titles.join(', ');
	};

	const getInitialSelectedItems = () => {
		if(formState) {
			let value;
			if(formState && formState.values && formState.values[source]) {
				value = formState.values[source];
			}
			return (selection === 'multiple') ? value.map((v) => { return {id:v}; }) : [{id: value}];
		} else if(initialSelection) {
			return (selection === 'multiple') ? initialSelection.map((v) => { return {id:v}; }) : [{id: initialSelection}];
		}

		return [];
	};

	let style = {overflow: 'hidden'};
	if(itemWidth) {
		style.width = itemWidth;
	}

	if(itemHeight) {
		style.maxHeight = itemHeight;
		style.height = itemHeight;
	}

	let formClass = classes[formControlClass];

	return (
		<React.Fragment>
			<FormControl
				className={formClass}
				margin="dense"
			>
				{label &&<InputLabel>
					{title}
				</InputLabel>}
				<Input
					fullWidth={fullWidth}
					ref={filterRef}
					type="text"
					style={style}
					placeholder={label ? null : title}
					value={getCategories()}
					onClick={onClick}
					inputProps={{
						readOnly: true,
					}}
					endAdornment={
						<InputAdornment position="end">
							{loading ? <CircularProgress color="inherit" size={20} /> : null}
							<IconButton
								size="small"
								onClick={(event) => {
									event.preventDefault();
									event.stopPropagation();
									setResetCategories((resetCategories+1));
								}}
							>
								<Clear fontSize="small" />
							</IconButton>
							<IconButton
								size="small"
								onClick={onClick}
							>
								<OpenInNew fontSize="small" />
							</IconButton>
						</InputAdornment>
					}
				/>
			</FormControl>

			<TreeView
				initialOpenLevel={0}
				setLoading={setLoading}
				resetCategories={resetCategories}
				selection={selection}
				initialSelection={getInitialSelectedItems()}
				resource={reference}
				showSearch={true}
				callback={updateCategories}
				openInDialog={true}
				title={title}
				open={open}
				handleClose={handleClose}
				searchTitleField="path"
			/>

		</React.Fragment>
	);
};

EventCategoryFilter.defaultProps = {
	formControlClass: 'formControl',
	fullWidth: false,
	withForm: true,
	selection: 'multiple'
};

EventCategoryFilter.propTypes = {
	itemHeight: PropTypes.any,
	itemWidth: PropTypes.any,
	label: PropTypes.any,
	fullWidth: PropTypes.bool,
	withForm: PropTypes.bool,
	reference: PropTypes.any,
	title: PropTypes.string,
	source: PropTypes.string,
	selection: PropTypes.string,
	formControlClass: PropTypes.string,
	initialSelected: PropTypes.any,
};


export default withTranslate(EventCategoryFilter);
