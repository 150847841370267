import _ from 'lodash';

class StringUtils{

	static parseInt(string,radix=10) {
		return _.parseInt(string, radix);
	}

	static simple_str_replace(s,pattern, replacement) {
		return _.replace(s, pattern, replacement);
	}

	static str_replace(s,pattern, replacement) {
		let p = new RegExp(pattern, "g");
		return s.replace(p, replacement);
	}

	static camelCase(v) {
		// return v;
		return _.camelCase(v);
	}

	static snakeCase(v) {
		// return v;
		return _.snakeCase(v);
	}

	/**
	 *
	 * Needs to be wrapped in
	 * <span dangerouslySetInnerHTML={{ __html: "'+text+'" }} />
	 *
	 * @param s
	 * @param p
	 * @returns {string}
	 */
	static highlight(s,p) {
		return s.replace(new RegExp(p, "gi"), (match) => `<span class="highlight--text">${match}</span>`);
	};

	static br2nl (str ) {
		if (typeof str === 'undefined' || str === null) {
			return '';
		}

		return (str + '').replace(/<br\s*\/?>/mg, "\n");
	}

	static nl2br (str, breakTag = '<br />') {
		if (typeof str === 'undefined' || str === null) {
			return '';
		}

		return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
	}

	static getInitials(fullname) {
		let n = '';

		if(!fullname) {
			return n;
		}

		const nameParts = fullName => fullName.split(' ');
		let name = nameParts(fullname);

		// if(typeof console === 'object') { console.log('name',name); }

		if(name && name.length) {
			if(name.length >= 2) {
				let f = name[0];
				let l = name[(name.length-1)];

				n = f.charAt(0) + l.charAt(0);
			} else {
				let f = name[0];
				n = f.charAt(0);
			}
		}

		return n;
	}

	static countLength(value,split,split_max) {

		let cc = value && value.length ? value.length : 0;

		if(split) {
			let cp = Math.floor((cc / split_max));
			if(cp >= 1) {
				cc = cp + '/' + (cc-(cp*split_max));
			}
		}

		return cc;
	}

	static _r_PhoneNumber(number) {
		return number.toString().replace(/[^0-9\+]+/g, '').replace(/\s+/, '');
	}

	static _f_PhoneNumber(number) {

		number = this._r_PhoneNumber(number);

		let formatted_number = number;

		if (number.length === 8) {
			formatted_number = number.replace(/([0-9]{2})([0-9]{3})([0-9]{3})/, '$1 $2 $3');
		} else if (number.length === 10) {
			formatted_number = number.replace(/([0-9]{4})([0-9]{3})([0-9]{3})/, '$1 $2 $3');
		} else if (number.indexOf('+') === 0) {
			formatted_number = number.replace(/([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{3})/, '$1 $2 $3 $4');
		}

		return formatted_number;
	}

	// static getInitialien(aLongName) {
	//
	// 	const transform = name => {
	//
	// 		if(typeof console === 'object') {
	// 			console.log('firstName %o firsTNameIN %o lastName %o nameparts %o',
	// 				firstName(name),
	// 				firstName(name).map(initial),
	// 				lastName(name).map(initial),
	// 				nameParts(name));
	// 		}
	//
	//
	// 		return firstName(name).map(initial).join(' ') + '' + lastName(name).map(initial).join(' ');
	// 	};
	//
	// 	const initial = name => name.charAt(0);
	// 	const lastName = fullName => nameParts(fullName).slice(positionOfLastName(fullName));
	// 	// const firstNames = fullName => nameParts(fullName).slice(0, positionOfLastName(fullName));
	// 	const firstName = fullName => {
	//
	// 		if(typeof console === 'object') {
	// 			console.log(
	// 				'FirstName POS %o',
	// 				nameParts(fullName)[0]
	// 			);
	// 		}
	//
	// 		nameParts(fullName)[0];
	// 	}
	//
	// 	const positionOfLastName = fullName => nameParts(fullName).length - 1;
	// 	const positionOfFirstName = fullName => nameParts(fullName)[0];
	//
	// 	const nameParts = fullName => fullName.split(' ');
	//
	// 	// aLongName = "Ladislaus Coolio Barry Crazy Jones";
	//
	// 	// console.log(transform(aLongName));
	//
	// 	return transform(aLongName);
	// }
	//
	// static shortenName(aLongName) {
	//
	// 	const transform = name => firstNames(name).map(initial).join(" ") + " " + lastName(name);
	//
	// 	const initial = name => name.charAt(0) + ".";
	// 	const lastName = fullName => nameParts(fullName).slice(positionOfLastName(fullName));
	//
	// 	const firstNames = fullName => nameParts(fullName).slice(0, positionOfLastName(fullName));
	// 	const positionOfLastName = fullName => nameParts(fullName).length - 1;
	//
	// 	const nameParts = fullName => fullName.split(" ");
	//
	// 	aLongName = "Ladislaus Coolio Barry Crazy Jones";
	//
	// 	console.log(transform(aLongName));
	//
	// 	return transform(aLongName);
	// }

}

export default StringUtils;
