// import fetch from 'cross-fetch';
// import request                            from 'superagent';
// import config                             from '../../src/host.config';
// import { Iterable, List, Map, fromJS }    from 'immutable';
import _ from 'lodash';
import LocalForage                        from '../utils/localForage';
// import ServerAdapter                      from '../../src/store/adapter';

/**
 * AppStateApi
 *
 *
 * superagent see@http://visionmedia.github.io/superagent/#-get-requests
 *
 * Editing Function, see@http://www.thegreatcodeadventure.com/react-redux-tutorial-part-vi-the-edit-feature/
 * example api: see@https://github.com/SophieDeBenedetto/catbook-redux/blob/blog-post/src/api/CatsApi.js
 */
class AppStateApi {

	/**
	 * Save/store the redux store here in order to use the dispatcher
	 * THIS IS NOT AN OFFICAL WAY ;)
	 * @type {null}
	 */
	static store = null;
	static lf_store = null;
	static state = {};
	static updateTimeout = {};
	static dispatch = null;

	static dispatchStore(d) {

		let dispatch;

		if(this.store) {
			dispatch = this.store.dispatch;
		} else if(this.dispatch) {
			dispatch = this.dispatch;
		}

		if(dispatch) {
			return dispatch(d);
		} else {
			throw 'Neather a store or dispatch is defined!';
		}
	}

	static setDispatch(d) {
		this.dispatch = d;
	};

	static setStore(store) {
		this.store = store;
	};

	// static getAdapter(adapter='JSONServerAdapter') {
	// 	return ServerAdapter.getAdpapter(adapter);
	// }

	static requestHeaders() {
		return {'AUTHORIZATION': `PHPOS-DEVELOPMENT`}
	}

	static setState(ns, v, appstate = null) {

		appstate = appstate || this.state;

		// if(typeof console === 'object') { console.log('SETSTATE',appstate); }
		// Tests with lodash instead of immutable
		// const nested = fromJS(appstate);
		// const n = nested.setIn(ns, v);

		// this.state = _.set(appstate, ns, v);
		_.set(appstate, ns, v);
		// if(typeof console === 'object') { console.log('setState FOUND OBJECT',appstate); }

		// this.state = n.toJS();
		this.state = Object.assign({},appstate);

		this.dispatchStore({type: 'UPDATE_APPSTATE', payload: this.state});

		if(this.updateTimeout) {
			clearTimeout(this.updateTimeout);
		}

		this.updateTimeout = setTimeout(
			() => this.updateAppState(this.state),
			1000 * 1
		);
	}

	static getState(ns) {
		// const nested = fromJS(this.state);
		// let n = nested.getIn(ns);
		// let a = null;
		// let b = null;

		// Tests with lodash instead of immutable...
		let n = _.get(this.state, ns);
		// if(typeof console === 'object') { console.log('getState FOUND OBJECT %0 with %o',ns,n); }

		// if(typeof n === 'object') {
		// 	n = (Iterable.isIndexed(n)) ? n.toArray() : n.toObject();
		// }

		return n;
	}

	static db() {
		this.lf_store = LocalForage.initDB('PHPOS',1,'appstate');
	}

	static deleteDB() { // test only!
		LocalForage.deleteDB('PHPOS');
	}

	static clearState() { // test only!
		if(!this.lf_store) {
			this.db();
		}

		LocalForage.clear(this.lf_store);
	}

	static removeItem() { // test only!
		if(!this.lf_store) {
			this.db();
		}

		LocalForage.removeItem(this.lf_store,'appstate');
	}

	static loadAppState() {
		if(!this.lf_store) {
			this.db();
		}

		return LocalForage.get(this.lf_store,'appstate').then((s) => {
			// if(typeof console === 'object') { console.log('loadAppState WITH PROMISE?',s); }
			this.state = s && s.state ? s.state : {};
			return this.state;
		}).catch(function(err) {
			// This code runs if there were any errors
			console.log(err);
			return err;
		});
	}

	static updateAppState(state) {

		 if(!this.lf_store) {
			this.db();
		}

		state = state || {t:1};

		return LocalForage.add(this.lf_store, 'appstate',{
			userId: 1,
			date: new Date(),
			creation_timestamp: new Date(),
			state: state,
		}).then((s) => {
			// if(typeof console === 'object') { console.log('updateAppState WITH PROMISE?',s); }
			this.state = s && s.state ? s.state : {};
		}).catch(function(err) {
			// This code runs if there were any errors
			console.log(err);
			return err;
		});
	}



	static createAppState(cat) {

	}

	static deleteAppState() {
		this.state = {t: 1};
		this.dispatchStore({type: 'UPDATE_APPSTATE', payload: this.state});
		this.updateAppState(this.state);
	}
}

export default AppStateApi;
