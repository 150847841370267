import React from 'react';
import {
    Button,
    GET_ONE,
    useMutation,
    useRefresh,
    useNotify,
} from 'react-admin';
import PropTypes from "prop-types";

const RowSetAction = (props) => {

    const {
        record,
        children,
        label,
        resource,
        action,
        note,
        method,
        header
    } = props;

    // if(typeof console === 'object') { console.log('RowSetAction',record,props); }

    const refresh = useRefresh();
    const notify = useNotify();

    const [doIt ,{ loading }] = useMutation(
        {
            type: method,
            resource: resource,
            payload: {
                id: record.id,
                '@action': action,
                data: {
                    '@action': action
                },
                // previousData: record
                // data: Object.assign({}, record,{
                //     '@action': action
                // })
            },
        },
        {
            onSuccess: () => {
                refresh();
                if(note) {notify(note);}
            },
            onFailure: error => notify('Error: posts not updated', 'warning'),
        }
    );

    return (
        <Button
            label={label}
            disabled={loading}
            onClick={doIt}
            header={header}
        >
            {children && children}
        </Button>
    );
};

RowSetAction.defaultProps = {
    method: GET_ONE,
    // method: UPDATE,
};

RowSetAction.propTypes = {
    method: PropTypes.string,
    action: PropTypes.string,
    note: PropTypes.string,
    resource: PropTypes.string,
    label: PropTypes.any,
    children: PropTypes.any,
    record: PropTypes.object,
    header: PropTypes.object,
};

export default RowSetAction;