import React, {useEffect} from 'react';
import PropTypes                        from 'prop-types';
import { useMutation, useNotify, UPDATE, DELETE } from 'react-admin';
import { Form }                         from 'react-final-form';
import arrayMutators                    from 'final-form-arrays';
import ApiPlatformUtils                 from '../../../../../api-platform-admin/src/utils/ApiPlatformUtils';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Edit, Delete } from "@material-ui/icons";
import { DeleteWithConfirmButton } from 'react-admin';
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import DomUtils from "../../../../utils/DomUtils";
import ObjectUtils from "../../../../utils/ObjectUtils";
import StringUtils from "../../../../utils/StringUtils";
// import ApiPlatformResources             from '../../../../../api-platform-admin/src/api/ApiPlatformResources';
// import ObjectUtils                      from '../../../../utils/ObjectUtils';
// import Toolbar from "@material-ui/core/Toolbar";
// import { TextInput }                    from 'react-admin';
// import { CardContent, Typography, Box } from '@material-ui/core';
// import { sanitizeEmptyValues }          from 'react-admin';
// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';

const useDeleteButtonStyles = makeStyles(
	theme => ({
		deleteButton: {
			minWidth: 'auto',
			color: theme.palette.error.main,
			'&:hover': {
				backgroundColor: fade(theme.palette.error.main, 0.12),
				// Reset on mouse devices
				'@media (hover: none)': {
					backgroundColor: 'transparent',
				},
			},
		},
	}),
	{ name: 'RaDeleteWithConfirmButton' }
);

/**
 * Inline Editor Field
 * + view and edit toggle*
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */

const iedWrStyle = {display: 'flex'};
const iedWrEfStyle = {paddingLeft: '5px'};

function InlineEditorField(props) {

	const {
		record,
		source,
		doubleClickToEdit,
		clickToEdit,
		editButton,
		deleteButton,
		callback,
		deleteCallback,
	} = props;

	const reference = props.resource;
	const notify = useNotify();
	const deleteButtonStyle = useDeleteButtonStyles();
	// let options = ApiPlatformResources.getResourceOptions(reference);

	const getHasPlainField = () => {
		if(doubleClickToEdit || clickToEdit || editButton) {
			return true;
		}
		return false;
	};

	const [showInputField, setShowInputField] = React.useState((!getHasPlainField()));

	const defaultSubscription = {
		submitting: true,
		pristine: true,
		valid: true,
		invalid: true,
	};

	const [ update ] = useMutation();
	const updateData = uData => update(
		{
			type: UPDATE,
			resource: reference,
			payload: {
				id: uData.id,
				data: uData.data
			},
		},
		{
			onSuccess: ({ data }) => {
				if(1=== 2 && typeof console === 'object') { console.log('SUCCEESSS',uData,data); }
				// loadEvents();

				// if(typeof callback === 'function') {
				// 	callback(reference,source,data,UPDATE);
				// }
			},
			onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
		}
	);

	const deleteRecord = (formProps) => {
		// if(typeof console === 'object') { console.log('formProps',formProps,record); }
		if(typeof deleteCallback === 'function') {
			deleteCallback(reference,source,record,DELETE);
		}
		else if(typeof callback === 'function') {
			callback(reference,source,record,DELETE);
		}
	};

	const hideInputField = () => {
		if(getHasPlainField()) {
			setShowInputField(false);
		}
	};

	const submitForm = (ur,form,complete) => {
		// if(typeof console === 'object') { console.log('submit',ur,form,ur[source],record[source]); }

		if(ur[source] !== record[source]) {
			let nd = { [ source ]: ur[ source ] };
			updateData( { data: nd, id: record.id } );
			// setRecord(Object.assign(record,nd));
			if(typeof callback === 'function') {
				callback(reference,source,Object.assign(record,nd),UPDATE);
			}
		}

		complete();
		hideInputField();
	};

	const getInputField = (handleSubmit,formProps) => {

		let inputField = null;

		let {
			api,
			inputFactory,
			field,
			resource,
			configFactory
		} = ApiPlatformUtils.findInputField(reference,source);

		if(field) {

			let ff;

			if(configFactory.conf) {
				ff = configFactory.conf.sanitizeInputFieldOptions(field,{
					removeLabel: true,
					removeResettable: true
				});
				// if(typeof console === 'object') { console.log('ff',source,ff,field); }
			} else {
				ff = ObjectUtils.clone(field);
			}

			if(!ff.inlineEditorProps) {
				ff.inlineEditorProps = {};
			}

			// if(typeof console === 'object') { console.log('record',record); }
			let domId = StringUtils.str_replace(record.id,'/','-')

			Object.assign(
				ff.inputProps,
				{
					variant:'standard',
					// label: null,
					key: source + domId,
					id: source + domId,
					// focus: true,
					submit: handleSubmit,
					onClick: (e) => {
						e.stopPropagation();
					},
					// onBlur:(e) => {
					// 	if(typeof console === 'object') { console.log('onBlur of field',e,e.target); }
					// 	// hideInputField();
					// },
					// onFocus:(e) => { if(typeof console === 'object') { console.log('onFocus',e); } },
					// onChange:(e) => { if(typeof console === 'object') { console.log('onChange',e); } },
					fullWidth:true,
					resettable:false,
			},ff.inlineEditorProps);

			// if(typeof console === 'object') { console.log('ff',source,ff); }

			inputField = inputFactory(ff, {
				api,
				resource,
			});


		}

		// if(typeof console === 'object') { console.log('editfields',inputField,field,source); }
		return inputField;
	};

	const getField = () => {

		let showField = null;
		let {
			api,
			fieldFactory,
			field,
			resource,
			configFactory
		} = ApiPlatformUtils.findField(reference,source);

		if(field) {

			// let field = foundfield;
			if(configFactory.conf) {
				field = configFactory.conf.sanitizeShowFieldOptions(field);
			}

			if(!field.fieldProps) {
				field.fieldProps = {};
			}

			if(!field.inlineFieldProps) {
				field.inlineFieldProps = {};
			}

			field.fieldProps.addLabel = false;

			Object.assign(field.fieldProps,{
				record: record,
				resource: resource,
				style:{
					paddingTop: '4px'
				},
				// basePath: basePath,
				key: source + record.id,
				onDoubleClick: (e) => {
					if(doubleClickToEdit) {
						e.stopPropagation();
						e.preventDefault();
						setShowInputField(true);
					}
					// if(typeof console === 'object') { console.log('onDoubleClick',e); }
				},
				onClick: (e) => {
					if(clickToEdit) {
						e.stopPropagation();
						e.preventDefault();
						setShowInputField(true);
					}
					// if(typeof console === 'object') { console.log('onClick',clickToEdit,e); }
				},
			},field.inlineFieldProps);

			showField = fieldFactory(field, {
				api,
				resource,
			});

			// if(typeof console === 'object') { console.log('showfields',showfields,showField,field,source); }
		}

		return showField;
	};

	if(!record) {
		return null;
	}


	if(!showInputField) {
		return (
			<div style={iedWrStyle}>
				{getField()}
				{1===2 && editButton && <Edit fontSize="small" onClick={() => setShowInputField(true)} style={iedWrEfStyle}/>}
				{editButton && <IconButton  size="small" onClick={() => setShowInputField(true)}>
					<Edit fontSize="small" style={iedWrEfStyle} />
				</IconButton>}
			</div>
		);
	}

	let deleteButtonMouseDown = false;
	const formStyle = deleteButton ? {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	} : {};

	const form = <Form
		// debug={(a,b) => { if(typeof console === 'object') { console.log('DEBUG',a,b); }}}
		initialValues={record}
		onSubmit={submitForm}
		mutators={{ ...arrayMutators }} // necessary for ArrayInput
		subscription={defaultSubscription} // don't redraw entire form each time one field changes
		//		key={version} // support for refresh button
		keepDirtyOnReinitialize
		render={({ handleSubmit, ...formProps }) => {
			// if(typeof console === 'object') { console.log('InlineEditorField.formProps',typeof handleSubmit,formProps,record); }
			return (
				<form
					onSubmit={handleSubmit}
					onBlur={(event) => {
						let t = true;
						if(DomUtils.findUpClassName(event.target, 'ra-delete-button')) {
							t = false;
							deleteButtonMouseDown = false;
						}

						if(deleteButtonMouseDown) {
							t = false;
						}

						if(t) handleSubmit();
					}}
					style={formStyle}
				>
					{getInputField(handleSubmit,formProps)}
					{deleteButton && <DeleteWithConfirmButton
						classes={deleteButtonStyle}
						resource={reference}
						record={record}
						label={null}
						onMouseDown={(e) => {
							deleteButtonMouseDown = true;
						}}
						redirect={() => {
							deleteRecord(formProps);
						}}
					/>}

				</form>
			);
		}}
	/>;

	if(getHasPlainField()) {
		return (
			<ClickAwayListener onClickAway={hideInputField}>
				<div>
					{form}
				</div>
			</ClickAwayListener>
		);
	}

	return (
		form
	);
};

InlineEditorField.defaultProps = {
	doubleClickToEdit: false,
	clickToEdit: false,
	editButton: false,
	deleteButton: false,
};

InlineEditorField.propTypes = {
	// classes: PropTypes.object,
	// translate: PropTypes.func
	source: PropTypes.string.isRequired,
	resource: PropTypes.string,
	record: PropTypes.object,
	doubleClickToEdit: PropTypes.bool,
	clickToEdit: PropTypes.bool,
	editButton: PropTypes.bool,
	deleteButton: PropTypes.bool,
	callback: PropTypes.func,
	deleteCallback: PropTypes.func,
};

export default InlineEditorField;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
