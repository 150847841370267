import React                       from 'react';
import LocalApi                    from '../LocalApi';
import ProgressBar                 from '../../../common/components/common/ProgressBar';
import { minLength, required }     from 'react-admin';
import VideoPreview                from '../../components/video/VideoPreview';
import { useTranslate }            from 'react-admin';
import EventCategoryFilter         from '../../scenes/Calendar/components/EventCategoryFilter';
import DateUtils                   from '../../../common/utils/DateUtils';
import MVTReferenceEditorInput     from '../../../common/components/react-admin/form/fields/MVTReferenceEditorInput';
import SupervisedUserCircleRounded from '@material-ui/core/SvgIcon/SvgIcon';
import ProjectDropdownSuggestion from "../../scenes/Projects/helpers/ProjectDropdownSuggestion";
import ShowAvailableResourses from '../../scenes/Calendar/components/editor/ShowAvailableResources';

// import AutocompleteInput           from '../../../common/components/react-admin/form/fields/MUIAutocompleteDownshift';
// import Grid                        from '@material-ui/core/Grid';
// import { Divider }             from '@material-ui/core';
// import TreeView                from '../../admin-components/TreeView';

// import { TextInput }           from 'react-admin';

// import Autocomplete            from '../../../common/components/react-admin/form/fields/MUIAutocompleteDownshift';
// import Toolbar        from '@material-ui/core/Toolbar';
// import Typography          from '@material-ui/core/Typography';
// import Tooltip             from '@material-ui/core/Tooltip';
// import IconButton          from '@material-ui/core/IconButton';
// // import DeleteIcon          from '@material-ui/core/SvgIcon/SvgIcon';
// import classNames          from 'classnames';
// // import { lighten } from '@material-ui/core/styles/colorManipulator';
// // import Tooltip from '@material-ui/core/Tooltip';
// import FilterListIcon      from '@material-ui/icons/FilterList';
// import MuiButton           from '../../common/components/common/MuiButton';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import { TextField, ReferenceField }       from 'react-admin';
// import ProgressBar from '../../common/components/common/ProgressBar';
// import EditButton           from '../../common/components/admin-grid/components/EditButton';

/**
 * cld_Projects
 *
 *
 *
 *
 *
 *
 */

class CalendarEvents extends LocalApi {

	static contentKey = 'calendar_events';
	static sorting = { field: 'start', order: 'ASC' };
	static show_available_resources = true;
	static peopleResourcesInstance = null;
	static technicalResourcesInstance = null;

	static getFormSettings() {

		if(!this.show_available_resources) {
			return {
				gridColumns: 2,
			};
		}

		return {
			gridColumns: [
				{
					style: {flexBasis: '100%'}
				},
				{},
				{},
			]
		};
	}

	static renderProjectSuggestion = (suggestion) => {
		return <ProjectDropdownSuggestion suggestion={suggestion} />
	};

	static getPeopleResourcesInstance(p) {
		this.peopleResourcesInstance = p;
	};

	static getTechnicalResourcesInstance(p) {
		this.technicalResourcesInstance = p;
	};

	static addResource(u, vs) {
		if(u === 'peopleResources') {
			this.peopleResourcesInstance.getSelectedItem(vs);
		} else if(u === 'technicalResources') {
			this.technicalResourcesInstance.getSelectedItem(vs);
		}
	};

	static getFormFields(formFields,map = 'formFields') {
		// if(typeof console === 'object') { console.log('getFormFields',formFields); }
		let f = [];
		const translate = useTranslate();
		let leftColIdx = 0;
		let rightColIdx = 1;

		if(this.show_available_resources) {
			leftColIdx = 1;
			rightColIdx = 2;
		}

		let fields = {
			title: {
				MVTInputField: true,
				col: leftColIdx,
				inputProps: {
					validate:  [required(), minLength(2)],
					autoFocus: true,
				}
			},
			externalTitle: null,

			category: {
				col: leftColIdx,
				JSX: <EventCategoryFilter
					initialOpenLevel={0}
					fullWidth={true}
					formControlClass="formControlEditor"
					selection="single"
					reference='event_categories'
					source="category"
					label={translate('resources.event_categories.single_name')}
					title={translate('resources.event_categories.single_name')}
				/>
			},
			// daysOfWeek: {
			// 	JSX: <Divider variant="inset" component="div" />
			// },
			daysOfWeek: null,
			allDay: { col: leftColIdx },
			temptative: { col: leftColIdx },
			start: { col: leftColIdx },
			end: { col: leftColIdx },
			follows: null,
			notes: {
				col: leftColIdx,
				inputProps: {
					label    : translate('fields.notes'),
					multiline: true,
				}
			},
			internalNotes: {
				col: leftColIdx,
				inputProps: {
					label    : translate('fields.internalNotes'),
					multiline: true,
				}
			},
			// project: {
			// 	MVTReferenceInput: true,
			// 	inputProps: {
			// 		tag     : 'AutocompleteInput',
			// 		loadResource: false,
			// 		// tagProps: {},
			// 	}
			// },
			project: {
				col: rightColIdx,
				AutocompleteInput: true,
				inputProps: {
					loadResource: true,
					createButton: true,
					label:translate('resources.projects.single_name'),
					permanentFilter:this.getRunningProjectFilter(),
					renderSuggestionFN: this.renderProjectSuggestion,
					searchField: 'project',
					// reference:"projects",
					// source:"project"

				}
			},
			peopleResources: {
				col: rightColIdx,
				MVTReferenceArrayInput: true,
				inputProps: {
					setParent: this.getPeopleResourcesInstance.bind(this),
					tag: 'AutocompleteArrayInput',
					label    : translate('fields.peopleResources'),
					tagProps: {
						optionText: 'name',
						reference: 'people',
						loadResource: true,
						createButton: true,
					},
					referenceTagProps: {
						basePath: '/people',
						reference: 'people'
					}
				}
			},
			technicalResources: {
				col: rightColIdx,
				MVTReferenceArrayInput: true,
				inputProps: {
					setParent: this.getTechnicalResourcesInstance.bind(this),
					tag: 'AutocompleteArrayInput',
					label    : translate('fields.technicalResources'),

					tagProps: {
						optionText: 'name',
						reference: 'technical_resources',
						search: false,
						loadResource: true,
					},
					// referenceTagProps: {
					// 	basePath: '/contacts',
					// 	reference: 'contacts'
					// }
				}
			},
			attendees: {
				col: rightColIdx,
				JSX: <MVTReferenceEditorInput
					title={translate('resources.event_participations.name')}
					icon={<SupervisedUserCircleRounded />}
					filterField="calendarEvent"
					// location={props.location}
					// resource={props.resource}
					reference="event_participations"
					// basePath={props.basePath}
					// filter={{project: projectId}}
					// initialValues={{project: projectId}}
					// referenceId={projectId}
					// cardStyle={{minWidth: 400}}
					// editor="drawer"
				/>
			},


			humanResources: null,

			// start: null,
			// end: null,
			// technicalResources: null,
			// peopleResources: null,
			// project: null,
			// category:null,

			calDescription: null,
			backgroundColor: null,
			categoryName: null,
			// attendees: null,
			masters: null,
			attendee: null,
			humanResource: null,
			technicalResource: null,
			eventsGroup: null,
			created: null,
			modified: null,
			creator: null,
			updater: null,
			icsInput: null,
			uuid: null,
			uid: null,
			source: null,
		};

		if(map === 'formFields') {
			f = this.mapFields(formFields,fields);
			// f = this.mapFields(fields,formFields);
		}

		if(this.show_available_resources) {
			f.unshift({
				name: 'show_available_resources',
				col: 0,
				JSX: <ShowAvailableResourses />
			});
		}

		// f.push(
		// 	{
		// 		name: "flag_project_status",
		// 		MVTSelectField: true,
		// 		inputProps: {
		// 			label    : 'Status',
		// 			choices: [
		// 				{ id: 'closed', name: 'Closed' },
		// 				{ id: 'open', name: 'Open' },
		// 			],
		// 			initialValue: 'open'
		// 			// validate: [required(), minLength(10)]
		// 		}
		// 	}
		// );

		return f;
	}

	static onBeforeFormRender(initialRecord,options={}) {
		// let { initialRecord } = options;

		if(
			initialRecord.allDay === true &&
			initialRecord.start &&
			initialRecord.end
		) {
			let isAfter = DateUtils.isAfter(initialRecord.end,initialRecord.start,'day');
			// if(typeof console === 'object') { console.log('isAfter',isAfter,initialRecord); }

			if(isAfter) {
				let newEnd = DateUtils.subtract( initialRecord.end, 1, 'days' );
				initialRecord.end = DateUtils.formatIso(newEnd);
				return {changedRecord: initialRecord};
			}
		}

		return {changedRecord: null};
	}

	static onBeforeSubmit(values,options={}) {

		if(
			values.allDay === true &&
			values.start &&
			values.end
		) {
			let isAfter = DateUtils.isAfter(values.end,values.start,'day');

			if(isAfter) {
				let newEnd = DateUtils.add( values.end, 1, 'days' );
				values.end = DateUtils.formatIso(newEnd);
			}
		}

		return values;
	}

	static validateForm(values) {
		// if(typeof console === 'object') { console.log('validateForm with values',values); }
		const errors = {
			// title: ['myroot.validation.required']
		};
		return errors;
	}

	static getGridPermanentFilter( permanentFilter ) {
		return permanentFilter;
	}

	static getGridFilterDefaults( filterDefaults ) {
		return {
			// title: '999',
			// description: 'super'
		};
	}

	static getGridFilter( ) {
		return [];

		return [{
			description: "",
			range: "http://www.w3.org/2001/XMLSchema#string",
			required: false,
			variable: "description",
		}];
	}

	static renderProgress(value,rowData,opts) {

		if(value === null) {
			value = 0;
		}

		// if(typeof console === 'object') { console.log('value',value, typeof value); }

		if(value && typeof value !== 'number') {
			value = parseInt(value);
		}

		return <ProgressBar percent={value} />;
	}

	static renderPreview(value,rowData,opts) {
		return <VideoPreview src={value} className="img--rectangle-preview grid" />
	}

	static getGridColumns(listFields,map = 'listfields') {
		// if(typeof console === 'object') { console.log('getGridColumns',listFields); }

		// map fields
		let lf = [];

		let fields = {
			title: {
				isSortable: true,
				// FunctionField: true,
				fieldProps: {
					width: 200,
					label    : 'Title',
				// 	label:'Author',
				// 	sortBy:'title',
				// 	render: record => {
				//
				// 		if(!record.description) {
				// 			return `${record.title}`
				// 		}
				//
				// 		return `${record.title}`
				// 	},
				}
			},
			daysOfWeek: null,
			follows: null,
			project: null,
			category: null,
			calDescription: null,
			backgroundColor: null,
			categoryName: null,
			attendees: null,
			humanResources: null,
			technicalResources: null,
			externalTitle: null,
			// description: {
			// 	isSortable: true,
			// 	// MVTListField: true,
			// 	fieldProps: {
			// 		label    : 'Description',
			// 		breakpointUp: 'lg',
			// 	}
			// },
			// creationDate: {
			// 	isSortable: true,
			// 	// MVTListField: true,
			// 	fieldProps: {
			// 		label    : 'Creation Date',
			// 		breakpointUp: 'md',
			//
			// 	}
			// },
			// tp: {
			// 	fieldProps: {
			// 		breakpointUp: 'lg',
			// 	}
			// },
			// tags: {
			// 	fieldProps: {
			// 		breakpointUp: 'lg',
			// 	}
			// },
			// calendarEvents: null,
			// // projectMemberships: null,
			// progress: null,
			// location: {
			// 	isSortable: true,
			// 	MVTReferenceField: true,
			// 	fieldProps: {
			// 		label    : 'Location',
			// 		breakpointUp: 'lg',
			// 		// breakpointDown: 'lg',
			// 		// referenceTag: 'ReferenceField',
			// 		// referenceTagProps: {basePath:'/locations',reference:'locations'},
			// 		tagProps: {source:'name'},
			// 	}
			// },
		};
		//
		// lf.push({
		// 	// MVTListField: true,
		// 	width       : 150,
		// 	source: '_c_preview',
		// 	name: '_c_preview',
		// 	range: "http://www.w3.org/2001/XMLSchema#string",
		// 	fieldProps: {
		// 		height: 90,
		// 		width: 180,
		// 		label      : 'Preview',
		// 		hidden      : false,
		// 		cellRenderer: this.renderPreview
		// 	}
		// });

		let df = [];
		if(map === 'listfields') {
			df = this.mapFields(listFields,fields);
		}

		lf = lf.concat(df);

		// lf.push({
		// 	// MVTListField: true,
		// 	width       : 150,
		// 	source: 'progress',
		// 	name: 'progress',
		// 	range: "http://www.w3.org/2001/XMLSchema#string",
		// 	fieldProps: {
		// 		label      : 'Progress',
		// 		hidden      : false,
		// 		cellRenderer: this.renderProgress
		// 	}
		// });

		return lf;
	}


}

export default CalendarEvents;
