import React, { useState, useEffect } from 'react';
import PropTypes                           from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm, useFormState }      from 'react-final-form';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
}));

// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';

function DumpForm(props) {
	const classes = useStyles();

	let formState = useFormState();
	let form = useForm();

	return (
		<div className={classes.root}>
			<ExpansionPanel>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography className={classes.heading}>Form Dump</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<pre>{JSON.stringify(formState.values, 0, 2)}</pre>
					<pre>{JSON.stringify(formState, 0, 2)}</pre>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</div>
	);
};

DumpForm.defaultProps = {

};

DumpForm.propTypes = {
	// classes: PropTypes.object,
	// translate: PropTypes.func
};

export default DumpForm;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
