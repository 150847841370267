import React, { useState, useEffect }                      from 'react';
import PropTypes                                           from 'prop-types';
import { makeStyles }                                      from '@material-ui/core/styles';
import Typography                                          from '@material-ui/core/Typography';
import Grid                                                from '@material-ui/core/Grid';
import { faCalendarAlt }                                   from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon }                                 from '@fortawesome/react-fontawesome';
import { GET_LIST, withTranslate, useMutation, useNotify } from 'react-admin';
import { Link as RouterLink }                              from 'react-router-dom';
/* eslint-disable jsx-a11y/anchor-is-valid */
import Link                                                from '@material-ui/core/Link';
import DateUtils                                           from '../../../../common/utils/DateUtils';

const pmsBgColor = {
	backgroundColor: '#679730',

};

function ProjectManagementSummaryEvents(props) {
	// let classes = useStyles();

	const {
		classes,
		projectId,
		// basePath,
		// project,
		// resource,
		translate
	} = props;

	const notify = useNotify();
	const [calendarEvents, setCalendarEvents] = useState();


	const getFilter = (event) => {
		let f = {};

		f.project = {selection: [projectId]};

		if(event.fromDate) {
			f.fromDate = event.fromDate;
			let toDate = DateUtils.add(f.fromDate,12, 'months');
			f.toDate = DateUtils.formatIso(toDate);
		}

		return f;
	};

	let [ mutate, {loading} ] = useMutation();
	const getEvents = event => mutate(
		{
			type: GET_LIST,
			resource: 'calendar_events',
			payload: {
				pagination: {
					page: 1,
					perPage: 5
				},
				sort: {
					field: 'start',
					order: event.order ? event.order : 'DESC'
				},
				filter: getFilter(event),
				groups: ['fullcalendar:read'],
			},
		},
		{
			onSuccess: (data) => {
				setCalendarEvents(data);
			},
			onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),

		}
	);

	useEffect(() => {
		if(!calendarEvents) {
			// getEvents({});
			getEvents({
				order: 'ASC',
				fromDate: DateUtils.formatIso()
			});
			// return null;
		}
	},[calendarEvents]);

	const getLastEventDate = () => {
		let d = DateUtils.formatIso(new Date());
		if(calendarEvents && calendarEvents.data && calendarEvents.data[0] && calendarEvents.data[0].start) {
			d = calendarEvents.data[0].start;
		}
		return d;
	};

	const getEventsSummary = () => {

		let t = null;
		let d = [];

		if(calendarEvents.data && calendarEvents.data[0]) {
			let event = calendarEvents.data[0];
			if(calendarEvents.total === 1) {
				d.push( <div key="total">{calendarEvents.total} {translate( 'project.eventsleft' )}</div> );
			} else {
				d.push( <div key="total">{calendarEvents.total} {translate( 'project.eventsleft' )}</div> );
			}

			d.push(<div key="lastEdit">{translate('project.next')}: "{event.title}" {translate('project.on')} {DateUtils.formatDate(event.start)} </div>);

		} else {
			d.push(<div key="total">0 {translate('project.eventsleft')}</div>);
		}

		if(d && d.length > 0) {
			t = <div>{d.map((a) => { return a;})}</div>;
		}

		return t;
	};


	return (
		<Link
			className={classes.masterListBox}
			component={RouterLink} to={'/planning/resources/projects/'+getLastEventDate() +'/'+ encodeURIComponent(projectId)+''}
			style={{backgroundColor: '#64764b'}}
		>

			<Grid
				container component="span" spacing={0} className={classes.inlinecontainer}
			>
				<Grid component="span" item xs={4} className={classes.inlineitemicon} style={pmsBgColor}>
					<FontAwesomeIcon className={classes.icon} icon={faCalendarAlt} />
				</Grid>
				<Grid component="span" item xs={8} className={classes.inlineitem}>
					<Typography component="span" className={classes.typoTitle} variant="h5">
						{translate('project.timing')}
					</Typography>
					<Typography component="span" className={classes.typo} variant="caption">
						{calendarEvents && getEventsSummary()}
						{loading && <div>Loading...</div>}
					</Typography>
				</Grid>
			</Grid>
		</Link>
	);
};

ProjectManagementSummaryEvents.defaultProps = {

};

ProjectManagementSummaryEvents.propTypes = {
	// classes: PropTypes.object,
	// translate: PropTypes.func
};

export default withTranslate(ProjectManagementSummaryEvents);
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
