export const REQUEST_APPSTATE = 'REQUEST_APPSTATE';
export const RECEIVE_APPSTATE = 'RECEIVE_APPSTATE';
export const SELECT_APPSTATE = 'SELECT_APPSTATE';
export const INVALIDATE_APPSTATE = 'INVALIDATE_APPSTATE';
export const UPDATE_APPSTATE = 'UPDATE_APPSTATE';
export const UPDATE_APPSTATE_SUCCESS = 'UPDATE_APPSTATE_SUCCESS';
export const UPDATE_APPSTATE_ERROR = 'UPDATE_APPSTATE_ERROR';

/**
 *
 * GET APPSTATE
 *
 * @param state
 * @param action
 * @returns {*}
 */
function appstate(
	state = {
		isFetchingAppState: false,
		didInvalidateAppState: false,
		appstate: {}
	},
	action
) {

	// if(typeof console === 'object') { console.log('REDUCER.appstate action.type',action, state); }

	switch (action.type) {
		case INVALIDATE_APPSTATE:
			return Object.assign({}, state, {
				didInvalidateAppState: true
			});
		case REQUEST_APPSTATE:
			return Object.assign({}, state, {
				isFetchingAppState: true,
				didInvalidateAppState: false
			});
		case UPDATE_APPSTATE_SUCCESS:
		case RECEIVE_APPSTATE:
			let t = Object.assign({}, state, {
				isFetchingAppState: false,
				didInvalidateAppState: false,
				appstate: action.appstate,
				lastUpdatedAppSate: action.receivedAt
			});
			// if(typeof console === 'object') { console.log('RECEIVE_APPSTATE',t); }
			return t;
		default:
			return state;
	}
}

export function appstateByPHPOS(state = {
	appstate: {
		Sidebar: {open: true},
		Hello: 'World'
	}
}, action) {

	// if(typeof console === 'object') { console.log('REDUCER.appstateByPHPOS state,action',state,action); }

	switch (action.type) {
		case INVALIDATE_APPSTATE:
		case RECEIVE_APPSTATE:
		case REQUEST_APPSTATE:
			return Object.assign({}, state, appstate(state, action));
		case UPDATE_APPSTATE_SUCCESS:
			// if(typeof console === 'object') { console.log('REDUCER.appstateByPHPOS state,action',state,action); }

			// if(typeof console === 'object') { console.log('REDUCER.UPDATE_APPSTATE_SUCCESS',true); }
			// return [
			// 	...state.filter(cat => cat.id !== action.cat.id),
			// 	Object.assign({}, action.cat)
			// ]
			return Object.assign({}, state, appstate(state, action));
		default:
			return state;
	}
}
