import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


function ProjectsAll(props) {
    return (
        <SvgIcon {...props}>
            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" role="img">
                <path
                    d="M18.6,3.2l2.2,4.4h-3.3l-2.2-4.4h-2.2l2.2,4.4H12L9.8,3.2H7.6l2.2,4.4H6.5L4.3,3.2H3.2C2,3.2,1,4.2,1,5.4 v13.2c0,1.2,1,2.2,2.2,2.2h17.6c1.2,0,2.2-1,2.2-2.2V3.2H18.6z"></path>
                <title>Projects: All</title>
            </svg>
        </SvgIcon>
    );
}

export default ProjectsAll;