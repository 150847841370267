import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


function PlanningMisc(props) {
    return (
        <SvgIcon {...props}>
            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" role="img">
                <g>
                    <path
                        d="M8.3,15.7c-0.5,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7c0.5,0,1.1,0.1,1.1-0.4C9.4,16.4,8.7,15.7,8.3,15.7z"></path>
                    <path
                        d="M15.7,15.7c-0.5,0-1.1,0.7-1.1,1.1s0.7,0.4,1.1,0.4s0.7-0.3,0.7-0.7S16.2,15.7,15.7,15.7z"></path>
                    <path d="M14.5,13.4c-0.2-0.4-0.6-0.7-1-0.7h-3c-0.5,0-0.9,0.3-1,0.7L9,14.6h6L14.5,13.4z"></path>
                    <path
                        d="M21.1,9.2H2.9c-0.3,0-0.5,0.2-0.5,0.5v11.2c0,1.1,0.9,2.1,2.1,2.1h15.1c1.1,0,2.1-0.9,2.1-2.1V9.8 C21.6,9.5,21.4,9.2,21.1,9.2z M18,14.2l-0.1,0.6c0,0.1-0.1,0.2-0.3,0.2h-0.5c0.3,0.3,0.5,0.7,0.5,1.1v1.1c0,0.4-0.1,0.7-0.4,1v1.3 c0,0.4-0.3,0.7-0.7,0.7h-0.7c-0.4,0-0.7-0.3-0.7-0.7v-0.7H9v0.7c0,0.4-0.3,0.7-0.7,0.7H7.5c-0.4,0-0.7-0.3-0.7-0.7v-1.3 c-0.2-0.3-0.4-0.6-0.4-1v-1.1c0-0.5,0.2-0.9,0.5-1.1H6.4c-0.1,0-0.2-0.1-0.3-0.2L6,14.2c0-0.2,0.1-0.3,0.3-0.3h1.4l0.4-1 c0.4-1,1.4-1.6,2.4-1.6h3c1.1,0,2,0.6,2.4,1.6l0.4,1h1.4C17.9,13.8,18,14,18,14.2z"></path>
                    <path
                        d="M19.6,3.8h-2.1V1.5C17.5,1.2,17.3,1,17,1h-1.7c-0.3,0-0.5,0.2-0.5,0.5v2.2H9.2V1.5C9.2,1.2,9,1,8.7,1H7 C6.7,1,6.5,1.2,6.5,1.5v2.2H4.4c-1.1,0-2.1,0.9-2.1,2.1v1.5c0,0.3,0.2,0.5,0.5,0.5h18.2c0.3,0,0.5-0.2,0.5-0.5V5.8 C21.6,4.7,20.7,3.8,19.6,3.8z"></path>
                </g>
                <title>Planning: Miscellaneous</title>
            </svg>
        </SvgIcon>
    );
}

export default PlanningMisc;