// eslint-disable-next-line no-unused-vars
import React             from 'react';
import LocalApi          from '../LocalApi';
import { useTranslate, UPDATE }           from 'react-admin';
import { minLength, required } from 'react-admin';
import useGridStyles from '../../../common/components/helpers/useGridStyles';
import classNames from 'classnames';
import {
	MovieFilter,
	GTranslate,
	Style,
	Description,
	Videocam,
	LocalMovies,
	Timelapse,
	SaveAlt,
	Gavel,
	OndemandVideo,
	TextFields,
	Subtitles,
	VolumeUp,
	Mic,
	SurroundSound,
	VerifiedUser,
	Dns,
	CloudDone,
	LocalShipping,
	Filter5,
	Filter1,
} from "@material-ui/icons";
import BulkSetAction from "../../../common/components/react-admin/grid/bulkActions/BulkSetAction";
import RowSetAction from "../../../common/components/react-admin/grid/rowActions/RowSetAction";
/**
 * cld_
 *
 *
 *
 *
 *
 *
 */

class Masters extends LocalApi {

	static contentKey = 'projects';
	static sorting = { field: 'clipId', order: 'ASC' };
	static groupBy = ['clipVersion'];
	// static hasEdit = false;
	// static hasShow = false;


	static getFormFields(formFields,map = 'formFields') {
		// if(typeof console === 'object') { console.log('getFormFields',formFields); }
		let f = [];
		const translate = useTranslate();

		/*

		 writableFields: Array(18)
		0: Field {name: "clipId", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/clipId", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
1: Field {name: "versionNumber", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/versionNumber", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
2: Field {name: "clipsGroup", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/clipsGroup", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
3: Field {name: "deliveryRegion", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/deliveryRegion", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
4: Field {name: "product", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/product", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
5: Field {name: "productCode", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/productCode", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
6: Field {name: "contentDescription", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/contentDescription", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
7: Field {name: "screenFormat", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/screenFormat", range: "https://cr-api.dev.highject.com/api/docs.jsonld#ScreenFormat", reference: Resource, required: false, …}
8: Field {name: "pixelFormat", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/pixelFormat", range: "https://cr-api.dev.highject.com/api/docs.jsonld#PixelFormat", reference: Resource, required: false, …}
9: Field {name: "clipLength", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/clipLength", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
10: Field {name: "targetChannels", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/targetChannels", range: "https://cr-api.dev.highject.com/api/docs.jsonld#TargetChannel", reference: Resource, required: false, …}
11: Field {name: "disclaimer", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/disclaimer", range: "http://www.w3.org/2001/XMLSchema#boolean", reference: null, required: false, …}
12: Field {name: "trailer", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/trailer", range: "http://www.w3.org/2001/XMLSchema#boolean", reference: null, required: false, …}
13: Field {name: "superTypeLanguage", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/superTypeLanguage", range: "https://cr-api.dev.highject.com/api/docs.jsonld#VideoLanguage", reference: Resource, required: false, …}
14: Field {name: "subtitleLanguage", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/subtitleLanguage", range: "https://cr-api.dev.highject.com/api/docs.jsonld#VideoLanguage", reference: Resource, required: false, …}
15: Field {name: "hasSound", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/hasSound", range: "http://www.w3.org/2001/XMLSchema#boolean", reference: null, required: false, …}
16: Field {name: "audioLanguage", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/audioLanguage", range: "https://cr-api.dev.highject.com/api/docs.jsonld#VideoLanguage", reference: Resource, required: false, …}
17: Field {name: "hasSfx", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/hasSfx", range: "http://www.w3.org/2001/XMLSchema#boolean", reference: null, required: false, …}
18: Field {name: "deliveryFormats", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/deliveryFormats", range: "https://cr-api.dev.highject.com/api/docs.jsonld#DeliveryMediaFormat", reference: Resource, required: false, …}
19: Field {name: "deliveryDate", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/deliveryDate", range: "http://www.w3.org/2001/XMLSchema#dateTime", reference: null, required: false, …}
20: Field {name: "hasFinalApproval", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/hasFinalApproval", range: "http://www.w3.org/2001/XMLSchema#boolean", reference: null, required: false, …}
21: Field {name: "deliveredToMediaPoolOn", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/deliveredToMediaPoolOn", range: "http://www.w3.org/2001/XMLSchema#dateTime", reference: null, required: false, …}
22: Field {name: "deliveredToSoMeCloudOn", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/deliveredToSoMeCloudOn", range: "http://www.w3.org/2001/XMLSchema#dateTime", reference: null, required: false, …}
23: Field {name: "delivered", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/delivered", range: "http://www.w3.org/2001/XMLSchema#boolean", reference: null, required: false, …}
24: Field {name: "notes", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/notes", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
25: Field {name: "status", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/status", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
26: Field {name: "masterList", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/masterList", range: "https://cr-api.dev.highject.com/api/docs.jsonld#MasterList", reference: Resource, required: false, …}
27: Field {name: "rank", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Master/rank", range: "http://www.w3.org/2001/XMLSchema#integer", reference: null, required: false, …}
length: 28
		 */
		let fields = {
			masterList: null,
			rank: {
				// MVTInputField: true,
				inputProps: {
					label: translate('fields.rank'),
					// validate:  [required(), minLength(1)],
					autoFocus: true,
				}
			},
			clipId: {
				MVTInputField: true,
				inputProps: {
					label: translate('fields.clipId'),
					validate:  [required(), minLength(2)],
				}
			},
			versionNumber: {
				MVTInputField: true,
				inputProps: {
					label: translate('fields.versionNumber'),
					// initialValue: '0.01'
					// validate:  [required(), minLength(1)]
				}
			},
			clipsGroup: {
				MVTInputField: true,
				inputProps: {
					label: translate('fields.clipsGroup'),
					validate:  [required(), minLength(1)]
				}
			},
			status: {
				MVTSelectField: true,
				inputProps: {
					label: translate('fields.status'),
					choices: [
						{
							id: translate('fieldChoices.status.new'),
							name: translate('fieldChoices.status.new'),
						},
						{
							id: translate('fieldChoices.status.ongoing'),
							name: translate('fieldChoices.status.ongoing'),
						},
						{
							id: translate('fieldChoices.status.review'),
							name: translate('fieldChoices.status.review'),
						},
					]
				},
				// MVTReferenceInput: true,
				// maxCardinality: 1,
				// input: AutocompleteInput,
				// loadResource: false,
				// search: false,
				// createButton: false,
				// type: 'select',
				// choices: [
				// 	{
				// 		id: translate('fieldChoices.status.new'),
				// 		name: translate('fieldChoices.status.new'),
				// 	},
				// 	{
				// 		id: translate('fieldChoices.status.ongoing'),
				// 		name: translate('fieldChoices.status.ongoing'),
				// 	},
				// 	{
				// 		id: translate('fieldChoices.status.review'),
				// 		name: translate('fieldChoices.status.review'),
				// 	},
				// ],
			},
			deliveryRegion: {
				MVTInputField: true,
				inputProps: {
					label: translate('fields.deliveryRegion'),
					// validate:  [required(), minLength(1)]
				}
			},
			product: {
				MVTInputField: true,
				inputProps: {
					label: translate('fields.product'),
					// validate:  [required(), minLength(1)]
				}
			},
			productCode: {
				MVTInputField: true,
				inputProps: {
					label: translate('fields.productCode'),
					// validate:  [required(), minLength(1)]
				}
			},
			contentDescription: {
				MVTInputField: true,
				inputProps: {
					label: translate('fields.contentDescription'),
					multiline: true,
					// validate:  [required(), minLength(1)]
				}
			},
			screenFormat: {
				MVTReferenceInput: true,
				inputProps: {
					tag     : 'AutocompleteInput',
					label    : translate('fields.screenFormat'),
					tagProps: {
						optionText: 'title',
						reference: 'screen_formats',
						loadResource: false,
						search: false,
						createButton: true,
						type: 'select',
					},
				}
			},
			pixelFormat: {
				MVTReferenceInput: true,
				inputProps: {
					tag     : 'AutocompleteInput',
					label    : translate('fields.pixelFormat'),
					tagProps: {
						optionText: 'title',
						reference: 'pixel_formats',
						loadResource: false,
						search: false,
						createButton: true,
						type: 'select',
					},
				}
			},
			clipLength: {
				MVTInputField: true,
				inputProps: {
					label: translate('fields.clipLength'),
					// validate:  [required(), minLength(1)]
				}
			},
			targetChannels: {
				MVTReferenceArrayInput: true,
				inputProps: {
					tag     : 'AutocompleteArrayInput',
					label    : translate('fields.targetChannels'),
					tagProps: {
						optionText: 'title',
						reference: 'target_channels',
						loadResource: false,
						search: false,
						multi: true,
						createButton: true,
					},
				}
			},
			disclaimer: {
				inputProps: {
					label: translate('fields.disclaimer'),
				}
			},
			trailer: {
				inputProps: {
					label: translate('fields.trailer'),
				}
			},
			superTypeLanguage: {
				MVTReferenceInput: true,
				inputProps: {
					tag     : 'AutocompleteInput',
					label    : translate('fields.superTypeLanguage'),
					tagProps: {
						optionText: 'title',
						reference: 'video_languages',
						loadResource: false,
						search: false,
						createButton: true,
						type: 'select',
					},
				}
			},
			subtitleLanguage: {
				MVTReferenceInput: true,
				inputProps: {
					tag     : 'AutocompleteInput',
					label    : translate('fields.subtitleLanguage'),
					tagProps: {
						optionText: 'title',
						reference: 'video_languages',
						loadResource: false,
						search: false,
						createButton: true,
						type: 'select',
					},
				}
			},
			hasSound: {
				inputProps: {
					label: translate('fields.hasSound'),
				}
			},
			audioLanguage: {
				MVTReferenceInput: true,
				inputProps: {
					tag     : 'AutocompleteInput',
					label    : translate('fields.audioLanguage'),
					tagProps: {
						optionText: 'title',
						reference: 'video_languages',
						loadResource: false,
						search: false,
						createButton: true,
						type: 'select',
					},
				}
			},
			hasSfx: {
				inputProps: {
					// range: 'GridBooleanInput',
					label: translate('fields.hasSfx'),
				}
			},
			deliveryFormats: {
				inputProps: {
					label: translate('fields.deliveryFormats'),
				}
			},
			deliveryDate: {
				inputProps: {
					label: translate('fields.deliveryDate'),
					settings:{
						fieldType: 'date'
					}
				}
			},
			hasFinalApproval: {
				inputProps: {
					label: translate('fields.hasFinalApproval'),
				}
			},
			deliveredToMediaPoolOn: {
				inputProps: {
					label: translate('fields.deliveredToMediaPoolOn'),
					settings:{
						fieldType: 'date'
					}
				}
			},
			deliveredToSoMeCloudOn: {
				inputProps: {
					label: translate('fields.deliveredToSoMeCloudOn'),
					settings:{
						fieldType: 'date'
					}
				}
			},
			delivered: {
				inputProps: {
					label: translate('fields.delivered'),
				}
			},
			notes: {
				inputProps: {
					label: translate('fields.notes'),
				}
			},

			// product: null,
			// project: null,
			// clipVersionNumber: null,
			// screenFormat: null,
			// voiceOverLanguage: null,
			// subtitleLanguage: null,
			// superTypeLanguage: null,
			// notes: null,
			// deliveryDate: null,
			// deliveryFormats: null,
			// deliveryTargets: null,
			// status: null,
			// delivered: null,
			// disclaimer: null,
		};

		if(map === 'formFields') {
			f = this.mapFields(formFields,fields);
		}

		return f;
	}

	static validateForm(values) {
		// if(typeof console === 'object') { console.log('validateForm with values',values); }
		const errors = {
			// title: ['myroot.validation.required']
		};
		return errors;
	}

	static getGridPermanentFilter( permanentFilter ) {
		return permanentFilter;
	}

	static getGridFilterDefaults( filterDefaults ) {
		return {
			// title: '999',
			// description: 'super'
		};
	}

	static getGridFilter( ) {
		return [];
	}

	static getGridGroupByColumns() {
		return ['clipsGroup']
	};

	static getGridColumns(listFields,map = 'listfields') {
		// if(typeof console === 'object') { console.log('getGridColumns',listFields); }

		const translate = useTranslate();
		const classes = useGridStyles();

		let InlineEditorField = true;

		let fields = {
			masterList: null,
			project: null,
			rank: {
				// InlineEditorField: InlineEditorField,
				fieldProps: {
					label: translate('fields.rank'),
					// cellAndHeaderClassName: classNames(classes.w100,classes.breakpointDownLG),
					cellAndHeaderClassName: classes.w20,
				}
			},
			clipId: {
				InlineEditorField: InlineEditorField,
				// InlineEditorField: true,
				fieldProps: {
					label: translate('fields.clipId'),
					cellAndHeaderClassName: classNames(classes.w100,classes.mw100),
				}
			},
			status: {
				InlineEditorField: InlineEditorField,
				fieldProps: {
					label: translate('fields.status'),
					// cellAndHeaderClassName: classes.w100,
					cellAndHeaderClassName: classNames(classes.w100,classes.breakpointDownMD),
				}
			},
			versionNumber: {
				InlineEditorField: InlineEditorField,
				fieldProps: {
					label: translate('fields.versionNumber'),
					header: {labelIcon: <Filter1 />},
					// cellAndHeaderClassName: classes.w40,
					cellAndHeaderClassName: classNames(classes.w40,classes.mw40,classes.breakpointDownMD),
				}
			},
			clipsGroup: {
				fieldProps: {
					label: translate('fields.clipsGroup'),
					header: {labelIcon: <MovieFilter />},
					cellAndHeaderClassName: classNames(classes.w100,classes.breakpointDownXL),
				}
			},
			deliveryRegion: {
				isSortable: true,
				fieldProps: {
					sortBy: 'deliveryRegion.title',
					label: translate('fields.deliveryRegion'),
					header: {labelIcon: <GTranslate />},
					// cellAndHeaderClassName: classes.w60,
					cellAndHeaderClassName: classNames(classes.w60,classes.breakpointDownMD),
				}
			},
			product: {
				InlineEditorField: InlineEditorField,
				fieldProps: {
					label: translate('fields.product'),
					// cellAndHeaderClassName: classNames(classes.w100,classes.breakpointDownLG),
					// cellAndHeaderClassName: classes.breakpointDownLG,
					cellAndHeaderClassName: classNames(classes.w100,classes.breakpointDownMD),
				}
			},
			productCode: {
				InlineEditorField: InlineEditorField,
				fieldProps: {
					label: translate('fields.productCode'),
					// cellAndHeaderClassName: classes.breakpointDownSM,
					cellAndHeaderClassName: classNames(classes.w100,classes.mw80,classes.breakpointDownSM),
					header: {labelIcon: <Style />},
				}
			},
			contentDescription: {
				InlineEditorField: InlineEditorField,
				fieldProps: {
					label: translate('fields.contentDescription'),
					// cellAndHeaderClassName: classes.breakpointDownLG,
					cellAndHeaderClassName: classNames(classes.w120,classes.mw160,classes.breakpointDownSM),
					header: {labelIcon: <Description />},
				}
			},
			screenFormat: {
				InlineEditorField: InlineEditorField,
				isSortable: true,
				MVTReferenceField: true,
				inlineEditorProps: {
					tagProps: {
						optionText: 'title',
						reference: 'screen_formats',
						createButton: false,
						resetButton: false,
						loadResource: false,
						search: false,
						type: 'select',
					}
				},
				fieldProps: {
					sortBy: 'screenFormat.title',
					label: translate('fields.screenFormat'),
					// cellAndHeaderClassName: classNames(classes.w40,classes.breakpointDownSM),
					cellAndHeaderClassName: classNames(classes.w80,classes.mw80,classes.breakpointDownSM),
					header: {labelIcon: <Videocam />},
				}
			},
			pixelFormat: {
				InlineEditorField: InlineEditorField,
				inlineEditorProps: {
					tagProps: {
						optionText: 'title',
						reference: 'pixel_formats',
						createButton: false,
						resetButton: false,
						loadResource: false,
						search: false,
						type: 'select',
					}
				},
				isSortable: true,
				MVTReferenceField: true,
				fieldProps: {
					sortBy: 'pixelFormat.title',
					label: translate('fields.pixelFormat'),
					header: {labelIcon: <LocalMovies />},
					cellAndHeaderClassName: classNames(classes.w120,classes.mw120,classes.breakpointDownSM),
				}
			},
			clipLength: {
				MVTInputField: true,
				fieldProps: {
					label: translate('fields.clipLength'),
					header: {labelIcon: <Timelapse />},
					cellAndHeaderClassName: classNames(classes.w80,classes.breakpointDownMD),
				}
			},
			targetChannels: {
				MVTReferenceArrayField: true,
				fieldProps: {
					label: translate('fields.targetChannels'),
					header: {labelIcon: <SaveAlt />},
					cellAndHeaderClassName: classNames(classes.w80,classes.breakpointDownMD),

				}
			},
			disclaimer: {
				InlineEditorField: InlineEditorField,
				inlineEditorProps: {
					range: 'GridBooleanInput',
				},
				fieldProps: {
					label: translate('fields.disclaimer'),
					header: {labelIcon: <Gavel />},
					cellAndHeaderClassName: classes.breakpointDownLG,
				}
			},
			trailer: {
				InlineEditorField: InlineEditorField,
				inlineEditorProps: {
					range: 'GridBooleanInput',
				},
				fieldProps: {
					label: translate('fields.trailer'),
					header: {labelIcon: <OndemandVideo />},
					cellAndHeaderClassName: classes.breakpointDownLG,
				}
			},
			superTypeLanguage: {
				InlineEditorField: InlineEditorField,
				inlineEditorProps: {
					tagProps: {
						optionText: 'title',
						reference: 'video_languages',
						createButton: false,
						resetButton: false,
						loadResource: false,
						search: false,
						type: 'select',
					}
				},
				MVTReferenceField: true,
				fieldProps: {
					label: translate('fields.superTypeLanguage'),
					header: {labelIcon: <TextFields />},
					cellAndHeaderClassName: classNames(classes.w80,classes.mw80,classes.breakpointDownLG),
				}
			},
			subtitleLanguage: {
				InlineEditorField: InlineEditorField,
				inlineEditorProps: {
					tagProps: {
						optionText: 'title',
						reference: 'video_languages',
						createButton: false,
						resetButton: false,
						loadResource: false,
						search: false,
						type: 'select',
					}
				},
				MVTReferenceField: true,
				fieldProps: {
					label: translate('fields.subtitleLanguage'),
					header: {labelIcon: <Subtitles />},
					cellAndHeaderClassName: classNames(classes.w80,classes.mw80,classes.breakpointDownLG),

				}
			},
			hasSound: {
				InlineEditorField: InlineEditorField,
				inlineEditorProps: {
					range: 'GridBooleanInput',
				},
				fieldProps: {
					label: translate('fields.hasSound'),
					header: {labelIcon: <VolumeUp />},
					cellAndHeaderClassName: classNames(classes.w60,classes.breakpointDownLG),

				}
			},
			audioLanguage: {
				InlineEditorField: InlineEditorField,
				inlineEditorProps: {
					tagProps: {
						optionText: 'title',
						reference: 'video_languages',
						createButton: false,
						resetButton: false,
						loadResource: false,
						search: false,
						type: 'select',
					}
				},
				MVTReferenceField: true,
				fieldProps: {
					label: translate('fields.audioLanguage'),
					header: {labelIcon: <Mic />},
					cellAndHeaderClassName: classNames(classes.w80,classes.mw80,classes.breakpointDownLG),
				}
			},
			hasSfx: {
				InlineEditorField: InlineEditorField,
				inlineEditorProps: {
					range: 'GridBooleanInput',
				},
				fieldProps: {
					label: translate('fields.hasSfx'),
					header: {labelIcon: <SurroundSound />},
					cellAndHeaderClassName: classNames(classes.w60,classes.breakpointDownLG),
				}
			},
			deliveryFormats: {
				fieldProps: {
					label: translate('fields.deliveryFormats'),
					cellAndHeaderClassName: classNames(classes.w60,classes.breakpointDownLG),
				}
			},
			deliveryDate: {
				InlineEditorField: InlineEditorField,
				fieldProps: {
					label: translate('fields.deliveryDate'),
					cellAndHeaderClassName: classNames(classes.w120,classes.mw140,classes.breakpointDownMD),
				}
			},
			hasFinalApproval: {
				InlineEditorField: InlineEditorField,
				inlineEditorProps: {
					range: 'GridBooleanInput',
				},
				fieldProps: {
					label: translate('fields.hasFinalApproval'),
					header: {labelIcon: <VerifiedUser />},
					cellAndHeaderClassName: classNames(classes.w60,classes.breakpointDownSM),
				}
			},
			deliveredToMediaPoolOn: {
				InlineEditorField: InlineEditorField,
				fieldProps: {
					label: translate('fields.deliveredToMediaPoolOn'),
					header: {labelIcon: <Dns />},
					cellAndHeaderClassName: classNames(classes.w120,classes.mw140,classes.breakpointDownLG),
				}
			},
			deliveredToSoMeCloudOn: {
				// InlineEditorField: InlineEditorField,
				fieldProps: {
					label: translate('fields.deliveredToSoMeCloudOn'),
					header: {labelIcon: <CloudDone />},
					cellAndHeaderClassName: classNames(classes.w120,classes.mw140,classes.breakpointDownLG),
				}
			},
			delivered: {
				InlineEditorField: InlineEditorField,
				inlineEditorProps: {
					range: 'GridBooleanInput',
				},
				fieldProps: {
					label: translate('fields.delivered'),
					header: {labelIcon: <LocalShipping />},
					cellAndHeaderClassName: classNames(classes.w40,classes.breakpointDownSM),
				}
			},
			notes: {
				fieldProps: {
					label: translate('fields.notes'),
					cellAndHeaderClassName: classes.breakpointDownXL,
				}
			},/**/

			// product: null,
			// project: null,
			// clipVersionNumber: null,
			// screenFormat: null,
			// voiceOverLanguage: null,
			// subtitleLanguage: null,
			// superTypeLanguage: null,
			// notes: null,
			// deliveryDate: null,
			// deliveryFormats: null,
			// deliveryTargets: null,
			// status: null,
			// delivered: null,
			// disclaimer: null,
		};

		let df = [];
		if(map === 'listfields') {
			df = this.mapFields(listFields,fields);
		}

		return df;
	}

	static getGridRowActions( ) {

		return [];
		// const translate = useTranslate();
		//
		// return [
		// 	<RowSetAction
		// 		key="duplicate"
		// 		method={UPDATE}
		// 		// label={translate('buttons.duplicate')}
		// 		label={null}
		// 		action="duplicate"
		// 		note={'Masters '+translate('msgs.duplicated')}
		// 		header={{
		// 			labelIcon: <Filter1 />,
		// 			label: translate('buttons.duplicate')
		// 		}}
		// 	>
		// 		<Filter1 />
		// 	</RowSetAction>
		// ];
	}

	static getGridBulkActions( ) {

		const translate = useTranslate();

		// return [];
		return [
			<BulkSetAction
				key="duplicate"
				label={translate('buttons.duplicate')}
				action="duplicate"
				note={'Masters '+translate('msgs.duplicated')}>
				<Filter1 />
			</BulkSetAction>,
			<BulkSetAction
				key="quintuplicate"
				label={translate('buttons.duplicate') + ' x5'}
				action="quintuplicate"
				note={'Masters '+translate('msgs.duplicated')}>
				<Filter5 />
			</BulkSetAction>
			// <BulkSetField key="BulkSetField" />
		];

	}

}

export default Masters;
