// INTERNATIONALIZATION
// https://github.com/marmelab/react-admin/blob/next/docs/Translation.md
// see Translation@https://marmelab.com/react-admin/Translation.html

import polyglotI18nProvider     from 'ra-i18n-polyglot';
import englishMessages          from 'ra-language-english';
import germanMessages           from 'ra-language-german';
import deLocal                  from '../i18n/de';
import enLocal                  from '../i18n/en';
import _                        from 'lodash';
import { resolveBrowserLocale } from 'react-admin';


const messages = {
	de: { ...germanMessages, ...deLocal },
	en: { ...englishMessages, ...enLocal },
};

let userLocale = 'en' || localStorage.getItem('lang'); // hardcoded en
if(!userLocale) {
	userLocale = resolveBrowserLocale();
}

export const browserLocale = userLocale;

// https://github.com/marmelab/react-admin/blob/next/docs/Translation.md
// asyncMessages NEEDED?
// const asyncMessages = {
// 	de: () => import('../i18n/de.js').then(messages => messages.default),
// 	en: () => import('../i18n/en.js').then(messages => messages.default),
// };

// if(typeof console === 'object') { console.log('browserLocale',browserLocale,messages[browserLocale]); }


const i18nProvider = polyglotI18nProvider(locale => {

	// if(typeof console === 'object') { console.log('locale',locale,browserLocale); }

	if(locale === 'de') {
		return _.merge(messages['en'],messages['de']);
	}

	return messages[locale] ? messages[locale] : messages.en;

}, browserLocale, {
	onMissingKey: (a) => a // hide console messages for missongs keys
});


export default i18nProvider;





//
// const i18nProvider_OLD = locale => {
//
//
// 	messages['de'] = _.merge(messages['de'],messages['en']);
//
// 	if(!messages[locale]) {
// 		if(typeof console === 'object') { console.log('NO LOCAL FOUND, RETURN EN',messages[locale],locale); }
// 		return messages['en'];
// 	}
//
// 	if (locale === browserLocale) {
// 		// initial call, must return synchronously
// 		return messages[browserLocale];
// 	}
//
// 	// change of locale after initial call returns a promise
// 	return asyncMessages[locale]();
// };
//
//
// const i18nProvider_B = polyglotI18nProvider(locale => {
//
// 	if(typeof console === 'object') { console.log('locale',locale,browserLocale); }
//
// 	if (locale !== 'en') {
// 		return messages[locale];
// 	}
//
//
// 	return messages['en'];
// });
