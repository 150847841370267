import React                         from 'react';
import PropTypes                     from 'prop-types';
import get                           from 'lodash/get';

import {
	SelectArrayInput,
	ReferenceArrayInput,
	// AutocompleteArrayInput
}                            from 'react-admin';
import getReferenceNameField from '../../../../../api-platform-admin/src/getReferenceNameField';
// import AutocompleteArrayInput from './ra-material-ui-tests/RA_AutocompleteArrayInput';
// import AutocompleteInput from './MUIAutocomplete';
import AutocompleteArrayInput     from './MUIAutocompleteDownshift';
import { makeStyles }        from '@material-ui/core';

// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';
/**
 *
 * MVTReferenceArrayInput
 *
 * config e.g.:
 * referenceTagProps: {basePath:'/locations',reference:'locations'},
 * tagProps: {source:'name'},
 *
 * @param props
 * @returns {*}
 * @constructor
 */

var styles = makeStyles({
	autocompleteContainer: {
		fontSize: '5rem'
	}
});


var stylesContainer = makeStyles({
	suggestionsContainer: {
		zIndex: 2210,
	},
});


var useAutocompleteSugestionArrayInputStyles = makeStyles({
	suggestionsContainer: {
		zIndex: 2210,
	},
});

const MVTReferenceArrayInput = (props) => {

	// if(typeof console === 'object') { console.log('MVTReferenceArrayInput',props); }
	let classes = styles();
	let classesContainer = stylesContainer();
	let autocompleteSugestionArrayInputStyles = useAutocompleteSugestionArrayInputStyles();

	let {
		refField,
		source,
		reference,
		tagProps,
		tag,
		referenceTag,
		referenceTagProps,
		...rest
	} = props;

	/**
	 * Componets array to match components in tag name of column definition
	 * @type {{NumberColumn: NumberColumn, DateColumn: DateColumn, Column: Column}}
	 */
	let components = {
		SelectArrayInput: SelectArrayInput,
		ReferenceArrayInput  : ReferenceArrayInput,
		AutocompleteArrayInput: AutocompleteArrayInput
	};

	const FieldTag = components[ tag || 'SelectArrayInput' ];
	const RefTag = components[ referenceTag || 'ReferenceArrayInput' ];
	if(!tagProps) {
		let refField = getReferenceNameField(props.reference);
		if(props.refField) {
			refField = props.refField;
		}

		tagProps = {
			optionText: refField,
			reference: reference.name
		};
	}

	if(!referenceTagProps) {
		referenceTagProps = {
			basePath:'/' + reference.name,
			reference: reference.name
		};
	}

	// if(typeof console === 'object') { console.log('MVTReferenceArrayInput.referenceTagProps',tag,props,referenceTagProps,tagProps); }

	let autoCompleteArrayOptions = {
		showEmpty: true,
		multiple: true
	};

	// load reference in AutocompleteArrayInput (no choices)
	if(tagProps.loadResource === true && tag === 'AutocompleteArrayInput') {
		return (
			<FieldTag
				optionText={refField}
				className={classes.autocompleteContainer}
				{...props}
				{...tagProps}
				{...autoCompleteArrayOptions}
				options={{
					suggestionsContainerProps: {
						classes: classesContainer
					}
				}}
			/>
		);
	}

	return (
		<RefTag
			fullWidth={true}

			label={source}
			source={source}
			{...rest}
			allowEmpty
			// label={field.name}

	        {...referenceTagProps}
		>
			<FieldTag
				optionText={refField}
				className={classes.autocompleteContainer}
				{...tagProps}
				{...autoCompleteArrayOptions}
				options={{
					suggestionsContainerProps: {
						classes: classesContainer
					}
				}}
			/>
			{/*<AutocompleteArrayInput*/}
			{/*	optionText={refField}*/}
			{/*	className={styles.autocompleteContainer}*/}
			{/*	options={{*/}
			{/*		suggestionsContainerProps: {*/}
			{/*			classes: autocompleteSugestionArrayInputStyles*/}
			{/*		}*/}
			{/*	}}*/}
			{/*/>*/}
		</RefTag>
	);
};

MVTReferenceArrayInput.defaultProps = {

};

MVTReferenceArrayInput.propTypes = {
	className: PropTypes.any,
	record: PropTypes.object,
	source: PropTypes.string,
	// translate: PropTypes.func
};

MVTReferenceArrayInput.displayName = 'MVTReferenceArrayInput';
export default MVTReferenceArrayInput;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
