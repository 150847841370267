import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


function CalndarIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" role="img">
                <path
                    d="M2.4,20.9c0,1.1,0.9,2.1,2.1,2.1h15.1c1.1,0,2.1-0.9,2.1-2.1V9.2H2.4V20.9z M16.1,12.5 c0-0.3,0.2-0.5,0.5-0.5h1.7c0.3,0,0.5,0.2,0.5,0.5v1.7c0,0.3-0.2,0.5-0.5,0.5h-1.7c-0.3,0-0.5-0.2-0.5-0.5V12.5z M16.1,18 c0-0.3,0.2-0.5,0.5-0.5h1.7c0.3,0,0.5,0.2,0.5,0.5v1.7c0,0.3-0.2,0.5-0.5,0.5h-1.7c-0.3,0-0.5-0.2-0.5-0.5V18z M10.6,12.5 c0-0.3,0.2-0.5,0.5-0.5h1.7c0.3,0,0.5,0.2,0.5,0.5v1.7c0,0.3-0.2,0.5-0.5,0.5h-1.7c-0.3,0-0.5-0.2-0.5-0.5V12.5z M10.6,18 c0-0.3,0.2-0.5,0.5-0.5h1.7c0.3,0,0.5,0.2,0.5,0.5v1.7c0,0.3-0.2,0.5-0.5,0.5h-1.7c-0.3,0-0.5-0.2-0.5-0.5V18z M5.1,12.5 c0-0.3,0.2-0.5,0.5-0.5h1.7c0.3,0,0.5,0.2,0.5,0.5v1.7c0,0.3-0.2,0.5-0.5,0.5H5.6c-0.3,0-0.5-0.2-0.5-0.5V12.5z M5.1,18 c0-0.3,0.2-0.5,0.5-0.5h1.7c0.3,0,0.5,0.2,0.5,0.5v1.7c0,0.3-0.2,0.5-0.5,0.5H5.6c-0.3,0-0.5-0.2-0.5-0.5V18z M19.6,3.8h-2.1V1.7 c0-0.4-0.3-0.7-0.7-0.7h-1.4c-0.4,0-0.7,0.3-0.7,0.7v2.1H9.2V1.7C9.2,1.3,8.9,1,8.6,1H7.2C6.8,1,6.5,1.3,6.5,1.7v2.1H4.4 c-1.1,0-2.1,0.9-2.1,2.1v2.1h19.2V5.8C21.6,4.7,20.7,3.8,19.6,3.8z"></path>
                <title>Calendar</title>
            </svg>
        </SvgIcon>
    );
}

export default CalndarIcon;