import React, { PureComponent }  from "react";
import PropTypes                 from 'prop-types';
import Grid                      from '@material-ui/core/Grid';
import { FontAwesomeIcon }       from '@fortawesome/react-fontawesome';
import { faSpinner, faSync }     from '@fortawesome/free-solid-svg-icons/index';
import CircularProgress          from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';
import { createStyles }          from '@material-ui/core';


const styles = function (theme) {
	// if(typeof console === 'object') { console.log('theme',theme); }
	return createStyles({
		fullsize: {
			flexGrow: 1,
			position: 'absolute',
			width: '100%',
			height: '100%',
			left: 0,
			right: 0,
			bottom: 0,
			top: 0,
			zIndex: 1,
			backgroundColor: '#242a2e',//theme.palette.backgroundColors.content,
			textAlign: 'center',
			padding: '10px'
		},
		fullsizeCenter: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexGrow: 1,
			position: 'absolute',
			width: '100%',
			height: '100%',
			left: 0,
			right: 0,
			bottom: 0,
			top: 0,
			zIndex: 20,
			backgroundColor: 'rgba(0,0,0,0.5)',
			textAlign: 'center',
			padding: '10px'
		},
		container: {
			flexGrow: 1,
		}
	});
};



class LoadingIndicator extends PureComponent {

	constructor(props) {
		super(props);

		this.msg = 'Loading...';
	}

	render() {

		const { type, wrapperClassName, classes } = this.props;
		let indicator = null;

		let standardWrapperClassName = 'standard-spinner-wrapper';
		standardWrapperClassName = (wrapperClassName) ? wrapperClassName : standardWrapperClassName;

		let fullsizeClassName = classes.fullsize;
		if(type === 'fullsize-center') {
			fullsizeClassName = classes.fullsizeCenter;
		}

		switch ( type ) {
			case'fullsize':
			case'fullsize-center':
				indicator = (<div className={fullsizeClassName}>
					{/*<FontAwesomeIcon icon={faSync} spin fixedWidth className="highlight" mask={['far', 'circle']} />*/}
					<CircularProgress
						className={"highlight"}
						color="inherit"
						size={18}
						thickness={5}
					/>
				</div>);
				break;
			case'fas-circle':
				indicator = (<div>
					{/*<FontAwesomeIcon icon={faSync} spin fixedWidth className="highlight" mask={['far', 'circle']} />*/}
					<CircularProgress
						className={"highlight"}
						color="inherit"
						size={18}
						thickness={5}
					/>
				</div>);
				break;
			case'container':
			case'fa-spinner-pulse':
				indicator = (
					<Grid container className={classes.container} spacing={0}>
						<Grid item xs={12} className={standardWrapperClassName}>
							{/*<FontAwesomeIcon icon={faSpinner} pulse fixedWidth className="highlight" mask={['far', 'circle']} />*/}
							<CircularProgress
								className={"highlight"}
								color="inherit"
								size={18}
								thickness={5}
							/>
						</Grid>
					</Grid>
				); // fa-pulse
				break;
			case'simple':
			case'fa-sync-spin':
				indicator = <FontAwesomeIcon icon={faSpinner} pulse fixedWidth className="highlight" mask={['far', 'circle']} />;
				break;
			case'fontello':
				indicator = <FontAwesomeIcon icon={faSync} spin fixedWidth className="highlight" mask={['far', 'circle']} />;
				break;
			default:
				indicator = (
					<div className={standardWrapperClassName}>
						{/*<FontAwesomeIcon icon={faSpinner} pulse fixedWidth className="highlight" mask={['far', 'circle']} />*/}
						<CircularProgress
							className={"highlight"}
							color="inherit"
							size={18}
							thickness={5}
						/>
					</div>
				);

		}

		return indicator;
	}
}

LoadingIndicator.propTypes = {
	// classes: PropTypes.object,
	type: PropTypes.string,
	wrapperClassName: PropTypes.string
};

export default withStyles(styles,{withTheme: true})(LoadingIndicator);
