// import moment from 'moment';
import config from '../../src/host.config';
import moment from "moment";

// let date_format = 'g:i:s A';
// let date_field_format = 'd.m.Y';
// let date_time_format = 'g:i A';
// let mysql_timestamp_format = 'YmdHis';
// let xTimestampFormat = 'd.m.Y H:i';
// let xDateFormat = 'd.m.Y';

export const TIMESTAMP_FORMAT = (config && config.format) ? config.format.timestamp : 'DD.MM.YYYY HH:mm';
export const DATE_FORMAT = (config && config.format) ? config.format.date : 'DD.MM.YYYY';
export const MYSQL_TIMESTAMP_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const MYSQL_DATEFORMAT = 'YYYY-MM-DD';

class DateUtils {

	static isDate(value) {
		return moment.isDate(value);
	}

	static add(v, n, s) {
		return moment(v).add(n, s);
	}

	static subtract(v, n, s) {
		return moment(v).subtract(n, s);
	}

	//moment('2010-01-01').isSame('2010-02-01', 'day');
	static isSame(v, n, s) {
		return moment(v).isSame(n, s);
	}

	//moment('2010-01-01').isSame('2010-02-01', 'day');
	static isSameDay(v, n) {
		return moment(v).isSame(n, 'day');
	}

	//moment('2010-10-20').isAfter('2009-12-31', 'year');
	static isAfter(v, n, s) {
		return moment(v).isAfter(n, s);
	}

	/**
	 * get a formated string from date object (or string)
	 *
	 * @param v
	 * @param format
	 */
	static format(v, format) {
		return moment(v).format(format);
	}

	/**
	 * get a date object
	 * @param v
	 * @returns {*|string}
	 */
	static formatDate(v,format=null) {
		if(!format) {
			format = DATE_FORMAT;
		}
		// var dt = new Date(v);
		// var dt = moment(v);
		return moment(v).format(format);
	}

	static formatIso(v) {
		return moment(v).format();
		// return moment(v).toISOString();
	}

	static parseIso(v) {
		return moment(v);
	}

	static parse(v, format) {
		return moment(v, format);
		// return parse(v, format, new Date());
	}

	static getDate(v, format) {
		return moment(v, format);
		// return parse(v, format, new Date());
	}

	/**
	 * send this date via api
	 *
	 * @param v
	 * @returns {*|string}
	 */
	// static sendDate(v) {
	// 	var dt = new Date(v);
	// 	if(DateUtils.isDate(v)) {
	// 		dt = v;
	// 	}
	// 	return Ext.Date.format(dt, MYSQL_DATEFORMAT);
	// }

	static getMySQLDateFormat() {
		return MYSQL_DATEFORMAT;
	}

	static getMySQLTimestampFormat() {
		return MYSQL_TIMESTAMP_FORMAT;
	}

	static getDateFormat() {
		return DATE_FORMAT;
	}

	static getTimestampFormat() {
		return TIMESTAMP_FORMAT;
	}
};

export default DateUtils;
