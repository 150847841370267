import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DateUtils from '../../utils/DateUtils';


export default class Date extends PureComponent {

	render() {
		const { value, className } = this.props;
		let { Tag, date_format, parseFormat } = this.props;
		let v = '';

		if(!parseFormat) {
			parseFormat = DateUtils.getMySQLDateFormat();
		}

		// guess date format
		if(
			value &&
			(!DateUtils.isDate(value)) &&
			value.length === 19
		) {
			parseFormat = DateUtils.getMySQLTimestampFormat();
			if(date_format === 'TIMESTAMP') {
				date_format = DateUtils.getTimestampFormat();
			} else {
				date_format = 'TIMESTAMP';
				date_format = DateUtils.getDateFormat();
			}
		}
		else if(date_format === 'TIMESTAMP') {
			date_format = DateUtils.getTimestampFormat();
			parseFormat = DateUtils.getMySQLTimestampFormat();
		}
		else if((!date_format) || date_format === 'DATE') {
			date_format = DateUtils.getDateFormat();
		}

		if(this.debug && typeof console === 'object') {
			console.log('GET DATE with date_format %o and value %o',date_format,value);
		}

		if(parseFormat === 'ISO') {
			let dt = DateUtils.parseIso( value );
			v = DateUtils.format( dt, date_format );
		}
		else if(DateUtils.isDate(value)) {
			v = DateUtils.format( value, date_format );
		} else {
			let dt = DateUtils.parse( value, parseFormat );
			v = DateUtils.format( dt, date_format );
		}

		if(!Tag) {
			Tag = 'span';
		}

		if(!v) {
			return null;
		}

		return (
			<Tag
				className={className}
			>
				{v}
			</Tag>
		);
	}
}

Date.propTypes = {
	value      : PropTypes.any,
	date_format: PropTypes.any,
	Tag        : PropTypes.string,
	className  : PropTypes.string
};
