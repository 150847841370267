import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


function People(props) {
    return (
        <SvgIcon {...props}>
            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" role="img">
                <path
                    d="M21.2,3.4H2.8C1.8,3.4,1,4.3,1,5.3v13.4c0,1,0.8,1.8,1.8,1.8h18.3c1,0,1.8-0.8,1.8-1.8V5.3 C23,4.3,22.2,3.4,21.2,3.4z M7.7,7.1c1.3,0,2.4,1.1,2.4,2.4S9.1,12,7.7,12s-2.4-1.1-2.4-2.4S6.4,7.1,7.7,7.1z M12,16.2 c0,0.4-0.4,0.7-0.9,0.7H4.3c-0.5,0-0.9-0.3-0.9-0.7v-0.7c0-1.2,1.1-2.2,2.6-2.2h0.2c0.5,0.2,1,0.3,1.5,0.3s1.1-0.1,1.5-0.3h0.2 c1.4,0,2.6,1,2.6,2.2V16.2z M20.6,14.1c0,0.2-0.1,0.3-0.3,0.3h-5.5c-0.2,0-0.3-0.1-0.3-0.3v-0.6c0-0.2,0.1-0.3,0.3-0.3h5.5 c0.2,0,0.3,0.1,0.3,0.3V14.1z M20.6,11.7c0,0.2-0.1,0.3-0.3,0.3h-5.5c-0.2,0-0.3-0.1-0.3-0.3v-0.6c0-0.2,0.1-0.3,0.3-0.3h5.5 c0.2,0,0.3,0.1,0.3,0.3V11.7z M20.6,9.2c0,0.2-0.1,0.3-0.3,0.3h-5.5c-0.2,0-0.3-0.1-0.3-0.3V8.6c0-0.2,0.1-0.3,0.3-0.3h5.5 c0.2,0,0.3,0.1,0.3,0.3V9.2z"></path>
                <title>People</title>
            </svg>
        </SvgIcon>
    );
}

export default People;