import React, {memo} from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import classnames from 'classnames';
import isEqual from "lodash/isEqual";
// import ReactUtils from "../../../utils/ReactUtils";

const sanitizeRestProps = ({
                               cellClassName,
                               className,
                               field,
                               formClassName,
                               headerClassName,
                               record,
                               basePath,
                               resource,
                               ...rest
                           }) => rest;

const DatagridCell = ({
                          className,
                          field,
                          record,
                          basePath,
                          resource,
                          ...rest
                      }) => {

    return (
        <TableCell
            className={classnames(className, field.props.cellClassName, field.props.cellAndHeaderClassName)}
            align={field.props.textAlign}
            {...sanitizeRestProps(rest)}
        >
            {React.cloneElement(field, {
                record,
                basePath: field.props.basePath || basePath,
                resource,
            })}

        </TableCell>
    );
};

DatagridCell.propTypes = {
    className: PropTypes.string,
    field: PropTypes.element,
    record: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    basePath: PropTypes.string,
    resource: PropTypes.string,
};

// wat? TypeScript looses the displayName if we don't set it explicitly
DatagridCell.displayName = 'DatagridCell';

const areEqual = (prevProps, nextProps) => {
    const { children: _, ...prevPropsWithoutChildren } = prevProps;
    const { children: __, ...nextPropsWithoutChildren } = nextProps;
    const isEq = isEqual(prevPropsWithoutChildren, nextPropsWithoutChildren);
    if(typeof console === 'object') { console.log('DatagridCell',isEq); }
    return isEq;
};

export const PureDatagridCell = memo(DatagridCell, areEqual);

PureDatagridCell.displayName = 'PureDatagridCell';

export default DatagridCell;