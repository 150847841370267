import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';



export default class Spacer extends PureComponent {
	render() {
		const { width, height, grow } = this.props;

		let display = 'block';

		if(width && width > 0) {
			display = 'inlne-block';
		}

		let style = { marginTop: height + 'px', width: width + 'px', display: display };

		if(grow) {
			//flexGrow: 1,
			style = {flexGrow: 1};
		}

		return (
			<div style={style} />
		);
	}
}

Spacer.defaultProps = {
	width: 0,
	height: 0,
};

Spacer.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	grow: PropTypes.bool,
};


