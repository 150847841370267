import AppStateApi from '../../api/AppStateApi';
import { call, put, takeEvery } from 'redux-saga/effects';


// function shouldFetchAppState(state) {
// 	const appstate = state.appstateByPHPOS;
//
// 	// if(typeof console === 'object') { console.log('shouldFetchAppState',appstate); }
// 	if(!appstate) {
// 		return false;
// 	} else if (typeof appstate.data === 'undefined') {
// 		return true;
// 	} else if (appstate.isFetchingAppState) {
// 		return false;
// 	} else {
// 		return appstate.didInvalidate;
// 	}
// }

function* fetchAppStateIfNeeded(action) {
	// if(typeof console === 'object') { console.log('fetchAppStateIfNeededfetchAppStateIfNeededfetchAppStateIfNeededfetchAppStateIfNeeded',action); }

	const appstate = yield AppStateApi.loadAppState().then( (response) => {
			// if(typeof console === 'object') { console.log('fetchAppStateIfNeeded.AppStateApi',response); }
			return response;
		}
	);

	yield put({ type: "RECEIVE_APPSTATE", appstate: appstate, });
}

export function* fetchAppState() {
	yield takeEvery('FETCH_APPSTATE_IF_NEEDED', fetchAppStateIfNeeded);
}

function* updateAppStateSuccess(action) {
	yield put({ type: "UPDATE_APPSTATE_SUCCESS", appstate: action.payload, });
}

export function* updateAppState() {
	yield takeEvery('UPDATE_APPSTATE', updateAppStateSuccess);
}


/**
 * APPSTATE, usage: namespace, key, value...
 * @type {string}
*/
//
// export const REQUEST_APPSTATE = 'REQUEST_APPSTATE';
// export const RECEIVE_APPSTATE = 'RECEIVE_APPSTATE';
// export const SELECT_APPSTATE = 'SELECT_APPSTATE';
// export const INVALIDATE_APPSTATE = 'INVALIDATE_APPSTATE';
//
//
// export function selectAppState(appstate) {
// 	return {
// 		type: SELECT_APPSTATE,
// 		appstate
// 	};
// }
//
// export function invalidateAppState() {
// 	return {
// 		type: INVALIDATE_APPSTATE
// 	};
// }
//
// function requestAppState() {
//
// 	// if(typeof console === 'object') { console.log('requestAppState',appdata); }
//
// 	return {
// 		type: REQUEST_APPSTATE
// 	};
// }
//
// function receiveAppState(appstate, json) {
//
// 	// if(typeof console === 'object') { console.log('receiveAppState',appstate, json); }
//
// 	return {
// 		type: RECEIVE_APPSTATE,
// 		appstate: json, //json.data.children.map(child => child.data),
// 		receivedAt: Date.now()
// 	};
// }
//
// function fetchAppState(appstate) {
// 	return dispatch => {
// 		dispatch(requestAppState(appstate));
// 		// if(typeof console === 'object') { console.log('process.env.VERSION',process.env.NODE_ENV,process.env.VERSION,process.env.API); }
//
// 		return AppStateApi.loadAppState()
// 			.then(json => dispatch(receiveAppState(appstate, json)));
// 	};
// }
//
// function shouldFetchAppState(state) {
// 	const appstate = state.appstateByPHPOS;
//
// 	// if(typeof console === 'object') { console.log('shouldFetchAppState',appstate); }
// 	if(!appstate) {
// 		return false;
// 	} else if (typeof appstate.data === 'undefined') {
// 		return true;
// 	} else if (appstate.isFetchingAppState) {
// 		return false;
// 	} else {
// 		return appstate.didInvalidate;
// 	}
// }
//
// export function silentfetchAppState(appstate) {
// 	return (dispatch, getState) => {
// 		return AppStateApi.loadAppState()
// 			.then(json => dispatch(receiveAppState(appstate, json)));
// 	};
// }
//
//
// export const UPDATE_APPSTATE = 'UPDATE_APPSTATE';
// export const UPDATE_APPSTATE_SUCCESS = 'UPDATE_APPSTATE_SUCCESS';
// export const UPDATE_APPSTATE_ERROR = 'UPDATE_APPSTATE_ERROR';
//
// export function updateAppStateSuccess(appstate) {
// 	return {type: UPDATE_APPSTATE_SUCCESS, appstate};
// }
//
// export function updateAppState(appstate) {
//
// 	return function (dispatch) {
// 		dispatch(updateAppStateSuccess(appstate));
// 	};
//
// }
