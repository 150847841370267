import React, { useState, useEffect } from 'react';
import PropTypes                      from 'prop-types';
import {
	TextInput,
	useInput,
}                                     from 'react-admin';
import { SketchPicker }               from 'react-color';
// import { SwatchesPicker } from 'react-color';
import { makeStyles }                 from '@material-ui/core';
import { useForm, useFormState }      from 'react-final-form';
import get                            from 'lodash/get';
import ObjectUtils                    from '../../../../utils/ObjectUtils';
import Popper             from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';


// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	popover: {
		position: 'absolute',
		zIndex: '2',
	},
	cover: {
		// position: 'fixed',
		position: 'absolute',
		top: '0px',
		right: '0px',
		bottom: '0px',
		left: '0px',
	},
	downshiftPopper:{
		zIndex: theme.zIndex.popper
	},
}));


const converters = {
	rgba: c => `rgba(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b}, ${c.rgb.a})`,
	rgb: c => `rgb(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b})`,
	hex: c => c.hex,

	rgba_rgb: c => c.rgb.a === 1 ? converters.rgb(c) : converters.rgba(c),
	rgba_hex: c => c.rgb.a === 1 ? converters.hex(c) : converters.rgba(c)
};

function MUIColorPicker(props) {

	const {
		// label,
		source,
		record,
	} = props;

	const {
		convert,
		disableAlpha,
		defaultValue,
		...rest
	} = props;

	// if(typeof console === 'object') { console.log('MUIColorPicker.props',props); }

	const [displayColorPicker, setDisplayColorPicker] = useState(false);
	const classes = useStyles();
	const form = useForm();
	const formState = useFormState();

	let value = defaultValue;
	if(formState && formState.values && ObjectUtils.get(formState.values,source)) { //formState.values[source]
		value = ObjectUtils.get(formState.values,source);
	}

	// if(typeof console === 'object') { console.log('MUIColorPicker.FOUND value',value,formState); }

	const openPicker = () => {
		handleClick();
	};

	const handleClick = () => {
		setDisplayColorPicker( !displayColorPicker );
	};

	const handleClose = () => {
		setDisplayColorPicker( false );
	};


	let inputPropId = "color-input";
	if(record && record.id) {
		inputPropId = inputPropId + record.id;
	}


	const getAnchorEl = () => {

		if(inputPropId) {
			// if(typeof console === 'object') { console.log('inputPropId',inputPropId,document.getElementById(inputPropId)); }
			return document.getElementById(inputPropId+ '');
		}

		return null;
	};

	const usePopper = true;

	return (
		<React.Fragment>
			<TextInput
				fullWidth={true}
				resettable={props.multiline ? false : true}
				clearAlwaysVisible={true}


				{...rest}

				onClick={openPicker}
				inputProps={{
					readOnly: true,
					style: { color: value },
					id: inputPropId
				}}
				// placeholder={label}
				// label={false}
			/>
			{usePopper && displayColorPicker ? <ClickAwayListener onClickAway={handleClose}><Popper
					className={classes.downshiftPopper}
					placement="bottom-start"
					open={displayColorPicker}
					anchorEl={getAnchorEl()}
				>
					<div className={ classes.cover } onClick={ handleClose }/>
					<SketchPicker
						disableAlpha={disableAlpha}
						color={value}
						onChange={c => {
							const newValue = converters[convert](c);
							form.change(source, newValue);
						}}
						presetColors={
							['#d32f2f', '#c2185b', '#7b1fa2', '#512da8', '#303f9f', '#1976d2', '#0288d1', '#0097a7',
							 '#00796b', '#388e3c', '#689f38', '#e64a19', '#5d4037', '#616161', '#455a64']
						}
					/>
				</Popper></ClickAwayListener>
				: null }

			{(!usePopper) && displayColorPicker ? <div className={ classes.popover }>
				<div className={ classes.cover } onClick={ handleClose }/>
				<SketchPicker
					disableAlpha={disableAlpha}
					color={value}
					onChange={c => {
						const newValue = converters[convert](c);
						form.change(source, newValue);
					}}
					presetColors={
						['#d32f2f', '#c2185b', '#7b1fa2', '#512da8', '#303f9f', '#1976d2', '#0288d1', '#0097a7',
						 '#00796b', '#388e3c', '#689f38', '#e64a19', '#5d4037', '#616161', '#455a64']
					}
				/>
			</div> : null }
		</React.Fragment>
	);
};

MUIColorPicker.defaultProps = {
	convert: 'hex',
	disableAlpha: true,
	defaultValue: '#000000',
};

MUIColorPicker.propTypes = {
	source: PropTypes.string,
	convert: PropTypes.string,
	defaultValue: PropTypes.string,
	disableAlpha: PropTypes.bool,
	// classes: PropTypes.object,
	// translate: PropTypes.func
};

export default MUIColorPicker;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
