import ObjectUtils from "./ObjectUtils";


class DomUtils {

	static stylesCreated = [];

	static querySelector(className) {
		return document.querySelector(className);
	};

	static querySelectorAll(className) {
		return document.querySelectorAll(className);
	};

	static querySelectorAllIn(el,className) {
		return el.querySelectorAll(className);
	};

	static querySelectorIn(el,className) {
		return el.querySelector(className);
	};

	static css(className,s,v) {
		let elements = this.querySelectorAll(className);
		for (let i = 0; i < elements.length; i++) {
			elements[i].style[s] = v;
		}
		// return this.querySelectorAll(className).style[s] = v;
	};

	static isTouch() {
		return (('ontouchstart' in window) || (window.navigator.msMaxTouchPoints > 0));
	};

	static docReady(fn) {
		// see if DOM is already available
		if (document.readyState === "complete" || document.readyState === "interactive") {
			// call on next available tick
			setTimeout(fn, 1);
		} else {
			document.addEventListener("DOMContentLoaded", fn);
		}
	};

	static on(selector,e,fn) {

		let elements;
		// let events = [];

		if(typeof selector === 'object') {
			elements = [selector];
		} else {
			elements = this.querySelectorAll(selector);
		}

		for (let i = 0; i < elements.length; i++) {
			elements[i].addEventListener(e, fn);
		}

		// return events;
	};

	static off(selector,e,fn) {
		let elements;

		if(typeof selector === 'object') {
			elements = [selector];
		} else {
			elements = this.querySelectorAll(selector);
		}

		for (let i = 0; i < elements.length; i++) {
			elements[i].removeEventListener(e, fn);
		}
	}

	static findUpElement(el, selector) {

		let t = this.querySelector(selector);

		if (el === t) {
			return el;
		}

		while (el.parentNode) {
			el = el.parentNode;
			if (el === t) {
				return el;
			}
		}
		return null;
	};

	static findUpClassName(el, className) {

		if (el.className && typeof el.className.indexOf === 'function' && el.className.indexOf(className) >= 0) {
			return el;
		}

		while (el.parentNode) {
			el = el.parentNode;
			if (el.className && typeof el.className.indexOf === 'function' && el.className.indexOf(className) >= 0) {
				return el;
			}
		}
		return null;
	};

	static findDownClassName(el, className) {

		if (el.className && typeof el.className.indexOf === 'function' && el.className.indexOf(className) >= 0) {
			return el;
		}

		let t = el.querySelector('.'+className);

		if(t) {
			return t;
		}

		// let nodeList = el.childNodes;
		//
		// for(let i = 0; i < nodeList.length; i++) {
		// 	el = nodeList[i];
		// 	if (el.className && typeof el.className.indexOf === 'function' && el.className.indexOf(className) >= 0) {
		// 		return el;
		// 	}
		// }
		return null;
	};

	static findUpTag(el, tag) {
		if (el.tagName === tag.toUpperCase()) {
			return el;
		}

		while (el.parentNode) {
			el = el.parentNode;
			if (el.tagName === tag.toUpperCase()) {
				return el;
			}
		}
		return null;
	};

	static getAttribute(el,a) {
		if(el && typeof el.getAttribute === 'function') {
			return el.getAttribute(a);
		}

		return null;
	}

	static insertAsFirstChild(parent,child) {
		return parent.insertBefore(child,parent.firstChild);
	}

	static appendChild(parent,child) {
		return parent.appendChild(child);
	}

	static findScrollPosition(elem) {
		let left = 0,
			top = 0;

		do {
			left += elem.offsetLeft-elem.scrollLeft;
			top += elem.offsetTop-elem.scrollTop;
		} while ( elem = elem.offsetParent );

		return { left: left, top: top };
	};

	// static getAbsoluteOffsetFromGivenElement ( el, relativeEl )
	// {   // finds the offset of el from relativeEl
	// 	var _x = 0;
	// 	var _y = 0;
	// 	while( el && el != relativeEl && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) )
	// 	{
	//
	// 		_x += el.offsetLeft - el.scrollLeft + el.clientLeft;
	// 		_y += el.offsetTop - el.scrollTop + el.clientTop;
	// 		// if(typeof console === 'object') { console.log('el',el,relativeEl,el.offsetTop,el.scrollTop,el.clientTop); }
	// 		el = el.offsetParent;
	// 	}
	// 	return { top: _y, left: _x };
	// }

	static findRelativTopPosition(el, parentEl) {
		let bodyRect = (parentEl) ? parentEl.getBoundingClientRect() : document.body.getBoundingClientRect(),
			elemRect = el.getBoundingClientRect();

		let offset   = elemRect.top - bodyRect.top;

		// if(typeof console === 'object') { console.log('bodyRect,elemRect',bodyRect,elemRect,offset); }
		return offset;
	};

	static findRelativLeftPosition(el, parentEl) {
		let bodyRect = (parentEl) ? parentEl.getBoundingClientRect() : document.body.getBoundingClientRect(),
			elemRect = el.getBoundingClientRect();

		let offset   = elemRect.left - bodyRect.left;

		// if(typeof console === 'object') { console.log('bodyRect,elemRect',bodyRect,elemRect,offset); }
		return offset;
	};

	static createStyle(selector, css) {

		if(!ObjectUtils.inArray(this.stylesCreated,selector)) {
			let head = document.head || document.getElementsByTagName('head')[0],
				style = document.createElement('style');

			head.appendChild(style);
			style.type = 'text/css';
			let s_css = selector+''+css;
			style.appendChild(document.createTextNode(s_css));
			this.stylesCreated.push(selector);
		}


	}

}

export default DomUtils;
