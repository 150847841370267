import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import shouldUpdate from 'recompose/shouldUpdate';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { FieldTitle, useTranslate } from 'ra-core';

// remove the sort icons when not active
const useStyles = makeStyles(
    {
        icon: {
            display: 'none',
        },
        active: {
            '& $icon': {
                display: 'inline',
            },
        },
    },
    { name: 'RaDatagridHeaderCell' }
);

const FieldTooltipp = React.forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return <span {...props} ref={ref} />
});

export const DatagridHeaderCell = props => {
    const {
        className,
        classes: classesOverride,
        field,
        currentSort,
        updateSort,
        resource,
        isSorting,
        ...rest
    } = props;
    const classes = useStyles(props);
    const translate = useTranslate();

    // if(typeof console === 'object') { console.log('DatagridHeaderCell',props); }

    const getHeaderCellLabel = () => {

        if(field.props.header && field.props.header.labelIcon) {
            return field.props.header.labelIcon;
        }

        return field.props.label;
    };

    const getHeaderCellLabelToolTipp = (sort) => {

        let label = field.props.label || '';

        if(field.props.header && field.props.header.label) {
            // if(typeof console === 'object') { console.log('hasLabelIcon',field.props.source,field.props,field.props.header.labelIcon); }
            label = field.props.header.label;
        }

        if(sort) {
            return translate('ra.action.sort') + ' ' + label;
        }

        return label;
    };

    const hasLabelIcon = () => {
        if(field.props.header && field.props.header.labelIcon) {
            // if(typeof console === 'object') { console.log('hasLabelIcon',field.props.source,field.props,field.props.header.labelIcon); }
            return true;
        }
        return false;
    };

    // if(field.props.source === 'product' && typeof console === 'object') {
    //     console.log('field.props.cellAndHeaderClassName',field.props.source,field.props);
    // }

    return (
        <TableCell
            className={classnames(className, field.props.headerClassName, field.props.cellAndHeaderClassName)}
            align={field.props.textAlign}
            variant="head"
            {...rest}
        >
            {field.props.sortable !== false &&
            (field.props.sortBy || field.props.source) ? (
                <Tooltip
                    title={getHeaderCellLabelToolTipp(true)}
                    placement={
                        field.props.textAlign === 'right'
                            ? 'bottom-end'
                            : 'bottom-start'
                    }
                    enterDelay={300}
                >
                    <TableSortLabel
                        active={
                            currentSort.field ===
                            (field.props.sortBy || field.props.source)
                        }
                        direction={currentSort.order === 'ASC' ? 'asc' : 'desc'}
                        data-sort={field.props.sortBy || field.props.source}
                        onClick={updateSort}
                        classes={classes}
                    >
                        <FieldTitle
                            label={getHeaderCellLabel()}
                            source={field.props.source}
                            resource={resource}
                        />
                    </TableSortLabel>
                </Tooltip>
            ) : (
                hasLabelIcon() ?
                    <Tooltip
                        title={getHeaderCellLabelToolTipp(false)}
                        placement={
                            field.props.textAlign === 'right'
                                ? 'bottom-end'
                                : 'bottom-start'
                        }
                        enterDelay={300}
                    >
                        <FieldTooltipp>
                            <FieldTitle
                                label={getHeaderCellLabel()}
                                source={field.props.source}
                                resource={resource}
                            />
                        </FieldTooltipp>
                    </Tooltip>
                    :
                    <FieldTitle
                        label={getHeaderCellLabel()}
                        source={field.props.source}
                        resource={resource}
                    />
            )}
        </TableCell>
    );
};

DatagridHeaderCell.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    field: PropTypes.element,
    currentSort: PropTypes.shape({
        sort: PropTypes.string,
        order: PropTypes.string,
    }).isRequired,
    isSorting: PropTypes.bool,
    sortable: PropTypes.bool,
    resource: PropTypes.string,
    updateSort: PropTypes.func.isRequired,
};

export default shouldUpdate(
    (props, nextProps) =>
        props.updateSort !== nextProps.updateSort ||
        props.currentSort.sort !== nextProps.currentSort.sort ||
        props.currentSort.order !== nextProps.currentSort.order ||
        (nextProps.isSorting && props.sortable !== nextProps.sortable)
)(DatagridHeaderCell);