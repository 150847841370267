//CUSTOM_FETCH_LOAD
import AppCacheApi from '../../api/AppCacheApi';
import ObjectUtils from '../../utils/ObjectUtils';

export default ( previousState = 0, action) => {
	let resource = false;
	if(action && action.meta && action.meta.resource) {
		resource = action.meta.resource;
	}
	// if(resource && AppCacheApi.debugReducer && resource === AppCacheApi.debugResource && typeof console === 'object') { console.log('cacheReducer with resource %o ',resource,action,previousState); }
	// if(typeof console === 'object') { console.log('cacheReducer ',action,previousState); }

	// if (action.type === 'CUSTOM_FETCH_LOAD') {
	// 	if(typeof console === 'object') { console.log('CUSTOM_FETCH_LOAD ',action,previousState); }
	// 	return previousState;
	// }
	//
	// if (action.type === 'CUSTOM_FETCH_LOADING') {
	// 	if(typeof console === 'object') { console.log('CUSTOM_FETCH_LOAD ',action,previousState); }
	// 	return previousState;
	// }

	if (
		// 1 === 2 &&
		ObjectUtils.inArray(AppCacheApi.cacheActions,action.type)
	) {
		if(resource && AppCacheApi.debugReducer && resource === AppCacheApi.debugResource && typeof console === 'object') { console.log('cacheReducer with resource %o ',resource,action,previousState); }
		AppCacheApi.setCache(action);
		return previousState;
	}



	return previousState;
}
