import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles }         from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon }              from '@fortawesome/react-fontawesome';

const styles = theme => ({
	root: {
		// flexGrow: 1,
	},
	paper: {
		textAlign: 'left',
		boxShadow: 'none',
		backgroundColor: 'transparent',
		padding: '0',
	},
	overflowText:{
		'&:after': {
			// backgroundColor: theme.palette.background.default,
			background: 'linear-gradient(to bottom, rgba(255,255,255,0.001) 0%, rgba(255,255,255,0.001) 50%, '+theme.palette.background.default+' 100%);',
			backgroundImage: '-webkit-gradient( linear, left top, left bottom, color-stop(0, rgba(36, 42, 46, 0)), color-stop(1, rgba(36, 42, 46, 100)))'
			// background-color: rgba(255,255,255,0.75);
// background: -moz-linear-gradient(top, transparent 0%,$body-background-color075 0%, $body-background-color 100%);
// background: -webkit-linear-gradient(top, transparent 0%,$body-background-color075 50%,$body-background-color 100%); /* Chrome10-25,Safari5.1-6 */
// background: linear-gradient(to bottom, transparent 0%, transparent 50%, $body-background-color 100%);
//
// background: -moz-linear-gradient(top, rgba(255,255,255,0.001) 0%,$body-background-color075 0%, $body-background-color 100%);
// background: -webkit-linear-gradient(top, rgba(255,255,255,0.001) 0%,$body-background-color075 50%,$body-background-color 100%); /* Chrome10-25,Safari5.1-6 */
// background: linear-gradient(to bottom, rgba(255,255,255,0.001) 0%, rgba(255,255,255,0.001) 50%, $body-background-color 100%);
		}
	},
	grid: {}
});


class LongDescription extends PureComponent {

	constructor(props) {
		super(props);

		this.defaultIcon = faChevronRight;
		this.cls = 'overflow-text';
		this.height = props.height;
		this.maxHeight = props.maxHeight;

		this.state = {
			open     : false,
			icon     : this.defaultIcon,
			cls      : this.cls,
			height   : this.height,
			maxHeight: this.maxHeight
		};

		this.toggle = this.toggle.bind(this);
	}

	componentDidMount() {
		const { open } = this.props;

		if(open) {
			this.toggle();
		}
	}

	toggle(e) {

		if(e) {
			if(e.target) {
				let nodeName = e.target.nodeName.toLowerCase();
				if(nodeName === 'a' || nodeName === 'button') {
					return false;
				}
			}
		}

		let open = !this.state.open;
		let icon = this.defaultIcon;
		let cls = this.cls;
		let height = this.height;
		let maxHeight = this.maxHeight;

		if(open) {
			icon = faChevronDown;
			cls='';
			height = 'auto';
			maxHeight = 'initial';
		}

		this.setState( {
			open: open,
			icon: icon,
			height: height,
			maxHeight: maxHeight,
			cls: cls
		});
	}

	render() {
		let {
			value,
			title,
			leftContainerWidth,
			leftContainerAlign,
			panelMargin,
			classes,
			maxWidth,
			backgroundColor,
			toggleOnBoxClick,
			lineBreaks
		} = this.props;
		let { open, icon, height, maxHeight, cls } = this.state;

		let iconClassName = 'iconButtonRight fas fa ';

		if(lineBreaks && open) {
			cls += ' pre__line';
		}

		if(backgroundColor) {
			cls += ' ' + backgroundColor;
		} else {
			cls += ' ' +classes.overflowText;
		}

		const cSt = {padding:panelMargin};
		const gSt = {
			maxWidth: maxWidth,
			width: 'calc(100% - '+leftContainerWidth+')',
		};
		const dst = {
			maxHeight: maxHeight,
			// height: height,
			overflow: 'hidden',
			position: 'relative'
		};
		const ggSt = {width:leftContainerWidth, textAlign: leftContainerAlign};

		return (
			<Grid
				container spacing={2} className={classes.root +' user-select'}
				style={cSt}
			>
				<Grid item style={ggSt}>
					<FontAwesomeIcon onClick={this.toggle} icon={icon} className={iconClassName} />
				</Grid>
				<Grid
					item
					style={gSt}
				>
					<div
						onClick={toggleOnBoxClick ? this.toggle : false }
					>
						<div className={cls} style={dst}>
							{
								title && title.length ?
								<div className="longdescription__ttile">{title}</div> :
								null
							}
							{value && value.length ? value : null}
							{this.props.children}
						</div>
					</div>
				</Grid>
			</Grid>
		);
	}
}

LongDescription.defaultProps = {
	height            : '3em',
	maxHeight         : '3em',
	maxWidth          : '600px',
	leftContainerWidth: '80px',
	leftContainerAlign: 'right',
	panelMargin       : '10px 0 10px 0',
	toggleOnBoxClick  : true,
};

LongDescription.propTypes = {
	maxHeight         : PropTypes.string,
	height            : PropTypes.string,
	value             : PropTypes.string,
	title             : PropTypes.string,
	panelMargin       : PropTypes.string,
	leftContainerAlign: PropTypes.string,
	leftContainerWidth: PropTypes.string,
	maxWidth          : PropTypes.string,
	backgroundColor   : PropTypes.string,
	open              : PropTypes.bool,
	lineBreaks        : PropTypes.bool,
	toggleOnBoxClick  : PropTypes.bool,
	children          : PropTypes.any,
	classes           : PropTypes.object,
};

export default withStyles(styles)(LongDescription);
