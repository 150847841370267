const apiHost = process.env.REACT_APP_API_ENTRYPOINT;
// const apiHost = 'https://lp-api.dev.highject.com';

// if(typeof console === 'object') { console.log('process.env.',process.env,apiHost); }

module.exports = {
	title: 'HIGHJECT',
	url: '',
	name: 'HIGHJECT APP',
	subtitle: '',
	owner: 'BytesAtWork Ltd.',
	favicon: '/public/_img/Yellowspace-Logo-1000x1000.png',
	output: {
        path: './build',
        publicPath: ''
    },
	i18n: {
		loadPath: '/src/i18n/'
	},
	api: {
		host: apiHost,
		path: apiHost+'/api'
	},
	host: {

	}
};
