import { createBrowserHistory } from 'history';
import config from '../host.config.js';
/**
 * Initialized in AsyncApp
 * see @link https://github.com/ReactTraining/react-router/blob/master/FAQ.md#how-do-i-access-the-history-object-outside-of-components
 * Usage:
 * import history from '../../calm/app/containers/history';
 * history.push('/app/about');
 *
 * // import { BrowserRouter as Router } from 'react-router-dom';
 * // disabled BrowserRouter and used Router in order to use the history object
 import { Router } from 'react-router-dom';
 import history from './history';
 *
 * https://www.npmjs.com/package/history
 */
const history = createBrowserHistory({
	// basename: '/app'//config.urlBasename || ''
} );

export default class History {

	static history;

	static getHistory() {
		this.history = history;
		return history;
	}

	static push( url, o = {} ) {
		const { output } = config;

		if ( output && output.publicPath ) {
			url = output.publicPath + url;
		}

		if(!url) {
			return this.history.push( o );
		}

		return this.history.push( url, o );
	}
};