import React from 'react';
import PropTypes                                       from 'prop-types';
import { Avatar }                                         from '@material-ui/core';
import AccountCircle                                   from '@material-ui/icons/AccountCircle';
import { withTranslate }                                   from 'ra-core';
import MuiMenu                                         from '../../common/components/common/MuiMenu';
import { faMinusCircle, faSignOutAlt, faUser, faUserShield } from '@fortawesome/free-solid-svg-icons';
import UserAvatar                              from '../components/user/UserAvatar';
import { createStyles }                        from '@material-ui/core';
import { withStyles }                          from '@material-ui/core/styles';
import authProvider                            from '../admin-store/authProvider';
import DefaultProfilePicSVG                    from '../../public/_img/icons/profile-picture-w.svg';
import AppStateApi                             from '../../common/api/AppStateApi';
import {
	VerifiedUserSharp
} from "@material-ui/icons";
// import UAV                                             from '../../common/utils/UAV';
// import classNames from 'classnames';
// import IconButton                                      from '@material-ui/core/IconButton';
// import Menu                                            from '@material-ui/core/Menu';


const styles = () =>
	createStyles({
		root: {
			display: 'flex',
			alignItems: 'center'
		},
		avatar: {
			width: 20,
			height: 20,
			margin: '0 8px 0 0',
		},
	});


class UserMenu extends React.Component {
	static propTypes = {
		children: PropTypes.node,
		label: PropTypes.string.isRequired,
		logout: PropTypes.node,
		icon: PropTypes.node,
		translate: PropTypes.func.isRequired,
	};

	static defaultProps = {
		label: 'ra.auth.user_menu',
		icon: <AccountCircle />,
	};

	state = {
		auth: true,
		anchorEl: null,
	};

	handleChange = (event, checked) => {
		this.setState({ auth: checked });
	};

	handleMenu = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	render() {
		const {
			// children, label, icon, logout,
			classes, translate } = this.props;
		const { user } = this.props;


		// if (!logout && !children) return null;

		// const { anchorEl } = this.state;
		// const open = Boolean(anchorEl);

		// if(typeof console === 'object') { console.log('USerMenu',user); }


		const removeUserSwitch = () => {
			AppStateApi.clearState();
			authProvider.setUserSwitch(null);
			window.location.href = '/';//indow.location.href;
		};

		const getMenuItems = () => {
			const userSwitch = authProvider.getUserSwitch();

			let menuItems = [
				{
					text:translate( 'menuitems.logout' ),
					icon: faSignOutAlt,
					selected: true,
					closeOnClick: authProvider.logout
				},
				{
					text: translate( 'menuitems.my_profile' ),
					icon: faUser,
					disabled: true,
				},
				{
					text: translate( 'menuitems.reset_appstate' ),
					icon: faMinusCircle,
					closeOnClick: () => {
						AppStateApi.clearState()
					}
				},
				// {
				// 	text: "Reload appdata",
				// 	icon: faLock,
				// 	show: UAV.isSysAdmin(),
				// 	disabled: true,
				//
				// },
				// {
				// 	text: "Exit user switch",
				// 	icon: faLock,
				// 	show: session._is_impersonated_user
				// }
			];

			if(userSwitch) {
				menuItems.push(
					{
						text: 'Clear User Switch',
						icon: faUserShield,
						closeOnClick: () => {
							removeUserSwitch()
						}
					}
				);
			}

			return menuItems;
		};

		return (
			<div className={classes.root}>
				<MuiMenu
					text={user.username}
					// icon={faUser}
					icon={<Avatar className={classes.avatar} alt={user.username} src={DefaultProfilePicSVG} />}
					// buttonColor="inherit"
					// buttonColor="secondary"
					buttonSize="small"
					buttonVariant="contained"
					menuItems={getMenuItems()}
				>
				</MuiMenu>
				{1===2 && <Avatar className={classes.avatar} alt={user.username} src={DefaultProfilePicSVG} />}
				{1 === 2 && <UserAvatar
					user={{
						id: user.id
					}}
					src={user.img}
					loadProfile={true}
					className="img--circle-preview small margin-top-5"
				/>}
			</div>
		);

		// return (
		// 	<div>
		// 		<Tooltip title={label && translate(label, { _: label })}>
		// 			<IconButton
		// 				aria-label={label && translate(label, { _: label })}
		// 				aria-owns={open ? 'menu-appbar' : null}
		// 				aria-haspopup={true}
		// 				color="inherit"
		// 				onClick={this.handleMenu}
		// 			>
		// 				{icon}
		// 			</IconButton>
		// 		</Tooltip>
		// 		<Menu
		// 			id="menu-appbar"
		// 			anchorEl={anchorEl}
		// 			anchorOrigin={{
		// 				vertical: 'top',
		// 				horizontal: 'right',
		// 			}}
		// 			transformOrigin={{
		// 				vertical: 'top',
		// 				horizontal: 'right',
		// 			}}
		// 			open={open}
		// 			onClose={this.handleClose}
		// 		>
		// 			{Children.map(children, menuItem =>
		// 				isValidElement(menuItem)
		// 				? cloneElement(menuItem, {
		// 					onClick: this.handleClose,
		// 				})
		// 				: null
		// 			)}
		// 			{logout}
		// 		</Menu>
		// 	</div>
		// );
	}
}

export default withStyles(styles)(withTranslate(UserMenu));
