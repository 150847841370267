import React             from 'react';
import LocalApi          from '../LocalApi';
// import { useTranslate }           from 'react-admin';
// import { minLength, required } from 'react-admin';
// import useGridStyles from '../../../common/components/helpers/useGridStyles';
// import classNames from 'classnames';

/**
 * cld_
 *
 *
 *
 *
 *
 *
 */

class Groups extends LocalApi {

	static contentKey = 'groups';
	// static sorting = { field: 'progress', order: 'DESC' };
	// static hasEdit = false;
	// static hasShow = false;


	static getGridPermanentFilter( permanentFilter ) {
		// return {isEntityGroup: false};
		return permanentFilter;
	}

	static getGridColumns(listFields,map = 'listfields') {
		// if(typeof console === 'object') { console.log('getGridColumns',listFields); }

		// const translate = useTranslate();

		/*
		readableFields: Array(12)
0: Field {name: "name", id: "http://schema.org/name", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
1: Field {name: "description", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Group/description", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
2: Field {name: "unixName", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Group/unixName", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
3: Field {name: "gidNumber", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Group/gidNumber", range: "http://www.w3.org/2001/XMLSchema#integer", reference: null, required: false, …}
4: Field {name: "origin", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Group/origin", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
5: Field {name: "doSync", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Group/doSync", range: "http://www.w3.org/2001/XMLSchema#boolean", reference: null, required: false, …}
6: Field {name: "lastSync", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Group/lastSync", range: "http://www.w3.org/2001/XMLSchema#dateTime", reference: null, required: false, …}
7: Field {name: "symfonyRoles", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Group/symfonyRoles", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
8: Field {name: "accessTag", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Group/accessTag", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
9: Field {name: "SAMAccountName", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Group/SAMAccountName", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
10: Field {name: "SecurityIdentifier", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Group/SecurityIdentifier", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
11: Field {name: "isEntityGroup", id: "https://cr-api.dev.highject.com/api/docs.jsonld#Group/isEntityGroup", range: "http://www.w3.org/2001/XMLSchema#boolean", reference: null, required: false, …}
length: 12
		 */

		let fields = {
			gidNumber: null,
			lastSync: null,
			// symfonyRoles: null,
			origin: null,
			accessTag: null,
			SAMAccountName: null,
			SecurityIdentifier: null,
			// isEntityGroup: null,
		};

		let df = [];
		if(map === 'listfields') {
			df = this.mapFields(listFields,fields);
		}

		return df;
	}

}

export default Groups;
