import React                  from 'react';
import PropTypes              from 'prop-types';
import Show                   from '../../../../api-platform-admin/src/Show_MVT';
import { makeStyles }         from '@material-ui/core';
import MuiModal               from '../../../components/common/MuiModal';
import GlobalLoadingIndicator from '../components/GlobalLoadingIndicator';

let useStyles = makeStyles(function (theme) {
	return ({
		drawerContent: {
			width: '100%',
			[theme.breakpoints.up('xs')]: {
				width: '100%',
			},
			[theme.breakpoints.up('sm')]: {
				width: '100%',
			},
		}
	});
});

function MuiModalShow(props) {

	let styles = useStyles();
	const {
		disableBackdropClick,
		title,
		closeButton,
		isOpen,
		handleEditorClose,
		resource,
		id,
		basePath,
		redirect,
		formProps,
		options,
		contentWidth,
		contentMinWidth,
		contentMaxWidth,
		maxWidth,
		dialogContentStyle,
		renderFields,
		toolbar
	} = props;

	// if(typeof console === 'object') { console.log('MuiModalEditor',isOpen); }

	const dialogCStyle = Object.assign({
		padding: 0,
		borderBottom: 0,
	},dialogContentStyle);

	return (
		<MuiModal
			draggable={true}
			disableBackdropClick={disableBackdropClick}
			closeButton={closeButton}
			contentWidth={contentWidth}
			contentMinWidth={contentMinWidth}
			contentMaxWidth={contentMaxWidth}
			maxWidth={maxWidth}
			open={isOpen}
			onClose={handleEditorClose}
			title={<React.Fragment>
				{title}
				<GlobalLoadingIndicator />
			</React.Fragment>}
			dialogContentStyle={dialogCStyle}
		>
			{isOpen ? (
				<React.Fragment>
					<Show
						toolbar={toolbar}
						renderFields={renderFields}
						className={styles.drawerContent}
						id={isOpen ? id : null}
						resource={resource}
						basePath={basePath}
						options={options}
						// redirect={false}
						onCancel={handleEditorClose}
						formProps={Object.assign({
							redirect: redirect,
						},formProps)}
					/>
				</React.Fragment>
			) : (
				 <div className={styles.drawerContent} />
			 )}
		</MuiModal>
	);
};

MuiModalShow.defaultProps = {
	dialogContentStyle: {},
	formProps: {},
	disableBackdropClick: false,
	renderFields: 'showfields',
};

MuiModalShow.propTypes = {
	title: PropTypes.string,
	renderFields: PropTypes.string,
	closeButton: PropTypes.bool,
	disableBackdropClick: PropTypes.bool,
	isOpen: PropTypes.bool,
	resource: PropTypes.string,
	basePath: PropTypes.string,
	id: PropTypes.any,
	redirect: PropTypes.any,
	contentWidth: PropTypes.any,
	contentMaxWidth: PropTypes.any,
	contentMinWidth: PropTypes.any,
	maxWidth: PropTypes.any,
	options: PropTypes.object,
	formProps: PropTypes.object,
	dialogContentStyle: PropTypes.object,
	handleEditorClose: PropTypes.func
};

export default MuiModalShow;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
