import React, { PureComponent } from 'react';
import PropTypes                from 'prop-types';
import StringUtils from '../../utils/StringUtils';
/**
 * PhoneNumber
 * https://github.com/nathanhoad/react-phone-number/blob/master/src/index.js
 */

export default class PhoneNumber extends PureComponent {

	constructor(props) {
		super(props);
	}

	getNummber() {
		let { value, record, recordField } = this.props;

		if(value) {
			return value;
		}

		if(typeof record === 'object' && record[recordField]) {
			return record[recordField];
		}

		return null;
	}


	render() {
		let { isLinked, className } = this.props;

		let value = this.getNummber();

		if (!value) {
			return null;
		}

		// number = number.toString().replace(/[^0-9\+]+/g, '').replace(/\s+/, '');
		value = StringUtils._r_PhoneNumber(value);
		let formatted_number = StringUtils._f_PhoneNumber(value);

		// if (number.length === 8) {
		// 	formatted_number = number.replace(/([0-9]{2})([0-9]{3})([0-9]{3})/, '$1 $2 $3');
		// } else if (number.length === 10) {
		// 	formatted_number = number.replace(/([0-9]{4})([0-9]{3})([0-9]{3})/, '$1 $2 $3');
		// } else if (number.indexOf('+') === 0) {
		// 	formatted_number = number.replace(/([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{3})/, '$1 $2 $3 $4');
		// }

		if (isLinked) {
			return (<a onClick={(e) => {e.stopPropagation(); return false;}} href={`tel:${value}`} className={className}>{formatted_number}</a>);
		} else {
			return (<span className={className}>{formatted_number}</span>);
		}
	}
}


PhoneNumber.defaultProps = {
	isLinked: false,
	recordField: 'number'
};

PhoneNumber.propTypes = {
	value: PropTypes.any,
	record: PropTypes.object,
	isLinked: PropTypes.bool,
	className: PropTypes.string,
	recordField: PropTypes.string,
};
