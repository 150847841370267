export const RECEIVE_USER_DATA = 'RECEIVE_USER_DATA';

export function userReducer(state = {
	data: null
}, action) {

	switch (action.type) {
		case RECEIVE_USER_DATA:
			let t = Object.assign({}, state, {
				isFetchingUserData: false,
				data: action.data,
			});

			// if(typeof console === 'object') { console.log('REDUCER.userReducer RECEIVE_USER_DATA state,action',t,action); }

			return t;
		default:
			return state;
	}
}
