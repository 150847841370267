import React from 'react';
import PropTypes                      from 'prop-types';
import { makeStyles }                 from '@material-ui/core/styles';
import Typography                     from '@material-ui/core/Typography';
import Grid                           from '@material-ui/core/Grid';
import { faListAlt }                  from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon }            from '@fortawesome/react-fontawesome';
import { withTranslate }              from 'react-admin';
import { Link as RouterLink }         from 'react-router-dom';
import Link                           from '@material-ui/core/Link';
import ProjectManagementSummaryEvents from './ProjectManagementSummaryEvents';

/* eslint-disable jsx-a11y/anchor-is-valid */
// import { MemoryRouter as Router } from 'react-router';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';
// import Paper                  from '@material-ui/core/Paper';
// import Divider                from '@material-ui/core/Divider';
// import MuiButton          from '../../../../common/components/common/MuiButton';
// import { FormatListBulleted } from '@material-ui/icons';

let useStyles = makeStyles(function (theme) {
	return (
		{
			container: {},
			inlinecontainer: {

			},
			inlineitem: {
				display: 'block',
			},
			inlineitemicon: {
				display: 'block',
				textAlign: 'center',
				minHeight: '125px',
				backgroundColor: '#4db3f9',
			},
			icon: {
				color: theme.palette.textColors.default,
				// fontSize: '80px',
				fontSize: '6vw;',
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
				paddingTop: theme.spacing(2),
				paddingBottom: theme.spacing(2),
			},
			typo: {
				textDecoration: 'none',
				padding: theme.spacing(0),
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(1),
				paddingBottom: theme.spacing(1),
				display: 'inline-block'
			},
			typoTitle: {
				fontWeight: 700,
				textDecoration: 'none',
				textTransform: 'uppercase',
				padding: theme.spacing(2),
				paddingBottom: theme.spacing(1),
				display: 'block'
			},
			masterListBox: {
				textDecoration: 'none',
				// ...theme.mixins.gutters(),
				// paddingLeft: theme.spacing(2),
				// paddingRight: theme.spacing(2),
				// paddingTop: theme.spacing(2),
				// paddingBottom: theme.spacing(2),
				// marginBottom: theme.spacing(2),
				// minHeight: '125px',
				display: 'block',
				backgroundColor: '#4597ec',
				'&:hover': {
					textDecoration: 'none',
				}
			},
		}
	);
});

// const LinkBehavior = React.forwardRef((props, ref) => {
// 	if(typeof console === 'object') { console.log('LinkBehavior.props',props); }
// 	return (
// 		<RouterLink ref={ref} to={props.basePath+'/'+encodeURIComponent(props.projectId)+'/masters'} {...props} />
// 	)
// });

function ProjectManagementSummary(props) {
	let classes = useStyles();

	const {
		basePath,
		projectId,
		project,
		resource,
		translate
	} = props;

	// if(typeof console === 'object') { console.log('ProjectManagementSummary.props',props); }

	const getMssterListsSummary= () => {

		let t = null;

		if(project.masterLists && project.masterLists.length) {
			if(project.masterLists.length === 1) {
				t = "1 " + translate('project.masterlist');
			} else {
				t = project.masterLists.length + " " + translate('project.masterlists');
			}
		}

		return t ? <span>{t}</span> : t;
	};

	return (
		<Grid
			container spacing={8} className={classes.container}
		>
			<Grid item xs={4}>
				<Link className={classes.masterListBox} component={RouterLink} to={basePath+'/'+encodeURIComponent(projectId)+'/masterlists'}>

					<Grid
						container component="span" spacing={0} className={classes.inlinecontainer}
					>
						<Grid component="span" item xs={4} className={classes.inlineitemicon}>
							<FontAwesomeIcon className={classes.icon} icon={faListAlt} />
						</Grid>
						<Grid component="span" item xs={8} className={classes.inlineitem}>
							<Typography component="span" className={classes.typoTitle} variant="h5">
								{translate('project.masterlist')}
							</Typography>
							<Typography component="span" className={classes.typo} variant="caption">
								{getMssterListsSummary()}
							</Typography>
						</Grid>
					</Grid>
				</Link>
			</Grid>
			<Grid item xs={4}>
				<ProjectManagementSummaryEvents
					projectId={projectId}
					classes={classes}
				/>
			</Grid>
			<Grid item xs={4}>
			</Grid>
		</Grid>
	);
};

ProjectManagementSummary.defaultProps = {

};

ProjectManagementSummary.propTypes = {
	// classes: PropTypes.object,
	// translate: PropTypes.func
};

export default withTranslate(ProjectManagementSummary);
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
