import React       from 'react';
import PropTypes   from 'prop-types';
import get         from 'lodash/get';
import {
	TextField,
	ReferenceArrayField,
	SingleFieldList,
	ChipField,
	// ReferenceManyField
}                  from 'react-admin';
import PhoneNumber from '../../../common/PhoneNumber';
import MVTSingleFieldList from "../../form/fields/MVTSingleFieldList";
import { makeStyles }        from '@material-ui/core';

// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';
/**
 *
 * MVTReferenceField
 *
 * config e.g.:
 * referenceTagProps: {basePath:'/locations',reference:'locations'},
 * tagProps: {source:'name'},
 *
 * @param props
 * @returns {*}
 * @constructor
 */


const useSingleListStyles = makeStyles(
	theme => ({
		root: {
			display: 'flex',
			flexWrap: 'wrap',
			flexDirection: 'column',
			marginTop: -theme.spacing(1),
			marginBottom: -theme.spacing(1),
		},
	}),
	{ name: 'RaSingleFieldList' }
);

const MVTReferenceArrayField = (props) => {

	let {
		value,
		//className,
		record,
		source,
		cellRenderer,
		column = {},
		columnIndex,
		rowIndex,
		reference,
		childSource,
		//...rest
	} = props;

	let singleListStyles = useSingleListStyles();

	/**
	 * Componets array to match components in tag name of column definition
	 * @type {{NumberColumn: NumberColumn, DateColumn: DateColumn, Column: Column}}
	 */
	let components = {
		TextField: TextField,
		ChipField: ChipField,
		ReferenceArrayField  : ReferenceArrayField,
		PhoneNumber: PhoneNumber,
		// Column      : Column
	};

	let listComponents = {
		MVTSingleFieldList: MVTSingleFieldList,
		SingleFieldList: SingleFieldList
	};

	if(!value) {
		value = get(record, source);
		if(value && typeof value !== 'string') {
			value = JSON.stringify(value);
		}
	}

	// if(typeof console === 'object') { console.log('MVTReferenceArrayField.props %o of %o with value %o',props,source,value); }

	if(typeof cellRenderer === 'function') {
		return cellRenderer(value,record,{column:column,columnIndex:columnIndex,rowIndex:rowIndex});
	}

	let { tag, referenceTag, listTag, tagProps, referenceTagProps, listTagProps } = column;
	const FieldTag = components[ tag || 'ChipField' ];
	const RefTag = components[ referenceTag || 'ReferenceArrayField' ];
	const ListTag = listComponents[ listTag || 'SingleFieldList' ];

	if(!tagProps) {
		tagProps = {
			source: childSource,
			key: childSource
		};
	}

	if(!referenceTagProps) {
		referenceTagProps = {
			basePath:'/' + reference.name,
			reference: reference.name
		};
	}

	if(!listTagProps) {
		listTagProps = {
			linkType: false
		};
	}

	switch(tag) {
		case'PhoneNumber':
			tagProps.isLinked = tagProps.isLinked || true;
			break;

	};

	// if(typeof console === 'object') { console.log('MVTReferenceArrayField.tagProps',tagProps); }

	return (
		<RefTag source={source} record={record} link={false} {...referenceTagProps}>
			{/*<FieldTag {...tagProps} />*/}
			<ListTag
				classes={singleListStyles}
				{...listTagProps}
			>
				<FieldTag size="small" {...tagProps} />
			</ListTag>
		</RefTag>
	);
};

MVTReferenceArrayField.defaultProps = {

};

MVTReferenceArrayField.propTypes = {
	className: PropTypes.object,
	record: PropTypes.object,
	source: PropTypes.string,
	// translate: PropTypes.func
};

MVTReferenceArrayField.displayName = 'MVTReferenceArrayField';
export default MVTReferenceArrayField;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
