import React                         from 'react';
import PropTypes                     from 'prop-types';
import get                           from 'lodash/get';

import {
	SelectInput,
	ReferenceInput,
	// AutocompleteInput
} from 'react-admin';
import getReferenceNameField         from '../../../../../api-platform-admin/src/getReferenceNameField';
// import AutocompleteInput from './ra-material-ui-tests/RA_AutocompleteInput';
// import AutocompleteInput from './MUIAutocomplete';
import AutocompleteInput from './MUIAutocompleteDownshift';

// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';
/**
 *
 * MVTReferenceField
 *
 * config e.g.:
 * referenceTagProps: {basePath:'/locations',reference:'locations'},
 * tagProps: {source:'name'},
 *
 * @param props
 * @returns {*}
 * @constructor
 */


const MVTReferenceInput = (props) => {

	// if(typeof console === 'object') { console.log('MVTReferenceInput',props); }

	let {
		refField,
		source,
		reference,
		tagProps,
		tag,
		referenceTag,
		referenceTagProps,
		...rest
	} = props;

	/**
	 * Componets array to match components in tag name of column definition
	 * @type {{NumberColumn: NumberColumn, DateColumn: DateColumn, Column: Column}}
	 */
	let components = {
		SelectInput: SelectInput,
		ReferenceInput  : ReferenceInput,
		AutocompleteInput: AutocompleteInput
	};

	const FieldTag = components[ tag || 'SelectInput' ];
	const RefTag = components[ referenceTag || 'ReferenceInput' ];
	if(!tagProps) {
		let refField = getReferenceNameField(props.reference);
		if(props.refField) {
			refField = props.refField;
		}

		tagProps = {
			optionText: refField,
			reference: reference.name
		};
	}

	if(!referenceTagProps) {
		referenceTagProps = {
			basePath:'/' + reference.name,
			reference: reference.name
		};
	}

	// if(typeof console === 'object') { console.log('MVTReferenceInput.rest',rest,typeof rest.label); }

	if(typeof rest.label === 'boolean') {
		rest.label = null;
	}

	// if(typeof console === 'object') { console.log('referenceTagPropsreferenceTagPropsreferenceTagProps',referenceTagProps); }

	return (
		<RefTag
			fullWidth={true}

			label={source}
			source={source}
			{...rest}
			allowEmpty

	        {...referenceTagProps}
		>
			<FieldTag {...tagProps} />
		</RefTag>
	);
};

MVTReferenceInput.defaultProps = {

};

MVTReferenceInput.propTypes = {
	className: PropTypes.any,
	record: PropTypes.object,
	source: PropTypes.string,
	// translate: PropTypes.func
};

MVTReferenceInput.displayName = 'MVTReferenceInput';
export default MVTReferenceInput;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
