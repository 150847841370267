import ServerAdapter from '../../src/store/adapter';
// import * as actions from '../../src/store/actions';

// const actions = {};

/**
 * ReduxStoreApi
 *
 * Abstract Class to set and get Models and Stores by PHPOSApi (do not call this Api directly)
 *
 *
 */
class ReduxStoreApi {

	static storeNamespace = 'PHPOS.store.';
	static modelNamespace = 'PHPOS.model.';
	static models = {};
	static stores = {};
	static baseStoreObj = {};

	static test() {
		// super();
		if(typeof console === 'object') { console.log('ReduxStoreApi-TEST',true); }
	}

	static getAdapter() {
		return ServerAdapter.getAdapter();
	}

	static getAction(g) {

		switch(g) {
			// case'request':
			// 	return actions.requestJson;
			// case'invalidate':
			// 	return actions.invalidateJson;
			default:
				return () => {}
		}


	}

	static setStore(store) {
		// if(typeof console === 'object') { console.log('ReduxStoreApi.bindedLocalStore',store); }
		this.store = store;
	}

	static getStore(options) {
		return this.store
	}

	/**
	 * Donno, if we really need this....
	 * @param options
	 * @returns {Promise<any>}
	 */
	static resolveStore(options) {

		return new Promise((resolve, reject) => {
			resolve(this.store);
		});
	}


}

export default ReduxStoreApi;
