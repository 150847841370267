import React, { useState, useEffect } from 'react';
// import PropTypes                           from 'prop-types';
import ObjectUtils from "../../../../../common/utils/ObjectUtils";
import { useFormState, useForm } from 'react-final-form';
// import FuncUtils from "../../../../../common/utils/FuncUtils";
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {GET_LIST, useMutation, useNotify, withTranslate} from 'react-admin';
import LoadingIndicator from "../../../../../common/components/common/LoadingIndicator";
// import request           from 'superagent';
// import config from '../../../../../src/host.config';
import ApiPlatformResources from "../../../../../api-platform-admin/src/api/ApiPlatformResources";
import DateUtils from "../../../../../common/utils/DateUtils";
// import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/People';
// import DoneIcon from '@material-ui/icons/Done';
// import CancelIcon from '@material-ui/icons/Block';
import Grid                                                      from '@material-ui/core/Grid';
import ShowAvailableResourcesChip from "./ShowAvailableResourcesChip";
// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	chipRoot: {
		display: 'flex',
		justifyContent: 'left',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
	gridroot: {
		// flexGrow: 1,
		margin: '3px 0 0 3px',
		padding: '0',
		justify: 'flex-start',
		justifyContent: 'space-between',
		maxHeight: '350px',
		overflowY: 'auto',
	},
	griditem: {
		padding: '0 !important',
		// marginRight: '20px',
		marginBottom: '10px',
		whiteSpace: 'nowrap',
	},
	occupied: {
		// backgroundColor: 'red',
		backgroundColor: theme.palette.backgroundColors.declineDark,
		'&:hover': {
			backgroundColor: theme.palette.backgroundColors.declineDarker,
		},
		'&:focus': {
			backgroundColor: theme.palette.backgroundColors.declineDark,
		},
	},
	occupiedIncluded: {
		// backgroundColor: 'red',
		backgroundColor: theme.palette.backgroundColors.declineDarker,
		'&:hover': {
			backgroundColor: theme.palette.backgroundColors.declineDarker,
		},
		'&:focus': {
			backgroundColor: theme.palette.backgroundColors.declineDarker,
		},
	},
	free: {
		// backgroundColor: 'green'
		backgroundColor: theme.palette.backgroundColors.confirmDark,
		'&:hover': {
			backgroundColor: theme.palette.backgroundColors.confirmDarker,
		},
		'&:focus': {
			backgroundColor: theme.palette.backgroundColors.confirmDark,
		},
	},
	freeIncluded: {
		// backgroundColor: 'green'
		backgroundColor: theme.palette.backgroundColors.confirmDarker,
		'&:hover': {
			backgroundColor: theme.palette.backgroundColors.confirmDarker,
		},
		'&:focus': {
			backgroundColor: theme.palette.backgroundColors.confirmDarker,
		},
	},
}));

/**
 *
 *
 * - editor freie mitarbeiter, freie workstations
 —> nur „permanant postworker“, wenn sie zeit haben sind sie grün, ansonsten rot
 —> auch für workstions
 —> bei roten, mit onmouseover auch die termine zeigen die das blockieren
 —> show available resources
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

// let completeData = {};

function ShowAvailableResources(props) {

	const { translate, resource } = props;

	const formState = useFormState();
	const form = useForm();
	const classes = useStyles();
	const notify = useNotify();
	const referenceOptions = ApiPlatformResources.getResourceOptions(resource);
	// if(typeof console === 'object') { console.log('ShowAvailableResources.props',props,referenceOptions); }
	// if(typeof console === 'object') { console.log('ShowAvailableResources.formState',formState,form); }

	const [date, setDate] = useState({});
	const [updateComposition, setUpdateComposition] = useState(0);
	const [data_h_f, setDataHF] = useState(null);
	const [data_h_o, setDataHO] = useState(null);
	const [data_t_f, setDataTF] = useState(null);
	const [data_t_o, setDataTO] = useState(null);
	const [expanded, setExpanded] = React.useState(false);
	const [isLoading, setIsLosding] = React.useState(false);

	const setData = (data,r,t) => {

		switch(r) {
			case'human_resources':
				if(t === 'free') {
					setDataHF(data);
				} else {
					setDataHO(data);
				}
				break;
			default:
				if(t === 'free') {
					setDataTF(data);
				} else {
					setDataTO(data);
				}
		}

	};

	useEffect(() => {

		if(formState && formState.values) {
			let a = {
				start: formState.values['start'],
				end: formState.values['end'],
				allDay: formState.values['allDay'],
			};

			let b = {
				start: date.start,
				end: date.end,
				allDay: date.allDay,
			};

			let isEqual = ObjectUtils.isEqual(a,b);

			if(!isEqual) {
				// if(typeof console === 'object') { console.log('IS NOT isEqual',a,b); }
				setDate(a);
			}

		}
	},[formState]);

	useEffect(() => {

		if(date && date.start && expanded) {
			// if(typeof console === 'object') { console.log('ShowAvailableResources.EFFECT.state',formState.values['start'],formState.values['end'],date); }
			// d_loadAvailResources();
			loadAvailResources();
		}

	},[date,expanded]);

	const getFilter = (event) => {

		return {
			pagination: {
				page: 1,
				perPage: 100
			},
			sort: {
				// field: sortField, order: 'ASC'
			},
			filter: event.payload,
			appParams: {noCache: true},
			// groups: ['related:read']
		};
	};

	let [ mutate ] = useMutation();
	const getResources = event => mutate(
		{
			type: GET_LIST,
			resource: event.reference,
			payload: getFilter(event),
		},
		{
			// action: 'CUSTOM_FETCH_LOAD',
			onSuccess: ({ data }) => {
				// if(typeof console === 'object') { console.log('getOptions.data',event,data); }

				if(!data) {
					data = [];
				}

				setIsLosding(false);
				setData(data,event.reference,event.payload.booking_status);
				return data;

			},
			onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
		}
	);

	const loadAvailResources = () => {
		if(date && date.start && expanded) {
			// if(typeof console === 'object') { console.log('ShowAvailableResources.loadAvailResources.formState',formState.values['start'],formState.values['end']); }
			// if(typeof console === 'object') { console.log('ShowAvailableResources.loadAvailResources.state',formState.values['start'],formState.values['end'],date); }
			if(!data_h_f) {
				setIsLosding(true);
			}

			let resources = [
				{
					reference:'human_resources',
					booking_status: 'free'
				},
				{
					reference:'human_resources',
					booking_status: 'occupied'
				},
				{
					reference:'technical_resources',
					booking_status: 'free'
				},
				{
					reference:'technical_resources',
					booking_status: 'occupied'
				}
			];


			let end = getEndDate();

			resources.forEach((r) => {
				getResources(
					{
						reference:r.reference,
						payload: {
							fromDate: DateUtils.formatIso(date.start),
							toDate: end,
							booking_status: r.booking_status,
						}
					}
				);
			});
		}
	};

	const getEndDate = () => {
		let end;
		if(!date.end) {
			let start = DateUtils.formatIso(date.start);
			if(date.allDay) {
				end = DateUtils.add(start,1,'days');
			} else {
				end = DateUtils.add(start,4,'hours');
			}
			end = DateUtils.formatIso(end);
		} else {
			end = DateUtils.formatIso(date.end);
		}

		return end;
	};

	// const d_loadAvailResources = FuncUtils.debounce(loadAvailResources, 500);

	const handleChange = () => (event, isExpanded) => {
		setExpanded(isExpanded ? true : false);
	};

	const handleAdd = (d,status,type) => {

		let u, v;

		switch(type) {
			case'technical_resources':
				u = 'technicalResources';
				v = d['id'];
				break;
			case'human_resources':
			default:
				u = 'peopleResources';
				v = d['person'];
				break;
		}

		let state = form.getState();
		let vs = state.values[u];
		if(!vs) {
			vs = [];
		}
		let isIncluded = ObjectUtils.inArray(vs,v);
		if(!isIncluded) {
			vs.push(v);
			form.change( u, vs );
			referenceOptions.configFactory.conf.addResource(u, vs);
			let up = updateComposition + 1;
			setUpdateComposition(up);
		}
	};

	const showHumanResources = (data,status,type) => {

		let r = [];
		let state = form.getState();
		let values = state.values;
		let end = getEndDate();
		let start = DateUtils.formatIso(date.start);

		data.forEach((d) => {

			// if(typeof console === 'object') { console.log('showHumanResources',values['technicalResources'],d); }

			let isIncluded;

			switch(type) {
				case'technical_resources':
					isIncluded = ObjectUtils.inArray(values['technicalResources'],d['id']);
					break;
				case'human_resources':
				default:
					isIncluded = ObjectUtils.inArray(values['peopleResources'],d['person']);
					break;
			}

			r.push(<ShowAvailableResourcesChip
				key={d['@id']}
				translate={translate}
				size="small"
				icon={<FaceIcon />}
				className={isIncluded ? classes[status+'Included'] : classes[status]}
				label={d.name}
				// disabled={type === 'occupied' ? true : false}
				clickable={true}
				onClick={ () => { handleAdd(d,status,type); } }
				status={status}
				type={type}
				resourceData={d}
				fromDate={ start }
				toDate={ end }
				// toDate={date.end ? DateUtils.formatIso(date.end) : ''}
				// color="primary"
				// onDelete={() => {
				// 	handleAdd(d);
				// }}
				// deleteIcon={type === 'occupied' ? <CancelIcon /> : <DoneIcon />}
			/>);

			// r.push(
			// 	<Chip
			// 		key={d['@id']}
			// 		size="small"
			// 		icon={<FaceIcon />}
			// 		className={isIncluded ? classes[type+'Included'] : classes[type]}
			// 		label={d.name}
			// 		// disabled={type === 'occupied' ? true : false}
			// 		clickable={true}
			// 		onClick={ () => { handleAdd(d,type); } }
			// 		// color="primary"
			// 		// onDelete={() => {
			// 		// 	handleAdd(d);
			// 		// }}
			// 		// deleteIcon={type === 'occupied' ? <CancelIcon /> : <DoneIcon />}
			// 	/>
			// );
		});

		if(r && r.length >= 0) {
			return r;
		}

		return null;
	};

	return (
		<div className={classes.root}>
			<ExpansionPanel expanded={expanded} onChange={handleChange()}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="ShowAvailableResources-content"
					id="ShowAvailableResources-header"
				>
					<Typography className={classes.heading}>
						{translate('fields.show_available_resources')}
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					{isLoading && <LoadingIndicator type="container" />}
					<Grid
						container
						spacing={2}
						direction="row"
						className={classes.gridroot}
					>
						<Grid item className={classes.griditem} xs={8}>
							{data_h_f &&
							<div className={classes.chipRoot}>{showHumanResources(data_h_f,'free','human_resources')}</div>
							}
							{data_h_o &&
							<div className={classes.chipRoot}>{showHumanResources(data_h_o,'occupied','human_resources')}</div>
							}
						</Grid>
						<Grid item className={classes.griditem} xs={4}>
							{data_t_f &&
							<div className={classes.chipRoot}>{showHumanResources(data_t_f,'free','technical_resources')}</div>
							}
							{data_t_o &&
							<div className={classes.chipRoot}>{showHumanResources(data_t_o,'occupied','technical_resources')}</div>
							}
						</Grid>
					</Grid>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</div>
	);
};

ShowAvailableResources.defaultProps = {

};

ShowAvailableResources.propTypes = {
	// classes: PropTypes.object,
	// translate: PropTypes.func
};

function areEqual(prevProps, nextProps) {

	let a = {
		// record: prevProps.record,
		start: prevProps.record ? prevProps.record.start : null,
		end: prevProps.record ? prevProps.record.end : null,
	};
	let b = {
		// record: nextProps.record,
		start: nextProps.record ? nextProps.record.start : null,
		end: nextProps.record ? nextProps.record.end : null,
	};

	let isEqualA = ObjectUtils.fastDeepEqual(a,b);
	if(isEqualA) {
		// if(typeof console === 'object') { console.log('areEqual',prevProps, nextProps); }
		// if(typeof console === 'object') { console.log('this.props.isEqualA',isEqualA,a,b); }
		return true;
	}

	/*
	 return true if passing nextProps to render would return
	 the same result as passing prevProps to render,
	 otherwise return false
	 */
}

export default React.memo(withTranslate(ShowAvailableResources),areEqual);
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
