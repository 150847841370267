import React, { useState, useEffect, useContext, useRef } from "react";
import ReactDOM from "react-dom";
import { NavLink, Link } from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import classNames from 'classnames';
// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import Link from '@material-ui/core/Link';
// import styled from "styled-components";



/**
 * https://jjenzz.com/smarter-dumb-breadcrumb
 *
 * https://jsmanifest.com/build-a-stunning-breadcrumb-component-in-react-with-plain-css/
 *
 * @type {React.Context<unknown>}
 */

let useStyles = makeStyles(function (theme) {
    return (
        {
            ol: {
                margin: '0 0 0 10px',
                paddingLeft: '0',
                listStyle: 'none',
            },
            li: {
                display: 'inline',
                '&::before': {
                    content: '""',
                    display: 'inline-block',
                    transform: 'rotate(15deg)',
                    borderRight: '1px solid currentColor',
                    height: '1em',
                    margin: '0 8px -0.2em',
                    // color: theme.palette.textColors.inactiveLink,
                    color: theme.palette.textColors.default,
                    paddingRight: theme.spacing(1),
                },
                '&:first-child': {
                    '&::before': {
                        content: '""',
                        display: 'none',
                    }
                },
                "& svg": {
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                }
            },
            'li:firstChild': {
                display: 'block',
                color: 'red',
                '&::before': {
                    content: '""',
                    display: 'none',
                },

            },
            link: {
                // color: theme.palette.textColors.inactiveLink,
                color: theme.palette.textColors.default,
                textDecoration: 'none',
                borderBottom: '1px solid transparent',

               '&:hover': {
                   color: theme.palette.textColors.default,
                    // borderColor: theme.palette.textColors.default,
                },

                '&.active': {
                    border: 'none',
                    // color:  theme.palette.textColors.default,
                }
            }
        }
    );
});

const Context = React.createContext();

const useBreadcrumbContext = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error("Missing BreadcrumbProvider.");
    }

    return context;
};

const BreadcrumbProvider = ({ children }) => {
    const portalNodeState = useState();

    return (
        <Context.Provider value={portalNodeState}>{children}</Context.Provider>
    );
};

const BreadcrumbPortal = ({ children }) => {
    const portalNodeRef = useRef();
    const [, setPortalNode] = useBreadcrumbContext();
    const classes = useStyles();

    useEffect(() => {
        setPortalNode(portalNodeRef.current);
    }, [setPortalNode]);

    return (
        <nav aria-label="breadcrumb">
            <ol ref={portalNodeRef} className={classes.ol} />
        </nav>
    );
};

// const Items = styled.ol`
//   margin: 0;
//   padding-left: 0;
//   list-style: none;
// `;

const Breadcrumb = ({ children, to, icon, isActive = false, listProps }) => {
    const [portalNode] = useBreadcrumbContext();
    const classes = useStyles();
    // if(typeof console === 'object') { console.log('portalNode',portalNode); }
    return portalNode
        ? ReactDOM.createPortal(
            <li {...listProps} className={classes.li}>
                {1 === 2 && isActive ?
                    <NavLink className={classes.link} to={to}>
                        {icon && icon}
                        {children}
                    </NavLink>
                    :
                    <Link
                        className={isActive ? classNames(classes.link, 'active') : classes.link}
                        to={to}
                    >
                        {icon && icon}
                        {children}
                    </Link>
                }
            </li>,
            portalNode
        )
        : null;
};

// const Item = styled.li`
//   display: inline;
//
//   & + &::before {
//     content: "";
//     display: inline-block;
//     transform: rotate(15deg);
//     border-right: 1px solid currentColor;
//     height: 1em;
//     margin: 0 8px -0.2em;
//   }
// `;

// const ItemLink = styled(NavLink).attrs({ exact: true })`
//   color: #36d;
//   text-decoration: none;
//   border-bottom: 1px solid transparent;
//
//   &:hover {
//     border-color: currentColor;
//   }
//
//   &.active {
//     border: none;
//     color: inherit;
//   }
// `;

export { BreadcrumbProvider, BreadcrumbPortal, Breadcrumb };
