import React, {
    Fragment,
    isValidElement,
    cloneElement,
    createElement,
    useState,
    useEffect,
    useCallback,
    memo,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TableCell, TableRow, Checkbox } from '@material-ui/core';
import { linkToRecord } from 'ra-core';
import isEqual from 'lodash/isEqual';

// import { DatagridCell } from 'react-admin';
import DatagridCell, {PureDatagridCell} from './MyDatagridCell';
import ExpandRowButton from './ExpandRowButton';
import { useHistory } from 'react-router-dom';
import ObjectUtils from "../../../utils/ObjectUtils";
import DomUtils from "../../../utils/DomUtils";
import memoize                from "memoize-one";

// import DatagridCell from './DatagridCell';
// import ExpandRowButton from './ExpandRowButton';

const computeNbColumns = (expand, groupIds, children, hasBulkActions) => {

    let nC = 0;

    if(expand || groupIds) {
        if(expand) {
            nC++;
        }
        if(hasBulkActions) {
            nC++;
        }

        nC = nC + React.Children.toArray(children).filter(child => !!child).length;
    }

    return nC;

    // return expand || groupIds
    //     ? 1 + // show expand button
    //     (hasBulkActions ? 1 : 0) + // checkbox column
    //     React.Children.toArray(children).filter(child => !!child).length // non-null children
    //     : 0; // we don't need to compute columns if there is no expand panel;

};

const defaultClasses = {};

const DatagridRow = ({
                         basePath,
                         children,
                         classes = defaultClasses,
                         className,
                         expand,
                         hasBulkActions,
                         hover,
                         id,
                         onToggleItem,
                         record,
                         resource,
                         rowClick,
                         selected,
                         style,
                         selectable,

                         // grouping
                         groupIds,
                         groupedIds,
                         ...rest
                     }) => {

    // if(typeof console === 'object') { console.log('Dategridrow',resource,rest); }

    const [expanded, setExpanded] = useState(false);

    const [nbColumns, setNbColumns] = useState(
        computeNbColumns(expand, groupIds, children, hasBulkActions)
    );

    useEffect(() => {
        // Fields can be hidden dynamically based on permissions;
        // The expand panel must span over the remaining columns
        // So we must recompute the number of columns to span on
        const newNbColumns = computeNbColumns(expand, groupIds, children, hasBulkActions);
        if (newNbColumns !== nbColumns) {
            setNbColumns(newNbColumns);
        }
    }, [expand, groupIds, nbColumns, children, hasBulkActions]);

    const history = useHistory();

    const handleToggleExpand = useCallback(
        event => {
            setExpanded(!expanded);
            event.stopPropagation();
        },
        [expanded]
    );

    const handleToggleSelection = useCallback(
        event => {

            // if(typeof console === 'object') { console.log('handleToggleSelection',event,rowClick); }

            onToggleItem(id);
            event.stopPropagation();
        },
        [id, onToggleItem]
    );

    /**
     * It's a common pitfall in React to assume events bubble up according to the DOM
     * but they actually bubble through portals.
     * See facebook/react#11387 for the relevant discussion about this.
     * @param event
     */
    const stopRowClickPropagation = (event) => {
        // if(typeof console === 'object') { console.log('handleClick',event,rowClick); }
        let t = false;
        if(DomUtils.findUpClassName(event.target, 'MuiDialog-root')) {
         t = true;
        }

        return t;
    };

    const handleClick = useCallback(
        async event => {

            if (stopRowClickPropagation(event)) return;
            if (!rowClick) return;
            event.persist();

            const effect =
                typeof rowClick === 'function'
                    ? await rowClick(id, basePath, record)
                    : rowClick;
            switch (effect) {
                case 'edit':
                    history.push(linkToRecord(basePath, id));
                    return;
                case 'show':
                    history.push(linkToRecord(basePath, id, 'show'));
                    return;
                case 'expand':
                    handleToggleExpand(event);
                    return;
                case 'toggleSelection':
                    handleToggleSelection(event);
                    return;
                default:
                    if (effect) history.push(effect);
                    return;
            }
        },
        [
            basePath,
            history,
            handleToggleExpand,
            handleToggleSelection,
            id,
            record,
            rowClick,
        ]
    );

    const recordIsGrouped = () => {
        let isGrouped = ObjectUtils.findRecord(groupIds,{id: record.id});
        return isGrouped;
    };

    const findGroupField = (r,g) => {

        let groupField = g.groupBy;
        let f = null;

        React.Children.toArray(children).forEach((child) => {
            if(child && child.props && child.props.source === groupField) {

                if(isValidElement(child)) {
                    let ff = null;
                    let field = ObjectUtils.clone(child.props.field);
                    // this is tricky... if the child is an InlineEditorField,
                    // we are recreating it
                    let options = child.props.options;
                    // if(typeof console === 'object') { console.log('findGroupField',field,child); }
                    if(field && field.InlineEditorField && options && options.fieldFactory) {
                        field.InlineEditorField = false;
                        ff = options.fieldFactory( field, options );
                    } else {
                        ff = child;
                    }

                    f =  <TableCell colSpan={nbColumns}>{
                        isValidElement(ff)
                            ? cloneElement(ff, {
                                record,
                                basePath,
                                resource,
                            })
                            : createElement(ff, {
                                record,
                                basePath,
                                resource,
                            })
                    }</TableCell>;
                }
            }
        });

        return f;
    };

    let isGrouped = null;
    if(groupIds && groupIds.length > 0 && recordIsGrouped()) {
        isGrouped = recordIsGrouped();
    }

    const memoFieldFactory = memoize(
        (field,basePath,record,resource,index,classes) => {
            // if(typeof console === 'object') { console.log('memoFieldFactory',field,index,resource); }
            return (
                isValidElement(field) ? (
                    <DatagridCell
                        key={`${id}-${field.props.source || index}`}
                        className={classnames(
                            `column-${field.props.source}`,
                            classes.rowCell
                        )}
                        record={record}
                        {...{field, basePath, resource}}
                    />
                ) : null
            );
        }
    );

    return (
        <Fragment>
            {isGrouped && (
                <TableRow className={classes.rowGroupCell} key={`${id}-group-${isGrouped.groupBy}`} id={`${id}-group-${isGrouped.groupBy}`}>
                    {findGroupField(record,isGrouped)}
                </TableRow>
            )}
            <TableRow
                className={className}
                key={id}
                style={style}
                hover={hover}
                onClick={handleClick}
                {...rest}
            >
                {expand && (
                    <TableCell
                        padding="none"
                        className={classes.expandIconCell}
                    >
                        <ExpandRowButton
                            classes={classes}
                            expanded={expanded}
                            onClick={handleToggleExpand}
                            expandContentId={`${id}-expand`}
                        />
                    </TableCell>
                )}
                {hasBulkActions && (
                    <TableCell padding="checkbox">
                        {selectable && (
                            <Checkbox
                                // color="primary"
                                className={`select-item ${classes.checkbox}`}
                                checked={selected}
                                onClick={handleToggleSelection}
                            />
                        )}
                    </TableCell>
                )}
                {React.Children.map(children, (field, index) => {
                        // if(typeof console === 'object') { console.log('field, index',index); }
                        // return (<td>huhu</td>);

                        // return memoFieldFactory(field,basePath,record,resource,index,classes);

                        return (
                            isValidElement(field) ? (
                                <DatagridCell
                                    key={`${id}-${field.props.source || index}`}
                                    className={classnames(
                                        `column-${field.props.source}`,
                                        classes.rowCell
                                    )}
                                    record={record}
                                   {...{field, basePath, resource}}
                                />
                            ) : null
                        );

                    }
                )}
            </TableRow>
            {expand && expanded && (
                <TableRow key={`${id}-expand`} id={`${id}-expand`}>
                    <TableCell colSpan={nbColumns}>
                        {isValidElement(expand)
                            ? cloneElement(expand, {
                                record,
                                basePath,
                                resource,
                                id: String(id),
                            })
                            : createElement(expand, {
                                record,
                                basePath,
                                resource,
                                id: String(id),
                            })}
                    </TableCell>
                </TableRow>
            )}
        </Fragment>
    );
};

DatagridRow.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
    hasBulkActions: PropTypes.bool.isRequired,
    hover: PropTypes.bool,
    id: PropTypes.any,
    onToggleItem: PropTypes.func,
    record: PropTypes.object.isRequired,
    resource: PropTypes.string,
    rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    selected: PropTypes.bool,
    style: PropTypes.object,
    selectable: PropTypes.bool,
};

DatagridRow.defaultProps = {
    hasBulkActions: false,
    hover: true,
    record: {},
    selected: false,
    selectable: true,
};

const areEqual = (prevProps, nextProps) => {
    const { children: _, ...prevPropsWithoutChildren } = prevProps;
    const { children: __, ...nextPropsWithoutChildren } = nextProps;
    let isEq = isEqual(prevPropsWithoutChildren, nextPropsWithoutChildren);

    // let a = {
    //     id: prevProps.id,
    //     record: prevProps.record,
    //     groupids: prevProps.groupids,
    //     selected: prevProps.selected,
    //     expand: prevProps.selected,
    // };
    // let b = {
    //     id: nextProps.id,
    //     record: nextProps.record,
    //     groupids: nextProps.groupids,
    //     selected: nextProps.selected,
    //     expand: nextProps.selected,
    // };
    //
    // let isEqualA = ObjectUtils.isEqual(a,b);
    // if(isEqualA && isEq) {
    //     if(typeof console === 'object') { console.log('LocalListMemo.areEqual',prevProps, nextProps); }
    //     // if(typeof console === 'object') { console.log('this.props.isEqualA',isEqualA,a,b); }
    //     return true;
    // } else {
    //     // if(typeof console === 'object') { console.log('this.props.NOT isEqualA',isEqualA,a,b); }
    //     if(typeof console === 'object') { console.log('LocalListMemo NOT EQUAL',prevProps, nextProps); }
    // }


    return isEq;
};

export const PureDatagridRow = memo(DatagridRow, areEqual);

PureDatagridRow.displayName = 'PureDatagridRow';

export default DatagridRow;
