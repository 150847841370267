// import React             from 'react';
import React                       from 'react';
import LocalApi                    from '../LocalApi';
import { useTranslate }            from 'react-admin';
import { minLength, required }     from 'react-admin';
import useGridStyles               from '../../../common/components/helpers/useGridStyles';
import classNames                  from 'classnames';
// import MVTReferenceEditorInput     from '../../../common/components/react-admin/form/fields/MVTReferenceEditorInput';
// import SupervisedUserCircleRounded from '@material-ui/core/SvgIcon/SvgIcon';
/**
 * cld_
 *
 *
 *
 *
 *
 *
 */

class People extends LocalApi {

	static contentKey = 'people';
	static sorting = { field: 'familyName', order: 'ASC' };
	// static hasEdit = false;
	// static hasShow = false;

	static getFormSettings() {

		const translate = useTranslate();

		return {
			tabbedForm: [{
				label: translate('fields.name'),
			},{
				label: translate('fields.contact'),
			}]
		};

	}


	static getFormFields(formFields,map = 'formFields') {
		// if(typeof console === 'object') { console.log('getFormFields',formFields); }
		let f = [];
		const translate = useTranslate();

		let fields = {
			honorificPrefix: {
				col: 0,
				inputProps: {
					label: translate('fields.honorificPrefix'),
					autoFocus: true,
				}
			},
			givenName: {
				col: 0,
				inputProps: {
					label: translate('fields.givenName'),
				}
			},
			familyName: {
				MVTInputField: true,
				col: 0,
				inputProps: {
					label: translate('fields.familyName'),
					validate:  [required(), minLength(2)]
				}
			},
			fullName: null,
			shortName: null,
			birthDate: {
				col: 0,
				inputProps: {
					// fieldType: 'date',
					settings: {
						fieldType: 'date'
					},
					label: translate('fields.birthdate'),
				}
			},
			gender: {
				col: 1,
				MVTSelectField: true,
				inputProps: {
					label: translate('fields.gender'),
					choices: [
						{
							id: 'M',
							name: translate('fields.male'),
						},
						{
							id: 'F',
							name: translate('fields.female'),
						}
					]
				}
			},
			jobTitle: {
				col: 1,
				inputProps: {
					label: translate('fields.jobTitle'),
				}
			},
			homepage: {
				col: 1,
				inputProps: {
					label: translate('fields.homepage'),
				}
			},
			isEmployee: {
				col: 1,
				inputProps: {
					label: translate('fields.isEmployee'),
				}
			},
			isSupplier: {
				col: 1,
				inputProps: {
					label: translate('fields.isSupplier'),
				}
			},
			humanResources: null,
			name: null,
			notes: {
				col: 0,
				formTab: 2,
				inputProps: {
					label    : translate('fields.notes'),
					multiline: true,
				}
			},
			email: {
				col: 0,
				formTab: 2,
				inputProps: {
					label: translate('fields.email'),
				}
			},
			companies: {
				col: 0,
				formTab: 2,
				MVTReferenceArrayInput: true,
				inputProps: {
					tag: 'AutocompleteArrayInput',
					label    : translate('fields.companies'),
					tagProps: {
						optionText: 'name',
						reference: 'companies',
						loadResource: true,
						createButton: true,
					},
					referenceTagProps: {
						basePath: '/companies',
						reference: 'companies'
					}
				}
			},
			// numbers: {
			// 	col: 1,
			// 	JSX: <MVTReferenceEditorInput
			// 		title={translate('resources.contact_numbers.name')}
			// 		icon={<SupervisedUserCircleRounded />}
			// 		filterField="contact"
			// 		// location={props.location}
			// 		// resource={props.resource}
			// 		reference="contact_numbers"
			// 		// basePath={props.basePath}
			// 		// filter={{project: projectId}}
			// 		// initialValues={{project: projectId}}
			// 		// referenceId={projectId}
			// 		// cardStyle={{minWidth: 400}}
			// 		// editor="drawer"
			// 	/>
			// },
			numbers: this.getNumbersInput(),
			addresses: this.getAddressesInput(),
			user: null,
			// numbers: null,

			// title: {
			// 	MVTInputField: true,
			// 	inputProps: {
			// 		label: translate('fields.label'),
			// 		validate:  [required(), minLength(2)]
			// 	}
			// },
		};

		if(map === 'formFields') {
			f = this.mapFields(formFields,fields);
		}

		// if(typeof console === 'object') { console.log('formFields, fields,f',formFields,fields,f); }

		return f;
	}

	static validateForm(values) {
		// if(typeof console === 'object') { console.log('validateForm with values',values); }
		const errors = {
			// title: ['myroot.validation.required']
		};
		return errors;
	}

	static getShowFields(showfields,map = 'showfields') {


		// const translate = useTranslate();
		// const classes = useGridStyles();

		let fields = this.getGridColumns(showfields);

		// if(typeof console === 'object') { console.log('getShowFields',fields); }

		let df = this.matchGridToShowFields(fields);

		return df;

		// return this.getGridColumns(fields);
	}

	static getGridPermanentFilter( permanentFilter ) {
		return permanentFilter;
	}

	static getGridFilterDefaults( filterDefaults ) {
		return {
			// title: '999',
			// description: 'super'
		};
	}

	static getGridFilter( ) {
		return [];
	}


	static getGridColumns(listFields,map = 'listfields') {
		// if(typeof console === 'object') { console.log('getGridColumns',listFields); }

		const translate = useTranslate();
		const classes = useGridStyles();

		let fields = {
			honorificPrefix: {
				// isSortable: true,
				// FunctionField: true,
				fieldProps: {
					width: 60,
					label    : translate('fields.honorificPrefix'),
					// cellClassName: classes.w60,
					// cellAndHeaderClassName: classes.breakpointDownXL,
					cellAndHeaderClassName: classNames(classes.w60,classes.breakpointDownLG),
				}
			},
			givenName: {
				// isSortable: true,
				fieldProps: {
					label    : translate('fields.givenName'),
				}
			},
			familyName: {
				// isSortable: true,
				fieldProps: {
					label    : translate('fields.familyName'),
				}
			},
			shortName: {
				// isSortable: true,
				fieldProps: {
					label    : translate('fields.shortName'),
					cellAndHeaderClassName: classes.breakpointDownXL,
				}
			},
			jobTitle: {
				// isSortable: true,
				fieldProps: {
					label    : translate('fields.jobTitle'),
				}
			},
			email: {
				// isSortable: true,
				fieldProps: {
					label    : translate('fields.email'),
					cellAndHeaderClassName: classNames(classes.maxw200,classes.breakpointDownMD),
					schemaId: 'http://schema.org/email'
				}
			},
			homepage: {
				fieldProps: {
					label    : translate('fields.homepage'),
					schemaId: 'http://schema.org/external-url',
					cellAndHeaderClassName: classNames(classes.breakpointDownLG),
				}
			},
			numbers: this.getNumbersListField(),
			fullName: null,
			isEmployee: {
				fieldProps: {
					label    : translate('fields.isEmployee'),
					cellAndHeaderClassName: classNames(classes.w60,classes.breakpointDownSM),
				}
			},
			isSupplier: {
				fieldProps: {
					label    : translate('fields.isSupplier'),
					cellAndHeaderClassName: classNames(classes.w60,classes.breakpointDownSM),
				}
			},
			user:  {
				// MVTReferenceField: true,
				ReferenceFieldEditor: true,
				fieldProps: {
					label    : translate('fields.user'),
					cellAndHeaderClassName: classNames(classes.maxw200,classes.breakpointDownLG),
				}
			},
			addresses: null,
			notes: null,
			name: null,
			humanResources: null,
			companies: {
				// MVTReferenceField: true,
				ReferenceFieldEditor: true,
				fieldProps: {
					label    : translate('fields.companies'),
					cellAndHeaderClassName: classNames(classes.maxw200,classes.breakpointDownLG),
				}
			},
			birthDate: null,
			gender: null,
		};

		let df = [];
		if(map === 'listfields') {
			df = this.mapFields(listFields,fields);
		}

		return df;
	}

}

export default People;
