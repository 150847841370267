import React                    from 'react';
import LocalApi                 from '../LocalApi';
import { useTranslate }         from 'react-admin';
import { minLength, required }  from 'react-admin';
import Avatar                   from '@material-ui/core/Avatar';
import UserAvatar               from '../../components/user/UserAvatar';
import ListItemAvatar           from '@material-ui/core/ListItemAvatar';
import { Typography }           from '@material-ui/core';
import MVTReferenceField        from '../../../common/components/react-admin/grid/fields/MVTReferenceField';
import MuiButton                from '../../../common/components/common/MuiButton';
import { faPen, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

/**
 * cld_
 *
 *
 *
 *
 *
 *
 */

class EventParticipations extends LocalApi {

	static contentKey = 'event_participations';
	static sorting = { field: 'name', order: 'ASC' };
	// static hasEdit = false;
	// static hasShow = false;


	static getFormFields(formFields,map = 'formFields') {
		// if(typeof console === 'object') { console.log('getFormFields',formFields); }
		let f = [];
		const translate = useTranslate();

		let fields = {
			rejectionReason: null,
			proposedDate: null,
			calendarEvent: null,
			person: {
				AutocompleteInput: true,
				inputProps: {
					loadResource: true,
					label:null,
					placeholder:translate('resources.people.single_name'),
					reference:"people",
					titleField: 'name',
					optionText: 'name',
					createButton: true,
					// source:"project"

				}
			},
			email: {
				inputProps: {
					label: null,
					placeholder:translate('fields.email'),
				}

			},
			// person: null,
		};

		if(map === 'formFields') {
			f = this.mapFields(formFields,fields);
		}

		return f;
	}

	static validateForm(values) {
		// if(typeof console === 'object') { console.log('validateForm with values',values); }
		const errors = {
			// title: ['myroot.validation.required']
		};
		return errors;
	}

	static getGridPermanentFilter( permanentFilter ) {
		return permanentFilter;
	}

	static getGridFilterDefaults( filterDefaults ) {
		return {
			// title: '999',
			// description: 'super'
		};
	}

	static getGridFilter( ) {
		return [];
	}

	static getGridColumns(listFields,map = 'listfields') {
		// if(typeof console === 'object') { console.log('getGridColumns',listFields); }

		// map fields
		// let lf = [];
		// const translate = useTranslate();

		let fields = {};

		let df = [];
		if(map === 'listfields') {
			df = this.mapFields(listFields,fields);
		}

		return df;
	}

	static getListAvatar(record) {
		return <ListItemAvatar>
			<Avatar>
				<UserAvatar />
			</Avatar>
		</ListItemAvatar>;
	}

	static getListPrimary(record) {
		return (
			<Typography variant="h6">
				{/*{record['name']}*/}
				{/*<MVTReferenceField*/}
				{/*	record={record}*/}
				{/*	childSource="fullName"*/}
				{/*	// basePath={basePath}*/}
				{/*	source="contact"*/}
				{/*	// reference="project_roles"*/}
				{/*	column={{name:'contact'}}*/}
				{/*	reference={{name:'contacts'}}*/}
				{/*	maxCardinality={1}*/}
				{/*/>*/}
			</Typography>);
	}

	static getListSecondary(record) {
		return (
			<React.Fragment>
				{/*<MVTReferenceField*/}
				{/*	record={record}*/}
				{/*	childSource="title"*/}
				{/*	// basePath={basePath}*/}
				{/*	source="projectRole"*/}
				{/*	// reference="project_roles"*/}
				{/*	column={{name:'projectRole'}}*/}
				{/*	reference={{name:'project_roles'}}*/}
				{/*	maxCardinality={1}*/}
				{/*/>*/}
				{1===2 &&<Typography
					component="span"
					variant="body2"
					// className={classes.inline}
					color="textPrimary"
				>
					{record['projectRole']}
					{/*<ReferenceField record={record} source="user" reference="users">*/}
					{/*	<TextField source="username" />*/}
					{/*</ReferenceField>*/}
				</Typography>}
			</React.Fragment>
		);
	}

}

export default EventParticipations;
