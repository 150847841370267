import MuiModal from '../modal';

/**
 * Just a wrapper in case we ant to change message handling
 *
 *
 */



class Msg {

	static show( title, msg, o = {} ) {
		let defaults = {};
		o = Object.assign(defaults,{title: title, msg: msg},o);
		this.MuiModal.show(o);
	};

	static prompt( title, msg, callback ) {
		// Ext.Msg.prompt( title, msg, callback );
	};

	static confirm( title, msg, callback, o = {} ) {
		// Ext.Msg.confirm( title, msg, callback );
		let defaults = {};
		o = Object.assign(defaults,{title: title, msg: msg, callback: callback},o);
		this.MuiModal.confirm(o);
	};

	static alert( title, msg, o = {} ) {
		let defaults = {};
		o = Object.assign(defaults,{title: title,msg:msg},o);
		this.MuiModal.alert(o);
	};

	/**
	 * Msg.toast({title:'Hello World',message:'Welcome to the new world',level:'info'})
	 * @param o
	 */
	static toast( o ) {

		let system = 'reactTostify';

		let defaults = {
			level: 'info',
			autoDismiss: 4,
		};

		o = Object.assign(defaults,o);

		if(system === 'reactTostify' && this.ReactToastify) {

			const options = {
				// onOpen: props => console.log(props.foo),
				// onClose: props => console.log(props.foo),
				// closeButton: <FontAwesomeCloseButton />,
				autoClose: 6000,
				// autoClose: false,
				type: this.ReactToastify.TYPE.INFO,
				hideProgressBar: false,
				pauseOnHover: true,
				// position: this.ReactToastify.POSITION.TOP_LEFT,
				// transition: MyCustomTransition,
				// and so on ...
			};

			switch(o.level) {
				case'error':
					options.type = this.ReactToastify.TYPE.ERROR;
					// options.autoClose = false;
					// options.autoDismiss = false;
					break;
				case'info':
				case'success':
					options.type = this.ReactToastify.TYPE.SUCCESS;
					break;
				default:
				case'warn':
				case'warning':
					options.type = this.ReactToastify.TYPE.WARN;
					break;
			}

			o = Object.assign(options,o);

			// let message = <span>{o.message}</span>;

			this.ReactToastify(o.message,o);
		} else if(this.NotificationsSystem){
			this.NotificationsSystem.addNotification( o );
		}
	}

	static error( msg ) {
		throw new Error( msg );
	}

	/**
	 * Static connection to the "ReactToastify" in
	 * AppBundle/Resources/app/calm/app/containers/ModalComponents.js
	 * @Link https://github.com/fkhadra/react-toastify#api
	 * @param n
	 */
	static connectReactToastify(n) {
		this.ReactToastify = n;
		Msg.ReactToastify = n;
	}

	static connectMuiModal(n) {
		this.MuiModal = n;
		Msg.MuiModal = n;
	}
};

export default Msg;
