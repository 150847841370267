// import request from 'superagent';
// import React from 'react';
// import ServerAdapter from '../Adapter/ServerAdapter';
// import PhoneNumber   from '../../phpos/components/global/PhoneNumber';
// import XHR           from '../../utils/XHR';
// import CalmDate      from '../../phpos/components/global/CalmDate';
// import i18n          from '../../../src/i18n';
// import CalmButton    from '../../phpos/components/global/CalmButton';
// import CalmHistory   from '../../app/containers/History';
// import YNSet         from '../../../src/stores/YNSet';

//Column

// import { requestJson } from '../../../src/store/actions';

/**
 * PHPOSApiGrid Mixin
 *
 * CLD_Config: change response
 * 1. split configuration and records
 * 2. get real fields of phpos definitions (to pass to model,
 * or load model in own file config) and use cld config
 * as "columns / fields" config
 *
 *
 */


let PHPOSApiGrid = (superclass) => class extends superclass {

	static test() {
		console.log('PHPOSApiGrid-Test',true);
		super.test();
	}

	/**
	 * Standard grid settings
	 * @returns {}
	 */
	static getGridSettings() {

		let s = {
			fullscreen: true
		};

		if(this.gridSettings) {
			Object.assign(s,this.gridSettings);
		}

		// if(typeof console === 'object') { console.log('getGridSettings',s); }

		return s;
	}

	static getGridConf( contentData = {} ) {
		// let contentKey = this.getContentKey( contentData );
		//
		// if(this.debug && typeof console === 'object') {
		// 	console.log(
		// 		'%c getGridConf with contentKey %s und baseParams %o',
		// 		'color: #ff00de',
		// 		contentData.contentKey,
		// 		contentData.baseParams
		// 	);
		// }
		//
		// if(this.baseParams) {
		// 	this.setExtraParams(this.baseParams);
		// }

		return new Promise( ( resolve, reject ) => {

			let columns = this.getGridColumns();
			let buttons = this.getGridButtons();

			let b = {
				success: true,
				columns: columns,
				gridButtons: buttons
			};

			let obj = Object.assign( this, contentData, b );

			resolve(obj);

			// resolve( this.getStore( obj )
			// 	.then( store => {
			// 			obj.store = store;
			// 			return obj;
			// 		}
			// 	) );
		} );
	}

	/**
	 * get columns
	 *
	 * array must contain:
	 * name: fieldname
	 * header: fieldlabel
	 *
	 * array may contain
	 * hidden: {boolean} default: false
	 * isSortable: {boolean} default: false
	 * columnWidth: {function}, must return a number
	 * width: {number}
	 * height: {number}
	 *
	 * tag: e.g. 'TextField', defined Tags in common/components/grid/mui-virtualized-table/index.js
	 * tagProps: {object} properties added to tag component
	 *
	 * referenceTag: e.g. 'ReferenceField', defined referenceTags in common/components/grid/mui-virtualized-table/index.js
	 * referenceTagProps: {object}, minmum requirements: {basePath:'/users',reference:'users'}
	 * (in case of using a referenceField, you must define a field which will be outlined in tagProps {source:'username'}
	 *
	 * cellRenderer: {function} gets @params column,value,rowData,opts, must return a compoment
	 *
	 * cell: {function} gets @params rowData, must return a component
	 * cellProps: {object}, cell properties
	 *
	 *
	 *
	 * @returns {{hidden: boolean, name: string, header: string, isSortable: boolean}[]}
	 */
	static getGridColumns() {

		return [
			{
				name: 'sid',
				header     : '#',
				hidden   : false,
				isSortable: true
			}
		];
	}

	static getGridButtons() {
		return [];
	}

	static getGridSorting(options) {

		let sorting = options.sorting || null;

		let n = this.getState('sortProperty');
		let d = this.getState('sortDirection');
		// if(typeof console === 'object') { console.log('getGridSorting-N',options,n,d); }

		if(n) {
			return {
				field: n,
				order: d ? d.toUpperCase() : 'ASC'
			};
		}

		// this.setGridSorting(sorting);
		// if(typeof console === 'object') { console.log('getGridSorting',sorting); }
		return sorting;
	}

	static setGridSorting(sorting,dir) {

		if(sorting) {
			this.setState('sortProperty', sorting);
			this.setState('sortDirection', dir);
		}

		return sorting;
	}

	/**
	 * load record
	 *
	 * Todo: cache record / return cached record
	 *
	 * @param callback
	 * @param o
	 */
	static loadRecords(callback, o={}) {

		// let url = this.getUrl();
		// let p = {};
		// let extraParams = this.getExtraParams();
		// if(typeof console === 'object') { console.log('loadRecords',extraParams); }

		// if(o && o.params) {
		// 	p = o.params;
		// }
		//
		// if(extraParams) {
		// 	p = Object.assign(p,extraParams);
		// }

		// if(this.debug && typeof console === 'object') {
		// 	console.log(
		// 		'%c PHPOSAPIGrid.loadRecord %s with extra params %o','color: #00f',
		// 		url,
		// 		p
		// 	);
		// }


		let a = this.getAction('request');
		let store = this.getStore();
		let apiEndpoint = this.getContentKey();

		// if(typeof console === 'object') { console.log('a',a,store); }
		let options = Object.assign( {
			apiEndpoint: apiEndpoint,
			adapter    : 'JSONAdapter',
			callback   : callback,
		}, o);

		store.dispatch(a(apiEndpoint,options));

		// if(o.removeAll) {
		// 	this.store.removeAll(true);
		// }
		//
		// this.store.load( {
		// 	callback: ( r, o, s ) => {
		// 		if(typeof callback === 'function') {
		// 			callback(r, o, s);
		// 		}
		// 	}
		// } );

	}

};

export default PHPOSApiGrid;
