import React, { PureComponent } from 'react';
import PropTypes                           from 'prop-types';
// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';
import DefaultProfilePicSVG from '../../../public/_img/icons/video-player.svg';

export default class VideoPreview extends PureComponent {

	constructor(props) {
		super(props);

		this.defaltSrc = '../../../public/_img/icons/profile-picture.svg';
	}

	getSrc() {
		let { src } = this.props;

		if(src && src.length) {
			return src;
		}

		return DefaultProfilePicSVG;//this.defaltSrc;

	}

	render() {

		let { className, alt, style } = this.props;

		// if(!className) {
		// 	className = 'img--rectangle-preview medium';
		// }

		return (
			<img
				style={style}
				alt={alt}
				// onClick={this.loadProfile}
				className={className}
				src={this.getSrc()}
			/>
		);
	}
}

VideoPreview.defaultProps = {
	className: 'img--rectangle-preview medium'
};

VideoPreview.propTypes = {
	src: PropTypes.string,
	className: PropTypes.string,
	alt: PropTypes.string,
	style: PropTypes.any,
};

// export default withTranslate()(withStyles(styles)(ComponentTemplate));
// export default withTranslate()(ComponentTemplate);
// export default withTranslate()(connect(null,null)(ComponentTemplate));
