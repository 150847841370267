import React             from 'react';
import LocalApi          from '../LocalApi';
import { useTranslate }           from 'react-admin';
import { minLength, required } from 'react-admin';
import useGridStyles from '../../../common/components/helpers/useGridStyles';
import SwitchUser from "../../../common/components/react-admin/form/actions/SwitchUser";
import authProvider from "../../admin-store/authProvider";
// import classNames from 'classnames';

/**
 * cld_
 *
 *
 *

 readableFields: Array(4)
 0: Field {name: "username", id: "http://schema.org/name", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: true, …}
 1: Field {name: "email", id: "http://schema.org/email", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: true, …}
 2: Field {name: "contact", id: "https://lp-api.dev.highject.com/api/docs.jsonld#User/contact", range: "https://lp-api.dev.highject.com/api/docs.jsonld#Contact", reference: Resource, required: false, …}
 3: Field {name: "groups", id: "https://lp-api.dev.highject.com/api/docs.jsonld#User/groups", range: "https://lp-api.dev.highject.com/api/docs.jsonld#Group", reference: Resource, required: false, …}
 length: 4
 __proto__: Array(0)
 writableFields: Array(5)
 0: Field {name: "username", id: "http://schema.org/name", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: true, …}
 1: Field {name: "email", id: "http://schema.org/email", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: true, …}
 2: Field {name: "password", id: "https://lp-api.dev.highject.com/api/docs.jsonld#User/password", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
 3: Field {name: "contact", id: "https://lp-api.dev.highject.com/api/docs.jsonld#User/contact", range: "https://lp-api.dev.highject.com/api/docs.jsonld#Contact", reference: Resource, required: false, …}
 4: Field {name: "groups", id: "https://lp-api.dev.highject.com/api/docs.jsonld#User/groups", range: "https://lp-api.dev.highject.com/api/docs.jsonld#Group", reference: Resource, required: false, …}
 length: 5

 *
 *
 */

class Users extends LocalApi {

	static contentKey = 'projects';
	static sorting = { field: 'progress', order: 'DESC' };
	// static hasEdit = false;
	static hasShow = true;


	static getFormFields(formFields,map = 'formFields') {
		// if(typeof console === 'object') { console.log('getFormFields',formFields); }
		let f = [];
		const translate = useTranslate();

		/*
		 readableFields: Array(4)
 0: Field {name: "username", id: "http://schema.org/name", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: true, …}
 1: Field {name: "email", id: "http://schema.org/email", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: true, …}
 2: Field {name: "contact", id: "https://lp-api.dev.highject.com/api/docs.jsonld#User/contact", range: "https://lp-api.dev.highject.com/api/docs.jsonld#Contact", reference: Resource, required: false, …}
 3: Field {name: "groups", id: "https://lp-api.dev.highject.com/api/docs.jsonld#User/groups", range: "https://lp-api.dev.highject.com/api/docs.jsonld#Group", reference: Resource, required: false, …}
 length: 4
		* */

		let fields = {
			username: {
				MVTInputField: true,
				inputProps: {
					label: translate('fields.username'),
					validate:  [required(), minLength(2)],
					autoFocus: true,
				}
			},
			email: {
				MVTInputField: true,
				inputProps: {
					label: translate('fields.email'),
					validate:  [required(), minLength(7)]
				}
			},
			password: {
				MVTPasswordInputField: true,
				inputProps: {
					label: translate('fields.password'),
					// validate:  [required(), minLength(7)]
				}
			},
			contact: {
				MVTReferenceInput: true,
				inputProps: {
					tag     : 'AutocompleteInput',
					label    : translate('fields.contact'),
					tagProps: {
						optionText: 'name',
						reference: 'people',
						loadResource: false,
						search: true,
						createButton: true,
					},
				}
			},
			groups: {
				MVTReferenceArrayInput: true,
				inputProps: {
					tag     : 'AutocompleteArrayInput',
					label    : translate('fields.groups'),
					tagProps: {
						optionText: 'name',
						reference: 'groups',
						loadResource: false,
						search: true,
						multi: true,
						// createButton: true,
					},
				}
			},
		};

		if(map === 'formFields') {
			f = this.mapFields(formFields,fields);
		}

		return f;
	}

	static validateForm(values) {
		// if(typeof console === 'object') { console.log('validateForm with values',values); }
		const errors = {
			// title: ['myroot.validation.required']
		};
		return errors;
	}

	static getGridPermanentFilter( permanentFilter ) {
		return permanentFilter;
	}

	static getGridFilterDefaults( filterDefaults ) {
		return {
			// title: '999',
			// description: 'super'
		};
	}

	static getGridFilter( ) {
		return [];
	}

	static getShowActions(data) {

		let allow = false;
		if(authProvider.checkPermission('ROLE_ADMIN')) {
			allow = true;
		}

		if(allow && typeof data === 'object' && data.id && data.id.length > 0) {
			return [<SwitchUser
				key="switchuser"
				label="Switch user"
			/>]
		}

		// if(typeof console === 'object') { console.log('NOOOOO getShowActions',true); }

		return [];
	}

	static getEditActions(data) {

		let allow = false;
		if(authProvider.checkPermission('ROLE_ADMIN')) {
			allow = true;
		}

		if(allow && typeof data === 'object' && data.id && data.id.length > 0) {
			return [<SwitchUser
				key="switchuser"
				label="Switch user"
			/>]
		}

		// if(typeof console === 'object') { console.log('NOOOOO getShowActions',true); }

		return [];
	}

	static getGridColumns(listFields,map = 'listfields') {
		// if(typeof console === 'object') { console.log('getGridColumns',listFields); }

		const translate = useTranslate();
		// const classes = useGridStyles();
		const classes = useGridStyles();
		/*
		 writableFields: Array(5)
 0: Field {name: "username", id: "http://schema.org/name", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: true, …}
 1: Field {name: "email", id: "http://schema.org/email", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: true, …}
 2: Field {name: "password", id: "https://lp-api.dev.highject.com/api/docs.jsonld#User/password", range: "http://www.w3.org/2001/XMLSchema#string", reference: null, required: false, …}
 3: Field {name: "contact", id: "https://lp-api.dev.highject.com/api/docs.jsonld#User/contact", range: "https://lp-api.dev.highject.com/api/docs.jsonld#Contact", reference: Resource, required: false, …}
 4: Field {name: "groups", id: "https://lp-api.dev.highject.com/api/docs.jsonld#User/groups", range: "https://lp-api.dev.highject.com/api/docs.jsonld#Group", reference: Resource, required: false, …}
 length: 5
		* */

		let fields = {
			username: {
				fieldProps: {
					label    : translate('fields.username'),
				}
			},
			email: {
				fieldProps: {
					label    : translate('fields.email'),
				}
			},
			contact: {
				// isSortable: true,
				MVTReferenceField: true,
				fieldProps: {
					label    :translate('fields.contacts'),
					// breakpointUp: 'lg',
					cellAndHeaderClassName: classes.breakpointDownSM,

					// referenceTag: 'ReferenceField',
					// referenceTagProps: {basePath:'/locations',reference:'locations'},
					tagProps: {source:'name'},
				}
			},
			// groups: null,
			groups: {
				// isSortable: true,
				MVTReferenceArrayField: true,
				fieldProps: {
					label    :translate('fields.groups'),
					breakpointUp: 'lg',
					cellAndHeaderClassName: classes.breakpointDownMD,

					// referenceTag: 'ReferenceField',
					// referenceTagProps: {basePath:'/locations',reference:'locations'},
					tagProps: {source:'name'},
				}
			},
		};

		let df = [];
		if(map === 'listfields') {
			df = this.mapFields(listFields,fields);
		}

		return df;
	}

}

export default Users;
