import ObjectUtils from '../../../../utils/ObjectUtils';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useInput, FieldTitle } from 'ra-core';
import { InputHelperText } from 'ra-ui-materialui';

import {
	DateTimePicker,
	KeyboardDateTimePicker,
	DatePicker,
	KeyboardDatePicker
}                       from "@material-ui/pickers";

// import { parseISO } from 'date-fns';
import { useFormState } from 'react-final-form';
// import { InputAdornment, IconButton } from '@material-ui/core';
// import { DateRange } from '@material-ui/icons'
// import moment from "moment";

// var __extends = (this && this.__extends) || (function () {return ObjectUtils.__extends(); })();
var __assign = (this && this.__assign) || function () { return ObjectUtils.__assign(this,arguments); };
var __rest = (this && this.__rest) || function (s, e) { return ObjectUtils.__rest(s, e); };
function sanitizeRestProps(_a) {
	var
		// alwaysOn = _a.alwaysOn,
		// basePath = _a.basePath,
		// component = _a.component,
		// defaultValue = _a.defaultValue,
		// formClassName = _a.formClassName,
		// initializeForm = _a.initializeForm,
		// input = _a.input,
		// isRequired = _a.isRequired,
		// label = _a.label,
		// limitChoicesToValue = _a.limitChoicesToValue,
		// locale = _a.locale,
		// meta = _a.meta,
		// options = _a.options,
		// optionText = _a.optionText,
		// optionValue = _a.optionValue,
		// record = _a.record,
		// resource = _a.resource,
		// allowEmpty = _a.allowEmpty,
		// source = _a.source,
		// textAlign = _a.textAlign,
		// translate = _a.translate,
		// translateChoice = _a.translateChoice,

		rest = __rest(_a, ["alwaysOn", "basePath", "component", "defaultValue", "initialValue", "formClassName", "initializeForm", "input", "isRequired", "label", "limitChoicesToValue", "locale", "meta", "options", "optionText", "optionValue", "record", "resource", "allowEmpty", "source", "textAlign", "translate", "translateChoice"]);
	return rest;
};

export var MVTDateTimeInput = function (_a) {

	const formState = useFormState();
	// if(typeof console === 'object') { console.log('MVTDateTimeInput.formState',formState); }

	let settings = _a.settings || {};
	let initialFocusedDate = null;

	// Special field allDayCheck
	// if allDayCheck is true change datetimefield field to datefield
	let allDayCheck = true;
	let keyboard = true;

	let dateField = DateTimePicker;
	if(keyboard) {
		dateField = KeyboardDateTimePicker;
	}
	let format = 'DD.MM.YYYY HH:mm';

	if(
		(allDayCheck && formState && formState.values && formState.values.allDay === true) ||
		(settings.fieldType === 'date')
	) {
		dateField = DatePicker;
		if (keyboard) {
			dateField = KeyboardDatePicker;
		}

		format = 'DD.MM.YYYY';
	}

	if(
		_a.source && _a.source === 'end' &&
		formState && formState.values && formState.values.start &&
		(!formState.values[_a.source])
		// && (!formState.touched[_a.source])
	) {
		initialFocusedDate = formState.values.start;
	}

	// if(typeof console === 'object') { console.log('formState',formState,_a, initialFocusedDate); }

	let label = _a.label,
		options = _a.options,
		source = _a.source,
		resource = _a.resource,
		helperText = _a.helperText,
		_b = _a.margin,
		margin = _b === void 0 ? 'dense' : _b,
		onBlur = _a.onBlur,

		custom_onChange = _a.onChange,

		onFocus = _a.onFocus,
		validate = _a.validate,
		_c = _a.variant,
		variant = _c === void 0 ? 'filled' : _c,
		rest = __rest(_a, ["label", "options", "source", "resource", "helperText", "margin", "onBlur", "onChange", "onFocus", "validate", "variant"]);

	// if(typeof console === 'object') { console.log('DateTimeField rest',rest); }

	let _d = useInput(
			__assign({
					onBlur: onBlur,
					onFocus: onFocus,
					resource: resource,
					source: source,
					validate: validate
				},
				rest
			)
		);


	const { onChange } = _d.input;
	// if(typeof console === 'object') { console.log('_D',_d); }

	let inputOnChange = (d) => {

		// if(typeof console === 'object') { console.log('inputOnChange',d); }
		// setDateValue(d);
		let s = null;
		if(d) {
			s = d.format();
		}
		// let st = moment(d).format();
		// if(typeof console === 'object') { console.log('onChange',d,s,st); }
		// if(typeof console === 'object') { console.log('onChange.d',d); }
		onChange(s);
		if(typeof custom_onChange === 'function') {
			custom_onChange(s);
		}
		return s;
	};


	let	id = _d.id,
		input = _d.input,
		isRequired = _d.isRequired,
		_e = _d.meta,
		error = _e.error,
		touched = _e.touched;


	if(!input.value) {
		input.value = null;
	}

	// const [dateValue,setDateValue] = useState(input.value);

	let v = __assign(
		{ id: id },
		{...input},
		{
			variant: variant,
			margin: margin,
			// type: "date",
			error: !!(touched && error),
			helperText: (touched && error) || helperText ? (React.createElement(InputHelperText, { touched: touched, error: error, helperText: helperText })) : null,
			label: label ? React.createElement(FieldTitle, { label: label, source: source, resource: resource, isRequired: isRequired }) : null,
			InputLabelProps: {
				shrink: true,
			}
		},
		options,
		sanitizeRestProps(rest),
		{
			ampm: false,
			showTodayButton: true,
			autoOk: true,
			clearable: true,
			variant: 'outlined',
			format: format,
			inputVariant: variant,
			onChange:inputOnChange,
			initialFocusedDate: initialFocusedDate,
			value: input.value //dateValue
		},
		// {
		// 	endAdornment: (
		// 		<InputAdornment position="end">
		// 			<IconButton size="small">
		// 				<DateRange />
		// 			</IconButton>
		// 		</InputAdornment>
		// 	),
		// 	InputProps: {
		// 		endAdornment: (
		// 			<InputAdornment position="end">
		// 				<IconButton size="small">
		// 					<DateRange />
		// 				</IconButton>
		// 			</InputAdornment>
		// 		),
		// 	}
		// }
	);

	// if(typeof console === 'object') { console.log('v',v); }


	return (
		React.createElement(
			dateField,
			v
		)
	);
};

MVTDateTimeInput.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool,PropTypes.object]),
	options: PropTypes.object,
	resource: PropTypes.string,
	source: PropTypes.string,
};

MVTDateTimeInput.defaultProps = {
	options: {},
};

export default MVTDateTimeInput;
