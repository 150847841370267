import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


function Companies(props) {
    return (
        <SvgIcon {...props}>
            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" role="img">
                <g>
                    <path
                        d="M7.3,11.9c0-0.2-0.1-0.3-0.3-0.3H6c-0.2,0-0.3,0.1-0.3,0.3v1c0,0.2,0.1,0.3,0.3,0.3h1 c0.2,0,0.3-0.1,0.3-0.3V11.9z"></path>
                    <path
                        d="M6,8.5h1c0.2,0,0.3-0.1,0.3-0.3v-1C7.3,7.1,7.1,7,7,7H6C5.9,7,5.7,7.1,5.7,7.3v1C5.7,8.4,5.9,8.5,6,8.5z"></path>
                    <path d="M8.5,14.6h-1c-0.2,0-0.3,0.1-0.3,0.3v2h1.5v-2C8.8,14.8,8.7,14.6,8.5,14.6z"></path>
                    <path
                        d="M6,10.8h1c0.2,0,0.3-0.1,0.3-0.3v-1c0-0.2-0.1-0.3-0.3-0.3H6c-0.2,0-0.3,0.1-0.3,0.3v1 C5.7,10.7,5.9,10.8,6,10.8z"></path>
                    <path
                        d="M10,9.3h-1c-0.2,0-0.3,0.1-0.3,0.3v1c0,0.2,0.1,0.3,0.3,0.3h1c0.2,0,0.3-0.1,0.3-0.3v-1 C10.3,9.4,10.2,9.3,10,9.3z"></path>
                    <path
                        d="M10,11.6h-1c-0.2,0-0.3,0.1-0.3,0.3v1c0,0.2,0.1,0.3,0.3,0.3h1c0.2,0,0.3-0.1,0.3-0.3v-1 C10.3,11.7,10.2,11.6,10,11.6z"></path>
                    <path
                        d="M21.2,3.4H2.8C1.8,3.4,1,4.3,1,5.3v13.4c0,1,0.8,1.8,1.8,1.8h18.3c1,0,1.8-0.8,1.8-1.8V5.3 C23,4.3,22.2,3.4,21.2,3.4z M13.4,17.7H2.7v-0.5c0-0.2,0.1-0.3,0.3-0.3h0.5V6c0-0.3,0.3-0.6,0.6-0.6h8c0.3,0,0.6,0.3,0.6,0.6v10.9 h0.5c0.2,0,0.3,0.1,0.3,0.3V17.7z M20.6,14.1c0,0.2-0.1,0.3-0.3,0.3h-5.5c-0.2,0-0.3-0.1-0.3-0.3v-0.6c0-0.2,0.1-0.3,0.3-0.3h5.5 c0.2,0,0.3,0.1,0.3,0.3V14.1z M20.6,11.7c0,0.2-0.1,0.3-0.3,0.3h-5.5c-0.2,0-0.3-0.1-0.3-0.3v-0.6c0-0.2,0.1-0.3,0.3-0.3h5.5 c0.2,0,0.3,0.1,0.3,0.3V11.7z M20.6,9.2c0,0.2-0.1,0.3-0.3,0.3h-5.5c-0.2,0-0.3-0.1-0.3-0.3V8.6c0-0.2,0.1-0.3,0.3-0.3h5.5 c0.2,0,0.3,0.1,0.3,0.3V9.2z"></path>
                    <path
                        d="M10,7h-1C8.9,7,8.8,7.1,8.8,7.3v1c0,0.2,0.1,0.3,0.3,0.3h1c0.2,0,0.3-0.1,0.3-0.3v-1C10.3,7.1,10.2,7,10,7z"></path>
                </g>
                <title>Companies</title>
            </svg>
        </SvgIcon>
    );
}

export default Companies;