import React          from 'react';
import PropTypes      from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
	root: {
		color: theme.palette.textColors.default,
		// backgroundColor: theme.palette.background.paper,
	},
	paragraph: {
		margin: theme.spacing(2)
	}
}));

// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';

function Error(props) {

	const classes = useStyles();

	return (
		<div className={classes.root}>
			<p className={classes.paragraph}>{props.error}</p>
		</div>
	);
};

Error.defaultProps = {

};

Error.propTypes = {
	error: PropTypes.string.isRequired
	// classes: PropTypes.object,
	// translate: PropTypes.func
};

export default Error;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
