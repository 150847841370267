import request       from 'superagent';
import ServerAdapter from '../../src/store/adapter';

import XHR from './XHR';
/**
 * UAV
 */
export default class UAV {

	static uav = {};

	static getAdapter(adapter='JSONServerAdapter') {
		return ServerAdapter.getAdpapter(adapter);
	}

	/**
	 * Static connection to the "appstate uav" in
	 * AppBundle/Resources/app/calm/app/containers/AsyncApp.js
	 * @param n
	 */
	static bindUserUAV(n) {
		this.uav = n;
	}

	// $this->evalUAV('sys','can_impersonate_users') + main role level muss höher sein als der target, oder sysadmin
	static canImpersonateUser(user_main_role_level) {

		if(this.isSysAdmin())
		{
			return true;
		}

		if(
			this.checkRight( 'sys', 'can_impersonate_users') &&
			this.getCurrentRoleLevel() >= user_main_role_level
		) {
			return true;
		}

		return false;
	}

	static isSysAdmin() {

		if(this.getCurrentRoleLevel() >= 1000)
		{
			return true;
		}

		return false;
	}

	static getCurrentRoleLevel() {
		var level = 0;

		if(this.uav
		   && this.uav
		   && this.uav['sys']
		   && this.uav['sys']['current_role_level'])
		{
			level = this.uav['sys']['current_role_level'];
		}

		return level;
	}

	static checkRight( clazz, flag) {

		if(this.isSysAdmin())
		{
			return true;
		}

		let hasRight = false;

		if(
			this.uav[clazz] &&
			this.uav[clazz][flag] &&
			(this.uav[clazz][flag] === 'y' || this.uav[clazz][flag] === true)
		) {
			hasRight = true;
		}

		return hasRight;
	}

	/**
	 * Check a Right
	 *
	 * @param clazz
	 * @param flag
	 * @param obj_class
	 * @param obj_sid
	 * @param sysadmin
	 * @returns {boolean}
	 */
	static evalUAV(callback, clazz, flag, obj_class, obj_sid = null, sysadmin = true) {
		
		var allow = false;

		// if(sysadmin && this.isSysAdmin())
		// {
		// 	return true;
		// }

		var p = {
			clazz: clazz,
			flag: flag,
			obj_class: obj_class,
			obj_sid: obj_sid,
			sysadmin: sysadmin
		};

		// let url = '/php-os/app_objectmanager/scripts/evalUAV.php';
		let url = '/php-os/app_objectmanager/evalUAV';
		let method = this.getAdapter().getApiMethod( 'POST' );
		let type = ServerAdapter.getApiType( 'POST' );

		return request( method, url )
			.send( p )
			.type( type )
			.then( response => {

				let res = response;
				let value = false;

				if ( response && response.body ) {
					res = response.body;
					value = res.value;
				}

				if ( typeof callback === 'function' ) {
					callback( value, res );
				}
			} )
			.catch( error => {
				XHR.superagentException(error);

				if ( typeof callback === 'function' ) {
					callback( { succes: false }, { error: error } );
				}
			} );
	}



	static checkUserPanelRoleRelationEditRights(callback, sid, comparisonRSC = 'app', assignmentRSC = null, obj_sid = null) {

		var allow = false;

		// if(sysadmin && this.isSysAdmin())
		// {
		// 	return true;
		// }

		var p = {
			sid: sid,
			comparisonRSC: comparisonRSC,
			assignmentRSC: assignmentRSC,
			obj_sid: obj_sid,
		};

		// let url = '/php-os/app_objectmanager/scripts/roleRights.php';
		let url = '/php-os/app_objectmanager/role-rights';
		let method = this.getAdapter().getApiMethod( 'POST' );
		let type = ServerAdapter.getApiType( 'POST' );

		return request( method, url )
			.send( p )
			.type( type )
			.then( response => {

				let res = response;
				let value = false;

				if ( response && response.body ) {
					res = response.body;
					value = res.value;
				}

				if ( typeof callback === 'function' ) {
					callback( value, res );
				}
			} )
			.catch( error => {
				XHR.superagentException(error);

				if ( typeof callback === 'function' ) {
					callback( { succes: false }, { error: error } );
				}
			} );
	}

};
