import React, { useState, useEffect, useCallback } from 'react';
import PropTypes                                   from 'prop-types';
import { withTranslate }                           from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';
import { makeStyles, withStyles }                  from '@material-ui/core/styles';
import MUITreeView                                 from '@material-ui/lab/TreeView';
import TreeItem                                    from '@material-ui/lab/TreeItem';
import ArrowDropDownIcon                           from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon                              from '@material-ui/icons/ArrowRight';
import { Checkbox }                                from '@material-ui/core';
import FormControlLabel                            from '@material-ui/core/FormControlLabel';
import { useMutation, GET_LIST, useNotify } from 'react-admin';
import ObjectUtils                          from '../../common/utils/ObjectUtils';
import AppStateApi                          from '../../common/api/AppStateApi';
import SearchIcon                           from '@material-ui/icons/Search';
import InputBase                            from '@material-ui/core/InputBase';
import Paper                                       from '@material-ui/core/Paper';
import IconButton                                  from '@material-ui/core/IconButton';
import FuncUtils        from '../../common/utils/FuncUtils';
import Dialog           from '@material-ui/core/Dialog';
import MuiDialogTitle   from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon        from '@material-ui/icons/Close';
import Typography       from '@material-ui/core/Typography';
import Button           from '@material-ui/core/Button';
import StringUtils      from '../../common/utils/StringUtils';
import Radio from '@material-ui/core/Radio';

const useStyles = makeStyles(function (theme) {
	return ({
		root      : {
			// padding : '40px',
			// height  : 216,
			// flexGrow: 1,
			maxWidth: 400,
		},
		treeItem: {
			'&:focus > .MuiTreeItem-content': {
				backgroundColor: 'transparent'
			}
		},
		searchRoot: {
			padding   : '2px 4px',
			display   : 'flex',
			alignItems: 'center',
			width     : 400,
		},
		input     : {
			marginLeft: theme.spacing( 1 ),
			flex      : 1,
		},
		iconButton: {
			padding: 10,
		},

		dialogTitleRoot: {
			margin: 0,
			padding: theme.spacing(2),
		},
		dialogContent: {
			maxHeight: '60vh',
			minHeight: '40vh',
			// height: '60vh',
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
		formControlLabel:{
			display: 'flex'
		},
		formfiledtag:{
			' .MuiFormControlLabel-label': {
				width: '100%'
			}
		},
	});
});


const components = {
	Checkbox: Checkbox,
	Radio  : Radio,
};

function CheckboxLabel(props) {
	const { label, value, handleChange, checked, selection } = props;
	const openTreeOnLabelTouch = false;
	const classes = useStyles();
	const FieldTag = (selection === 'multiple') ? components['Checkbox']  : components['Radio'];

	if(openTreeOnLabelTouch) {
		return (
			<div>
				<FieldTag
					checked={checked}
					onChange={handleChange(value,label)}
					onClick={e => e.stopPropagation()}
					value={value}
					// color="primary"
				/>
				{label}
			</div>
		);
	}

	return (
		<FormControlLabel
			className={classes.formControlLabel}
			control={
				<FieldTag
					className={classes.formfiledtag}
					checked={checked}
					onChange={handleChange(value,label)}
					value={value}
					// color="primary"
				/>
			}
			label={label}
		/>
	);
}

const DialogTitle = props => {
	const classes = useStyles();
	const { children, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.dialogTitleRoot} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
};

const DialogContent = withStyles(theme => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

const treeViewStyle = { width: 24 };

function TreeView(props) {

	const classes = useStyles();

	let {
		resource,
		namespace,
		showSearch,
		callback,
		titleField,
		searchTitleField,
		translate,
		openInDialog,
		open,
		handleClose,
		title,
		selection,
		initialSelection,
		initialOpenLevel,
		resetCategories,
		setLoading
	} = props;

	let isMounted = true;
	let initialLevelExpandedNodes = [];
	let searchRef = React.createRef();
	const [data,setData] = useState(null);
	const [checkedItems,setCheckedItems] = useState(initialSelection);
	const [defaultExpanded,setDefaultExpanded] = useState([]);
	const [searchString,setSearchString] = useState(null);
	const [isInitialized,setIsInitialized] = useState(false);
	// const [isMounted,setIsMounted] = useState(false);
	const notify = useNotify();

	let checkedRecords = [];

	// if(typeof console === 'object') { console.log('TreeView.initialSelection',title,isInitialized); }

	if(!resource) {
		notify('Error: no resource defined.');
	}

	if(!namespace) {
		namespace = 'trieview.' + resource;
	}

	useEffect(() => {
		let expandedNodes = AppStateApi.getState(namespace);
		if(expandedNodes) {
			setDefaultExpanded(expandedNodes);
		}
		// setIsMounted(true);
		getData();

		return () => {
			checkedRecords = [];
			isMounted = false;
		};
	},[resource]);


	useEffect(() => {
		// if(typeof console === 'object') { console.log('SET SEARCH STRING',typeof searchString); }
		if(typeof searchString === 'string') {
			// if(typeof console === 'object') { console.log('USE SEARCH STRING',searchString); }
			getData({search: searchString});
			if(typeof setLoading === 'function') {
				setLoading(true);
			}
		}
	},[searchString]);

	useEffect(() => {
		if(resetCategories) {
			// if(typeof console === 'object') { console.log('resetCategories',resetCategories); }
			setItems([]);
		}
	},[resetCategories]);


	// useEffect(() => {
	// 	if(open) {
	// 		// if(typeof console === 'object') { console.log('useEffect.searchRef.open',searchRef); }
	//
	// 		// if(typeof console === 'object') { console.log('searchRef',searchRef); }
	// 		// setItems([]);
	// 	}
	// },[open]);





	const getFilter = (event) => {

		// let f = event.dateRange;
		// f['project'] = {selection: event.projectId};

		let f = {};
		let sortField = (searchString) ? searchTitleField : titleField;

		if(event && event.search) {
			f[titleField] = event.search;
		} else {
			f.level = 0;
		}

		return {
			pagination: {
				page: 1,
				perPage: 100
			},
			sort: {
				field: sortField, order: 'ASC'
			},
			filter: f,
			// appParams: {getSubresources: 1},
			groups: ['related:read']
		};
	};


	let [ mutate ] = useMutation();
	const getData = event => mutate(
		{
			type: GET_LIST,
			resource: resource,
			payload: getFilter(event),
		},
		{
			onSuccess: ({ data }) => {
				// if(typeof console === 'object') { console.log('isMounted',isMounted); }
				if(isMounted) {
					setData( data );
					if ( typeof setLoading === 'function' ) {
						setLoading( false );
					}
				}
				return data;

			},
			onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
		}
	);

	const onNodeToggle = (event, expandedNodes) => {

		// if(typeof console === 'object') { console.log('xevent, nodeId, expanded',event, expandedNodes); }
		//
		// let expandedNodes = AppStateApi.getState(namespace);
		// if(typeof console === 'object') { console.log('expandedNodes',expandedNodes); }
		//
		// if(!expandedNodes) {
		// 	expandedNodes = [];
		// }
		//
		// if(expanded) {
		// 	let isExpanded = ObjectUtils.findIndex(expandedNodes,nodeId);
		// 	if(isExpanded < 0) {
		// 		expandedNodes.push(nodeId);
		// 	}
		// } else {
		// 	let isExpanded = ObjectUtils.findIndex(expandedNodes,nodeId);
		// 	if(isExpanded < 0) {
		// 		ObjectUtils.remove(expandedNodes, function(n) {
		// 			return n === nodeId;
		// 		});
		// 	}
		// }
		// if(typeof console === 'object') { console.log('expandedNodes',expandedNodes); }
		AppStateApi.setState(namespace,expandedNodes);
	};

	const handleChange = (record,title) => event => {

		if(1===2 && typeof console === 'object') { console.log('handleChange',record,title); }

		let newCheckedItems = ObjectUtils.clone(checkedItems);

		if(event.target.checked) {
			let isChecked = ObjectUtils.findRecord(checkedItems,{id: record.id});
			if(!isChecked) {
				if(selection === 'single') {
					newCheckedItems = [{
						...record,
						checked: event.target.checked
					}];
				} else {
					newCheckedItems.push( {
						...record,
						checked: event.target.checked
					} );
				}
			}
		} else {
			let isChecked = ObjectUtils.findIndex(checkedItems,{id: record.id});
			if(isChecked >= 0) {
				ObjectUtils.remove(newCheckedItems, function(n) {
					return n.id === record.id;
				});
			}
		}

		setItems(newCheckedItems);

	};

	const setItems = (newCheckedItems) => {
		setCheckedItems(newCheckedItems);

		if(typeof callback === 'function') {
			callback(newCheckedItems);
		}
	};

	const getTree = (data) => {
		let childs = [];

		let herachical = (searchString) ? false : true;
		let labelField = (searchString) ? searchTitleField : titleField;


		if(data) {
			data.map( ( c ) => {


				let checked = false;
				let checkedRecord = ObjectUtils.findRecord(checkedItems,{id: c.id});
				if(checkedRecord) {
					checked = true;
					checkedRecords.push(c);
				}


				// if(typeof console === 'object') { console.log('checkedItems',checkedItems,checkedRecord,c); }

				let t = (searchString) ? StringUtils.highlight(c[labelField],searchString) : c[labelField]; //highlight

				if(typeof c.level !== 'undefined' && c.level <= initialOpenLevel) {
					initialLevelExpandedNodes.push(c.id);
				}

				if(herachical && c.children && c.children.length) {
					childs.push(
						<TreeItem className={classes.treeItem} key={c.id} nodeId={c.id} label={
							<CheckboxLabel
								checked={checked}
								handleChange={handleChange}
								value={c}
								label={t}
								selection={selection}
							/>
						}>
							{getTree( c.children )}
						</TreeItem>
					);
				} else {
					childs.push(
						<TreeItem className={classes.treeItem} key={c.id} nodeId={c.id} label={
							<CheckboxLabel
								selection={selection}
								checked={checked}
								handleChange={handleChange}
								value={c}
								label={<span dangerouslySetInnerHTML={{ __html: t }} />}
							/>
						} />
					);
				}
				return c;
			} );
		}


		return (childs.length) ? childs : null;
	};

	const search = (ev,s) => {
		let ref = searchRef;
		if(s) {
			ref = s;
		}
		// getData({search: ref.current.value});
		setSearchString(ref.current.value);
	};

	const onKeyPress = (ev) => {
		if (ev.key === 'Enter') {
			ev.preventDefault();
			// search(ev);
		}
		debounceSearch(ev,searchRef);
	};

	const debounceSearch = useCallback(FuncUtils.debounce(search, 500), []);

	if(!data) {
		return null;
	}

	let tree = getTree(data);

	// load initial checked records in to parent component
	if(!isInitialized) {
		if(typeof callback === 'function' && checkedRecords && checkedRecords.length) {
			setIsInitialized(true);
			callback(checkedRecords);
		}
	}
	// if(typeof console === 'object') { console.log('defaultExpanded',defaultExpanded); }
	// if(typeof console === 'object') { console.log('initialexpandedNodes',initialLevelExpandedNodes); }

	// if(!open) {
	// 	return null;
	// }

	let mtv = <MUITreeView
		className={classes.root}
		defaultExpanded={defaultExpanded.concat(initialLevelExpandedNodes)}
		onNodeToggle={onNodeToggle}
		defaultCollapseIcon={<ArrowDropDownIcon />}
		defaultExpandIcon={<ArrowRightIcon />}
		defaultEndIcon={<div style={treeViewStyle} />}
	>
		{tree}
	</MUITreeView>;

	let searchButton = null;
	if(showSearch) {
		searchButton = <Paper className={classes.searchRoot}>
			<InputBase
				inputRef={searchRef}
				className={classes.input}
				placeholder={translate( 'search' )}
				inputProps={
					{
						'aria-label': translate( 'search' ),
						// autoFocus: open
					}
				}
				onKeyUp={onKeyPress}
				// autoFocus={open}
				// onKeyPress={onKeyPress}
			/>
			<IconButton
				className={classes.iconButton}
				aria-label={translate( 'search' )}
				onClick={search}
			>
				<SearchIcon />
			</IconButton>
		</Paper>;
	}

	return (
		<React.Fragment>
			{openInDialog ? <Dialog onClose={handleClose}
				              // aria-labelledby="customized-dialog-title"
				                    open={open}
			              >
				              <DialogTitle
					              // id="customized-dialog-title"
					              onClose={handleClose}
				              >
					              {title}
				              </DialogTitle>
				              <DialogContent dividers className={classes.dialogContent}>
					              {mtv}
				              </DialogContent>
				              <DialogActions>
					              {searchButton}
					              <Button autoFocus variant="contained" onClick={handleClose} color="primary">
						              {translate( 'buttons.takeover' )}
					              </Button>
				              </DialogActions>
			              </Dialog> :
			 <React.Fragment>
				 {mtv}
				 {searchButton}
			 </React.Fragment>
			}
		</React.Fragment>
	);
}

TreeView.defaultProps = {
	resource: 'event_categories',
	showSearch: true,
	openInDialog: false,
	titleField: 'title',
	searchTitleField: 'path',
	selection: 'multiple',
	initialSelection: []
};

TreeView.propTypes = {
	resource: PropTypes.string,
	namespace: PropTypes.string,
	showSearch: PropTypes.bool,
	callback: PropTypes.func,
	titleField: PropTypes.string,
	searchTitleField: PropTypes.string,
	selection: PropTypes.string,
	// dialog props
	title: PropTypes.string,
	openInDialog: PropTypes.bool,
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	initialSelection: PropTypes.any,
	resetCategories: PropTypes.number,
	initialOpenLevel: PropTypes.number,
};

// export default TreeView;
export default withTranslate(TreeView);
