// import { GET_LIST } from 'react-admin';
import AppCacheApi  from '../../common/api/AppCacheApi';
import ObjectUtils  from '../../common/utils/ObjectUtils';

// /users/me

const enhanceDataProvider = requestHandler => (type, resource, params) => {
	if(resource === AppCacheApi.debugResource && typeof console === 'object') {
		console.log('enhanceDataProvider.type, resource, params',type, resource, params);
	}
	// if(typeof console === 'object') { console.log('enhanceDataProvider.type, resource, params',type, resource, params); }
	// for other request types and resources, fall back to the default request handler

	if(
		ObjectUtils.inArray(AppCacheApi.invalidateCacheTypes,type)
	) {
		AppCacheApi.invalidateCache(resource);
	}
	else if (
		ObjectUtils.inArray(AppCacheApi.cacheTypes,type)
	) {
		const cache = AppCacheApi.getCache(type, resource, params);
		if(cache) {
			// if(typeof console === 'object') { console.log('FOUND CACHE!!!',resource,cache); }
			return Promise.resolve(cache);
			// const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
			// return wait(0).then(() => Promise.resolve(cache));
		}
	}

	// I know I only GET or UPDATE the profile as there is only one for the current user
	// To showcase how I can do something completely different here, I'll store it in local storage
	// You can replace this with a customized fetch call to your own API route, too
	// if (resource === 'profile') {
	// 	if (type === GET_ONE) {
	// 		const storedProfile = localStorage.getItem('profile');
	//
	// 		if (storedProfile) {
	// 			return Promise.resolve({
	// 				data: JSON.parse(storedProfile),
	// 			});
	// 		}
	//
	// 		// No profile yet, return a default one
	// 		// It's important that I send the same id as requested in params.
	// 		// Indeed, react-admin will verify it and may throw an error if they are different
	// 		// I don't have to do it when the profile exists as it will be included in the data stored in the local storage
	// 		return Promise.resolve({
	// 			data: { id: params.id, nickname: '' },
	// 		});
	// 	}
	//
	// 	if (type === UPDATE) {
	// 		localStorage.setItem('profile', JSON.stringify(params.data));
	// 		return Promise.resolve({ data: params.data });
	// 	}
	// }


	return requestHandler(type, resource, params);
};

export default enhanceDataProvider;
