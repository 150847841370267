import React from 'react';
import {
    Button,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';
import PropTypes from "prop-types";

const BulkSetAction = (props) => {

    const { selectedIds, children, label, resource, action, note } = props;

    // if(typeof console === 'object') { console.log('BulkSetField',selectedIds,props); }

    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany, { loading }] = useUpdateMany(
        resource,
        selectedIds,
        {
            '@action': action
        },
        {
            onSuccess: () => {
                refresh();
                if(note) {notify(note);}
                unselectAll(resource);
            },
            onFailure: error => notify('Error: posts not updated', 'warning'),
        }
    );

    return (
        <Button
            label={label}
            disabled={loading}
            onClick={updateMany}
        >
            {children && children}
        </Button>
    );
};

BulkSetAction.propTypes = {
    action: PropTypes.string,
    note: PropTypes.string,
    resource: PropTypes.string,
    label: PropTypes.any,
    children: PropTypes.any,
    selectedIds: PropTypes.array,
};

export default BulkSetAction;