import React          from 'react';
import PropTypes      from 'prop-types';
import Show           from '../../../../api-platform-admin/src/Show_MVT';
import MuiDrawer      from '../../common/MuiDrawer';
import { makeStyles } from '@material-ui/core';
import Toolbar        from '@material-ui/core/Toolbar';
import { IconButton, Typography, AppBar }        from '@material-ui/core';
import { Close }        from '@material-ui/icons';
// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';
const drawerWidth = 400;

let useStyles = makeStyles(function (theme) {
	return ({
		drawerContent: {
			width: drawerWidth,
			[theme.breakpoints.up('xs')]: {
				// width: '100%',
				width: '100vw'
			},
			[theme.breakpoints.up('sm')]: {
				width: drawerWidth,
				// flexShrink: 0,
			},
		},
		grow: {
			flexGrow: 1,
		},
		title: {
			display: 'none',
			[theme.breakpoints.up('sm')]: {
				display: 'block',
			},
		}
	});
});

function MuiDrawerShow(props) {

	let styles = useStyles();
	const {
		disableBackdropClick,
		title,
		closeButton,
		isOpen,
		handleEditorClose,
		resource,
		id,
		basePath,
		redirect,
		formProps,
		options
	} = props;

	// if(typeof console === 'object') { console.log('MuiDrawerEditor',disableBackdropClick); }

	return (
		<MuiDrawer
			disableBackdropClick={disableBackdropClick}
			// variant="permanent"
			open={isOpen}
			anchor="right"
			onClose={handleEditorClose}
		>
			{isOpen ? (
				<React.Fragment>
					{(title || closeButton) && <AppBar
						// color="secondary"
						position="static"
					><Toolbar

						className="mtv__editor--toolbar"
					>
						<Typography className={styles.title} variant="h6" noWrap>
							{title}
						</Typography>

						<div className={styles.grow} />
						{closeButton &&<IconButton
							onClick={handleEditorClose}
							aria-label="Close" color="inherit"
						>
							<Close />
						</IconButton>}
					</Toolbar></AppBar>}
					<Show
						className={styles.drawerContent}
						id={isOpen ? id : null}
						resource={resource}
						basePath={basePath}
						options={options}
						// redirect={false}
						onCancel={handleEditorClose}
						formProps={Object.assign({
							redirect: redirect,
						},formProps)}
					/>
				</React.Fragment>
			) : (
				 <div className={styles.drawerContent} />
			 )}
		</MuiDrawer>
	);
};

MuiDrawerShow.defaultProps = {
	formProps: {},
	disableBackdropClick: false,
};

MuiDrawerShow.propTypes = {
	title: PropTypes.string,
	closeButton: PropTypes.bool,
	disableBackdropClick: PropTypes.bool,
	isOpen: PropTypes.bool,
	resource: PropTypes.string,
	basePath: PropTypes.string,
	id: PropTypes.any,
	redirect: PropTypes.any,
	options: PropTypes.object,
	formProps: PropTypes.object,
	// classes: PropTypes.object,
	handleEditorClose: PropTypes.func
};

export default MuiDrawerShow;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
