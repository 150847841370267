import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { RefreshIconButton } from 'ra-ui-materialui';
import {
    RefreshButton,
} from 'react-admin';

const useStyles = makeStyles({
    loader: {
        marginLeft: 8,
        marginRight: 8,
    },
});

export const GlobalLoadingIndicator = ({
    classes: classesOverride,
    className,
   showRefresh,
                                           refreshButtonType,
                                           refreshButtonProps,
                                           circularProgressProps,
    ...rest
}) => {
    const loading = useSelector(state => state.admin.loading > 0);
    const classes = useStyles({ classes: classesOverride });

    const getRefreshButton = () => {

        if(refreshButtonType === 'button') {
            return <RefreshButton label={null} {...refreshButtonProps} />
        }

        return <RefreshIconButton {...refreshButtonProps} />;
    };


    return 1===2 || loading ? (
        <CircularProgress
            className={classNames('app-loader', classes.loader, className)}
            color="inherit"
            size={18}
            thickness={5}
            {...circularProgressProps}
            {...rest}
        />
    ) : (
        showRefresh ? getRefreshButton() : null
    );
};

GlobalLoadingIndicator.defaultProps = {
    refreshButtonType: 'icon',
    refreshButtonProps: {},
    circularProgressProps: {},
};

GlobalLoadingIndicator.propTypes = {
    classes: PropTypes.object,
    refreshButtonProps: PropTypes.object,
    circularProgressProps: PropTypes.object,
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    refreshButtonType: PropTypes.string,
    width: PropTypes.string,
};

export default GlobalLoadingIndicator;
