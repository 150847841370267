// import './App.scss';
// import Api from '@api-platform/api-doc-parser/lib/Api';
import { Admin } from 'react-admin';
import PropTypes from 'prop-types';
import React from 'react';
import fieldFactory from '../api-platform-admin/src/fieldFactory';
import inputFactory from '../api-platform-admin/src/inputFactory';
import LayoutWithTheme from './admin-containers/Layout';
import parameterFactory from '../api-platform-admin/src/parameterFactory';
import resourceFactory from '../api-platform-admin/src/resourceFactory';
import configFactory from '../api-platform-admin/src/configFactory';
import History from './admin-containers/History';

// Custom STORE
// import { Provider } from 'react-redux';
// import createAdminStore from './admin-store/createAdminStore';

// CUSTOM SAGAS
// import { getBitcoinSaga } from './admin-store/sagas/bitcoinSaga';
import { fetchAppState, updateAppState } from '../common/store/sagas/appstateSaga';

// CUSTOM REDUCER
// import bitcoinRateReducer from './admin-store/reducers/bitcoinRateReducer';
// import customFetchLoad from './admin-store/reducers/customFetchLoad';
import { appstateByPHPOS } from '../common/store/reducers/appstateReducer';
import { userReducer } from '../common/store/reducers/userReducer';
import cacheReducer from '../common/store/reducers/cacheReducer';


// Resource Routing
// import { ProjectEdit } from './scenes/Projects/ProjectEdit';
// import { ProjectCreate } from './scenes/Projects/ProjectCreate';
// import { ProjectList }from './scenes/Projects/ProjectList';
// import projects from './scenes/Projects';
import ProjectEditor from './scenes/Projects/ProjectEditor';

// Icons
import Duo       from '@material-ui/icons/Duo';

// INTERNATIONALIZATION
// see Translation@https://marmelab.com/react-admin/Translation.html
import i18nProvider, { browserLocale } from './admin-store/i18nProvider';
import { Login }           from '../common/components/react-admin';

// Static ROUTING
import CustomRoutes from './CustomRoutes';

// Configs
import LocalApi             from './admin-api/LocalApi';
import Projects             from './admin-api/resource-config/projects';
import ProjectMemberships   from './admin-api/resource-config/project_memberships';
import EventParticipations   from './admin-api/resource-config/event_participations';
import ContactNumbers   from './admin-api/resource-config/contact_numbers';
import ContactAddresses   from './admin-api/resource-config/contact_addresses';
import CalendarEvents       from './admin-api/resource-config/calendar_events';
import ProjectRoles       from './admin-api/resource-config/project_roles';
import People       from './admin-api/resource-config/people';
import Companies       from './admin-api/resource-config/companies';
import Vendors       from './admin-api/resource-config/verndors';
import Masters       from './admin-api/resource-config/masters';
import ProjectMasterLists       from './admin-api/resource-config/project_master_lists';
import Users       from './admin-api/resource-config/users';
import Groups       from './admin-api/resource-config/groups';
import Default       from './admin-api/resource-config/default';
// import Locations from './admin-api/resource-config/locations';
// import TreeViewTest         from './scenes/Test/TreeViewTest';
import ApiPlatformResources from '../api-platform-admin/src/api/ApiPlatformResources';
// import ProjectList          from './scenes/Projects/ProjectList';

const history = History.getHistory();


const AdminBuilder = props => {
	const {
		api,
		fieldFactory,
		inputFactory,
		resourceFactory,
		parameterFactory,
		title = api.title,
		resources = api.resources.filter(({deprecated}) => !deprecated),
		listFieldFilter,
	} = props;

	// if(typeof console === 'object') { console.log('AdminBuilder.props',props); }

	let factorizedResources = {};

	const getResources = (resources) => {

		return resources.map((resource) => {

			// if(typeof console === 'object') { console.log('resource',resource); }

			const config = configFactory();
			let conf = null;
			let rF = null;
			let hasShow = true;

			let createType = 'drawer';
			let editType = 'drawer';
			let showType = 'drawer';
			// let icon = null;

			switch(resource.name) {
				case'projects':
					conf = Projects;
					break;
				case'project_master_lists':
					conf = ProjectMasterLists;
					break;
				case'users':
					conf = Users;
					break;
				case'project_roles':
					conf = ProjectRoles;
					hasShow = false;
					break;
				case'calendar_events':
					conf = CalendarEvents;
					break;
				case'project_memberships':
					conf = ProjectMemberships;
					break;
				case'event_participations':
					conf = EventParticipations;
					break;
				case'locations':
					conf = LocalApi;
					break;
				case'groups':
					conf = Groups;
					break;
				case'people':
					conf = People;
					createType = 'modal';
					editType = 'modal';
					showType = 'modal';
					break;
				case'companies':
					conf = Companies;
					createType = 'modal';
					editType = 'modal';
					showType = 'modal';
					break;
				case'vendors':
					conf = Vendors;
					createType = 'modal';
					editType = 'modal';
					showType = 'modal';
					break;
				case'masters':
					conf = Masters;
					// createType = 'modal';
					// editType = 'modal';
					// showType = 'modal';
					break;
				case'contact_numbers':
					conf = ContactNumbers;
					break;
				case'contact_addresses':
					conf = ContactAddresses;
					break;
				default:
					conf = Default;
					hasShow = false;
			}

			if(resource.name === 'projects') {
				rF = resourceFactory(
					resource,
					api,
					fieldFactory,
					inputFactory,
					parameterFactory,
					listFieldFilter,
					configFactory(
						Object.assign(config.options,{
							listType: 'dg',
							createType: createType,
							// editType: editType,
							// showType: showType,
							editResource: ProjectEditor
						}),
						conf
					)
				)
			}
			else if(conf) {

				// if(typeof console === 'object') { console.log('hasShow?',resource,hasShow,showType); }

				rF = resourceFactory(
					resource,
					api,
					fieldFactory,
					inputFactory,
					parameterFactory,
					listFieldFilter,
					configFactory(
						Object.assign(config.options,{
							listType: 'dg',
							createType: createType,
							editType: editType,
							showType: hasShow ? showType : null,
						}),
						conf
					)
				)
			}
			else {
				rF = resourceFactory(
					resource,
					api,
					fieldFactory,
					inputFactory,
					parameterFactory,
					listFieldFilter,
					config
				);
			}



			// if(typeof console === 'object') { console.log('factorizedResources',resource.name,rF); }

			factorizedResources[resource.name] = rF;
			return rF;
		});
	};

	const getCustomRoutes = () => {
		// if(typeof console === 'object') { console.log('resourceFactories',factorizedResources,resources); }

		let CalendarEventsRosourceOptions = factorizedResources && factorizedResources['calendar_events'] ? factorizedResources['calendar_events'].props : {};
		let ProjectsRosourceOptions = factorizedResources && factorizedResources['projects'] ? factorizedResources['projects'].props : {};

		if(1===2 && typeof console === 'object') {
			console.log('CalendarEventsRosourceOptions',
				factorizedResources.length,
				factorizedResources['calendar_events'],
				// factorizedResources['calendar_events'].props,
				CalendarEventsRosourceOptions,
				ProjectsRosourceOptions
			);
		}

		let customRoutes = CustomRoutes({factorizedResources:factorizedResources});

		return props.customRoutes.concat(customRoutes);
	};

	let resourceFactories = getResources(resources);
	// if(typeof console === 'object') { console.log('resourceFactorieso',resourceFactories); }
	ApiPlatformResources.setResources(resourceFactories);

	// return (
	// 	<Provider
	// 		store={createAdminStore({
	// 			authProvider: props.authProvider,
	// 			dataProvider: props.dataProvider,
	// 			history: history
	// 		})}
	// 	>
	// 		<Admin
	// 			{...props}
	// 			dashboard={Calendar}
	// 			customRoutes={getCustomRoutes()}
	// 			loginPage={Login}
	// 			title={title}
	// 			history={history}
	// 			locale={browserLocale}
	// 			i18nProvider={i18nProvider}
	// 		>
	// 			{resourceFactories}
	// 		</Admin>
	// 	</Provider>
	// );

	return (
		<Admin
			{...props}
			authProvider={props.authProvider}
			dataProvider={props.dataProvider}
			// dashboard={Planning}
			customRoutes={getCustomRoutes()}
			customSagas={[
				// getBitcoinSaga,
				fetchAppState,
				updateAppState
			]}
			customReducers={{
				// bitcoinRate: bitcoinRateReducer,
				appstateByPHPOS: appstateByPHPOS,
				user: userReducer,
				cache: cacheReducer,
				// custom: customFetchLoad
			}}
			loginPage={Login}
			title={title}
			history={history}
			locale={browserLocale}
			i18nProvider={i18nProvider}
		>
			{resourceFactories}
		</Admin>
	);
};

AdminBuilder.defaultProps = {
	fieldFactory,
	inputFactory,
	resourceFactory,
	parameterFactory,
	// theme,
	layout: LayoutWithTheme,
	// authProvider: authProvider
};

AdminBuilder.propTypes = {
	api: PropTypes.object, // was PropTypes.instanceOf(Api).isRequired,
	fieldFactory: PropTypes.func,
	inputFactory: PropTypes.func,
	parameterFactory: PropTypes.func,
	resourceFactory: PropTypes.func,
	// authProvider: PropTypes.func,
	dataProvider: PropTypes.func.isRequired,
	resources: PropTypes.array,
};

export default AdminBuilder;
