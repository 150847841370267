import React             from 'react';
import PropTypes                           from 'prop-types';
import Dialog            from '@material-ui/core/Dialog';
import DialogActions     from '@material-ui/core/DialogActions';
import DialogContent     from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle       from '@material-ui/core/DialogTitle';
import Msg               from '../utils/Msg';
import { withTranslate }     from 'react-admin';
import Utils             from '../utils/Utils';
import MuiButton from '../components/common/MuiButton';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	dialogContent: {
		minWidth: '280px',
	}
});

class SimpleDialog extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			open: this.props.open
		};
		// Object.assign(this.state,props);

		this.handleClose = this.handleClose.bind(this);
		this.handleAction = this.handleAction.bind(this);
		this.handleClickOpen = this.handleClickOpen.bind(this);
	}

	handleClickOpen() {
		this.setState({ open: true });
	};

	handleClose() {
		this.setState({ open: false });
		const { afterClose } = this.props;

		if(typeof afterClose === 'function') {
			afterClose(this.props);
		}
	};

	handleAction(action) {
		// if(typeof console === 'object') { console.log('handleAction',action); }

		if(action.close === true) {
			this.handleClose();
		}


		if(typeof action.callback === 'function') {
			action.callback(action,this.props);
		}
	}

	alert() {
		Msg.alert(null,'Lirum, larum');
	}

	getActions() {
		const { actions, translate } = this.props;

		if(actions === true) {
			return (
				<DialogActions>
					<MuiButton text={translate('buttons.ok')} onClick={this.handleClose} color="primary" variant="contained" autoFocus />
					{/*<MuiButton text="Alert" onClick={this.alert} />*/}
				</DialogActions>
			);
		} else if(typeof actions === 'object' && actions.length > 0) {
			return (
				<DialogActions>
					{actions.map((action) => {
						if(!action.key) {
							action.key = Utils._id();
						}

						action.onClick = this.handleAction.bind(this,action);

						return <MuiButton {...action} />
					})}
					{/*<MuiButton text="Alert" onClick={this.alert} />*/}
				</DialogActions>
			);
		}
	}

	render() {

		const {
			title,
			msg,
			classes,
			disableBackdropClick
		} = this.props;

		// if(typeof console === 'object') { console.log('Alert.render this.props, this.state',this.props,this.state); }

		return (
			<Dialog
				open={this.state.open}
				onClose={this.handleClose}
				disableBackdropClick={disableBackdropClick}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
				<DialogContent
					className={classes.dialogContent}
				>
					{msg &&<DialogContentText id="alert-dialog-description">
						{msg}
					</DialogContentText>}
					{this.props.children && this.props.children}
				</DialogContent>
				{this.getActions()}
			</Dialog>
		);
	}
}

SimpleDialog.defaultProps = {
	disableBackdropClick: false
};

SimpleDialog.propTypes = {
	classes: PropTypes.object.isRequired,
	msg: PropTypes.string,
	title: PropTypes.string,
	disableBackdropClick: PropTypes.bool
};

export default withStyles(styles)(withTranslate(SimpleDialog));
