import React                                          from 'react';
import PropTypes                                      from 'prop-types';
import Create                                         from '../../../../api-platform-admin/src/Create_MVT';
import MuiDrawer                                      from '../../common/MuiDrawer';
import { AppBar, IconButton, makeStyles, Typography } from '@material-ui/core';
import Edit                                           from '../../../../api-platform-admin/src/Edit_MVT';
import Toolbar                                        from '@material-ui/core/Toolbar';
import { Close }                                      from '@material-ui/icons';
// import { withTranslate } from 'react-admin';
// import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core/styles';

const drawerWidth = 400;

let useStyles = makeStyles(function (theme) {
	return ({
		drawerContent: {
			width: drawerWidth,
			[theme.breakpoints.up('xs')]: {
				// width: '100%',
				width: '100vw'
			},
			[theme.breakpoints.up('sm')]: {
				width: drawerWidth,
				// flexShrink: 0,
			},
		},
		grow: {
			flexGrow: 1,
		},
		title: {
			display: 'none',
			[theme.breakpoints.up('sm')]: {
				display: 'block',
			},
		}
	});
});

function MuiDrawerCreator(props) {

	let styles = useStyles();
	const {
		disableBackdropClick,
		title,
		closeButton,
		isOpen,
		handleEditorClose,
		resource,
		basePath,
		redirect,
		options,
		location,
		formProps,
		initialValues
	} = props;

	// if(typeof console === 'object') { console.log('MuiDrawerEditor',props); }

	return (
		<MuiDrawer
			disableBackdropClick={disableBackdropClick}
			open={isOpen}
			anchor="right"
			onClose={handleEditorClose}
		>
			{isOpen ? (
				<React.Fragment>
					{(title || closeButton) && <AppBar
						position="static"
					><Toolbar

						className="mtv__editor--toolbar"
					>
						<Typography className={styles.title} variant="h6" noWrap>
							{title}
						</Typography>

						<div className={styles.grow} />
						{closeButton &&<IconButton
							onClick={handleEditorClose}
							aria-label="Close" color="inherit"
						>
							<Close />
						</IconButton>}
					</Toolbar></AppBar>}
					<Create
						className={styles.drawerContent}
						location={location}
						resource={resource}
						basePath={basePath}
						options={options}
						// redirect={false}
						onCancel={handleEditorClose}
						// formProps={{
						// 	redirect: redirect,
						// 	initialValues: initialValues
						// }}
						formProps={Object.assign({
							redirect: redirect,
							initialValues: initialValues
						},formProps)}
					/>
				</React.Fragment>

			) : (
				 <div className={styles.drawerContent} />
			 )}
		</MuiDrawer>
	);
};

MuiDrawerCreator.defaultProps = {
	formProps: {},
	disableBackdropClick: false
};

MuiDrawerCreator.propTypes = {
	title: PropTypes.string,
	closeButton: PropTypes.bool,
	disableBackdropClick: PropTypes.bool,
	isOpen: PropTypes.bool,
	resource: PropTypes.string,
	basePath: PropTypes.string,
	id: PropTypes.any,
	redirect: PropTypes.any,
	options: PropTypes.object,
	initialValues: PropTypes.object,
	location: PropTypes.object,
	formProps: PropTypes.object,
	handleEditorClose: PropTypes.func
};

export default MuiDrawerCreator;
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
