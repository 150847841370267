import React, { useState, useEffect, cloneElement } from 'react';
import PropTypes       from 'prop-types';
import { withTranslate } from 'react-admin';
import { makeStyles }             from '@material-ui/core/styles';
import Button                             from '@material-ui/core/Button';
import Dialog                             from '@material-ui/core/Dialog';
import DialogActions              from '@material-ui/core/DialogActions';
import DialogContent              from '@material-ui/core/DialogContent';
import DialogContentText          from '@material-ui/core/DialogContentText';
import DialogTitle                from '@material-ui/core/DialogTitle';
import { IconButton, Typography } from '@material-ui/core';
import { Close }                  from '@material-ui/icons';
import useMediaQuery              from '@material-ui/core/useMediaQuery';
import { useTheme }               from '@material-ui/core/styles';
import LoadingIndicator           from './LoadingIndicator';
import Utils from "../../utils/Utils";
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

function DraggablePaperComponent(props) {
	return (
		<Draggable handle={"#"+props['aria-labelledby']} cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

function PaperComponent(props) {
	return (
		<Paper {...props} />
	);
}

let useStyles = makeStyles(function (theme) {
	return (
		{
			dialogPaper: {
				// minHeight: '80vh',
				// maxHeight: '80vh',
				overflow: 'hidden',
			},
			paper: {
				position       : 'absolute',
				width          : props => props.width,
				backgroundColor: theme.palette.background.paper,
				border         : '2px solid #000',
				boxShadow      : theme.shadows[ 5 ],
				padding        : theme.spacing( 2, 4, 3 ),
			},
			dialogContent: {
				minHeight: '100px',
				// width          : props => props.width,
				// minHeight: '80vh',
				// maxHeight: '80vh',
				// overflow: 'hidden',
			},
			dialogTitle:{
				display: 'flex',
				alignItems: 'center',
			},
			grow: {
				flexGrow: 1,
			},
		}
	);
});

// let useDialogStyles = makeStyles(function (theme) {
// 	return (
// 		{
// 			paper: {
// 				// minHeight: '80vh',
// 				// maxHeight: '80vh',
// 				overflow: 'hidden',
// 				backgroundColor: 'red',
// 			},
// 			scrollPaper: {
// 				overflow: 'hidden',
// 				backgroundColor: 'green',
// 			},
// 			container: {
// 				overflow: 'hidden',
// 				backgroundColor: 'yellow',
// 			}
// 		}
// 	);
// });

const draggableStyle = { cursor: 'move' };
const noStyle = {};

function MuiModal(props) {

	const {
		translate,
		contentWidth,
		contentMinWidth,
		contentMaxWidth,
		maxWidth,
		open,
		scrollType,
		onClose,
		title,
		contentText,
		description,
		dialogActions,
		closeButton,
		disableBackdropClick,
		dialogContentStyle,
		// onEntered,
		defaultIsEntered,
		draggable
	} = props;

	const [isEntered, setIsEntered] = useState(defaultIsEntered);
	const [id] = useState(Utils._id());

	const classes = useStyles({width: contentWidth});
	// const dialogClasses = useDialogStyles({width: contentWidth});
	const theme = useTheme();
	// getModalStyle is not a pure function, we roll the style only on the first render
	// const [modalStyle] = React.useState(getModalStyle);

	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const descriptionElementRef = React.useRef(null);
	React.useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [open]);


	// const dialogContentStyle = {};
	let dCS = {...dialogContentStyle};
	if(contentWidth) {
		dCS.width = contentWidth;
	}

	if(contentMinWidth) {
		dCS.minWidth = contentMinWidth;
	}

	if(contentMaxWidth) {
		dCS.maxWidth = contentMaxWidth;
	}
	// if(typeof console === 'object') { console.log('props',props); }

	const onEntering = () => {
		// if(typeof console === 'object') { console.log('onEntered',a,b); }
		if(isEntered !== true) {
			setIsEntered(true);
		}
	};

	const labelledby = 'dialog-title-'+id;
	const describedby = 'dialog-description-'+id;


	return (
		<Dialog
			aria-labelledby={labelledby}
			aria-describedby={describedby}
			open={open}
			onClose={onClose}
			scroll={scrollType}
			fullScreen={fullScreen}
			disableBackdropClick={disableBackdropClick}
			PaperComponent={draggable ? DraggablePaperComponent : PaperComponent}
			// onBackdropClick={(ev) => {
			// 	if(typeof console === 'object') { console.log('onBackdropClick',ev); }
			// }}
			maxWidth={maxWidth}
			onEntering={onEntering}
		>
			{title &&<DialogTitle
				className={classes.dialogTitle}
				disableTypography={true}
				id={labelledby}
				style={draggable ? draggableStyle : noStyle}
			>
				<Typography variant="h6" noWrap>
					{title}
				</Typography>
				<div className={classes.grow} />

				{closeButton &&<IconButton
					// disableRipple={true}
					onClick={onClose}
					aria-label="Close" color="inherit"
				>
					<Close />
				</IconButton>}
			</DialogTitle>}
			<DialogContent
				className={classes.dialogContent}
				style={dCS}
				dividers={scrollType === 'paper'}
			>
				{isEntered && contentText && <DialogContentText
					id={describedby}
					ref={descriptionElementRef}
					tabIndex={-1}
				>
					{contentText}
				</DialogContentText>}
				{isEntered && props.children}
				{!isEntered && <LoadingIndicator />}
				{/*{cloneElement(props.children,{className: styles.drawerContent})}*/}
			</DialogContent>
			{dialogActions && <DialogActions>
				{closeButton && <Button onClick={onClose}>
					{translate('buttons.cancel')}
				</Button>}
				{dialogActions}
			</DialogActions>}
		</Dialog>
	);
}

MuiModal.defaultProps = {
	// width: 320,
	draggable: false,
	defaultIsEntered: null,
	maxWidth: 'sm',
	dialogContentStyle: {},
	minWidth: 320,
	scrollType: 'paper',
	disableBackdropClick: false,
};


MuiModal.propTypes = {
	dialogContentStyle: PropTypes.object,
	contentWidth: PropTypes.any,
	defaultIsEntered: PropTypes.any,
	contentMinWidth: PropTypes.any,
	contentMaxWidth: PropTypes.any,
	title: PropTypes.any,
	scrollType: PropTypes.string,
	contentText: PropTypes.string,
	closeButton: PropTypes.bool,
	disableBackdropClick: PropTypes.bool,
	open: PropTypes.bool,
	draggable: PropTypes.bool,
	onClose: PropTypes.func
};

export default withTranslate(MuiModal);
// export default withStyles(styles)(withTranslate(ComponentTemplate));
// export default withTranslate(ComponentTemplate);
// export default connect(null,null)(withTranslate(ComponentTemplate));
